import React, {Component} from 'react';
import Axios from 'axios';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import StrictModeDroppable from '../Vendors/StrictModeDroppable';
import withRouter from '../Functions/WithRouter';

const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';
const dragIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/drag-icon.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const skillsIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png";

const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  console.log('show me result', result)
  // for (let i = 1; i <= result.length; i++) {
  //   console.log(i);
  //
  //   result[i - 1]['rank'] = i
  //
  // }

  return result;
};

const grid = 5;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? getComputedStyle(document.documentElement).getPropertyValue('--primaryColor') : 'white',
  border: '1px solid ' + getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),
  color: isDragging ? 'white' : getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F5F5F5' : 'transparent',
  padding: grid,
  width: null,
});

class TakeAssessment extends Component {
    constructor(props) {
        super(props)

        this.state = {
          emailId: null,
          username: '',
          cuFirstName: '',
          cuLastName: '',

          questions: [' '],
          descriptions: [],
          categories: [' '],
          questionIndex: 0,
          curentQuestion: '',
          option1: '',
          option2: '',
          option3: '',
          option4: '',
          option5: '',
          option1Disabled: false,
          option2Disabled: false,
          option3Disabled: false,
          option4Disabled: false,
          option5Disabled: false,
          responses: '',//'123111111111111111111111111111111111111111111111111111111125',
          optionSelected: '0',
          wpResponses: [],

          assessments: [],
          index: 0,

          answerTypes: [],
          answerChoices: [],

          gravitateValues: [],
          employerValues: [],
          topValues: [],

          pathwayOptions: [],

          done: false,
          type: 'Interest Assessment',

          animating: false,

          interestScoreData: null,
          careerMatchData: null,
          interestsData: {},
          skillsData: [],
          skillsAnswers: '',
          personalityData: [],
          resultsData: [null, null, null, null]
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)

        this.option1Clicked = this.option1Clicked.bind(this)
        this.option2Clicked = this.option2Clicked.bind(this)
        this.option3Clicked = this.option3Clicked.bind(this)
        this.option4Clicked = this.option4Clicked.bind(this)
        this.option5Clicked = this.option5Clicked.bind(this)
        this.renderAnswerChoices = this.renderAnswerChoices.bind(this)
        this.getResults = this.getResults.bind(this)

        this.nextQuestion = this.nextQuestion.bind(this)
        this.previousQuestion = this.previousQuestion.bind(this)

        this.onDragEnd = this.onDragEnd.bind(this)

        this.renderQuestions = this.renderQuestions.bind(this)
        this.itemClicked = this.itemClicked.bind(this)
        this.submitAssessment = this.submitAssessment.bind(this)

        this.renderTaggingAssessment = this.renderTaggingAssessment.bind(this)
        this.addItem = this.addItem.bind(this)
        this.removeItem = this.removeItem.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.searchCompetencies = this.searchCompetencies.bind(this)
        this.competencyClicked = this.competencyClicked.bind(this)
        this.pullRecommendationOptions = this.pullRecommendationOptions.bind(this)
        this.addToSkills = this.addToSkills.bind(this)

    }

    onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      console.log('show result: ', result)

      if (this.state.type === 'values') {

        if (result.destination.droppableId === 'gravitate') {
          const topGravitateValues = reorder(
            this.state.topGravitateValues,
            result.source.index,
            result.destination.index
          );

          this.setState({ topGravitateValues });
        } else if (result.destination.droppableId === 'employer') {
          const topEmployerValues = reorder(
            this.state.topEmployerValues,
            result.source.index,
            result.destination.index
          );

          this.setState({ topEmployerValues });
        }
      } else {

        let index = 0
        if (result.destination.droppableId && result.destination.droppableId.split('|')) {
          index = Number(result.destination.droppableId.split('|')[1])
        }

        if (this.state.questions[index].includes('technology trends')) {
          const technologyTrends = reorder(
            this.state.technologyTrends,
            result.source.index,
            result.destination.index
          );

          let wpResponses = this.state.wpResponses
          wpResponses[index] = technologyTrends

          this.setState({ technologyTrends, wpResponses });
        } else if (this.state.questions[index].includes('societal problems')) {
          const societalProblems = reorder(
            this.state.societalProblems,
            result.source.index,
            result.destination.index
          );

          let wpResponses = this.state.wpResponses
          wpResponses[index] = societalProblems

          this.setState({ societalProblems, wpResponses });
        }
      }
    }

    componentDidMount(){
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.index !== prevProps.index) {
        this.retrieveData()
      } else if (this.props.assessment !== prevProps.assessment) {
        this.retrieveData()
      } else if (this.props.resultsData !== prevProps.resultsData) {
        this.retrieveData()
      }
     }

    retrieveData(){
      console.log('retrieveData called in SubTakeAssessment', this.props.resultsData)

      let assessmentTitle = this.props.assessmentTitle
      let type = this.props.type
      const assessments = this.props.assessments
      const index = this.props.index
      const assessment = this.props.assessment
      let resultsData = this.props.resultsData
      const assessmentDescription = this.props.assessmentDescription

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let pathway = localStorage.getItem('pathway');
      let remoteAuth = localStorage.getItem('remoteAuth');

      Axios.get('/api/org', { params: { orgCode: activeOrg } })
      .then((response) => {
        console.log('Org info query attempted');

        if (response.data.success) {
          console.log('org info query worked!')

          let placementPartners = null
          if (response.data.orgInfo.placementPartners) {
            placementPartners = response.data.orgInfo.placementPartners

          }
          let orgContactEmail = response.data.orgInfo.orgContactEmail

          this.setState({ placementPartners, orgContactEmail })
        }
      })

      this.setState({ emailId: email, username, cuFirstName, cuLastName, activeOrg, orgFocus, assessmentTitle,
        assessmentDescription, type, assessments, index, assessment, resultsData, remoteAuth })

      Axios.get('/api/assessment/questions')
      .then((response) => {
          //console.log('O*Net worked', response.data.body, this);
          console.log('show me sub response', response.data)
          if ( response.data.success === true ) {
            console.log('sub ass query worked', type)

            let questions = []
            let categories = []
            let functions = []
            let option1: ''
            let option2: ''
            let option3: ''
            let option4: ''
            let option5: ''

            let answerTypes = []
            let answerChoices = []

            let realTechSkillsLength = 0
            let otherLength = 0

            let descriptions = []

            let gravitateValues = []
            let employerValues = []

            if (type === 'work preferences') {

              for (let i = 1; i <= response.data.assessments.workPreferenceQuestions.length; i++) {
                console.log('show at: ', response.data.assessments.workPreferenceQuestions[i - 1].answerType)

                if ((window.innerWidth <= 768 && response.data.assessments.workPreferenceQuestions[i - 1].answerType === 'Ranking') || (window.location.pathname.includes('/apply') && response.data.assessments.workPreferenceQuestions[i - 1].answerType === 'Ranking')) {
                  // skip until we have a solution
                } else {
                  questions.push(response.data.assessments.workPreferenceQuestions[i - 1].name)
                  answerTypes.push(response.data.assessments.workPreferenceQuestions[i - 1].answerType)
                  answerChoices.push(response.data.assessments.workPreferenceQuestions[i - 1].answerChoices)
                }
              }

              //temporary - DELETE
              // questions.push('Rank your interest in the following macro-technology trends that may substantially disrupt the labor market. You may consider working on related projects and joining companies that have placed bets in these areas:')
              // answerTypes.push('Ranking')
              // answerChoices.push(['technologyTrends'])
              //
              // questions.push('Rank your interest in solving the following macro-societal problems. You may consider working on related projects and joining companies looking to solve these problems:')
              // answerTypes.push('Ranking')
              // answerChoices.push(['societalProblems'])

              //console.log('gut check', answerTypes, answerChoices)

            } else if (type === 'interests') {
              //questions = response.data.assessments.interestQuestions
              // questions = ['Realistic (The Do-Er)', 'Investigative (The Thinker)', 'Artistic (The Creator)',
              // 'Social (The Helper)', 'Enterprising (The Persuader)', 'Conventional (The Organizer)'
              // ]
              questions = ['How much do you agree that the below description describes you?', 'How much do you agree that the below description describes you?',
                'How much do you agree that the below description describes you?', 'How much do you agree that the below description describes you?',
                'How much do you agree that the below description describes you?','How much do you agree that the below description describes you?'
              ]
              descriptions = [
                'You like to work with things.\n\nYou tend to be assertive and competitive, and are interested in activities requiring motor coordination, skill and strength.\n\nYou approach problem solving by doing something, rather than talking about it, or sitting and thinking about it.\n\nYou also prefer concrete approaches to problem solving, rather than abstract theory.\n\nFinally, your interests tend to focus on scientific or mechanical rather than cultural and aesthetic areas.',
                'You tend to prefer to work with data.\n\nYou like to think and observe rather than act, to organize and understand information rather than to persuade.\n\nYou also prefer individual rather than people oriented activities.',
                'You like to work with ideas and things.\n\nYou tend to be creative, open, inventive, original, perceptive, sensitive, independent and emotional. You rebel against structure and rules, but enjoy tasks involving people or physical skills.\n\nYou tend to be more emotional than the others.',
                'You like to work with people and seem to satisfy their needs in teaching or helping situations.\n\nYou tend to be drawn more to seek close relationships with other people and are less apt to want to be really intellectual or physical.',
                'You like to work with people and data.\n\nYou are likely a good talker, and use this skill to lead or persuade others.\n\nYou also value reputation, power, money and status.',
                'You prefer to work with data and you like rules and regulations.\n\n You emphasize self-control, and you like structure and order.\n\n You dislike unstructured or unclear work and unclear interpersonal situations.\n\nYou also place value on reputation, power, or status.'
              ]
              option1 = 'Strongly Disagree'
              option2 = 'Disagree'
              option3 = 'Unsure'
              option4 = 'Agree'
              option5 = 'Strongly Agree'
              type = 'interests'
            } else if (type === 'skills') {

              console.log('about to count skill assessment', activeOrg)

              if (this.props.skillQuestions) {
                console.log('check 2', this.props.skillQuestions)

                const skills = this.props.skillQuestions

                // should be
                for (let i = 1; i <= skills.length; i++) {
                  if (skills[i - 1].title) {
                    questions.push(skills[i - 1].title)
                  } else if (skills[i - 1].name) {
                    questions.push(skills[i - 1].name)
                  }

                  categories.push(skills[i - 1].skillType)
                  functions.push(skills[i - 1].skillType)
                  descriptions.push(skills[i - 1].description)
                }

                option1 = '10th Percentile (Not Skilled)'
                option2 = '30th Percentile'
                option3 = '50th Percentile (Average Skilled)'
                option4 = '70th Percentile'
                option5 = '90th Percentile (Highly Skilled)'
                type = 'skills'

                // console.log('skills: ', skills, questions, categories, functions)
                const currentQuestion = questions[0]
                realTechSkillsLength = questions.length
                // console.log('show questions 12: ', questions)

                this.setState({
                  questions, categories, functions, currentQuestion,
                  option1, option2, option3, option4, option5, type,
                  answerTypes, answerChoices,
                  realTechSkillsLength, otherLength, descriptions
                })

              }

              this.pullRecommendationOptions(activeOrg, null, pathway)
              // const orgCode = activeOrg
              // //questions are from benchmark
              // Axios.get('/api/benchmarks/skill-assessment', { params: { orgCode } })
              // .then((response) => {
              //
              //   if (response.data.success) {
              //     console.log('Benchmark query worked', response.data);
              //
              //     let skills = []
              //     let rawSkills = []
              //
              //     for (let i = 1; i <= response.data.benchmarks.length; i++) {
              //
              //       if (activeOrg === 'dpscd') {
              //         // console.log('inners 1', response.data.benchmarks[i - 1].pathway, pathway)
              //         if (response.data.benchmarks[i - 1].pathway === pathway) {
              //
              //           let tempSkills = response.data.benchmarks[i - 1].skills
              //           if (tempSkills && tempSkills.length > 0) {
              //             // console.log('inners 2')
              //             for (let j = 1; j <= tempSkills.length; j++) {
              //               if (tempSkills[j - 1].skillType !== 'Trait') {
              //                 if (!rawSkills.includes(tempSkills[j - 1].title)) {
              //                  skills.push({ name: tempSkills[j - 1].title, skillType: tempSkills[j - 1].skillType, benchmarkTitle: response.data.benchmarks[i - 1].title, benchmarkFunction: response.data.benchmarks[i - 1].jobFunction })
              //                  rawSkills.push(tempSkills[j - 1].title)
              //                 } else {
              //                   console.log('not include')
              //                 }
              //               }
              //             }
              //           }
              //         }
              //       } else {
              //         let tempSkills = response.data.benchmarks[i - 1].skills
              //         if (tempSkills && tempSkills.length > 0) {
              //           for (let j = 1; j <= tempSkills.length; j++) {
              //             if (tempSkills[j - 1].skillType !== 'Trait') {
              //               if (!rawSkills.includes(tempSkills[j - 1].title)) {
              //                skills.push({ name: tempSkills[j - 1].title, skillType: tempSkills[j - 1].skillType, benchmarkTitle: response.data.benchmarks[i - 1].title, benchmarkFunction: response.data.benchmarks[i - 1].jobFunction })
              //                rawSkills.push(tempSkills[j - 1].title)
              //               } else {
              //                 console.log('not include')
              //               }
              //             }
              //           }
              //         }
              //       }
              //     }
              //
              //     skills.sort(function(a,b) {
              //       return b.benchmarkFunction - a.benchmarkFunction;
              //     })
              //
              //     console.log('record skills length: ', skills.length)
              //
              //     for (let i = 1; i <= skills.length; i++) {
              //       questions.push(skills[i - 1].name)
              //       categories.push(skills[i - 1].benchmarkFunction)
              //       functions.push(skills[i - 1].benchmarkFunction)
              //       descriptions.push(skills[i - 1].description)
              //     }
              //
              //     option1 = '10th Percentile (Not Skilled)'
              //     option2 = '30th Percentile'
              //     option3 = '50th Percentile (Average Skilled)'
              //     option4 = '70th Percentile'
              //     option5 = '90th Percentile (Highly Skilled)'
              //     type = 'skills'
              //
              //     console.log('skills: ', skills, questions, categories, functions, this.state.responses)
              //     const currentQuestion = questions[0]
              //     realTechSkillsLength = questions.length
              //
              //     this.setState({
              //       questions, categories, functions, currentQuestion,
              //       option1, option2, option3, option4, option5, type,
              //       answerTypes, answerChoices,
              //       realTechSkillsLength, otherLength, descriptions
              //     })
              //
              //   } else {
              //     console.log('no benchmarks found', response.data.message)
              //
              //   }
              //
              // }).catch((error) => {
              //     console.log('Benchmark query did not work', error);
              // });

              // else if (activeOrg === 'c2c' || activeOrg === 'block' || activeOrg === 'dpscd') {
              //
              //
              // } else {
              //
              //   let generalSkills = response.data.assessments.generalSkills
              //   let socialSkills = response.data.assessments.socialSkills
              //   let techSkills = response.data.assessments.techSkills
              //
              //   /*
              //   let techSkills = [
              //     { name: 'How good are you at picking up and using tech tools efficiently (e.g. Microsoft Excel)?', category: 'Tech Tools'},
              //     { name: 'How good are you at programming with HTML, Javascript, or some other language', category: 'Programming Languages'}
              //   ]*/
              //   realTechSkillsLength = response.data.assessments.techSkills.length
              //   otherLength = generalSkills.length + socialSkills.length
              //   let questionCount = generalSkills.length + socialSkills.length + techSkills.length
              //   console.log('what does count look like', questionCount, generalSkills.length, techSkills.length)
              //
              //   let techSkillsArray = []
              //
              //   for (let i = 1; i <= questionCount; i++) {
              //     console.log('loop questions', i)
              //
              //     if (i <= generalSkills.length) {
              //       categories.push('General Skills')
              //       functions.push("")
              //     } else if (i <= generalSkills.length + socialSkills.length) {
              //       categories.push('Social Skills')
              //       functions.push("")
              //     } else {
              //       categories.push('Technical Skills')
              //       techSkillsArray.push(techSkills[i - 1 - generalSkills.length - socialSkills.length].name)
              //       functions.push(techSkills[i - 1 - generalSkills.length - socialSkills.length].function)
              //       console.log('let me see the function', techSkills[i - 1  - generalSkills.length - socialSkills.length].function)
              //     }
              //   }
              //   questions = generalSkills.concat(socialSkills, techSkillsArray)
              //
              //   option1 = '10th Percentile (Not Skilled)'
              //   option2 = '30th Percentile'
              //   option3 = '50th Percentile (Average Skilled)'
              //   option4 = '70th Percentile'
              //   option5 = '90th Percentile (Highly Skilled)'
              //   type = 'skills'
              // }

            } else if (type === 'personality') {
              let mbQuestions = response.data.assessments.mbQuestions
              let fiveFactorsQuestions = response.data.assessments.fiveFactorsQuestions

              const cultureQuestions= response.data.assessments.cultureQuestions
              const disorderQuestions= response.data.assessments.disorderQuestions

              questions = mbQuestions.concat(fiveFactorsQuestions, cultureQuestions, disorderQuestions)
              option1 = 'Strongly Disagree'
              option2 = 'Disagree'
              option3 = 'Unsure'
              option4 = 'Agree'
              option5 = 'Strongly Agree'
              type = 'personality'
            } else if (type === 'values') {

              gravitateValues = response.data.assessments.gravitateValues
              employerValues = response.data.assessments.employerValues
              questions = ['','','','']
            }

            console.log('show answer choices', answerChoices, questions, this.state.questionIndex, type)
            this.setState({
                questions:  questions,
                categories: categories,
                functions: functions,
                currentQuestion: questions[0],
                option1: option1,
                option2: option2,
                option3: option3,
                option4: option4,
                option5: option5,
                type: type,
                answerTypes, answerChoices,

                realTechSkillsLength, otherLength, descriptions,

                gravitateValues, employerValues

            })

            Axios.get('/api/workoptions')
            .then((response) => {
              console.log('Work options query tried', response.data);

              if (response.data.success) {
                console.log('Work options query succeeded')

                let functionOptions = []
                for (let i = 1; i <= response.data.workOptions[0].functionOptions.length; i++) {
                  if (i > 1) {
                    functionOptions.push(response.data.workOptions[0].functionOptions[i - 1])
                  }
                }

                let industryOptions = []
                for (let i = 1; i <= response.data.workOptions[0].industryOptions.length; i++) {
                  if (i > 1) {
                    industryOptions.push(response.data.workOptions[0].industryOptions[i - 1])
                  }
                }

                let hoursPerWeekOptions = []
                for (let i = 1; i <= response.data.workOptions[0].hoursPerWeekOptions.length; i++) {
                  if (i > 1) {
                    hoursPerWeekOptions.push(response.data.workOptions[0].hoursPerWeekOptions[i - 1])
                  }
                }

                let annualPayOptions = []
                for (let i = 1; i <= response.data.workOptions[0].annualPayOptions.length; i++) {
                  if (i > 1) {
                    annualPayOptions.push(response.data.workOptions[0].annualPayOptions[i - 1])
                  }
                }

                let employeeCountOptions = []
                for (let i = 1; i <= response.data.workOptions[0].employeeCountOptions.length; i++) {
                  if (i > 1) {
                    employeeCountOptions.push(response.data.workOptions[0].employeeCountOptions[i - 1])
                  }
                }

                let employerTypeOptions = []
                for (let i = 1; i <= response.data.workOptions[0].employerTypeOptions.length; i++) {
                  if (i > 1) {
                    employerTypeOptions.push(response.data.workOptions[0].employerTypeOptions[i - 1])
                  }
                }

                let workDistanceOptions = []
                for (let i = 1; i <= response.data.workOptions[0].workDistanceOptions.length; i++) {

                  if (i > 1) {
                    workDistanceOptions.push(response.data.workOptions[0].workDistanceOptions[i - 1])
                  }
                }

                let workStyleOptions = []
                for (let i = 1; i <= response.data.workOptions[0].workStyleOptions.length; i++) {
                  if (i > 1) {
                    workStyleOptions.push(response.data.workOptions[0].workStyleOptions[i - 1])
                  }
                }

                const technologyTrends = response.data.workOptions[0].technologyTrends
                const societalProblems = response.data.workOptions[0].societalProblems

                for (let i = 1; i <= answerChoices.length; i++) {
                  let ac = answerChoices[i - 1]
                  console.log('looping through acs: ', ac)
                  if (ac) {
                    if (ac[0] === 'employeeCountOptions') {
                      ac = employeeCountOptions
                    }

                    if (ac[0] === 'typeOptions') {
                      ac = employerTypeOptions
                      console.log('typeOptions: ', employerTypeOptions)
                    }

                    if (ac[0] === 'hourOptions') {
                      ac = hoursPerWeekOptions
                    }

                    if (ac[0] === 'payOptions') {
                      ac = annualPayOptions
                    }

                    if (ac[0] === 'functionOptions') {
                      ac = functionOptions
                    }

                    if (ac[0] === 'industryOptions') {
                      ac = industryOptions
                    }

                    console.log('log work distance ', i, ac[0], ac, workDistanceOptions)
                    if (ac[0] === 'workDistanceOptions') {
                      ac = workDistanceOptions
                    }

                    if (ac[0] === 'workStyleOptions') {
                      ac = workStyleOptions
                    }

                    if (ac[0] === 'technologyTrends') {
                      ac = technologyTrends
                    }

                    if (ac[0] === 'societalProblems') {
                      ac = societalProblems
                    }

                    answerChoices[i - 1] = ac
                  }
                }

                this.setState({ answerChoices, technologyTrends, societalProblems })

                Axios.get('/api/assessment/results', { params: { emailId: email } })
                .then((response) => {
                    console.log('query for assessment results worked on load');

                    if (response.data.success) {

                      console.log('actual assessment results', response.data)

                      let responses = ''
                      let wpResponses = []
                      let optionSelected = '0'
                      let ogSkillAnswers = []
                      console.log('testing 3', this.state.type)

                      if (type === 'work preferences') {

                        // wpResponses = response.data.results.workPreferenceAnswers

                        let tempWpResponses = response.data.results.workPreferenceAnswers

                        if (tempWpResponses && tempWpResponses.length) {
                          for (let i = 1; i <= tempWpResponses.length; i++) {
                            console.log('counter 1: ', i)
                            if (tempWpResponses[i - 1][0] === '[') {
                              console.log('counter 2: ', i)
                              const removedArrayEnds = tempWpResponses[i - 1].substring(1,tempWpResponses[i - 1].length - 1).replace(/"/g,"").replace(/"/g,"")
                              let convertedArray = removedArrayEnds.split(",")

                              if (i === 1|| i === 2) {
                                // convertedArray = removedArrayEnds.split(",")
                                // convertedArray = removedArrayEnds.match(/(".*?"|[^\s",][^",]+[^\s",])(?=\s*,|\s*$)/g);
                                console.log('counter 3: ', i)

                                convertedArray = []

                                let options = []
                                if (i === 1) {
                                  options = functionOptions // function question
                                } else if (i === 2) {
                                  options = industryOptions // industry question
                                }
                                console.log('show options: ', options)
                                for (let j = 1; j <= options.length; j++) {
                                  console.log('compare: ', tempWpResponses[i - 1])
                                  if (options[j - 1] && options[j - 1] !== '' && tempWpResponses[i - 1].includes(options[j - 1])) {
                                    convertedArray.push(options[j - 1])
                                  }
                                }
                                // console.log('yeah?', convertedArray)
                                //var arr = str.match(/(".*?"|[^\s",][^",]+[^\s",])(?=\s*,|\s*$)/g);

                                // "["Performing and EntertainingInstalling, Repairing, and Maintenance"]"
                              }

                              // console.log('show convertedArray: ', convertedArray)

                              wpResponses.push(convertedArray)
                              //wpResponses.push(tempWpResponses[i - 1])
                            } else {

                              console.log('show string: ', tempWpResponses[i - 1])
                              let wpResponse = tempWpResponses[i - 1]
                              if (wpResponse) {
                                if (i === 7) {

                                  // wpResponse = wpResponse.split(" ")[0] + ' miles'
                                  // if (wpResponse.split(" ")[0].length === 3) {
                                  //   wpResponse = wpResponse.substring(1,3) + ' miles'
                                  // }
                                  wpResponse = wpResponse.replace(/\"/g, "")

                                } else if (i === 4 || i === 5 || i === 6){

                                  wpResponse = wpResponse.replace(/\\/g, "")
                                  wpResponse = wpResponse.replace(/\"/g, "")
                                  // wpResponse = wpResponse.replace(/\\/g, "")
                                  console.log('show the annoying response: ', wpResponse)

                                }
                              }

                              wpResponses.push(wpResponse)
                            }
                          }
                        }
                      }

                      if (type === 'interests' && response.data.results && response.data.results.interestAnswers) {

                        let interestAnswers = response.data.results.interestAnswers
                        responses = interestAnswers[0] + interestAnswers[2] + interestAnswers[4] + interestAnswers[6] + interestAnswers[8] + interestAnswers[10]
                        optionSelected = responses[0]
                        console.log('lets see interest responses ', responses, optionSelected)
                      }

                      if (type === 'skills' && response.data.results && response.data.results.newSkillAnswers && response.data.results.newSkillAnswers.length > 0) {
                        let skillQuestions = this.props.skillQuestions
                        ogSkillAnswers = response.data.results.newSkillAnswers

                        responses = ''

                        if (skillQuestions) {
                          for (let i = 1; i <= skillQuestions.length; i++) {
                            if (ogSkillAnswers.some(sa => sa.name === skillQuestions[i - 1].name)) {
                              console.log('compare the questions 1: ', skillQuestions[i - 1])
                              const answerIndex = ogSkillAnswers.findIndex(sa => sa.name === skillQuestions[i - 1].name);
                              responses = responses + ogSkillAnswers[answerIndex].score
                            } else if (ogSkillAnswers.some(sa => sa.name === skillQuestions[i - 1].title)) {
                              const answerIndex = ogSkillAnswers.findIndex(sa => sa.name === skillQuestions[i - 1].title);
                              console.log('compare the questions 2: ', answerIndex, ogSkillAnswers)
                              responses = responses + ogSkillAnswers[answerIndex].score
                            } else {
                              responses = responses + '0'
                            }
                          }
                        }

                        console.log('show totalAnswers: ', responses, skillQuestions, ogSkillAnswers, response.data.results.skillsAnswers)

                        // responses = response.data.results.skillsAnswers
                        optionSelected = responses[0]

                        // refresh the data
                        resultsData[3] = response.data.results.newSkillAnswers
                      } else if (type === 'skills' && response.data.results.skillsAnswers) {
                        responses = response.data.results.skillsAnswers
                        optionSelected = responses[0]
                      }
                      console.log('t3: ', responses)
                      if (type === 'personality' && response.data.results.personalityAnswers) {
                        responses = response.data.results.personalityAnswers
                        optionSelected = responses[0]
                      }
                      console.log('t4: ', responses)
                      let topValues = this.state.topValues
                      let topGravitateValues = response.data.results.topGravitateValues
                      let topEmployerValues = response.data.results.topEmployerValues
                      if (type === 'values' && response.data.results.topGravitateValues && topEmployerValues) {
                        responses = response.data.results.topGravitateValues.concat(topEmployerValues)
                        topValues = response.data.results.topGravitateValues
                      }


                      this.setState({
                        resultsId: response.data.results._id, responses, optionSelected, wpResponses,
                        topValues, topGravitateValues, topEmployerValues,
                        ogSkillAnswers, resultsData
                      });

                    } else {
                      console.log('error response for assessments', response.data)
                      // this.setState({ resultsErrorMessage: response.data.message })
                    }

                }).catch((error) => {
                    console.log('query for assessment results did not work', error);
                })


              } else {
                console.log('no work options data found', response.data.message)

              }
            }).catch((error) => {
                console.log('query for work options did not work', error);
            })
          } else {
            console.log('Assessment question query failed', response.data.message)
          }


      }).catch((error) => {
          console.log('Assessment query did not work', error);
      });
    }

    pullRecommendationOptions(orgCode, pathways, pathway) {
      console.log('pullRecommendationOptions called', orgCode, pathways, pathway)

      // exceptions w/ benchmarks: dpscd, unite-la?
      orgCode = 'general'

      Axios.get('/api/benchmarks', { params: { orgCode } })
      .then((response) => {

        if (response.data.success) {
          console.log('Benchmark query worked', response.data);

          let pathwayOptions = [{ value: '' }]
          let values = []
          for (let i = 1; i <= response.data.benchmarks.length; i++) {

            let value = response.data.benchmarks[i - 1].jobFunction

            let meetsCriteria = false
            if (pathways) {
              value = response.data.benchmarks[i - 1].pathway
              for (let j = 1; j <= pathways.length; j++) {
                if (pathways[j - 1]) {
                  let pathwayName = pathways[j - 1].name
                  if (pathways[j - 1].school) {
                    pathwayName = pathwayName + ' | ' + pathways[j - 1].school
                  }

                  if ((pathwayName === value) && !values.includes(value)) {
                    meetsCriteria = true
                  }
                }
              }

            } else if (!values.includes(value)) {
              meetsCriteria = true
            }

            if (meetsCriteria) {
              values.push(value)

              if (response.data.benchmarks[i - 1].competencies) {
                const competencies = response.data.benchmarks[i - 1].competencies
                pathwayOptions.push({ value, competencies })
              } else {
                let skills = response.data.benchmarks[i - 1].skills
                let skillObjects = []

                for (let j = 1; j <= skills.length; j++) {
                  if (skills[j - 1].title && skills[j - 1].skillType) {
                    const name = skills[j - 1].title
                    const skillType = skills[j - 1].skillType
                    skillObjects.push({ name, skillType })

                  }
                }

                pathwayOptions.push({ value, skills: skillObjects })
              }
            }
          }

          this.setState({ pathwayOptions })

        } else {
          console.log('no benchmarks found', response.data.message)

        }

      }).catch((error) => {
          console.log('Benchmark query did not work', error);
      });
    }

    formChangeHandler(event) {
      console.log('formChangeHandler called', event )

      let wpResponses = this.state.wpResponses
      if (event.target.name === 'shortResponse') {

        if (event.target.value === '') {
          wpResponses[this.state.questionIndex] = null
          this.setState({ shortResponse: event.target.value, wpResponses })
        } else {
          wpResponses[this.state.questionIndex] = event.target.value
          this.setState({ shortResponse: event.target.value, wpResponses })
        }
      } else if (event.target.name.includes('shortResponse')) {
        const index = Number(event.target.name.split('|')[1])
        if (event.target.value === '') {
          wpResponses[index] = null
          this.setState({ wpResponses })
        } else {
          wpResponses[index] = event.target.value
          this.setState({ wpResponses })
        }
      } else if (event.target.name === 'longResponse') {

        if (event.target.value === '') {
          wpResponses[this.state.questionIndex] = null
          this.setState({ longResponse: event.target.value, wpResponses })
        } else {
          wpResponses[this.state.questionIndex] = event.target.value
          this.setState({ longResponse: event.target.value, wpResponses })
        }
      } else if (event.target.name.includes('longResponse')) {
        const index = Number(event.target.name.split('|')[1])
        if (event.target.value === '') {
          wpResponses[index] = null
          this.setState({ wpResponses })
        } else {
          wpResponses[index] = event.target.value
          this.setState({ wpResponses })
        }
      } else if (event.target.name === 'multipleChoice') {
        wpResponses[this.state.questionIndex] = event.target.value
        this.setState({ multipleChoice: event.target.value, wpResponses })
      } else if (event.target.name.includes('multipleChoice')) {

        const index = Number(event.target.name.split('|')[1])
        if (event.target.value === '') {
          wpResponses[index] = null
          this.setState({ wpResponses })
        } else {
          wpResponses[index] = event.target.value
          this.setState({ wpResponses })
        }

      } else if (event.target.name === 'multipleAnswer') {
        // wpResponses[this.state.questionIndex] = event.target.value
        // this.setState({ multipleChoice: event.target.value, wpResponses })

        if (this.state.type === 'values') {

          let topValues = this.state.topValues
          if (topValues.includes(event.target.value)) {
            let index = topValues.indexOf(event.target.value);
            if (index > -1) {
              topValues.splice(index, 1);
            }
          } else {

            if (topValues.length < 10) {
              topValues.push(event.target.value)
            } else {
              // limiting to 10 values
            }
          }

          this.setState({ multipleAnswer: topValues, topValues })

        } else {
          let thisResponseArray = wpResponses[this.state.questionIndex]
          if (Array.isArray(thisResponseArray)) {
            if (thisResponseArray.includes(event.target.value)) {
              let index = thisResponseArray.indexOf(event.target.value);
              if (index > -1) {
                thisResponseArray.splice(index, 1);
              }
            } else {

              thisResponseArray.push(event.target.value)

            }
          } else {
            thisResponseArray = []
            thisResponseArray.push(event.target.value)
          }

          wpResponses[this.state.questionIndex] = thisResponseArray
          this.setState({ multipleAnswer: thisResponseArray, wpResponses })
        }
      } else if (event.target.name.includes('multipleAnswer')) {
        // wpResponses[this.state.questionIndex] = event.target.value
        // this.setState({ multipleChoice: event.target.value, wpResponses })

        if (this.state.type === 'values') {

          let topGravitateValues = this.state.topGravitateValues
          let topEmployerValues = this.state.topEmployerValues

          let index = Number(event.target.name.split("|")[1])
          if (index === 0) {
            console.log('index is 0', event.target.value)
            if (topGravitateValues && topGravitateValues.includes(event.target.value)) {
              let index = topGravitateValues.indexOf(event.target.value);
              if (index > -1) {
                topGravitateValues.splice(index, 1);
              }
            } else {

              if (!topGravitateValues) {
                topGravitateValues = [event.target.value]
              } else if (topGravitateValues.length < 10) {
                console.log('adding to gravitateValues')
                topGravitateValues.push(event.target.value)
              } else {
                // limiting to 10 values
              }
            }
          } else {
            console.log('index is not 0')
            if (topEmployerValues && topEmployerValues.includes(event.target.value)) {
              let index = topEmployerValues.indexOf(event.target.value);
              if (index > -1) {
                topEmployerValues.splice(index, 1);
              }
            } else {

              if (!topEmployerValues) {
                topEmployerValues = [event.target.value]
              } else if (topEmployerValues.length < 10) {
                topEmployerValues.push(event.target.value)
              } else {
                // limiting to 10 values
              }
            }
          }
          console.log('show topGravitateValues: ', topGravitateValues)
          this.setState({ topGravitateValues, topEmployerValues })

        } else {
          const firstIndex = Number(event.target.name.split('|')[1])
          let thisResponseArray = wpResponses[firstIndex]
          if (Array.isArray(thisResponseArray)) {
            if (thisResponseArray.includes(event.target.value)) {
              let index = thisResponseArray.indexOf(event.target.value);
              if (index > -1) {
                thisResponseArray.splice(index, 1);
              }
            } else {

              thisResponseArray.push(event.target.value)

            }
          } else {
            thisResponseArray = []
            thisResponseArray.push(event.target.value)
          }

          wpResponses[firstIndex] = thisResponseArray
          this.setState({ wpResponses })
        }
      } else if (event.target.name === 'boolean') {
        wpResponses[this.state.questionIndex] = event.target.value
        this.setState({ boolean: event.target.value, wpResponses })
      } else if (event.target.name === 'pathway') {
        let selectedPathway = event.target.value

        let skills = []
        let competencies = []
        let questions = []
        let categories = []
        for (let i = 1; i <= this.state.pathwayOptions.length; i++) {
          if (this.state.pathwayOptions[i - 1].value === selectedPathway) {
            skills = this.state.pathwayOptions[i - 1].skills
            competencies = this.state.pathwayOptions[i - 1].competencies
          }
        }

        let responses = ''
        if (skills && skills.length > 0){
          if (skills.length !== 0) {
            for (let i = 1; i <= skills.length; i++) {
              if (skills[i - 1].skillType !== 'Trait') {
                responses = responses + '0'
                questions.push(skills[i - 1].name)
                categories.push(skills[i - 1].skillType)
              }
            }
          }
        }

        this.setState({ pathway: selectedPathway, selectedPathway, skills, competencies, responses, questions, categories })
      } else if (event.target.name === 'skillName') {
        this.setState({ [event.target.name]: event.target.value })
        this.searchCompetencies(event.target.value, ['General Skill','Skill','Hard Skill','Soft Skill','Work Style','Ability','Tools Used'], null)
      } else {
        this.setState({ [event.target.name]: event.target.value })
      }
    }

    searchCompetencies(competency, types, index) {
      console.log('searchCompetencies ', competency, types, index)

      if (competency === '') {
        const competencyOptions = []
        this.setState({ competencyOptions })
      } else {
        Axios.get('/api/competency/search', { params: { searchString: competency, types } })
        .then((response) => {
          console.log('Competency search query attempted', response.data);

            if (response.data.success) {
              console.log('competency search query worked')

              const competencyOptions = response.data.competencies
              this.setState({ competencyOptions });

            } else {
              console.log('competency search query did not work', response.data.message)
            }

        }).catch((error) => {
            console.log('Competency search query did not work for some reason', error);
        });
      }
    }

    competencyClicked(optionIndex, type) {
      console.log('competencyClicked', optionIndex, type, this.state.competencyOptions[optionIndex])

      let skillName = this.state.competencyOptions[optionIndex].name

      const competencyOptions = []
      this.setState({ skillName, competencyOptions })

    }

    option1Clicked() {
      console.log('option 1 clicked');

      this.handleClick('1');
    }

    option2Clicked() {
      console.log('option 2 clicked')

      this.handleClick('2');
    }

    option3Clicked() {
      console.log('option 3 clicked')

      this.handleClick('3');
    }

    option4Clicked() {
      console.log('option 4 clicked')

      this.handleClick('4');
    }

    option5Clicked() {
      console.log('option 5 clicked')

      this.handleClick('5');
    }

    handleClick(optionSelected) {
      console.log('handleClick', optionSelected)
      if (this.state.type === 'skills') {

        let newQuestionIndex = this.state.questionIndex
        let newResponses = this.state.responses

        if (newResponses.length === this.state.questionIndex) {
          newQuestionIndex = this.state.questionIndex + 1
          newResponses = newResponses + optionSelected
          console.log('first one')
        } else if (newResponses.length > this.state.questionIndex + 1) {
          newResponses = newResponses.substring(0,this.state.questionIndex) + optionSelected + newResponses.substring(this.state.questionIndex + 1,newResponses.length)
        } else {
          newResponses = newResponses.substring(0,newResponses.length - 1) + optionSelected
        }

        console.log('compare counts', newQuestionIndex, newResponses.length, newResponses)
        this.setState({
          responses: newResponses, optionSelected
        })
      } else {

        // const newQuestionIndex = this.state.questionIndex + 1
        let newResponses = this.state.responses
        if (newResponses.length === this.state.questionIndex) {
          newResponses = newResponses + optionSelected
        } else if (newResponses.length > this.state.questionIndex + 1) {
          newResponses = newResponses.substring(0,this.state.questionIndex) + optionSelected + newResponses.substring(this.state.questionIndex + 1,newResponses.length)
        } else {
          newResponses = newResponses.substring(0,newResponses.length - 1) + optionSelected
        }

        this.setState({
          responses: newResponses, optionSelected
        })
      }
    }

    nextQuestion() {
      console.log('questionIndex called', this.state.responses, this.state.gravitateValues, this.state.topValues)

      let newQuestionIndex = this.state.questionIndex + 1
      let currentQuestion = this.state.questions[newQuestionIndex]

      if (this.state.questionIndex === this.state.questions.length - 1) {

        let totalResponses = '';
        if (this.state.type === 'interests') {
          //questions follow a pattern of pairs:
          for (let i = 1; i <= 60; i++) {
            if (i % 12 === 1 || i % 12 === 2) {
              totalResponses = totalResponses + this.state.responses.substring(0,1)
            } else if (i % 12 === 3 || i % 12 === 4) {
              totalResponses = totalResponses + this.state.responses.substring(1,2)
            } else if (i % 12 === 5 || i % 12 === 6) {
              totalResponses = totalResponses + this.state.responses.substring(2,3)
            } else if (i % 12 === 7 || i % 12 === 8) {
              totalResponses = totalResponses + this.state.responses.substring(3,4)
            } else if (i % 12 === 9 || i % 12 === 10) {
              totalResponses = totalResponses + this.state.responses.substring(4,5)
            } else {
              totalResponses = totalResponses + this.state.responses.substring(5,6)           //this.setState({ responses: this.state.responses + optionSelected })
            }
          }
        } else if (this.state.type === 'work preferences') {
          totalResponses = this.state.wpResponses
        } else if (this.state.type === 'values') {
          totalResponses = this.state.topGravitateValues.concat(this.state.topValues)
        } else {
          totalResponses = this.state.responses
        }

        this.getResults(totalResponses);

      } else {

        if (this.state.type === 'skills') {

          let newResponses = this.state.responses
          let optionSelected = this.state.optionSelected

          let option1 = '10th Percentile (Not Skilled)'
          let option2 = '30th Percentile'
          let option3 = '50th Percentile (Average Skilled)'
          let option4 = '70th Percentile'
          let option5 = '90th Percentile (Highly Skilled)'

          if (this.state.questions[newQuestionIndex].startsWith("How many hours best describes the time")) {
            option1 = '0 - 20 Hours'
            option2 = '20 - 100 Hours'
            option3 = '100 - 1,000 Hours'
            option4 = '1,000 - 5,000 Hours'
            option5 = '5,000 Hours+'
          }

          if (this.state.questions[this.state.questionIndex].startsWith("How many hours best describes the time")) {

            if (!this.state.questions[this.state.questionIndex].includes("spreadsheet")) {
              if (optionSelected === "1" || optionSelected === "2") {
                console.log('we got here 2', this.state.questions[this.state.questionIndex])
                let functionCount = 1

                for (let i = 1; i <= this.state.functions.length; i++) {
                  const functionIndex = i - 1
                  if (functionIndex > this.state.questionIndex) {
                    console.log('startedCount', this.state.functions[i - 1], this.state.functions[this.state.questionIndex])
                    if (this.state.functions[i - 1] === this.state.functions[this.state.questionIndex]) {
                      console.log('we got here 3', this.state.functions[i - 1], this.state.questions[i - 1])
                      functionCount = functionCount + 1

                      const tempIndex = newQuestionIndex + functionCount - 1
                      if (newResponses[tempIndex]) {
                        newResponses = newResponses.substring(0,tempIndex - 1) + "1" + newResponses.substring(tempIndex, newResponses.length)
                      } else {
                        newResponses = newResponses + "1"
                      }

                      if (this.state.questions.length !== i) {
                        newQuestionIndex = newQuestionIndex + 1
                        currentQuestion = this.state.questions[newQuestionIndex]
                      }
                    }
                  }
                }
                console.log('we got here 4', this.state.questionIndex, newQuestionIndex, this.state.questions.length - 1, this.state.questions[this.state.questions.length - 1], newResponses)

                if (this.state.questions[newQuestionIndex]) {
                  if (this.state.questions[newQuestionIndex].startsWith("How many hours best describes the time")) {
                    option1 = '0 - 20 Hours'
                    option2 = '20 - 100 Hours'
                    option3 = '100 Hours - 1,000 Hours'
                    option4 = '1,000 Hours - 5,000 Hours'
                    option5 = '5,000 Hours+'
                  }
                }
              }
            }
          }

          optionSelected = '0'
          if (newResponses[newQuestionIndex]) {
            optionSelected = newResponses[newQuestionIndex]
          }

          this.setState({ option1, option2, option3, option4, option5, responses: newResponses,
            questionIndex: newQuestionIndex, currentQuestion, optionSelected })

        } else if (this.state.type === 'work preferences'){
          console.log('were in wp')

          let shortResponse = ''
          let longResponse = ''
          let multipleChoice = ''
          let boolean = ''

          if (this.state.wpResponses[newQuestionIndex]) {
            if (this.state.answerTypes[newQuestionIndex] === 'shortResponse') {
              shortResponse = this.state.wpResponses[newQuestionIndex]
            } else if (this.state.answerTypes[newQuestionIndex] === 'longResponse') {
              longResponse = this.state.wpResponses[newQuestionIndex]
            } else if (this.state.answerTypes[newQuestionIndex] === 'multipleChoice') {
              multipleChoice = this.state.wpResponses[newQuestionIndex]
            } else if (this.state.answerTypes[newQuestionIndex] === 'boolean') {
              boolean = this.state.wpResponses[newQuestionIndex]
            }
          }

          console.log('were in wp 2', multipleChoice)

          this.setState({ questionIndex: newQuestionIndex, currentQuestion,
            shortResponse, longResponse, multipleChoice, boolean })

        } else {

          let optionSelected = '0'
          if (this.state.responses[newQuestionIndex]) {
            optionSelected = this.state.responses[newQuestionIndex]
            console.log('and here', optionSelected, this.state.responses, newQuestionIndex)
          }

          let selectedAnswer = this.state.selectedAnswer
          // let winningAnswer = this.state.selectedAnswer
          if (this.state.type === 'values') {
            selectedAnswer = null
          }

          let topGravitateValues = this.state.topGravitateValues
          let topValues = this.state.topValues

          if (this.state.type === 'values') {
            if (this.state.questionIndex % 2 === 1) {
              if (!topGravitateValues) {
                topGravitateValues = this.state.topValues
                console.log('show gravitateValues 1: ', topGravitateValues, topValues )
              }

              if (!this.state.topEmployerValues) {
                topValues = []
              } else {
                topValues = this.state.topEmployerValues
              }

            } else {

            }
          }
          console.log('show gravitateValues 2: ', topGravitateValues, topValues )
          // let orderedValues = this.state.orderedValues
          // if (orderedValues) {
          //
          // } else {
          //   orderedValues = this.state.topValues
          // }
          // console.log('show ordereredValues: ', orderedValues)
          this.setState({ questionIndex: newQuestionIndex, currentQuestion, optionSelected, selectedAnswer,
            topGravitateValues, topValues,
          })
        }
      }
    }

    previousQuestion() {
      console.log('previousQuestion called')

      let newQuestionIndex = this.state.questionIndex - 1
      let currentQuestion = this.state.questions[newQuestionIndex]

      if (this.state.type === 'skills') {

        let newResponses = this.state.responses
        let optionSelected = this.state.optionSelected

        let option1 = '10th Percentile (Not Skilled)'
        let option2 = '30th Percentile'
        let option3 = '50th Percentile (Average Skilled)'
        let option4 = '70th Percentile'
        let option5 = '90th Percentile (Highly Skilled)'

        if (this.state.questions[newQuestionIndex].startsWith("How many hours best describes the time")) {
          option1 = '0 - 20 Hours'
          option2 = '20 - 100 Hours'
          option3 = '100 - 1,000 Hours'
          option4 = '1,000 - 5,000 Hours'
          option5 = '5,000 Hours+'
        }

        optionSelected = '0'
        if (newResponses[newQuestionIndex]) {
          optionSelected = newResponses[newQuestionIndex]
        }

        this.setState({ option1, option2, option3, option4, option5, responses: newResponses,
          questionIndex: newQuestionIndex, currentQuestion, optionSelected })

      } else if (this.state.type === 'work preferences'){
        console.log('were in wp')

        let shortResponse = ''
        let longResponse = ''
        let multipleChoice = ''
        let boolean = ''

        if (this.state.wpResponses[newQuestionIndex]) {
          if (this.state.answerTypes[newQuestionIndex] === 'shortResponse') {
            shortResponse = this.state.wpResponses[newQuestionIndex]
          } else if (this.state.answerTypes[newQuestionIndex] === 'longResponse') {
            longResponse = this.state.wpResponses[newQuestionIndex]
          } else if (this.state.answerTypes[newQuestionIndex] === 'multipleChoice') {
            multipleChoice = this.state.wpResponses[newQuestionIndex]
          } else if (this.state.answerTypes[newQuestionIndex] === 'boolean') {
            boolean = this.state.wpResponses[newQuestionIndex]
          }
        }

        console.log('were in wp 2', multipleChoice)

        this.setState({ questionIndex: newQuestionIndex, currentQuestion,
          shortResponse, longResponse, multipleChoice, boolean })

      } else {
        console.log('were in here')
        let optionSelected = '0'
        if (this.state.responses[newQuestionIndex]) {
          optionSelected = this.state.responses[newQuestionIndex]
          console.log('and here', optionSelected, this.state.responses, newQuestionIndex)
        }

        this.setState({ questionIndex: newQuestionIndex, currentQuestion, optionSelected })
      }

    }

    submitAssessment() {
      console.log('submitAssessment called')

      if (this.state.type === 'skills' && !window.location.pathname.includes('/apply')) {

        const cuFirstName = this.state.cuFirstName
        const cuLastName = this.state.cuLastName
        const emailId = this.state.emailId
        const remoteAuth = this.state.remoteAuth

        Axios.put('/api/skills/update/direct', { cuFirstName, cuLastName, emailId, remoteAuth, newSkillAnswers: this.state.resultsData[3] })
        .then((response) => {
            console.log('query for assessment skill results attempted');

            if (response.data.success) {
              console.log('got actual assessment results', response.data)

              let resultsData = this.state.resultsData
              this.setState({ resultsData, skillsData: resultsData });

              let resultsPath = '/app/assessments/assessment-details'
              if (window.location.pathname.includes('/advisor')) {
                resultsPath = '/advisor/career-assessments/assessment-details'
              }

              let assessment = { title: this.state.assessmentTitle }
              if (this.state.assessment) {
                assessment = this.state.assessment
              }

              window.scrollTo(0, 0)
              this.props.navigate(resultsPath, { state: { assessment, resultsData: this.state.resultsData, assessments: this.state.assessments, index: this.state.index }})

            } else {
              console.log('error response', response.data)
              this.setState({ resultsErrorMessage: response.data.message })
            }

        }).catch((error) => {
            console.log('query for assessment results did not work', error);
        })

      } else {
        let responsesCount = this.state.responses.length
        if (this.state.type === 'work preferences') {
          responsesCount = this.state.wpResponses.length
        } else if (this.state.type === 'values') {
          if (this.state.topGravitateValues && this.state.topGravitateValues.length > 0) {
            responsesCount = 0 + 2
          }

          if (this.state.topEmployerValues && this.state.topEmployerValues.length > 0) {
            responsesCount = responsesCount + 2
          }
        }

        console.log('compare: ', responsesCount, this.state.questions.length)
        if (responsesCount !== this.state.questions.length && responsesCount < this.state.questions.length) {
          this.setState({ resultsErrorMessage: 'Please answer all of the questions' })
        } else {
          let totalResponses = '';
          let ogSkillAnswers = this.state.ogSkillAnswers

          if (this.state.type === 'interests') {
            //questions follow a pattern of pairs:
            for (let i = 1; i <= 60; i++) {
              if (i % 12 === 1 || i % 12 === 2) {
                totalResponses = totalResponses + this.state.responses.substring(0,1)
              } else if (i % 12 === 3 || i % 12 === 4) {
                totalResponses = totalResponses + this.state.responses.substring(1,2)
              } else if (i % 12 === 5 || i % 12 === 6) {
                totalResponses = totalResponses + this.state.responses.substring(2,3)
              } else if (i % 12 === 7 || i % 12 === 8) {
                totalResponses = totalResponses + this.state.responses.substring(3,4)
              } else if (i % 12 === 9 || i % 12 === 10) {
                totalResponses = totalResponses + this.state.responses.substring(4,5)
              } else {
                totalResponses = totalResponses + this.state.responses.substring(5,6)           //this.setState({ responses: this.state.responses + optionSelected })
              }
            }
          } else if (this.state.type === 'work preferences'){
            totalResponses = this.state.wpResponses
          } else if (this.state.type === 'values'){

            totalResponses = []
            for (let i = 1; i <= this.state.topGravitateValues.length; i++) {
              totalResponses.push({ value: this.state.topGravitateValues[i - 1], valueType: 'gravitate'})
            }
            for (let i = 1; i <= this.state.topEmployerValues.length; i++) {
              totalResponses.push({ value: this.state.topEmployerValues[i - 1], valueType: 'employer'})
            }
          } else {
            totalResponses = this.state.responses
          }

          this.getResults(totalResponses, ogSkillAnswers);
        }
      }
    }

    getResults(totalResponses, ogSkillAnswers) {
        console.log('get results was called', totalResponses.length, totalResponses, this.state.type, ogSkillAnswers)

        this.setState({ resultsErrorMessage: null, animating: true })

        const cuFirstName = this.state.cuFirstName
        const cuLastName = this.state.cuLastName
        const emailId = this.state.emailId
        const username = this.state.username
        const remoteAuth = this.state.remoteAuth
        const orgCode = this.state.activeOrg
        const orgContactEmail = this.state.orgContactEmail

        Axios.put('/api/assessment/results/calculate', { questions: this.state.questions, answers: totalResponses, type: this.state.type, ogSkillAnswers, cuFirstName, cuLastName, emailId, username, remoteAuth, orgCode, orgContactEmail })
        .then((response) => {
            console.log('query for assessment results attempted');

            if (response.data.success) {

              console.log('got actual assessment results', response.data)

              if (this.state.type === 'work preferences') {

                let resultsData = this.state.resultsData
                console.log('what is resultsData: ', resultsData)
                let index = 0
                // if (window.location.pathname.includes('/advisor')) {
                //   index = 3
                // }
                resultsData[index] = totalResponses
                this.setState({ totalResponses, resultsData })
                if (window.location.pathname.includes('/apply')) {
                 this.props.passData(this.state.type, totalResponses, index)
                }


              } else if (this.state.type === 'interests') {

                let resultsData = this.state.resultsData
                let index = 1
                // if (window.location.pathname.includes('/advisor')) {
                //   index = 0
                // }
                resultsData[index] = response.data.scores
                console.log('show interest resultsData: ', response.data.scores)

                this.setState({
                  totalResponses: totalResponses,
                  resultsData,
                  interestsData: response.data.scores,
                 });
                 if (window.location.pathname.includes('/apply')) {
                  this.props.passData(this.state.type, response.data.scores, index)
                 }
              } else if (this.state.type === 'personality'){
                //personality

                let resultsData = this.state.resultsData

                let index = 2
                // if (window.location.pathname.includes('/advisor')) {
                //   index = 1
                // }
                resultsData[index] = response.data.scores

                this.setState({
                  totalResponses: totalResponses,
                  resultsData,
                  personalityData: response.data.scores
                 });
                 if (window.location.pathname.includes('/apply')) {
                  this.props.passData(this.state.type, response.data.scores, index)
                 }
              } else if (this.state.type === 'skills'){

                let resultsData = this.state.resultsData
                let index = 3
                // if (window.location.pathname.includes('/advisor')) {
                //   index = 2
                // }
                resultsData[index] = response.data.scores

                this.setState({
                  totalResponses: totalResponses,
                  resultsData,
                  skillsData: response.data.scores
                 });
                 if (window.location.pathname.includes('/apply')) {
                  this.props.passData(this.state.type, response.data.answers, index)
                 }
              } else if (this.state.type === 'values'){
                console.log('show response data: ', response.data)

                 let resultsData = this.state.resultsData

                 let index = 4
                 // if (window.location.pathname.includes('/advisor')) {
                 //   index = 4
                 // }

                 resultsData[index] = response.data.scores

                 this.setState({ resultsData, valuesData: response.data.scores });
                 if (window.location.pathname.includes('/apply')) {
                  this.props.passData(this.state.type, response.data.scores, index)
                 }

              } else {

                console.log('type was not classified')

              }

              this.setState({ animating: false, done: true })

            } else {
              console.log('error response', response.data)
              this.setState({ resultsErrorMessage: response.data.message })
            }

        }).catch((error) => {
            console.log('error when calculating assessment results', error);
        })
    }

    renderAnswerChoices(passedIndex) {
      console.log('renderAnswerChoices called', this.state.answerTypes[this.state.questionIndex], this.state.answerChoices, this.state.answerChoices[this.state.questionIndex])

      let rows = []

      if (this.state.type === 'values') {

        if (passedIndex % 2 === 0) {
          let answerChoices = []
          let testAnswers = []
          if (passedIndex === 0) {
            answerChoices = this.state.gravitateValues
            if (this.state.topGravitateValues) {
              testAnswers = this.state.topGravitateValues
            }

          } else {
            answerChoices = this.state.employerValues
            if (this.state.topEmployerValues) {
              testAnswers = this.state.topEmployerValues
            }
          }

          console.log('show topValues: ', this.state.topValues, answerChoices[passedIndex])

          rows.push(
            <div key={this.state.questions[passedIndex] + passedIndex} className="row-10">
              {(answerChoices) && (
                <div>
                  {answerChoices.map((value, optionIndex) =>
                    <div key={passedIndex}>
                      <div className="float-left row-5 right-padding">
                        {(testAnswers.includes(value)) ? (
                          <div>
                            <button className="background-button tag-container-6 cta-background-color" onClick={() => this.formChangeHandler({ target: { name: "multipleAnswer|" + passedIndex, value }}) }>
                              <div>
                                <div className="float-left left-text">
                                  <p className="description-text-2 white-text row-10">{value}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button className="background-button tag-container-6" onClick={() => this.formChangeHandler({ target: { name: "multipleAnswer|" + passedIndex, value }}) }>
                              <div>
                                <div className="float-left left-text">
                                  <p className="description-text-2 row-10">{value}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </button>
                          </div>
                        )}
                      </div>

                    </div>
                  )}
                  <div className="clear" />
                </div>
              )}
            </div>
          )

        } else {

          let topValues = this.state.topValues
          console.log('show topValues 1: ', topValues)
          let droppableId = 'gravitate'
          if (passedIndex === 1 && this.state.gravitateValues) {
            if (this.state.topGravitateValues && this.state.topGravitateValues.length > 0) {
              topValues = this.state.topGravitateValues
            } else {
              // topValues = this.state.gravitateValues
            }

            droppableId = 'gravitate'
          } else if (passedIndex === 3 && this.state.employerValues) {
            if (this.state.topEmployerValues && this.state.topEmployerValues.length > 0) {
              topValues = this.state.topEmployerValues
            } else {
              // topValues = this.state.employerValues
            }
            droppableId = 'employer'
          }
          console.log('show topValues 2: ', topValues)

          rows.push(
            <div>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <StrictModeDroppable droppableId={droppableId}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {topValues.map((item, index) => (
                        <Draggable key={item} draggableId={item} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div>
                                <div className="fixed-column-60 top-padding">
                                  <img src={dragIcon} alt="Compass loading gif icon" className="image-auto-20 vertically-centered-container left-padding-20"/>
                                </div>
                                <div className="calc-column-offset-60">
                                  <p className="row-10 left-text">#{index + 1}: {item}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>

              <div className="spacer" />
            </div>
          )
        }

      } else {

        if (this.state.answerTypes[passedIndex] === 'Short Response') {

          let eventName = 'shortResponse|' + passedIndex

          rows.push(
            <div key={"sp" + this.state.questionIndex.toString()}>
              <input className="text-field" type="text" placeholder="Write your answer..." name={eventName} value={this.state.wpResponses[passedIndex]} onChange={this.formChangeHandler} />
            </div>
          )
        } else if (this.state.answerTypes[passedIndex] === 'Long Response') {
          console.log('long response type')

          let eventName = 'longResponse|' + passedIndex

          rows.push(
            <div key={"lr" + this.state.questionIndex.toString()}>
              <textarea type="text" className="text-field" placeholder="Write your answer..." name={eventName} value={this.state.wpResponses[passedIndex]} onChange={this.formChangeHandler}></textarea>
            </div>
          )
        } else if (this.state.answerTypes[passedIndex] === 'Multiple Choice') {
          console.log('multiple choice type')

          let responses = this.state.responses
          if (this.state.type === 'work preferences') {
            responses = this.state.wpResponses
          }
          console.log('show this response: ', responses[passedIndex], this.state.answerChoices[passedIndex])

          rows.push(
            <div key={this.state.questions[passedIndex] + passedIndex} className="row-10">
              {(this.state.answerChoices[passedIndex]) && (
                <div>
                  {this.state.answerChoices[passedIndex].map((value, optionIndex) =>
                    <div key={passedIndex}>
                      <div className="float-left row-5 right-padding">
                        {(Array.isArray(responses[passedIndex])) ? (
                          <div>
                            {(responses[passedIndex].includes(this.state.answerChoices[passedIndex][optionIndex])) ? (
                              <div>
                                <button className="background-button tag-container-6 cta-background-color" onClick={() => this.formChangeHandler({ target: { name: "multipleChoice|" + passedIndex, value: this.state.answerChoices[passedIndex][optionIndex]}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 white-text row-10">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button className="background-button tag-container-6" onClick={() => this.formChangeHandler({ target: { name: "multipleChoice|" + passedIndex, value: this.state.answerChoices[passedIndex][optionIndex]}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 row-10">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {(responses[passedIndex] === this.state.answerChoices[passedIndex][optionIndex]) ? (
                              <div>
                                <button className="background-button tag-container-6 cta-background-color" onClick={() => this.formChangeHandler({ target: { name: "multipleChoice|" + passedIndex, value: this.state.answerChoices[passedIndex][optionIndex]}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 white-text row-10">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button className="background-button tag-container-6" onClick={() => this.formChangeHandler({ target: { name: "multipleChoice|" + passedIndex, value: this.state.answerChoices[passedIndex][optionIndex]}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 row-10">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                    </div>
                  )}
                  <div className="clear" />
                </div>
              )}
            </div>
          )

        } else if (this.state.answerTypes[passedIndex] === 'Multiple Answer') {
          console.log('multiple answer type:', this.state.answerChoices)

          let responses = this.state.responses
          if (this.state.type === 'work preferences') {
            responses = this.state.wpResponses
          }

          let eventName = 'multipleAnswer|' + passedIndex

          console.log('show responses: ', responses[passedIndex])

          rows.push(
            <div key={this.state.questions[passedIndex] + passedIndex} className="row-10">
              {(this.state.answerChoices[passedIndex]) && (
                <div>
                  {this.state.answerChoices[passedIndex].map((value, optionIndex) =>
                    <div key={passedIndex}>
                      <div className="float-left row-5 right-padding">
                        {(Array.isArray(responses[passedIndex]) && responses[passedIndex].includes(this.state.answerChoices[passedIndex][optionIndex])) ? (
                          <div>
                            <button className="background-button tag-container-6 cta-background-color" onClick={() => this.formChangeHandler({ target: { name: eventName, value: this.state.answerChoices[passedIndex][optionIndex]}})}>
                              <div>
                                <div className="float-left left-text">
                                  <p className="description-text-2 white-text row-10">{value}</p>
                                </div>
                              </div>
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button className="background-button tag-container-6" onClick={() => this.formChangeHandler({ target: { name: eventName, value: this.state.answerChoices[passedIndex][optionIndex]}})}>
                              <div>
                                <div className="float-left left-text">
                                  <p className="description-text-2 row-10">{value}</p>
                                </div>
                              </div>
                            </button>
                          </div>
                        )}
                      </div>

                    </div>
                  )}
                  <div className="clear" />
                </div>
              )}
            </div>
          )

        } else if (this.state.answerTypes[passedIndex] === 'Ranking') {
          console.log('ranking type')

          let answerChoices = []
          let draggableId = 'techTrends' + passedIndex
          if (this.state.questions[passedIndex].includes('technology trends')) {
            draggableId = 'techTrends|' + passedIndex
            if (this.state.wpResponses[passedIndex]) {
              answerChoices = this.state.wpResponses[passedIndex]
            } else {
              answerChoices = this.state.technologyTrends
            }

          } else if (this.state.questions[passedIndex].includes('societal problems')) {
            draggableId = 'societalProblems|' + passedIndex
            if (this.state.wpResponses[passedIndex]) {
              answerChoices = this.state.wpResponses[passedIndex]
            } else {
              answerChoices = this.state.societalProblems
            }
          }

          rows.push(
            <div key="ranking">
              <div>
                <p>Please drag & drop the below options to the order you prefer.</p>
                <div className="spacer" />
              </div>

              {(answerChoices) && (
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <StrictModeDroppable droppableId={draggableId}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {answerChoices.map((item, index) => (
                          <Draggable key={item} draggableId={item} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div>
                                  <div className="fixed-column-60 top-padding">
                                    <img src={dragIcon} alt="Compass loading gif icon" className="image-auto-20 vertically-centered-container left-padding-20"/>
                                  </div>
                                  <div className="calc-column-offset-60">
                                    <p className="row-10 left-text">#{index + 1}: {item}</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </StrictModeDroppable>
                </DragDropContext>
              )}

            </div>
          )

        } else if (this.state.answerTypes[this.state.questionIndex] === 'Boolean') {
          console.log('boolean type here')

          // const answerChoices = ['Yes','No']
          // for (let i = 1; i <= answerChoices.length; i++) {
          //   console.log('counting', i)
          //   const index = i - 1
          //   const event = { target: { name: "boolean", value: answerChoices[index]}}
          //
          //   rows.push(
          //     <div key={this.state.questionIndex + index}>
          //       <a onClick={() => this.formChangeHandler(event)} className="background-link">
          //         <div style={ this.state.wpResponses[this.state.questionIndex] === answerChoices[i - 1] ? { backgroundColor: '#87CEFA', border: '1px solid #fff' } : { backgroundColor: 'white', border: '1px solid #87CEFA'}}>
          //           <p style={this.state.wpResponses[this.state.questionIndex] === answerChoices[i - 1] ? {color: 'white'}: {color: '#87CEFA'}}>{answerChoices[i - 1]}</p>
          //         </div>
          //       </a>
          //     </div>
          //   )
          // }
        }
      }

      return rows
    }

    renderQuestions() {
      console.log('renderQuestions called')

      let rows = []

      if (this.state.questions) {
        for (let i = 1; i <= this.state.questions.length; i++) {
          const index = i - 1
          if (index === 0) {
            console.log('show questions 1: ', this.state.questions[index])
          }

          // console.log('show answer: ',this.state.skills[i - 1].answer, i, this.state.skills[i - 1].title)

          let lowEndText = 'Strongly Disagree'
          let highEndText = 'Strongly Agree'
          if (this.state.type === 'skills') {
            console.log('show questions: ', this.state.questions)
            if (this.state.questions[index] && this.state.questions[index].toLowerCase().includes('how many hours')) {
              lowEndText = '0 - 20 Hours'
              highEndText = '5,000 Hours+'
            } else {
              lowEndText = 'Bottom 20th Percentile'
              highEndText = 'Top 20th Percentile'
            }
          }

          rows.push(
            <div key={"question|" + i} className="row-15 left-text">

              { (this.state.type === 'work preferences' || this.state.type === 'personality') && (
                <div>
                  <p className="capitalize-text half-bold-text heading-text-4 row-10">{i}. {this.state.questions[index]}</p>

                </div>
              )}

              { (this.state.type === 'interests') && (
                <div>
                  <p className="capitalize-text half-bold-text heading-text-4 row-10">{i}. {this.state.questions[index]}</p>
                  <p className="row-10">{this.state.descriptions[index]}</p>
                </div>
              )}

              { (this.state.type === 'skills') && (
                <div>
                  <p className="capitalize-text half-bold-text heading-text-4 row-10">{i}. {this.state.questions[index]}</p>

                  {(this.state.descriptions[index]) ? (
                    <div>
                      <p className="description-text-3 row-10">{this.state.descriptions[index]}</p>
                    </div>
                  ) : (
                    <div>
                      { (this.state.categories[index] === "Technical Skills") ? (
                        <p className="description-text-2 row-10">Function: {this.state.functions[index]}</p>
                      ) : (
                        <p className="description-text-2 row-10">Category: {this.state.categories[index]}</p>
                      )}
                    </div>
                  )}

                </div>
              )}

              {(this.state.type === 'values') && (
                <div>
                  {(index % 2 === 0) ? (
                    <div>
                      {(index === 0) ? (
                        <div>
                          <p className="capitalize-text half-bold-text heading-text-4">You gravitate toward people who are (Select 10):</p>

                          <div className="spacer" />

                          {(this.state.topGravitateValues && this.state.topGravitateValues.length > 0) && (
                            <div>
                              <p className="description-text-1 error-color clear-margin">[{this.state.topGravitateValues.length} Selected]</p>
                              <div className="spacer" /><div className="spacer" />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          <p className="half-bold-text row-10 heading-text-4">What are the top 10 values that an employer would provide for you (Select 10):</p>

                          {(this.state.topEmployerValues && this.state.topEmployerValues.length > 0) && (
                            <div>
                              <label className="description-text-1 error-color clear-margin">[{this.state.topEmployerValues.length} Selected]</label>
                              <div className="clear" />
                              <div className="spacer" /><div className="spacer" />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div>
                        <p className="capitalize-text half-bold-text heading-text-4">Rank the top 10 values from highest to lowest:</p>
                      </div>

                      <div className="spacer" />
                      {(index === 1) ? (
                        <div>
                          <label className="capitalize-text">(according to who you gravitate towards)</label>
                          <div className="clear" />
                          <p className="description-text-2 error-color row-10">[Drag and drop may not work effectively on some mobile and modal views. Adjust on web, non-modal view (i.e., from profile) if you face this issue.]</p>
                        </div>
                      ) : (
                        <div>
                          <label className="capitalize-text">(according to who you value in employers)</label>
                          <div className="clear" />
                          <p className="description-text-2 error-color row-10">[Drag and drop may not work effectively on some mobile and modal views. Adjust on web, non-modal view (i.e., from profile) if you face this issue.]</p>
                        </div>
                      )}

                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    </div>
                  )}
                </div>
              )}

              {(this.state.type === 'work preferences' || this.state.type === 'values') ? (
                <div>
                  <div>
                    {this.renderAnswerChoices(index)}
                  </div>
                </div>
              ) : (
                <div className="row-10 center-text">
                  <div className={(this.state.questions[i - 1].score === 1 || this.state.responses[i - 1] === '1') ? "relative-column-20 cta-border row-15 horizontal-padding-4 cta-background-color white-text" : "relative-column-20 cta-border row-15 horizontal-padding-4"} >
                    <a className="background-link" onClick={() => this.itemClicked(index,1)}>
                      <div className="full-space">
                        <p>1</p>
                      </div>
                    </a>
                  </div>
                  <div className={(this.state.questions[i - 1].score === 2 || this.state.responses[i - 1] === '2') ? "relative-column-20 cta-border row-15 horizontal-padding-4 cta-background-color white-text" : "relative-column-20 cta-border row-15 horizontal-padding-4"} >
                    <a className="background-link" onClick={() => this.itemClicked(index,2)}>
                      <div className="full-space">
                        <p>2</p>
                      </div>
                    </a>
                  </div>
                  <div className={(this.state.questions[i - 1].score === 3 || this.state.responses[i - 1] === '3') ? "relative-column-20 cta-border row-15 horizontal-padding-4 cta-background-color white-text" : "relative-column-20 cta-border row-15 horizontal-padding-4"} >
                    <a className="background-link" onClick={() => this.itemClicked(index,3)}>
                      <div className="full-space">
                        <p>3</p>
                      </div>
                    </a>
                  </div>
                  <div className={(this.state.questions[i - 1].score === 4 || this.state.responses[i - 1] === '4') ? "relative-column-20 cta-border row-15 horizontal-padding-4 cta-background-color white-text" : "relative-column-20 cta-border row-15 horizontal-padding-4"} >
                    <a className="background-link" onClick={() => this.itemClicked(index,4)}>
                      <div className="full-space">
                        <p>4</p>
                      </div>
                    </a>
                  </div>
                  <div className={(this.state.questions[i - 1].score === 5 || this.state.responses[i - 1] === '5') ? "relative-column-20 cta-border row-15 horizontal-padding-4 cta-background-color white-text" : "relative-column-20 cta-border row-15 horizontal-padding-4"} >
                    <a className="background-link" onClick={() => this.itemClicked(index,5)}>
                      <div className="full-space">
                        <p>5</p>
                      </div>
                    </a>
                  </div>
                  <div className="clear" />
                  <div>
                    <div className="container-left left-text">
                      <p className="description-text-3 full-width row-10">{lowEndText}</p>
                    </div>
                    <div className="container-right right-text">
                      <p className="description-text-3 full-width row-10">{highEndText}</p>
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              )}
            </div>
          )
        }
      }

      return rows
    }

    itemClicked(index1, index2) {
      console.log('itemClicked called', index1, index2, typeof index1, typeof index2)

      let responses = this.state.responses
      if (responses.length === index1) {
        responses = responses + index2.toString()
        console.log('option 1', responses)
      } else if (responses.length > index1 + 1) {
        responses = responses.substring(0,index1) + index2.toString() + responses.substring(index1 + 1,responses.length)
        console.log('option 2', responses)
      } else {
        responses = responses.substring(0,responses.length - 1) + index2.toString()
        console.log('option 3', responses, responses.length)
      }

      // responses[index1] = index2
      this.setState({ responses })
      //
      // if (this.state.type === 'skills') {
      //
      //
      //   // let newQuestionIndex = this.state.questionIndex
      //   // let newResponses = this.state.responses
      //   //
      //   // if (newResponses.length === this.state.questionIndex) {
      //   //   newQuestionIndex = this.state.questionIndex + 1
      //   //   newResponses = newResponses + optionSelected
      //   //   console.log('first one')
      //   // } else if (newResponses.length > this.state.questionIndex + 1) {
      //   //   newResponses = newResponses.substring(0,this.state.questionIndex) + optionSelected + newResponses.substring(this.state.questionIndex + 1,newResponses.length)
      //   // } else {
      //   //   newResponses = newResponses.substring(0,newResponses.length - 1) + optionSelected
      //   // }
      //   //
      //   // console.log('compare counts', newQuestionIndex, newResponses.length, newResponses)
      //   // this.setState({
      //   //   responses: newResponses, optionSelected
      //   // })
      //
      // } else {
      //
      //   // let newResponses = this.state.responses
      //   // if (newResponses.length === this.state.questionIndex) {
      //   //   newResponses = newResponses + optionSelected
      //   // } else if (newResponses.length > this.state.questionIndex + 1) {
      //   //   newResponses = newResponses.substring(0,this.state.questionIndex) + optionSelected + newResponses.substring(this.state.questionIndex + 1,newResponses.length)
      //   // } else {
      //   //   newResponses = newResponses.substring(0,newResponses.length - 1) + optionSelected
      //   // }
      //   //
      //   // this.setState({
      //   //   responses: newResponses, optionSelected
      //   // })
      // }
    }

    renderTaggingAssessment() {
      console.log('renderTaggingAssessment called')

      function tagBackgroundConverter(score) {
        // console.log('tagBackgroundConverter called', score)

        let backgroundColorClass = 'quinary-background'
        if (score === 1) {
          backgroundColorClass = 'quinary-background-light'
        } else if (score === 2) {
          backgroundColorClass = 'tertiary-background-light'
        } else if (score === 3) {
          backgroundColorClass = 'secondary-background-light'
        } else if (score === 4) {
          backgroundColorClass = 'primary-background-light'
        } else if (score === 5) {
          backgroundColorClass = 'senary-background-light'
        }

        return backgroundColorClass
      }

      return (
        <div key={0}>
          <div>
            <div className={(window.innerWidth <= 768) ? "full-width" : "relative-column-60 right-padding"}>
              <label className="profile-label left-text">Name of Skill</label>
              <input className="text-field" type="text" placeholder="Skill name..." name="skillName" value={this.state.skillName} onChange={this.formChangeHandler} />
            </div>
            <div className={(window.innerWidth <= 768) ? "calc-column-offset-70 top-margin-5" : "calc-column-offset-70-of-40"}>
              <div>
                <div className="float-left right-margin">
                  <label className="profile-label left-text">Your Percentile</label>
                </div>
                <div>
                  <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                  <div className="float-left noti-bubble-info-7-9">
                    <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showPercentileInfo: true })}>
                      <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                    </a>
                  </div>
                </div>
                <div className="clear" />
              </div>

              <select name="score" className="dropdown" value={this.state.score} onChange={this.formChangeHandler}>
                <option value=""></option>
                <option value="5">Top 20%</option>
                <option value="4">Top 40%</option>
                <option value="3">Top 60%</option>
                <option value="2">Bottom 40%</option>
                <option value="1">Bottom 20%</option>
              </select>
            </div>
            <div className="fixed-column-70 top-margin-40">
              <button className="btn btn-squarish" onClick={() => this.addItem()}>Add</button>
            </div>
            <div className="clear" />
          </div>

          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="row-5 error-color left-text">{this.state.errorMessage}</p>}
          {(this.state.successMessage && this.state.successMessage !== '') && <p className="row-5 cta-color left-text">{this.state.successMessage}</p>}

          {(this.state.competencyOptions) && (
            <div>
              <div className="spacer" />

              {this.state.competencyOptions.map((value, optionIndex) =>
                <div key={value._id} className="left-text full-width">
                  <button className="background-button full-width" onClick={() => this.competencyClicked(optionIndex, null)}>
                    <div className="full-width">
                      <div className="float-left right-padding top-margin">
                        <img src={skillsIcon} alt="GC" className="image-auto-22" />
                      </div>
                      <div className="float-left">
                        <p className="cta-color row-10">{value.name}</p>
                      </div>
                    </div>
                  </button>
                </div>
              )}
            </div>
          )}

          {(this.state.resultsData && this.state.resultsData[3] && this.state.resultsData[3].length > 0) && (
            <div className="row-20">
              {this.state.resultsData[3].map((value, optionIndex) =>
                <div key={value} className="float-left">
                  <div className="relative-position float-left right-margin-negative-12">
                    <div className="mini-spacer" />
                    <button className="background-button" onClick={() => this.removeItem(optionIndex)}>
                      <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                    </button>
                  </div>
                  <div className={"row-15 horizontal-padding-5 slightly-rounded-corners float-left right-margin top-margin description-text-2 bold-text " + tagBackgroundConverter(this.state.resultsData[3][optionIndex].score)}>
                    <p>{this.state.resultsData[3][optionIndex].name} - {this.state.resultsData[3][optionIndex].score}</p>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="clear" />
        </div>
      )
    }

    addItem() {
      console.log('addItem called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      if (!this.state.skillName || this.state.skillName === '') {
        this.setState({ errorMessage: 'Please add a skill', isSaving: false })
      } else if (!this.state.score || this.state.score === '') {
        this.setState({ errorMessage: 'Please add a score for the skill', isSaving: false})
      } else {
        let resultsData = this.state.resultsData
        if (resultsData[3] && resultsData[3].some(s => s.name.toLowerCase().trim() === this.state.skillName.toLowerCase().trim())) {
          this.setState({ errorMessage: 'You have already added this skill', isSaving: false })
        } else {
          if (resultsData[3]) {
            resultsData[3].unshift({ name: this.state.skillName, score: Number(this.state.score) })
          } else {
            resultsData[3] = [{ name: this.state.skillName, score: Number(this.state.score) }]
          }

          let skillName = ''
          let score = ''
          // this.setState({ resultsData, skillName, score, isSaving: false })

          const cuFirstName = this.state.cuFirstName
          const cuLastName = this.state.cuLastName
          const emailId = this.state.emailId
          const remoteAuth = this.state.remoteAuth

          Axios.put('/api/skills/update/direct', { cuFirstName, cuLastName, emailId, remoteAuth, newSkillAnswers: resultsData[3] })
          .then((response) => {
              console.log('query for assessment skill results attempted');

              if (response.data.success) {
                console.log('got actual assessment results', response.data)
                this.setState({ resultsData, skillName, score, isSaving: false, successMessage: 'Saved changes to your skill bank' })

              } else {
                console.log('error response', response.data)
                this.setState({ errorMessage: response.data.message })
              }

          }).catch((error) => {
              console.log('query for assessment results did not work', error);
          })
        }
      }
    }

    removeItem(index) {
      console.log('removeItem called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      let resultsData = this.state.resultsData
      resultsData[3].splice(index,1)
      // this.setState({ resultsData })

      const cuFirstName = this.state.cuFirstName
      const cuLastName = this.state.cuLastName
      const emailId = this.state.emailId
      const remoteAuth = this.state.remoteAuth

      Axios.put('/api/skills/update/direct', { cuFirstName, cuLastName, emailId, remoteAuth, newSkillAnswers: resultsData[3] })
      .then((response) => {
          console.log('query for assessment skill results attempted');

          if (response.data.success) {
            console.log('got actual assessment results', response.data)
            this.setState({ resultsData, isSaving: false, successMessage: 'Saved changes to your skill bank' })

          } else {
            console.log('error response', response.data)
            this.setState({ errorMessage: response.data.message })
          }

      }).catch((error) => {
          console.log('query for assessment results did not work', error);
      })
    }

    closeModal() {
      console.log('closeModal called')

      this.setState({ modalIsOpen: false, showRateByPathway: false, showPercentileInfo: false })
    }

    addToSkills() {
      console.log('addToSkills called')

      this.setState({ errorMessage: null, successMessage: null })

      let resultsData = this.state.resultsData
      if (this.state.responses && this.state.responses.length > 0) {
        for (let i = 1; i <= this.state.responses.length; i++) {
          console.log('show each response: ', this.state.responses[i - 1])
          if (this.state.responses[i - 1] && this.state.responses[i - 1] !== '0') {
            console.log('yes', i)
            if (resultsData[3].some(s => s.name.toLowerCase().trim() === this.state.questions[i - 1].toLowerCase().trim())) {
              // dont add duplicate
              const resultsIndex = resultsData[3].findIndex(s => s.name.toLowerCase().trim() === this.state.questions[i - 1].toLowerCase().trim());
              resultsData[3][resultsIndex] = { name: this.state.questions[i - 1], score: Number(this.state.responses[i - 1])}
            } else {
              resultsData[3].unshift({ name: this.state.questions[i - 1], score: Number(this.state.responses[i - 1])})
            }

            // resultsData[3].unshift({ name: this.state.skillName, score: Number(this.state.score) })
          }
        }
        this.setState({ resultsData, modalIsOpen: false })
      } else {

        this.setState({ errorMessage: 'Something went wrong' })
      }
    }

    render() {

      let resultsPath = '/app/assessments/assessment-details'
      if (window.location.pathname.includes('/advisor')) {
        resultsPath = '/advisor/career-assessments/assessment-details'
      }

      let assessment = { title: this.state.assessmentTitle }
      if (this.state.assessment) {
        assessment = this.state.assessment
      }

      return (
          <div>

            <div className={(!window.location.pathname.includes('/apply') && !window.location.pathname.includes('/app/walkthrough')) && "standard-container-2"}>
              {(this.state.animating) ? (
                <div className="flex-container flex-center full-space">
                  <div>
                    <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                    <div className="spacer" /><div className="spacer" /><div className="spacer" />
                    {(this.state.orgFocus === 'Placement') ? (
                      <p className="center-text cta-color bold-text row-10">Calculating results...</p>
                    ) : (
                      <p className="center-text cta-color bold-text row-10">Calculating results and work matches...</p>
                    )}

                  </div>
                </div>
              ) : (
                <div>
                  {this.state.assessmentTitle && (
                    <div>
                      <div className="full-width center-text">
                        <div>
                          {!window.location.pathname.includes('/app/walkthrough') && (
                            <div>
                              {(!window.location.pathname.includes('/apply')) && (
                                <div className="super-spacer"/>
                              )}

                              <div>
                                <div className="relative-column-10">
                                  <div className="spacer" /><div className="spacer" />
                                  {(window.location.pathname.includes('/apply')) ? (
                                    <button className="background-button" onClick={() => this.props.closeModal()}>
                                      <div>
                                        <img src={closeIcon} alt="Compass arrow indicator icon" className="image-auto-15" />
                                      </div>
                                    </button>
                                  ) : (
                                    <Link className="secret-link" to={resultsPath} state={{ assessments: this.state.assessments, index: this.state.index, assessment, resultsData: this.state.resultsData }}>
                                      <div>
                                        <img src={arrowIndicatorIcon} alt="GC" className="image-auto-20 rotate-180" />
                                      </div>
                                    </Link>
                                  )}
                                </div>
                                <div className="relative-column-80">
                                  <div className="half-spacer" /><div className="mini-spacer" />
                                  <label className="heading-text-2 center-text">{this.state.assessmentTitle}</label>
                                  <div className="clear" />
                                  <div className="half-spacer" />
                                  {(this.state.questions && this.state.type === 'skills') && (
                                    <div>
                                      {(window.location.pathname.includes('/apply')) && (
                                        <label className="description-text-color">{this.state.questions.length} Questions</label>
                                      )}

                                      <div className="clear" />
                                      <div className="spacer" />
                                      <div className="spacer" />
                                    </div>
                                  )}
                                </div>
                                <div className="relative-column-10 row-5">
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          )}

                          {(this.state.done === true) ? (
                            <div>
                                <div>
                                  <p className="heading-text-6 row-10">You're done!</p>
                                </div>

                                <div className="padding-20">
                                  <div>
                                    <div className="flex-container row-direction flex-center" >
                                      <div className="full-width center-text">

                                        {(window.location.pathname.includes('/apply')) ? (
                                          <div>
                                            <p>Your results have been saved and imported into your application.</p>
                                            <div className="spacer" />
                                            <button className="btn btn-primary" onClick={() => this.props.closeModal()}>Close View</button>
                                          </div>
                                        ) : (
                                          <div>
                                            <p>View your results on the previous screen.</p>
                                            <div className="spacer" />
                                            <button className="btn btn-primary" onClick={() => this.props.navigate(resultsPath, { state: { assessment, resultsData: this.state.resultsData, assessments: this.state.assessments, index: this.state.index }})}>View Results</button>
                                          </div>
                                        )}

                                      </div>
                                    </div>
                                  </div>

                                  <p className="error-message row-10">{this.state.resultsErrorMessage}</p>
                                </div>
                            </div>
                          ) : (
                              <div>
                                <div>
                                  {!window.location.pathname.includes('/app/walkthrough') && (
                                    <div>
                                      {(this.state.type === 'skills') && (
                                        <div className="top-padding">
                                          {(window.location.pathname.includes('/apply')) ? (
                                            <div>
                                              <p>This employer desires to hire candidates who have the following skills. Compared to your peers, in what percentile would you rate yourself?</p>
                                            </div>
                                          ) : (
                                            <div>
                                              <p>These skills serve as a comprehensive record for your reference and a resource to import into applications. Not sure what skills to add? <button className="background-button bold-text cta-color" onClick={() => this.setState({ modalIsOpen: true, showRateByPathway: true })}>Click here</button> for suggestions by career pathway.</p>
                                            </div>
                                          )}
                                        </div>
                                      )}

                                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                    </div>
                                  )}

                                  {(this.state.type === 'skills' && !window.location.pathname.includes('/apply')) ? (
                                    <div>

                                      {this.renderTaggingAssessment()}
                                      <div className="clear" />
                                      <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                    </div>
                                  ) : (
                                    <div>
                                      {this.renderQuestions()}
                                    </div>
                                  )}

                                  {(this.state.resultsErrorMessage) && <p className="error-message row-10">{this.state.resultsErrorMessage}</p>}


                                  {(!window.location.pathname.includes('/app/walkthrough') && (window.location.pathname.includes('/apply') || this.state.type !== 'skills')) && (
                                    <div>

                                      <button className="btn btn-primary" onClick={() => this.submitAssessment()}>Submit</button>
                                    </div>
                                  )}

                                </div>
                              </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <Modal
             isOpen={this.state.modalIsOpen}
             onAfterOpen={this.afterOpenModal}
             onRequestClose={this.closeModal}
             className="modal"
             overlayClassName="modal-overlay"
             contentLabel="Example Modal"
           >
             <div key="info" className="full-width padding-40">

              {(this.state.showPercentileInfo) && (
                <div>
                  <div>
                    <div className="calc-column-offset-50">
                      <p className="heading-text-2 row-10">Percentile Info</p>
                    </div>
                    <div className="fixed-column-50">
                      <button className="background-button" onClick={() => this.closeModal()}>
                        <img src={closeIcon} alt="GC" className="image-auto-15 pin-right" />
                      </button>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="spacer" /><div className="spacer" />
                  <p>Choose what percentile you would rate yourself compared to your peers. For example, if you rate yourself in the top 20% in Microsoft Excel, you are saying that you are more skilled than four of five people, on average, in your age group.</p>
                </div>
              )}

              {(this.state.showRateByPathway) && (
                <div>
                  <div>
                    <div className="calc-column-offset-50">
                      <p className="heading-text-2 row-10">Rate Yourself By Pathway</p>
                    </div>
                    <div className="fixed-column-50">
                      <button className="background-button" onClick={() => this.closeModal()}>
                        <img src={closeIcon} alt="GC" className="image-auto-15 pin-right" />
                      </button>
                    </div>
                    <div className="clear" />
                  </div>
                  <div className="spacer" /><div className="spacer" />

                  <div className="row-10">
                    <p className="profile-label">Select a Pathway</p>
                    <select name="pathway" className="dropdown" value={this.state.selectedPathway} onChange={this.formChangeHandler}>
                        {this.state.pathwayOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                    </select>
                  </div>

                  <div className="row-10">
                    {(this.state.selectedPathway && this.state.selectedPathway !== '') && (
                      <div>
                        {this.renderQuestions()}
                        <div className="clear" />

                        <div className="spacer" /><div className="spacer" />

                        {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="row-5 error-color left-text">{this.state.errorMessage}</p>}
                        {(this.state.successMessage && this.state.successMessage !== '') && <p className="row-5 error-color left-text">{this.state.successMessage}</p>}

                        <button className="btn btn-primary right-margin" onClick={() => this.addToSkills()}>Bulk Add to Skills Bank</button>
                        <button className="btn btn-secondary" onClick={() => this.closeModal()}>Cancel</button>
                      </div>
                    )}
                  </div>
                </div>
              )}
             </div>
           </Modal>
          </div>

      )
    }
}

export default withRouter(TakeAssessment);
