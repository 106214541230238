import React, {Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import Switch from 'react-switch';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ProjectDetails from './ProjectDetails';
import EditProject from './EditProject';
import EditSubscription from '../Common/EditSubscription';
import SwitchOrgs from '../Common/SwitchOrgs';
import SubRenderSuggestion from '../Common/RenderSuggestion';
import withRouter from '../Functions/WithRouter';
import {signOut} from '../Services/AuthRoutes';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {convertStringToDate} from '../Functions/ConvertStringToDate';

// https://fosternation.my.salesforce.com/sfc/p/i0000000Z5Iz/a/Do000000LHAa/ZtCxiIT5ShN7oXVaTvtiJj7._NLPoMSczsCtkHtK1us
const addProfilePhotoIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-profile-photo-icon.png';
// const addProfilePhotoIcon = 'https://fosternation.box.com/s/mmwyc2zdjcw1lkt0iia378ki1r3r3lzk';
const addIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/add-icon.png';
const editIconGrey = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/edit-icon-grey.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';
const confidentialityIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/confidentiality-icon.png';
const feedbackIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/feedback-icon-blue.png';
const questionMarkBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/question-mark-blue.png';
const detailsIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/details-icon-blue.png';
const reachIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/reach-icon.png';
const prizeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/prize-icon.png';
const settingsIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/settings-icon.png';
const checkmarkIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png";
const deniedIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png";
const deleteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/delete-icon-dark.png';
const skillsIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png';
const courseIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/course-icon-dark.png';
const arrowIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-icon.png';
const checkboxEmpty = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-empty.png';
const checkboxChecked = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkbox-checked.png';
const loadingGIF = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/loading-gif.gif';

class EditProfileDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDoc: true,

            showModule: false,
            showDetailsInBasic: true,
            allowMultipleFiles: true,
            allowMultipleRaces: false,
            showEducationArray: true,

            profilePicFile: null,
            profilePicImage: null,
            profilePicPath: null,
            coverPicFile: null,
            coverPicImage: null,
            coverPicPath: null,
            firstNameValue: '',
            lastNameValue: '',
            careerGoalValue: '',
            linkedInURL: '',
            resumeURLValue: '',
            customWebsiteURL: '',

            degree: '',
            major: '',
            gradYear: '',
            race: '',
            gender: '',
            veteranStatus: '',
            workAuthorization: '',
            collaborators: [],

            profilePicHasChanged: false,
            coverPicHasChanged: false,
            textFormHasChanged: false,
            isWaiting: false,

            dateOptions: [],
            educationDateOptions: [],
            functionOptions: [],
            industryOptions: [],
            payOptions: [],
            hoursPerWeekOptions: [],
            hourOptions: [],
            projectCategoryOptions: [],
            binaryOptions: ['', 'Yes','No'],
            collaboratorOptions: [],
            raceOptions: [],
            genderOptions: [],
            preferredPronounOptions: [],
            veteranStatusOptions: ["","I am a protected veteran","I am an unprotected veteran","I am not a veteran","Do not disclose"],
            workAuthorizationOptions: [],
            registrationOptions: [],
            hometownOptions: [],
            schoolOptions: [],
            pathwayOptions: [],
            newPathwayOptions: [],
            gradYearOptions: [],
            degreeOptions: [],
            educationStatusOptions: [],
            basicCountOptions: [],
            numberOfMembersOptions: [],
            householdIncomeOptions: [],
            fosterYouthOptions: [],
            homelessOptions: [],
            incarceratedOptions: [],
            adversityListOptions: [],
            tenureOptions: [{value: '0 - 1 Year'}, {value: '1 - 2 Years'},{value: '2 -4 Years'},{value: '4 - 6 Years'},{value: '6 - 10 Years'},{value: '10+ Years'}],
            ratingOptions: [{value: ''},{value: '0'},{value: '1'},{value: '2'},{value: '3'},{value: '4'},{value: '5'}],
            politicalAlignmentOptions: ['','Liberal','Moderately Liberal','Conservative','Moderately Conservative','Independent'],
            publicPreferenceOptions: ['','All','Some','None'],
            publicProfileExtentOptions: ['','Only Connections','Only Connections and Members','Public'],
            postOptions: [],
            projectOptions: [],
            goalOptions: [],
            passionOptions: [],
            assessmentOptions: [],
            endorsementOptions: [],
            careerGoalOptions: [],
            resumeOptions: [],
            estimatedHoursOptions: [],
            certificateOptions: [],
            addCertificateMethodOptions: ['','Yes, select from options','No, create a new certificate'],
            certificateDegreeTypeOptions: ['','Certificate','Badge','Certification'],

            projects: [],
            experience: [],
            isEditingProjectsArray: [],
            isEditingExperienceArray: [],
            isEditingExtracurricularArray: [],
            isEditingAwardArray: [],

            projectHasChanged: false,
            projectHasChangedArray: [],
            experienceHasChanged: false,
            experienceHasChangedArray: [],
            extracurricularHasChangedArray: [],
            awardHasChangedArray: [],

            extracurriculars: [],
            awards: [],

            publicPosts: [],
            publicProjects: [],
            publicGoals: [],
            publicPassions: [],
            publicAssessments: [],
            publicEndorsements: [],
            careerGoals: [],

            errorMessage: '',
            successMessage: '',
            serverErrorMessageProfilePic: '',
            serverSuccessMessageProfilePic: ''

        }

        this.addItem = this.addItem.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.fetchAllProfileData = this.fetchAllProfileData.bind(this)
        this.renderProjects = this.renderProjects.bind(this)
        this.renderExperience = this.renderExperience.bind(this)
        this.renderExtras = this.renderExtras.bind(this)
        this.saveProject = this.saveProject.bind(this)
        this.saveExperience = this.saveExperience.bind(this)
        this.saveExtras = this.saveExtras.bind(this)
        this.changeContinual = this.changeContinual.bind(this)
        this.deleteItem = this.deleteItem.bind(this)

        this.closeModal = this.closeModal.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.removeTag = this.removeTag.bind(this)
        this.verifyLegalAge = this.verifyLegalAge.bind(this)

        this.switchPreferences = this.switchPreferences.bind(this)
        this.savePreferences = this.savePreferences.bind(this)
        this.itemSelected = this.itemSelected.bind(this)

        this.imgError = this.imgError.bind(this)
        this.passData = this.passData.bind(this)
        this.passProjectState = this.passProjectState.bind(this)

        this.searchSchools = this.searchSchools.bind(this)
        this.segueToApp = this.segueToApp.bind(this)

        this.prepareProjectDetails = this.prepareProjectDetails.bind(this)

        this.renderProfilePic = this.renderProfilePic.bind(this)
        this.renderProfileBasics = this.renderProfileBasics.bind(this)
        this.renderResumeModule = this.renderResumeModule.bind(this)
        this.renderProfileDetails = this.renderProfileDetails.bind(this)
        this.renderProfilePrivacyPreferences = this.renderProfilePrivacyPreferences.bind(this)
        this.configureModal = this.configureModal.bind(this)
        this.testBirthdate = this.testBirthdate.bind(this)

        this.renderEducation = this.renderEducation.bind(this)
        this.renderCertifications = this.renderCertifications.bind(this)
        this.renderCareerInfo = this.renderCareerInfo.bind(this)
        this.renderPersonalInfo = this.renderPersonalInfo.bind(this)
        this.markResume = this.markResume.bind(this)
        this.updatePrimaryResume = this.updatePrimaryResume.bind(this)

        this.toggleConfirmDelete = this.toggleConfirmDelete.bind(this)
        this.renderConfirmDeleteWidget = this.renderConfirmDeleteWidget.bind(this)
    }

    componentDidMount() {
      this.retrieveData()
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called within edit profile ', this.props.location, prevProps.location)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        this.retrieveData()
      } else if (this.props.objectId !== prevProps.objectId) {
        this.retrieveData()
      } else if (this.props.location && prevProps.location && this.props.location.pathname !== prevProps.location.pathname) {
        console.log('got in!')

        this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called')

      let email = localStorage.getItem('email');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgName = localStorage.getItem('orgName')
      let orgFocus = localStorage.getItem('orgFocus');
      let roleName = localStorage.getItem('roleName');
      let remoteAuth = localStorage.getItem('remoteAuth')

      let publicProfileExtentOptions = ['','Only Connections','Only Connections and Members','Public']
      if (remoteAuth) {
        publicProfileExtentOptions = ['','Only Connections','Only Connections and Members']
      }

      const currentPath = window.location.pathname
      const objectId = this.props.objectId

      let placementAgency = false
      if (activeOrg === 'bixel' || activeOrg === 'c2c' || activeOrg === 'exp' || activeOrg === 'unite-la') {
        placementAgency = true
      } else if (orgFocus === 'Placement') {
        placementAgency = true
      }

      if (this.props.passedProfile) {
        email = this.props.passedProfile.email
        username = this.props.passedProfile.username
      }

      this.setState({ activeOrg, emailId: email, username, orgFocus, roleName, currentPath,
        publicProfileExtentOptions, remoteAuth, orgName, placementAgency
      })

      const collaboratorOptions = [{value: '0'}, {value: '1'},{value: '2'}, {value: '3'}, {value: '4'}, {value: '5'}]
      // const hourOptions = [{value: ''}, {value: '< 10'},{value: '10 - 50'}, {value: '50 - 100'}, {value: '100 - 500'}, {value: '500 - 1000'}, {value: '1000 - 5000'}, {value: '5000 - 10000'}, {value: '10000+'}]

      let dateOptions = []
      let educationDateOptions = []

      const currentMonth = new Date().getMonth()
      const currentYear = new Date().getFullYear()

      let numberOfYears = 25
      let educationBump = 5
      let month = ''
      let year = currentYear - numberOfYears

      // console.log('show me current stuff', currentMonth, currentYear)
      for (let i = 1; i <= ((numberOfYears + educationBump) * 12); i++) {
        // console.log('show me stuff', i, (i + currentMonth + 1) % 12)
        if ((i + currentMonth + 1) % 12 === 2) {
          month = 'January'
        } else if ((i + currentMonth + 1) % 12 === 3) {
          month = 'February'
        } else if ((i + currentMonth + 1) % 12 === 4) {
          month = 'March'
        } else if ((i + currentMonth + 1) % 12 === 5) {
          month = 'April'
        } else if ((i + currentMonth + 1) % 12 === 6) {
          month = 'May'
        } else if ((i + currentMonth + 1) % 12 === 7) {
          month = 'June'
        } else if ((i + currentMonth + 1) % 12 === 8) {
          month = 'July'
        } else if ((i + currentMonth + 1) % 12 === 9) {
          month = 'August'
        } else if ((i + currentMonth + 1) % 12 === 10) {
          month = 'September'
        } else if ((i + currentMonth + 1) % 12 === 11) {
          month = 'October'
        } else if ((i + currentMonth + 1) % 12 === 0) {
          month = 'November'
        } else if ((i + currentMonth + 1) % 12 === 1) {
          month = 'December'
        }

        if (month === 'January') {
          year = year + 1
        }

        // dateOptions.push({ value: month + ' ' + year})
        if (i <= (numberOfYears * 13)) {
          dateOptions.push({ value: month + ' ' + year})
        }
        educationDateOptions.push({ value: month + ' ' + year })

      }

      const startDate = dateOptions[dateOptions.legnth - 1]
      const endDate = dateOptions[dateOptions.length - 1]

      Axios.get('/api/org', { params: { orgCode: activeOrg } })
      .then((response) => {
        console.log('Org info query attempted cc', response.data);

          if (response.data.success) {
            console.log('org info query worked')

            let schoolOptions = []
            if (response.data.orgInfo.schools) {
              schoolOptions = response.data.orgInfo.schools
              schoolOptions.unshift('')
            }

            let pathwayOptions = []
            if (response.data.orgInfo.pathways) {
              pathwayOptions = response.data.orgInfo.pathways
              pathwayOptions.unshift({ name: ''})
            }

            let gradYearOptions = []
            if (response.data.orgInfo.gradYearOptions) {
              gradYearOptions = response.data.orgInfo.gradYearOptions
              gradYearOptions.unshift('')
            }

            let projectCategoryOptions = [{value: 'I am not sure'}]
            if (activeOrg === 'c2c') {
              if (response.data.orgInfo.projectCategories) {
                for (let i = 1; i <= response.data.orgInfo.projectCategories.length; i++) {
                  if (i > 1) {
                    projectCategoryOptions.push({ value: response.data.orgInfo.projectCategories[i - 1]})
                  }
                }
              }
            }

            let raceOptions = []
            if (response.data.orgInfo.raceOptions && response.data.orgInfo.raceOptions.length > 0) {
              raceOptions = response.data.orgInfo.raceOptions
              raceOptions.unshift('')
            }

            const degree = response.data.orgInfo.degreeType

            this.fetchAllProfileData(email, degree, activeOrg)

            const requirePersonalInfo = response.data.orgInfo.requirePersonalInfo
            const includeAlternativeContacts = response.data.orgInfo.includeAlternativeContacts
            const allowMultipleRaces = response.data.orgInfo.allowMultipleRaces
            const disableUploadingResume = response.data.orgInfo.disableUploadingResume
            // let certificateOptions = []
            // if (response.data.orgInfo.certificateOptions) {
            //   certificateOptions = [{ name: '' }].concat(response.data.orgInfo.certificateOptions)
            // }

            this.setState({ requirePersonalInfo, includeAlternativeContacts, allowMultipleRaces, disableUploadingResume })

            Axios.get('/api/courses', { params: { orgCode: activeOrg, courseTypes: ['Certificate','Badge','Certification'] } })
            .then((response) => {
              console.log('course query attempted')
              if (response.data.success && response.data.courses && response.data.courses.length > 0) {
                console.log('Course details query worked', response.data);

                let certificateOptions = [{ name: '' }].concat(response.data.courses)
                this.setState({ certificateOptions })

              } else {
                console.log('no courses found', response.data.message)
              }

            }).catch((error) => {
                console.log('Course query did not work', error);
            });

            Axios.get('/api/workoptions')
            .then((response) => {
              console.log('Work options query tried', response.data);

              if (response.data.success) {
                console.log('Work options query succeeded')

                let functionOptions = [{value: 'I am not sure'}]
                for (let i = 1; i <= response.data.workOptions[0].functionOptions.length; i++) {
                  if (i > 1) {
                    functionOptions.push({ value: response.data.workOptions[0].functionOptions[i - 1]})
                  }
                }

                let industryOptions = [{value: 'I am not sure'}]
                for (let i = 1; i <= response.data.workOptions[0].industryOptions.length; i++) {
                  if (i > 1) {
                    industryOptions.push({ value: response.data.workOptions[0].industryOptions[i - 1]})
                  }
                }

                let workDistanceOptions = [{value: '0 miles'},{value: '10 miles'}]
                for (let i = 1; i <= response.data.workOptions[0].workDistanceOptions.length; i++) {
                  if (i > 1) {
                    workDistanceOptions.push({ value: response.data.workOptions[0].workDistanceOptions[i - 1]})
                  }
                }

                let hoursPerWeekOptions = [{value: ''}]
                for (let i = 1; i <= response.data.workOptions[0].hoursPerWeekOptions.length; i++) {
                  if (i > 1) {
                    hoursPerWeekOptions.push({ value: response.data.workOptions[0].hoursPerWeekOptions[i - 1]})
                  }
                }

                let hourOptions = [{value: ''}]
                for (let i = 1; i <= response.data.workOptions[0].hourOptions.length; i++) {
                  if (i > 1) {
                    hourOptions.push({ value: response.data.workOptions[0].hourOptions[i - 1]})
                  }
                }

                let workTypeOptions = [{value: 'Internship'}]
                for (let i = 1; i <= response.data.workOptions[0].workTypeOptions.length; i++) {
                  if (i > 1) {
                    workTypeOptions.push({ value: response.data.workOptions[0].workTypeOptions[i - 1]})
                  }
                }

                let hourlyPayOptions = [{value: 'Flexible'}]
                for (let i = 1; i <= response.data.workOptions[0].hourlyPayOptions.length; i++) {
                  if (i > 1) {
                    hourlyPayOptions.push({ value: response.data.workOptions[0].hourlyPayOptions[i - 1]})
                  }
                }

                let annualPayOptions = [{value: 'I am not sure'}]
                for (let i = 1; i <= response.data.workOptions[0].annualPayOptions.length; i++) {
                  if (i > 1) {
                    annualPayOptions.push({ value: response.data.workOptions[0].annualPayOptions[i - 1]})
                  }
                }

                const degreeOptions = response.data.workOptions[0].degreeOptions
                const educationStatusOptions = degreeOptions.concat(['Not currently enrolled in school'])

                // let gradYearOptions = []
                const startingPoint = new Date().getFullYear() - 6
                for (let i = 1; i <= 10; i++) {
                  gradYearOptions.push(startingPoint + i)
                }

                // let projectCategoryOptions = [{value: 'I am not sure'}]
                for (let i = 1; i <= response.data.workOptions[0].projectCategoryOptions.length; i++) {
                  if (i > 1) {
                    projectCategoryOptions.push({ value: response.data.workOptions[0].projectCategoryOptions[i - 1]})
                  }
                }

                // gradYearOptions.push('Other')

                let politicalAlignmentOptions = ['']
                if (response.data.workOptions[0].individualPoliticalAlignmentOptions) {
                  for (let i = 1; i <= response.data.workOptions[0].individualPoliticalAlignmentOptions.length; i++) {
                    politicalAlignmentOptions.push(response.data.workOptions[0].individualPoliticalAlignmentOptions[i - 1])
                  }
                }

                let registrationOptions = ['']
                if (response.data.workOptions[0].unitedStateOptions) {
                  for (let i = 1; i <= response.data.workOptions[0].unitedStateOptions.length; i++) {
                    registrationOptions.push(response.data.workOptions[0].unitedStateOptions[i - 1])
                  }
                }

                let hometownOptions = registrationOptions

                const basicCountOptions = ['','1','2','3','4','5','6','7','8','9','10']
                let householdIncomeOptions = response.data.workOptions[0].lowIncomeOptions
                householdIncomeOptions.unshift('')
                let fosterYouthOptions = response.data.workOptions[0].fosterYouthOptions
                fosterYouthOptions.unshift('')
                let homelessOptions = response.data.workOptions[0].homelessOptions
                homelessOptions.unshift('')
                let incarceratedOptions = response.data.workOptions[0].incarceratedOptions
                incarceratedOptions.unshift('')

                let adversityListOptions = []
                if (response.data.workOptions[0].adversityListOptions) {
                  adversityListOptions = response.data.workOptions[0].adversityListOptions
                }

                const careerGoalOptions = response.data.workOptions[0].careerGoalOptions

                // let raceOptions = ['']
                if (raceOptions.length < 2) {
                  if (response.data.workOptions[0].raceOptions) {
                    raceOptions = response.data.workOptions[0].raceOptions
                    raceOptions.unshift('')
                  }
                }

                let genderOptions = ['']
                if (response.data.workOptions[0].genderOptions) {
                  for (let i = 1; i <= response.data.workOptions[0].genderOptions.length; i++) {
                    genderOptions.push(response.data.workOptions[0].genderOptions[i - 1])
                  }
                }

                let preferredPronounOptions = ['']
                if (response.data.workOptions[0].preferredPronounOptions) {
                  for (let i = 1; i <= response.data.workOptions[0].preferredPronounOptions.length; i++) {
                    preferredPronounOptions.push(response.data.workOptions[0].preferredPronounOptions[i - 1])
                  }
                }

                let numberOfMembersOptions = ['']
                if (response.data.workOptions[0].numberOfMembersOptions) {
                  for (let i = 1; i <= response.data.workOptions[0].numberOfMembersOptions.length; i++) {
                    numberOfMembersOptions.push(response.data.workOptions[0].numberOfMembersOptions[i - 1])
                  }
                }

                let workAuthorizationOptions = ['']
                if (response.data.workOptions[0].workAuthorizationOptions) {
                  for (let i = 1; i <= response.data.workOptions[0].workAuthorizationOptions.length; i++) {
                    workAuthorizationOptions.push(response.data.workOptions[0].workAuthorizationOptions[i - 1])
                  }
                }

                const estimatedHoursOptions = response.data.workOptions[0].durationOptions

                this.setState({ functionOptions, industryOptions, gradYearOptions, pathwayOptions,
                  workDistanceOptions, hoursPerWeekOptions, workTypeOptions, hourlyPayOptions, payOptions: annualPayOptions,
                  projectCategoryOptions, dateOptions,educationDateOptions, collaboratorOptions, hourOptions, degreeOptions, educationStatusOptions,
                  politicalAlignmentOptions, registrationOptions, hometownOptions, schoolOptions,
                  basicCountOptions, householdIncomeOptions, fosterYouthOptions, homelessOptions, incarceratedOptions,
                  adversityListOptions, careerGoalOptions, raceOptions, genderOptions, preferredPronounOptions,
                  numberOfMembersOptions,
                  workAuthorizationOptions, estimatedHoursOptions
                })

              } else {
                console.log('no jobFamilies data found', response.data.message)

                const functionOptions = [{value: 'Undecided'}]
                const industryOptions = [{value: 'Undecided'}]
                //const workDistanceOptions = [{value: '0 miles'},{value: '10 miles'}]
                const hoursPerWeekOptions = [{value: '~ 40 hours / week'}]
                //const workTypeOptions = [{value: 'Internship'}]
                //const hourlyPayOptions = [{value: 'Flexible'}]
                const payOptions = [{value: 'Flexible'}]
                const hourOptions = [{value: ''}]

                this.setState({ functionOptions, industryOptions, hoursPerWeekOptions, payOptions, dateOptions, collaboratorOptions, hourOptions, startDate, endDate })

              }
            }).catch((error) => {
                console.log('query for work options did not work', error);
            })

          } else {
            console.log('org info query did not work', response.data.message)
          }

      }).catch((error) => {
          console.log('Org info query did not work for some reason', error);
      });

      Axios.get('/api/pathways', { params: { orgCode: activeOrg }})
      .then((response) => {
        console.log('Pathways query attempted no 1', response.data);

        if (response.data.success) {
          console.log('pathway query worked no 1')

          if (response.data.pathways.length !== 0) {

            let newPathwayOptions = []
            if (response.data.pathways && response.data.pathways.length > 0) {
              newPathwayOptions = [{ title: '' }].concat(response.data.pathways)
            }

            this.setState({ newPathwayOptions });
          }

        } else {
          console.log('pathway query did not work', response.data.message)
        }

      }).catch((error) => {
          console.log('Pathway query did not work for some reason', error);
      });

      // let problemPlatformTeacher = false
      // let problemPlatformMentor = false
      // if (window.location.pathname.includes('/problem-platform')) {
      //   if (this.state.roleName === 'Teacher') {
      //     problemPlatformTeacher = true
      //   }
      //   if (this.state.roleName === 'Mentor') {
      //     problemPlatformMentor = true
      //   }
      // }
    }

    fetchAllProfileData (email, orgDegree, activeOrg) {
      console.log('about to fetchAllProfileData', email, orgDegree, activeOrg)

      Axios.get('/api/users/profile/details/' + email)
      .then((response) => {
        if (response.data.success) {

          const responseData = response.data

          let resumeName = null
          const resumeURLValue = responseData.user.resumeURL
          if (resumeURLValue) {
            if (resumeURLValue.split("%7C")[2]) {
              resumeName = resumeURLValue.split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
            } else {
              resumeName = 'Resume uploaded'
            }
          }

          let resumes  = responseData.user.resumes
          let resumeNames = []
          if (resumes && resumes.length > 0) {
            resumes = []
            for (let i = 1; i <= responseData.user.resumes.length; i++) {
              resumes.push({ url: responseData.user.resumes[i - 1], source: 'Upload' })
              if (responseData.user.resumes[i - 1].split("%7C")[2]) {
                resumeNames.push(responseData.user.resumes[i - 1].split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+"))
              } else {
                resumeNames.push('Resume File #' + i)
              }
            }
          } else {
            if (resumeURLValue) {
              resumes.push({ url: resumeURLValue, source: 'Upload' })
              resumeNames.push(resumeName)
            }
          }

          let degree = ''
          if (responseData.user.degree) {
            degree = responseData.user.degree
          } else if (orgDegree) {
            degree = orgDegree
          }

          let confirmUsername = true
          if (responseData.user.uniqueUsername) {
            confirmUsername = false
          }

          const publicProfile = responseData.user.publicProfile
          const publicProfileExtent = responseData.user.publicProfileExtent
          const publicPreferences = responseData.user.publicPreferences
          const headline = responseData.user.headline

          // eventually render publicPreferences directly
          let postPublicPreference = null
          let publicPosts = []
          let projectPublicPreference = null
          let publicProjects = []
          let goalPublicPreference = null
          let publicGoals = []
          let passionPublicPreference = null
          let publicPassions = []
          let assessmentPublicPreference = null
          let publicAssessments = []
          let endorsementPublicPreference = null
          let publicEndorsements = []
          let resumePublicPreference = null
          let publicResume = null
          let publicResumeName = null

          if (publicPreferences) {
            for (let i = 1; i <= publicPreferences.length; i++) {
              if (publicPreferences[i - 1].name === 'Post') {
                postPublicPreference = publicPreferences[i - 1].value
                publicPosts = publicPreferences[i - 1].publicItems
              } else if (publicPreferences[i - 1].name === 'Project') {
                projectPublicPreference = publicPreferences[i - 1].value
                publicProjects = publicPreferences[i - 1].publicItems
              } else if (publicPreferences[i - 1].name === 'Goal') {
                goalPublicPreference = publicPreferences[i - 1].value
                publicGoals = publicPreferences[i - 1].publicItems
              } else if (publicPreferences[i - 1].name === 'Passion') {
                passionPublicPreference = publicPreferences[i - 1].value
                publicPassions = publicPreferences[i - 1].publicItems
              } else if (publicPreferences[i - 1].name === 'Assessment') {
                assessmentPublicPreference = publicPreferences[i - 1].value
                publicAssessments = publicPreferences[i - 1].publicItems
              } else if (publicPreferences[i - 1].name === 'Endorsement') {
                endorsementPublicPreference = publicPreferences[i - 1].value
                publicEndorsements = publicPreferences[i - 1].publicItems
              } else if (publicPreferences[i - 1].name === 'Resume') {
                resumePublicPreference = publicPreferences[i - 1].value
                if (publicPreferences[i - 1].publicItems && publicPreferences[i - 1].publicItems.length > 0) {
                  publicResume = publicPreferences[i - 1].publicItems[0]
                  publicResumeName = publicPreferences[i - 1].publicItems[0].split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
                }
              }
            }
          }

          let education = []
          if (activeOrg === 'unite-la') {
            education = [{ name: '' }]
          }

          if (responseData.user.education && responseData.user.education.length > 0) {
            education = responseData.user.education
          }

          let certificates = []
          if (responseData.user.certificates && responseData.user.certificates.length > 0) {
            certificates = responseData.user.certificates
          }

          // console.log('show me education: ', education)
          this.setState({
            firstNameValue: responseData.user.firstName,
            lastNameValue: responseData.user.lastName,
            linkedInURL: responseData.user.linkedInURL,
            resumeURLValue, resumeName,
            resumes, resumeNames,
            customWebsiteURL: responseData.user.customWebsiteURL,
            instagramURL: responseData.user.instagramURL,
            facebookURL: responseData.user.facebookURL,
            twitterURL: responseData.user.twitterURL,
            videoResumeURL: responseData.user.videoResumeURL,
            educationStatus: responseData.user.educationStatus,
            education, certificates,
            school: responseData.user.school,
            schoolName: responseData.user.school,
            schoolDistrict: responseData.user.schoolDistrict,
            degree, orgDegree,
            degreeAttained: responseData.user.degree,
            major: responseData.user.major,
            pathway: responseData.user.pathway,
            gradYear: responseData.user.gradYear,
            race: responseData.user.race,
            races: responseData.user.races,
            selfDescribedRace: responseData.user.selfDescribedRace,
            gender: responseData.user.gender,
            preferredPronoun: responseData.user.preferredPronoun,
            language: responseData.user.language,
            country: responseData.user.country,
            veteranStatus: responseData.user.veteran,
            workAuthorization: responseData.user.workAuthorization,
            rawPictureURL: responseData.user.rawPictureURL,
            pictureURL: responseData.user.pictureURL,
            profilePicPath: responseData.user.profilePicPath,
            remoteAuth: responseData.user.remoteAuth,
            politicalAlignment: responseData.user.politicalAlignment,
            stateRegistration: responseData.user.stateRegistration,
            currentCongressionalDistrict: responseData.user.currentCongressionalDistrict,
            hometown: responseData.user.hometown,
            homeCongressionalDistrict: responseData.user.homeCongressionalDistrict,
            dacaStatus: responseData.user.dacaStatus,
            successDefined: responseData.user.successDefined,

            dateOfBirth: responseData.user.dateOfBirth,
            phoneNumber: responseData.user.phoneNumber,
            alternativeEmail: responseData.user.alternativeEmail,
            alternativePhoneNumber: responseData.user.alternativePhoneNumber,
            address: responseData.user.address,
            city: responseData.user.city,
            numberOfMembers: responseData.user.numberOfMembers,
            householdIncome: responseData.user.householdIncome,
            fosterYouth: responseData.user.fosterYouth,
            homeless: responseData.user.homeless,
            incarcerated: responseData.user.incarcerated,
            adversityList: responseData.user.adversityList,

            jobTitle: responseData.user.jobTitle,
            employerName: responseData.user.employerName,
            zipcode: responseData.user.zipcode,
            workTenure: responseData.user.workTenure,
            overallFit: responseData.user.overallFit,
            studyFields: responseData.user.studyFields,
            careerTrack: responseData.user.careerTrack,
            homeNumber: responseData.user.homeNumber,
            lastOfSocialSecurity: responseData.user.lastOfSocialSecurity,
            IEPPlan: responseData.user.IEPPlan,
            parentName: responseData.user.parentName,
            parentRelationship: responseData.user.parentRelationship,
            parentPhone: responseData.user.parentPhone,
            parentEmail: responseData.user.parentEmail,
            emergencyContactName: responseData.user.emergencyContactName,
            emergencyContactRelationship: responseData.user.emergencyContactRelationship,
            emergencyContactPhone: responseData.user.emergencyContactPhone,
            emergencyContactEmail: responseData.user.emergencyContactEmail,

            careerGoals: responseData.user.careerGoals,
            oauthUid: responseData.user.oauthUid,
            location: responseData.user.location,

            careerStatus: responseData.user.careerStatus,
            dreamCareer: responseData.user.dreamCareer,
            dreamCompany: responseData.user.dreamCompany,
            primaryCareerInterest: responseData.user.primaryCareerInterest,
            secondaryCareerInterest: responseData.user.secondaryCareerInterest,
            tertiaryCareerInterest: responseData.user.tertiaryCareerInterest,

            confirmUsername, publicProfile, publicProfileExtent, publicPreferences,
            postPublicPreference, publicPosts,
            projectPublicPreference, publicProjects,
            goalPublicPreference, publicGoals,
            passionPublicPreference, publicPassions,
            assessmentPublicPreference, publicAssessments,
            endorsementPublicPreference, publicEndorsements,
            resumePublicPreference, publicResume, publicResumeName,
            headline
          });

          let docQueryLink = '/api/resumes'
          if (this.state.showDoc) {
            docQueryLink = '/api/documents'
          }

          Axios.get(docQueryLink, { params: { emailId: email } })
         .then((response) => {
           console.log('Resumes query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved resumes')

             let docs = response.data.resumes
             let slug = "resumes"
             if (this.state.showDoc) {
               docs = response.data.documents
               slug = "documents"
             }

             if (docs) {
               for (let i = 0; i < docs.length; i++) {
                 if ((this.state.showDoc && docs[i].docType === 'Resume') || !this.state.showDoc) {
                   console.log('tester', i)
                   resumes.push({ url: window.location.protocol + "//" + window.location.host + "/" + slug + "/preview/" + docs[i]._id, source: 'Native', _id: docs[i]._id })
                   resumeNames.push(docs[i].fileName.replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+"))
                 }
               }
             }

             this.setState({ resumes, resumeNames })

           } else {
             console.log('no resumes data found', response.data.message)
           }

         }).catch((error) => {
             console.log('Resumes query did not work', error);
         });

        }
      }).catch((error) => {
          console.log('Profile pic fetch did not work', error);
      });

      Axios.get('/api/projects', { params: { emailId: email, includeCollaborations: true } })
      .then((response) => {
        console.log('Projects query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved projects')

            if (response.data.projects) {

              const projects = response.data.projects
              if (projects.length > 0) {
                console.log('the array is greater than 0')

                let isEditingProjectsArray = []
                let projectHasChangedArray = []

                let showGrade = false
                let modalIsOpen = false
                let selectedIndex = 0

                let collaboratorProjects = []

                for (let i = 0; i < projects.length; i++) {
                  console.log('let see iteration', i)
                  isEditingProjectsArray.push(false)
                  projectHasChangedArray.push(false)

                  // lets not worry about this now
                  // if (projects[i].collaborators && projects[i].collaborators.length > 0) {
                  //   for (let j = 0; j < projects[i].collaborators.length; j++) {
                  //     if (!projects[i].collaborators[j].joined) {
                  //       let projectToAdd = projects[i]
                  //       projectToAdd['collaboratorEmail'] = projects[i].collaborators[j].email
                  //       collaboratorProjects.push(projectToAdd)
                  //     }
                  //   }
                  // }

                  //if coming from notifications
                  const objectId = this.props.objectId
                  if (objectId) {

                    // let objectId = this.props.location.state.objectId
                    let localObjectId = localStorage.getItem('objectId');

                    console.log('compare projectids: ', projects[i]._id, objectId)
                    if (localObjectId && projects[i]._id === objectId) {

                      showGrade = true
                      modalIsOpen = true
                      selectedIndex = i
                    }
                  }
                }

                const projectOptions = [{ name: 'Select a project' }].concat(projects)

                this.setState({
                  projects, isEditingProjectsArray, projectHasChangedArray,
                  showGrade, modalIsOpen, selectedIndex, projectOptions
                })

                // update collaborators
                console.log('show collaboratorProjects: ', collaboratorProjects.length)

                // pull info by collaboratorEmail
                // lets not worry about this now

              }
            }

          } else {
            console.log('no project data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Project query did not work', error);
      });

      Axios.get('/api/experience', { params: { emailId: email } })
      .then((response) => {
        console.log('Experience query attempted', response.data);

          if (response.data.success) {
            console.log('successfully retrieved experience')

            if (response.data.experience) {

              const experience = response.data.experience
              if (experience.length > 0) {
                console.log('the array is greater than 0')

                let isEditingExperienceArray = []
                let experienceHasChangedArray = []

                for (let i = 0; i < experience.length; i++) {
                  console.log('let see iteration', i)
                  isEditingExperienceArray.push(false)
                  experienceHasChangedArray.push(false)
                }

                this.setState({ experience, isEditingExperienceArray, experienceHasChangedArray })

              }
            }

          } else {
            console.log('no experience data found', response.data.message)
          }

      }).catch((error) => {
          console.log('Experience query did not work', error);
      });

      // console.log('about to extra')
      Axios.get('/api/extras', { params: { emailId: email } })
      .then((response) => {
        console.log('Extras query attempted', response.data);

        if (response.data.success) {
          console.log('successfully retrieved extras')

          if (response.data.extras) {

            const extras = response.data.extras
            if (extras.length > 0) {
              console.log('the array is greater than 0')

              let extracurriculars = []
              let isEditingExtracurricularArray = []
              let extracurricularHasChangedArray = []

              let awards = []
              let isEditingAwardArray = []
              let awardHasChangedArray = []

              for (let i = 0; i < extras.length; i++) {
                if (extras[i].type === 'Extracurricular') {
                  extracurriculars.push(extras[i])
                  isEditingExtracurricularArray.push(false)
                  extracurricularHasChangedArray.push(false)
                } else if (extras[i].type === 'Award') {
                  awards.push(extras[i])
                  isEditingAwardArray.push(false)
                  awardHasChangedArray.push(false)
                }
              }

              this.setState({
                extracurriculars, isEditingExtracurricularArray, extracurricularHasChangedArray,
                awards,isEditingAwardArray, awardHasChangedArray
              })

            }
          }

        } else {
          console.log('no experience data found', response.data.message)
        }

      }).catch((error) => {
          console.log('Experience query did not work', error);
      });

      if (window.location.pathname.includes('public')) {
        // goalOptions for public profile
        Axios.get('/api/logs/goals', { params: { emailId: email } })
        .then((response) => {

            if (response.data.success) {
              console.log('Goals received query worked', response.data);

              let goalOptions = [{ title: 'Select a goal'}].concat(response.data.goals)
              this.setState({ goalOptions })

            } else {
              console.log('no goal data found', response.data.message)
            }

        }).catch((error) => {
            console.log('Goal query did not work', error);
        });

        // passionOptions for public profile
        Axios.get('/api/logs/passions', { params: { emailId: email } })
        .then((response) => {

            if (response.data.success) {
              console.log('Passions received query worked', response.data);

              let passionOptions = [{ passionTitle: 'Select a passion'}].concat(response.data.passions)
              this.setState({ passionOptions })

            } else {
              console.log('no passion data found', response.data.message)
            }

        }).catch((error) => {
            console.log('Passion query did not work', error);
        });

        Axios.get('/api/assessment/results', { params: { emailId: email } })
         .then((response) => {
           console.log('query for assessment results worked');

           if (response.data.success) {

             console.log('actual assessment results', response.data)

             if (response.data.results) {

               let assessmentOptions = ["Select an Assessment"]
               // const updatedAt = response.data.results.updatedAt
               if (response.data.results.workPreferenceAnswers && response.data.results.workPreferenceAnswers.length > 0) {
                 assessmentOptions.push('Work Preferences')
               }
               if (response.data.results.interestScores && response.data.results.interestScores.length > 0) {
                 assessmentOptions.push('Interests')
               }
               if (response.data.results.skillsScores && response.data.results.skillsScores.length > 0) {
                 assessmentOptions.push('Skills')
               }
               if (response.data.results.personalityScores) {
                 assessmentOptions.push('Personality')
               }
               if (response.data.results.topGravitateValues && response.data.results.topGravitateValues.length > 0 && response.data.results.topEmployerValues && response.data.results.topEmployerValues.length > 0) {
                 assessmentOptions.push('Values')
               }

               this.setState({ assessmentOptions });
             }

           } else {
             console.log('error response', response.data)

             this.setState({ resultsErrorMessage: response.data.message })
           }

       }).catch((error) => {
           console.log('query for assessment results did not work', error);
       })

        // retrieve endorsements
        Axios.get('/api/story', { params: { emailId: email } })
        .then((response) => {
            console.log('Story query worked', response.data);

            if (response.data.success) {

              const endorsementOptions = [{ senderFirstName: 'Select an Endorsement' }].concat(response.data.stories)
              this.setState({ endorsementOptions })
            }

        }).catch((error) => {
            console.log('Story query did not work', error);
        });
      }
    }

    componentDidCatch(error, info) {
      console.log('componentDidCatch called:', error, info)
    }

    formChangeHandler = event => {
      console.log('show data: ', event.target.name)

      if (event.target.name === 'profilePic') {
        console.log('profilePicSelectedHandler changed', event.target.files[0])

        if (event.target.files[0]) {
          const mbLimit = 10
          if (event.target.files[0].size > mbLimit * 1024 * 1024) {
            console.log('file is too big')

            const errorMessage = 'File must be less than ' + mbLimit + 'MB. This file is ' + (event.target.files[0].size / (1024 * 1024)).toFixed() + 'MB'
            this.setState({ serverSuccessProfilePic: false, serverErrorMessageProfilePic: errorMessage })

          } else {
            console.log('file is small enough', event.target.files[0].size)

            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ profilePicImage: e.target.result });
                console.log('how do i access the image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
            // this.setState({ profilePicFile: event.target.files[0], profilePicHasChanged: true })
            this.saveFile(event.target.name, event.target.files[0])
          }
        }

      } else if (event.target.name === 'pictureURL') {
        //only covers Google Drive for now
        // https://drive.google.com/file/d/1x5MeSXzjC4dbmwfESGpspe7WnmkbNKLB/view?usp=sharing
        // https://drive.google.com/uc?export=view&id=1x5MeSXzjC4dbmwfESGpspe7WnmkbNKLB
        const rawPictureURL = event.target.value

        // .indexOf('<saml:Attribute Name="uid"')
        const startString = rawPictureURL.indexOf('/d/')
        const endString = rawPictureURL.indexOf('/view')

        if (startString > 0 && endString > 0 && rawPictureURL.includes('google')) {
          const pictureId = rawPictureURL.substring(startString + 3, endString)
          const pictureURL = 'https://drive.google.com/uc?export=view&id=' + pictureId

          this.setState({ rawPictureURL, pictureURL, textFormHasChanged: true })
          console.log('show pic values 1: ', rawPictureURL, pictureURL)

          if (window.location.pathname.includes('/apply')) {
            this.props.passData('pictureURL', event.target.value, null, 'basic')
          }
        } else {
          const pictureURL = ''
          this.setState({ rawPictureURL, pictureURL, textFormHasChanged: true })
          console.log('show pic values 2: ', rawPictureURL, pictureURL)

          if (window.location.pathname.includes('/apply')) {
            this.props.passData('pictureURL', event.target.value, null, 'basic')
          }
        }

      } else if (event.target.name === 'coverPic') {
        console.log('coverPicSelectedHandler changed', event.target.files[0])

        if (event.target.files[0]) {
            let reader = new FileReader();
            console.log('entered the file reader stage')
            reader.onload = (e) => {
                this.setState({ coverPicImage: e.target.result });
                console.log('how do i access the cover image', e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
            this.setState({ coverPicFile: event.target.files[0], coverPicHasChanged: true })
        }
      } else if (event.target.name.includes('education|')) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let education = this.state.education
        education[index][name] = event.target.value
        this.setState({ education, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData('education', education, null, 'basic')
        }
        if (name === 'name') {
          this.searchSchools(event.target.value)
        }
      } else if (event.target.name.includes('certificate|')) {
        const name = event.target.name.split("|")[1]
        const index = Number(event.target.name.split("|")[2])

        let certificates = this.state.certificates
        certificates[index][name] = event.target.value

        if (name === 'certificateOption') {
          console.log('is name correct 1? ')
          let selectedCertificateOption = null
          for (let i = 1; i <= this.state.certificateOptions.length; i++) {
            if (this.state.certificateOptions[i - 1].name === event.target.value) {
              selectedCertificateOption = this.state.certificateOptions[i - 1]
            }
          }
          console.log('is name correct 2? ', selectedCertificateOption)
          if (selectedCertificateOption) {
            // certificates[index]['name'] = selectedCertificateOption.name
            // certificates[index]['issuer'] = selectedCertificateOption.issuer
            // certificates[index]['estimatedHours'] = selectedCertificateOption.estimatedHours
            // certificates[index]['pathway'] = selectedCertificateOption.pathway
            // certificates[index]['workFunction'] = selectedCertificateOption.workFunction
            // certificates[index]['industry'] = selectedCertificateOption.industry
            // certificates[index]['description'] = selectedCertificateOption.description

            certificates[index]['courseId'] = selectedCertificateOption._id
            certificates[index]['name'] = selectedCertificateOption.name
            certificates[index]['imageURL'] = selectedCertificateOption.imageURL
            certificates[index]['category'] = selectedCertificateOption.category
            certificates[index]['programURL'] = selectedCertificateOption.programURL
            certificates[index]['schoolURL'] = selectedCertificateOption.schoolURL
            certificates[index]['schoolName'] = selectedCertificateOption.schoolName
            certificates[index]['degreeType'] = selectedCertificateOption.degreeType
            certificates[index]['programMethod'] = selectedCertificateOption.programMethod
            certificates[index]['location'] = selectedCertificateOption.location
            certificates[index]['estimatedHours'] = selectedCertificateOption.estimatedHours
            certificates[index]['description'] = selectedCertificateOption.description
            certificates[index]['gradeLevel'] = selectedCertificateOption.gradeLevel
            certificates[index]['knowledgeLevel'] = selectedCertificateOption.knowledgeLevel
            certificates[index]['functions'] = selectedCertificateOption.functions
            certificates[index]['pathways'] = selectedCertificateOption.pathways
            certificates[index]['industry'] = selectedCertificateOption.industry
            certificates[index]['price'] = selectedCertificateOption.price
            certificates[index]['createdAt'] = new Date()
            certificates[index]['updatedAt'] = new Date()

          }
        }

        this.setState({ certificates, textFormHasChanged: true })

        if (window.location.pathname.includes('/apply')) {
          this.props.passData('certificates', certificates, null, 'basic')
        }
        // if (name === 'name') {
        //   this.searchSchools(event.target.value)
        // }
      } else if (event.target.name === 'firstName') {
        this.setState({ firstNameValue: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData('firstName', event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'lastName') {
        this.setState({ lastNameValue: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData('lastName', event.target.value, null, 'basic')
        }

      } else if (event.target.name === 'linkedInURL') {
        this.setState({ linkedInURL: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData('linkedInURL', event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'resumeURL') {
        console.log('resumeURLSelectedHandler changed', event)
        this.setState({ resumeURLValue: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'resume') {
        console.log('profilePicSelectedHandler changed', event.target.files[0])

        if (event.target.files[0]) {
          if ( event.target.files[0].size > 1 * 1024 * 1024) {
            console.log('file is too big')

            const errorMessage = 'File must be less than 1MB.'
            this.setState({ serverSuccessResume: false, serverErrorMessageResume: errorMessage, serverSuccessMessageResume: null })

          } else {
            console.log('file is small enough', event.target.files[0].size)

            let reader = new FileReader();
            reader.onload = (e) => {

              // const resumeName = e.target.result
              // this.setState({ profilePicImage: e.target.result });
              // console.log('how do i access the image', event.target.files[0].name)
            };
            reader.readAsDataURL(event.target.files[0]);
            // this.setState({ profilePicFile: event.target.files[0], profilePicHasChanged: true })
            console.log('how do i access the image', event.target.files[0].name)
            this.saveFile(event.target.name, event.target.files[0])
          }
        }
      } else if (event.target.name === 'customWebsiteURL') {
        console.log('customWebsiteURLSelectedHandler changed', event)
        this.setState({ customWebsiteURL: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData('customWebsiteURL', event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'school') {
        this.setState({ schoolName: event.target.value, textFormHasChanged: true })
        this.searchSchools(event.target.value)
      } else if (event.target.name === 'schoolName') {
        this.setState({ schoolName: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
        this.searchSchools(event.target.value)
      } else if (event.target.name === 'degree') {
        this.setState({ degree: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'degreeAttained') {
        this.setState({ degreeAttained: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'major') {
        this.setState({ major: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'pathway') {
        this.setState({ pathway: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'gradYear') {
        this.setState({ gradYear: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'race') {
        this.setState({ race: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'gender') {
        this.setState({ gender: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'veteranStatus') {
        this.setState({ veteranStatus: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'workAuthorization') {
        this.setState({ workAuthorization: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'overallFit') {
        this.setState({ overallFit: event.target.value, textFormHasChanged: true })
      } else if (event.target.name.includes('projectTitle')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['name'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('projectURL')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['url'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('projectCategory')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['category'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('projectDescription')) {

        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['description'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('startDate')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['startDate'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('endDate')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['endDate'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('collaboratorEmail')) {
        const collaboratorEmail = event.target.value
        let projectHasChanged = true
        this.setState({ collaboratorEmail, projectHasChanged })
      } else if (event.target.name.includes('collaboratorCount')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['collaboratorCount'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('collaborator')) {
        const nameArray = event.target.name.split("|")
        const macroIndex = nameArray[1]
        const microIndex = nameArray[2]

        let projects = this.state.projects
        let collaborators = projects[macroIndex].collaborators
        if (collaborators) {
          collaborators[microIndex] = event.target.value
        } else {
          collaborators = [event.target.value]
        }

        projects[macroIndex]['collaborators'] = collaborators
        console.log('show collaborators: ', projects[macroIndex].collaborators)

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[macroIndex] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('projectHours')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['hours'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('projectTotalHours')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['totalHours'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('projectFocus')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['focus'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('skillTags')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['skillTags'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })

      } else if (event.target.name.includes('industryTags')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['industryTags'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('projectFunction')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['jobFunction'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })

      } else if (event.target.name.includes('projectIndustry')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let projects = this.state.projects
        projects[index]['industry'] = event.target.value

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray[index] = true

        let projectHasChanged = true
        this.setState({ projects, projectHasChanged, projectHasChangedArray })
      } else if (event.target.name.includes('isEditingProjectsArray')) {

        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let isEditingProjectsArray = this.state.isEditingProjectsArray
        if (isEditingProjectsArray[index]) {
          isEditingProjectsArray[index] = false
        } else {
          isEditingProjectsArray[index] = true
        }
        this.setState({ isEditingProjectsArray })
      } else if (event.target.name.includes('isEditingExperienceArray')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let isEditingExperienceArray = this.state.isEditingExperienceArray
        if (isEditingExperienceArray[index]) {
          isEditingExperienceArray[index] = false
        } else {
          isEditingExperienceArray[index] = true
        }
        this.setState({ isEditingExperienceArray })

      } else if (event.target.name.includes('isEditingExtracurricularArray')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let isEditingExtracurricularArray = this.state.isEditingExtracurricularArray
        if (isEditingExtracurricularArray[index]) {
          isEditingExtracurricularArray[index] = false
        } else {
          isEditingExtracurricularArray[index] = true
        }
        this.setState({ isEditingExtracurricularArray })
      } else if (event.target.name.includes('isEditingAwardArray')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let isEditingAwardArray = this.state.isEditingAwardArray
        if (isEditingAwardArray[index]) {
          isEditingAwardArray[index] = false
        } else {
          isEditingAwardArray[index] = true
        }
        this.setState({ isEditingAwardArray })

      } else if (event.target.name.includes('jobTitle|')) {

        const nameArray = event.target.name.split("|")
        const index = nameArray[1]
        console.log('we in here??? job title', this.state.experience, index)
        let experience = this.state.experience
        experience[index]['jobTitle'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('employerName|')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['employerName'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('experienceStartDate')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['startDate'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('experienceEndDate')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['endDate'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('jobFunction')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['jobFunction'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('jobIndustry')) {

        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['industry'] = event.target.value
        console.log('show industry ', nameArray, index, experience)
        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })

      } else if (event.target.name.includes('payInterest')) {
        console.log('in payInterest')
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['payInterest'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })

      } else if (event.target.name.includes('pay')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['pay'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('wasPaid')) {

        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['wasPaid'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('hoursPerWeek')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['hoursPerWeek'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('experienceSkillTags')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['skillTags'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('experienceSupervisorFirstName')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['supervisorFirstName'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('experienceSupervisorLastName')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['supervisorLastName'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('experienceSupervisorTitle')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['supervisorTitle'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('experienceSupervisorEmail')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['supervisorEmail'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })

      } else if (event.target.name.includes('experienceDescription')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['description'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('workInterest')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['workInterest'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('workSkill')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['workSkill'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('teamInterest')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['teamInterest'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('employerInterest')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['employerInterest'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })

      } else if (event.target.name.includes('overallFit')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let experience = this.state.experience
        experience[index]['overallFit'] = event.target.value

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        let experienceHasChanged = true
        this.setState({ experience, experienceHasChanged, experienceHasChangedArray })
      } else if (event.target.name.includes('activityName')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let extracurriculars = this.state.extracurriculars
        extracurriculars[index]['activityName'] = event.target.value

        let extracurricularHasChangedArray = this.state.extracurricularHasChangedArray
        extracurricularHasChangedArray[index] = true

        let extracurricularHasChanged = true
        this.setState({ extracurriculars, extracurricularHasChanged, extracurricularHasChangedArray })
      } else if (event.target.name.includes('roleName')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let extracurriculars = this.state.extracurriculars
        extracurriculars[index]['roleName'] = event.target.value

        let extracurricularHasChangedArray = this.state.extracurricularHasChangedArray
        extracurricularHasChangedArray[index] = true

        let extracurricularHasChanged = true
        this.setState({ extracurriculars, extracurricularHasChanged, extracurricularHasChangedArray })
      } else if (event.target.name.includes('extracurricularStartDate')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let extracurriculars = this.state.extracurriculars
        extracurriculars[index]['startDate'] = event.target.value

        let extracurricularHasChangedArray = this.state.extracurricularHasChangedArray
        extracurricularHasChangedArray[index] = true

        let extracurricularHasChanged = true
        this.setState({ extracurriculars, extracurricularHasChanged, extracurricularHasChangedArray })
      } else if (event.target.name.includes('extracurricularEndDate')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let extracurriculars = this.state.extracurriculars
        extracurriculars[index]['endDate'] = event.target.value

        let extracurricularHasChangedArray = this.state.extracurricularHasChangedArray
        extracurricularHasChangedArray[index] = true

        let extracurricularHasChanged = true
        this.setState({ extracurriculars, extracurricularHasChanged, extracurricularHasChangedArray })
      } else if (event.target.name.includes('extracurricularHoursPerWeek')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let extracurriculars = this.state.extracurriculars
        extracurriculars[index]['hoursPerWeek'] = event.target.value

        let extracurricularHasChangedArray = this.state.extracurricularHasChangedArray
        extracurricularHasChangedArray[index] = true

        let extracurricularHasChanged = true
        this.setState({ extracurriculars, extracurricularHasChanged, extracurricularHasChangedArray })
      } else if (event.target.name.includes('extracurricularDescription')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let extracurriculars = this.state.extracurriculars
        extracurriculars[index]['description'] = event.target.value

        let extracurricularHasChangedArray = this.state.extracurricularHasChangedArray
        extracurricularHasChangedArray[index] = true

        let extracurricularHasChanged = true
        this.setState({ extracurriculars, extracurricularHasChanged, extracurricularHasChangedArray })
      } else if (event.target.name.includes('awardName')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let awards = this.state.awards
        awards[index]['name'] = event.target.value

        let awardHasChangedArray = this.state.awardHasChangedArray
        awardHasChangedArray[index] = true

        let awardHasChanged = true
        this.setState({ awards, awardHasChanged, awardHasChangedArray })

      } else if (event.target.name.includes('awardDate')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let awards = this.state.awards
        awards[index]['awardDate'] = event.target.value

        let awardHasChangedArray = this.state.awardHasChangedArray
        awardHasChangedArray[index] = true

        let awardHasChanged = true
        this.setState({ awards, awardHasChanged, awardHasChangedArray })

      } else if (event.target.name.includes('awardDescription')) {
        const nameArray = event.target.name.split("|")
        const index = nameArray[1]

        let awards = this.state.awards
        awards[index]['description'] = event.target.value

        let awardHasChangedArray = this.state.awardHasChangedArray
        awardHasChangedArray[index] = true

        let awardHasChanged = true
        this.setState({ awards, awardHasChanged, awardHasChangedArray })

      } else if (event.target.name === 'politicalAlignment') {
        this.setState({ politicalAlignment: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'stateRegistration') {
        this.setState({ stateRegistration: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'currentCongressionalDistrict') {
        this.setState({ currentCongressionalDistrict: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'hometown') {
        this.setState({ hometown: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'homeCongressionalDistrict') {
        this.setState({ homeCongressionalDistrict: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'dacaStatus') {
        this.setState({ dacaStatus: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'dateOfBirth') {
        this.setState({ dateOfBirth: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'phoneNumber') {
        this.setState({ phoneNumber: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'address') {
        this.setState({ address: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'city') {
        this.setState({ city: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'numberOfMembers') {
        this.setState({ numberOfMembers: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'householdIncome') {
        this.setState({ householdIncome: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'fosterYouth') {
        this.setState({ fosterYouth: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'homeless') {
        this.setState({ homeless: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'incarcerated') {
        this.setState({ incarcerated: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'adversityList') {
        this.setState({ adversityList: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'jobTitle') {
        this.setState({ jobTitle: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'employerName') {
        this.setState({ employerName: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'zipcode') {
        this.setState({ zipcode: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'workTenure') {
        this.setState({ workTenure: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'studyFields') {
        this.setState({ studyFields: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'careerTrack') {
        this.setState({ careerTrack: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'homeNumber') {
        this.setState({ homeNumber: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      } else if (event.target.name === 'lastOfSocialSecurity') {
        this.setState({ lastOfSocialSecurity: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'IEPPlan') {
        this.setState({ IEPPlan: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'parentName') {
        this.setState({ parentName: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'parentRelationship') {
        this.setState({ parentRelationship: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'parentPhone') {
        this.setState({ parentPhone: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'parentEmail') {
        this.setState({ parentEmail: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'emergencyContactName') {
        this.setState({ emergencyContactName: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'emergencyContactRelationship') {
        this.setState({ emergencyContactRelationship: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'emergencyContactPhone') {
        this.setState({ emergencyContactPhone: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'emergencyContactEmail') {
        this.setState({ emergencyContactEmail: event.target.value, textFormHasChanged: true })
      } else if (event.target.name === 'projectPublicPreference') {
        this.setState({ projectPublicPreference: event.target.value })
      } else if (event.target.name === 'goalPublicPreference') {
        this.setState({ goalPublicPreference: event.target.value })
      } else if (event.target.name === 'passionPublicPreference') {
        this.setState({ passionPublicPreference: event.target.value })
      } else if (event.target.name === 'assessmentPublicPreference') {
        this.setState({ assessmentPublicPreference: event.target.value })
      } else if (event.target.name === 'endorsementPublicPreference') {
        this.setState({ endorsementPublicPreference: event.target.value })
      } else if (event.target.name === 'resumePublicPreference') {
        let selectedResume = this.state.selectedResume
        if (this.state.resumes && this.state.resumes.length > 0) {
          selectedResume = this.state.resumeNames[this.state.resumeNames.length - 1]
        }
        this.setState({ resumePublicPreference: event.target.value, selectedResume })
      } else if (event.target.name === 'selectedProject') {
        this.setState({ selectedProject: event.target.value })
      } else if (event.target.name === 'selectedGoal') {
        this.setState({ selectedGoal: event.target.value })
      } else if (event.target.name === 'selectedPassion') {
        this.setState({ selectedPassion: event.target.value })
      } else if (event.target.name === 'selectedAssessment') {
        this.setState({ selectedAssessment: event.target.value })
      } else if (event.target.name === 'selectedEndorsement') {
        this.setState({ selectedEndorsement: event.target.value })
      } else if (event.target.name === 'publicProfileExtent') {
        if (event.target.value === 'Only Connections and Members' || event.target.value === 'Public') {
          // must be over 18
          if (!this.state.dateOfBirth) {
            // prompt to enter date of birth
            this.setState({ modalIsOpen: true, showBirthdate: true, tempPublicProfileExtent: event.target.value })
          } else {
            this.setState({ [event.target.name]: event.target.value, textFormHasChanged: true })
          }
        } else {
          this.setState({ [event.target.name]: event.target.value, textFormHasChanged: true })
        }
      } else {
        console.log('there was an error in formChangeHandler')
        this.setState({ [event.target.name]: event.target.value, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(event.target.name, event.target.value, null, 'basic')
        }
      }
    }

    saveFile(category, passedFile) {
      console.log('saveFile called', category, passedFile)

      this.setState({ serverErrorMessageResume: null, serverSuccessMessageResume: null })

      const emailId = this.state.emailId
      const fileName = passedFile.name
      let originalName = category + '|' + emailId + '|' + fileName + '|' + new Date()

      let fileData = new FormData();
      fileData.append('baseFileName', passedFile, originalName)

      console.log('show file info: ', passedFile, originalName)

      const postObject = { mode: 'no-cors', method: "POST", body: fileData }
      fetch("/api/file-upload", postObject).then(function (res) {
        console.log('what is the profile pic response', res);
        if (res.ok) {

          if (category === 'profilePic') {
            const serverSuccessProfilePic = true
            const serverSuccessMessageProfilePic = category.charAt(0).toUpperCase() + category.slice(1) + ' saved successfully!'
            this.setState({ serverSuccessProfilePic, serverSuccessMessageProfilePic, profilePicFile: passedFile })
          } else if (category === 'resume') {
            const serverSuccessResume = true
            const serverSuccessMessageResume = category.charAt(0).toUpperCase() + category.slice(1) + ' saved successfully!'
            this.setState({ serverSuccessResume, serverSuccessMessageResume, resumeFile: passedFile, resumeName: fileName })
          }

          const self = this

          res.json()
          .then(function(data) {
            console.log('show data: ', data)
            let newFilePath = data.filePath
            console.log('show filePath: ', newFilePath)

            if (window.location.pathname.includes('/apply') && category === 'profilePic') {
              self.props.passData('pictureURL', newFilePath, null, 'basic')
            }

            let existingFilePath = null
            if (category === 'profilePic') {
              if (self.state.pictureURL) {
                existingFilePath = self.state.pictureURL
              } else if (self.state.profilePicPath) {
                existingFilePath = self.state.profilePicPath
              }
            } else if (category === 'resume') {
              existingFilePath = self.state.resumeURLValue

              let resumes = self.state.resumes
              let resumeNames = self.state.resumeNames
              if (resumes && resumes.length > 0) {
                resumes.push({ url: newFilePath, source: 'Upload' })
                if (newFilePath.split("%7C")[2]) {
                  resumeNames.push(newFilePath.split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+"))
                } else {
                  resumeNames.push('Resume File #' + resumes.length)
                }
              } else {
                resumes = [{ url: newFilePath, source: 'Upload' }]
                if (newFilePath.split("%7C")[2]) {
                  resumeNames = [newFilePath.split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")]
                } else {
                  resumeNames = ['Resume File #1']
                }
              }

              self.setState({
                serverPostSuccess: true,
                serverSuccessMessage: 'File was saved successfully', resumeURLValue: newFilePath, resumes, resumeNames
              })
            }

            // remove existing file
            if (existingFilePath && !self.state.allowMultipleFiles) {
              const deleteArray = existingFilePath.split("amazonaws.com/")
              console.log('show deleteArrary: ', deleteArray)
              const deleteKey = deleteArray[1].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
              console.log('show deleteKey: ', deleteKey)

              Axios.put('/api/file', { deleteKey })
              .then((response) => {
                console.log('tried to delete', response.data)
                if (response.data.success) {
                  //save values
                  console.log('File delete worked');

                  if (category === 'profilePic') {
                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully',
                      profilePicPath: newFilePath, pictureURL: newFilePath
                    })
                  } else if (category === 'resume') {

                    let resumes = this.state.resumes
                    let resumeNames = this.state.resumes
                    if (resumes && resumes.length > 0) {
                      resumes.push({ url: newFilePath, source: 'Upload' })
                      if (newFilePath.split("%7C")[2]) {
                        resumeNames.push(newFilePath.split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+"))
                      } else {
                        resumeNames.push('Resume File #' + resumes.length)
                      }
                    } else {
                      resumes = [{ url: newFilePath, source: 'Upload' }]
                      if (newFilePath.split("%7C")[2]) {
                        resumeNames = [newFilePath.split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")]
                      } else {
                        resumeNames = ['Resume File #1']
                      }
                    }

                    self.setState({
                      serverPostSuccess: true,
                      serverSuccessMessage: 'File was saved successfully', resumeURLValue: newFilePath, resumes, resumeNames
                    })
                  }

                } else {
                  console.error('there was an error saving the file');
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: response.data.message,
                  })
                }
              }).catch((error) => {
                  console.log('The saving did not work', error);
                  self.setState({
                    serverPostSuccess: false,
                    serverErrorMessage: error,
                  })
              });
            }
          })

        } else if (res.status === 401) {
          //unauthorized
          this.setState({
              serverSuccessProfilePic: false,
              serverErrorMessageProfilePic: 'There was an error saving profile pic: Unauthorized save.'
          })
        } else if (res.status === 413) {
          //too large

          const errorMessage = 'payload too large'
          console.error(errorMessage);

          this.setState({
              serverSuccessProfilePic: false,
              serverErrorMessageProfilePic: errorMessage
          })
        }
      }.bind(this), function (e) {
        //there was an error
        this.setState({
            serverSuccessProfilePic: false,
            serverErrorMessageProfilePic: 'There was an error saving profile pic:' + e
        })
      }.bind(this));
    }

    switchPreferences(change, type, index) {
      console.log('switchPreferences called', change, type, index)

      if (type === 'certificate') {
        let certificates = this.state.certificates
        certificates[index]['isContinual'] = change
        this.setState({ certificates, formHasChanged: true })
      } else if (type === 'complete') {

        let certificates = this.state.certificates
        certificates[index]['isCompleted'] = change
        this.setState({ certificates, formHasChanged: true })
      } else {
        if (change) {

          if (this.state.confirmUsername) {
            this.savePreferences(false, true)
          } else {
            this.savePreferences(false, false, false)
          }
        } else {

          this.savePreferences(true, null)
        }
      }
    }

    segueToApp() {
      console.log('segueToApp called')

      window.scrollTo(0, 0)

      if (this.state.showModule) {
        window.scrollTo(0, 0)
        // this.setState({ pageIndex: this.state.pageIndex + 1, isSaving: false })
        this.props.movePage(true)
      } else {
        window.scrollTo(0, 0)

        if (this.props.opportunityId) {
          this.props.navigate('/app/opportunities/' + this.props.opportunityId)
        } else {
          this.props.navigate('/app')
        }
      }
    }

    savePreferences(makePrivate, confirmUsername, segue) {
      console.log('savePreferences called', makePrivate, confirmUsername, segue)

      this.setState({ isSaving: true, publicPreferencesErrorMessage: null, publicPreferencesSuccessMessage: null })

      if (makePrivate) {
        // save profile as profile
        console.log('save profile as private')

        const emailId = this.state.emailId
        Axios.post('/api/profile/public-preferences', { emailId, makePrivate })
        .then((response) => {

          if (response.data.success) {
            //save values

            console.log('Public preferences save worked ', response.data);
            this.setState({ publicProfile: false, publicPreferencesSuccessMessage: 'Public preferences saved successfully', isSaving: false })

          } else {
            console.log('preferenes did not save successfully')
            this.setState({ publicPreferencesErrorMessage: response.data.message, isSaving: false })
          }

        }).catch((error) => {
            console.log('Preferences save did not work', error);
            this.setState({ publicPreferencesErrorMessage: 'Something went wrong saving preferences', isSaving: false })
        });

      } else {

        if (confirmUsername) {
          // check uniqueness of username, create unique username, create profile link
          console.log('create unique username')

          if (!this.state.dateOfBirth) {
            // verify over 18
            this.setState({ isSaving: false, modalIsOpen: true, showProjectDetail: false, showGrade: false, showJobFunction: false, showIndustry: false, skillTagsInfo: false, showSettings: false, showBirthdate: true })

          } else {

            let birthdate = this.state.dateOfBirth
            console.log('show birthdate: ', birthdate, new Date(birthdate))
            // 2021-04-01

            let legalBirthdate = new Date()
            legalBirthdate.setFullYear(new Date().getFullYear() - 18)

            // console.log('compare: ', birthdate, legalBirthdate)

            if (new Date(birthdate) < legalBirthdate) {
              const emailId = this.state.emailId
              const username = this.state.username

              Axios.get('/api/profile/confirm-unique-username', { params: { emailId, username } })
              .then((response) => {
                console.log('Confirm unique username query attempted', response.data);

                  if (response.data.success) {
                    console.log('unique username query worked')

                    const username = response.data.username
                    localStorage.setItem('username', username)

                    this.setState({ publicProfile: true, isSaving: false, username, confirmUsername: false })
                    if (window.location.pathname.includes('/app/walkthrough')) {
                      this.segueToApp()
                    }

                  } else {
                    console.log('there was an error')
                    this.setState({ publicPreferencesErrorMessage: 'There was an error creating your username', isSaving: false })
                  }
              })
            } else {

              this.setState({ isSaving: false, publicPreferencesErrorMessage: 'You are under the age limit to share your profile publicly.', modalIsOpen: true, showProjectDetail: false, showGrade: false, showJobFunction: false, showIndustry: false, skillTagsInfo: false, showSettings: false, showBirthdate: true })
            }
          }

        } else {
          console.log('save specific preferences')

          if (this.state.publicProfile && !this.state.projectPublicPreference) {
            this.setState({ publicPreferencesErrorMessage: 'Please add which projects you want public', isSaving: false})
          } else if (this.state.publicProfile && !this.state.goalPublicPreference) {
            this.setState({ publicPreferencesErrorMessage: 'Please add which goals you want public', isSaving: false})
          } else if (this.state.publicProfile && !this.state.passionPublicPreference) {
            this.setState({ publicPreferencesErrorMessage: 'Please add which passions you want public', isSaving: false})
          } else if (this.state.publicProfile && !this.state.assessmentPublicPreference) {
            this.setState({ publicPreferencesErrorMessage: 'Please add which assessments you want public', isSaving: false})
          } else if (this.state.publicProfile && !this.state.endorsementPublicPreference) {
            this.setState({ publicPreferencesErrorMessage: 'Please add which endorsements you want public', isSaving: false})
          } else {

            const emailId = this.state.emailId
            const publicProfileExtent = this.state.publicProfileExtent

            let publicResume = null
            if (this.state.publicResumeName) {
              if (this.state.resumes && this.state.resumes.length > 0) {
                for (let i = 1; i <= this.state.resumes.length; i++) {
                  if (this.state.resumes[i - 1].split("%7C")[2] && this.state.resumes[i - 1].url.split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+") === this.state.publicResumeName) {
                    publicResume = this.state.resumes[i - 1].url
                  }
                }
              }
            }

            const publicPreferences = [
              { name: 'Post', value: this.state.postPublicPreference, publicItems: this.state.publicPosts },
              { name: 'Project', value: this.state.projectPublicPreference, publicItems: this.state.publicProjects },
              { name: 'Goal', value: this.state.goalPublicPreference, publicItems: this.state.publicGoals },
              { name: 'Passion', value: this.state.passionPublicPreference, publicItems: this.state.publicPassions },
              { name: 'Assessment', value: this.state.assessmentPublicPreference, publicItems: this.state.publicAssessments },
              { name: 'Endorsement', value: this.state.endorsementPublicPreference, publicItems: this.state.publicEndorsements },
              { name: 'Resume', value: this.state.resumePublicPreference, publicItems: publicResume },
            ]

            const headline = this.state.headline

            let doNotPublicize = false
            let completedOnboarding = false
            if (segue) {
              doNotPublicize = true
              completedOnboarding = true
            }

            // save public profile preferences to user profile
            Axios.post('/api/profile/public-preferences', { emailId, publicProfileExtent, publicPreferences, headline, doNotPublicize, completedOnboarding })
            .then((response) => {

              if (response.data.success) {
                //save values

                console.log('Public preferences save worked ', response.data);
                this.setState({ publicProfile: true, publicPreferencesSuccessMessage: 'Public preferences saved successfully', isSaving: false })
                if (window.location.pathname.includes('/app/walkthrough') && segue) {
                  this.segueToApp()
                }

              } else {
                console.log('preferenes did not save successfully')
                this.setState({ publicPreferencesErrorMessage: response.data.message, isSaving: false })
              }

            }).catch((error) => {
                console.log('Preferences save did not work', error);
                this.setState({ publicPreferencesErrorMessage: 'Something went wrong saving preferences', isSaving: false })
            });

          }
        }
      }
    }

    verifyLegalAge() {
      console.log('verifyLegalAge called')

      this.setState({ isSaving: true, publicPreferencesErrorMessage: null, publicPreferencesSuccessMessage: null })

      if (!this.state.dateOfBirth) {
        // verify over 18
        this.setState({ isSaving: false, publicPreferencesErrorMessage: 'Please add your birthdate' })
      } else {

        let self = this
        function finishPublicSetup(birthdate) {
          console.log('finishPublicSetup called', birthdate)
          // save dateOfBirth and call savePreferences

          const emailId = self.state.emailId
          const dateOfBirth = birthdate
          const updatedAt = new Date()

          const userObject = { emailId, dateOfBirth, updatedAt }

          Axios.post('/api/users/profile/details', userObject)
          .then((response) => {

            if (response.data.success) {
              //save values
              console.log('User update worked', response.data);
              self.setState({ isSaving: false, modalIsOpen: false, publicProfileExtent: self.state.tempPublicProfileExtent })
              self.savePreferences(false, true)

            } else {
              console.error('there was an error saving to user profile');
              self.setState({ isSaving: false, publicPreferencesErrorMessage: 'There was an error saving your birthdate' })
            }
          }).catch((error) => {
              console.log('Saving to user profile did not work', error);
          });
        }

        let birthdate = this.state.dateOfBirth
        console.log('show birthdate: ', birthdate, new Date(birthdate))
        // 2021-04-01

        let legalBirthdate = new Date()
        legalBirthdate.setFullYear(new Date().getFullYear() - 18)

        console.log('compare: ', birthdate, legalBirthdate)

        if (new Date(birthdate) < legalBirthdate) {
          finishPublicSetup(birthdate)
        } else {
          this.setState({ isSaving: false, publicPreferencesErrorMessage: 'You are under the age limit to share your profile publicly.' })
        }
      }
    }

    handleSubmit(event) {
      console.log('handleSubmit called in EditProfileDetails')

      if (this.state.experience && this.state.experience.length > 0) {
        for (let i = 1; i <= this.state.experience.length; i++) {
          this.saveExperience('experience|' + i.toString())
        }
      }
      if (this.state.projects && this.state.projects.length > 0) {
        for (let i = 1; i <= this.state.projects.length; i++) {
          this.saveProject('project|' + i.toString())
        }
      }

      if (this.state.extracurriculars && this.state.extracurriculars.length > 0) {
        for (let i = 1; i <= this.state.extracurriculars.length; i++) {
          this.saveExtras('extracurricular|' + i.toString(),'extracurricular')
        }
      }
      if (this.state.awards && this.state.awards.length > 0) {
        for (let i = 1; i <= this.state.awards.length; i++) {
          this.saveExtras('award|' + i.toString(),'award')
        }
      }

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const emailId = this.state.emailId
      let updatedAt = new Date();

      if (this.state.textFormHasChanged || window.location.pathname.includes('/app/walkthrough')) {
          console.log('used has changed the text portions of the form!!!')

          if (this.state.firstNameValue === '') {
            this.setState({ errorMessage: 'Please add your first name', isSaving: false })
          } else if (this.state.lastNameValue === '') {
            this.setState({ errorMessage: 'Please add your last name', isSaving: false})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && (!this.state.educationStatus || this.state.educationStatus === '')) {
            this.setState({ errorMessage: 'Please add your education status for program and matching purposes.', isSaving: false})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && (!this.state.address || this.state.address === '')) {
            this.setState({ errorMessage: 'Please add your address for program and matching purposes.', isSaving: false})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && (!this.state.city || this.state.city === '')) {
            this.setState({ errorMessage: 'Please add your city for program and matching purposes.'})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && (!this.state.zipcode || this.state.zipcode === '')) {
            this.setState({ errorMessage: 'Please add your zip code for program and matching purposes.', isSaving: false})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && (!this.state.phoneNumber || this.state.phoneNumber === '')) {
            this.setState({ errorMessage: 'Please add your phone number for program and matching purposes.', isSaving: false})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && (!this.state.dateOfBirth || this.state.dateOfBirth === '')) {
            this.setState({ errorMessage: 'Please add your date of birth for program and matching purposes.', isSaving: false})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && this.testBirthdate(this.state.dateOfBirth)) {
            this.setState({ errorMessage: 'Please add a valid birthdate.', isSaving: false})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && ((!this.state.race || this.state.race === '') && (!this.state.races || this.state.races.length === 0))) {
            this.setState({ errorMessage: 'Please add your race(s) for program and matching purposes.', isSaving: false})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && (!this.state.gender || this.state.gender === '')) {
            this.setState({ errorMessage: 'Please add your gender for program and matching purposes.', isSaving: false})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && (!this.state.workAuthorization || this.state.workAuthorization === '')) {
            this.setState({ errorMessage: 'Please add your work authorization for program and matching purposes.', isSaving: false})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && (!this.state.numberOfMembers || this.state.numberOfMembers === '')) {
            this.setState({ errorMessage: 'Please add the number of members in your household last name', isSaving: false})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && (!this.state.householdIncome || this.state.householdIncome === '')) {
            this.setState({ errorMessage: 'Please add your household income for program and matching purposes.', isSaving: false})
          // } else if (this.state.requirePersonalInfo && (!this.state.fosterYouth || this.state.fosterYouth === '')) {
          //   this.setState({ errorMessage: 'Please add whether you are a foster youth for program and matching purposes.'})
          // } else if (this.state.requirePersonalInfo && (!this.state.homeless || this.state.homeless === '')) {
          //   this.setState({ errorMessage: 'Please add whether you were homeless for program and matching purposes.'})
          // } else if (this.state.requirePersonalInfo && (!this.state.incarcerated || this.state.incarcerated === '')) {
          //   this.setState({ errorMessage: 'Please add whether you were incarcerated for program and matching purposes.'})
          } else if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers')) && (!this.state.adversityList || this.state.adversityList.length === 0)) {
            this.setState({ errorMessage: 'Please "designate all that apply" for program and matching purposes.', isSaving: false })
          } else {
            let liu = ''

            if (this.state.linkedInURL) {
              liu = this.state.linkedInURL
              const prefix = liu.substring(0,4);

              if (prefix !== "http") {
                liu = "http://" + liu
              }
            }

            let ru = ''
            if (this.state.resumeURLValue) {
              ru = this.state.resumeURLValue
              const prefix = ru.substring(0,4);

              if (prefix !== "http") {
                ru = "http://" + ru
              }
            }

            let cwu = ''
            if (this.state.customWebsiteURL) {

              cwu = this.state.customWebsiteURL
              const prefix = cwu.substring(0,4);

              if (prefix !== "http") {
                cwu = "http://" + cwu
              }
            }

            const rawPictureURL = this.state.rawPictureURL
            const pictureURL = this.state.pictureURL
            const firstNameValue = this.state.firstNameValue.trim()
            const lastNameValue = this.state.lastNameValue.trim()
            const linkedInURL = liu
            const resumeURL = ru
            const customWebsiteURL = cwu
            const instagramURL = this.state.instagramURL
            const facebookURL = this.state.facebookURL
            const twitterURL = this.state.twitterURL
            const videoResumeURL = this.state.videoResumeURL
            const headline = this.state.headline

            let school = this.state.schoolName
            if (window.location.pathname.includes('/advisor') || window.location.pathname.includes('/organizations')) {
              school = this.state.schoolName
            }
            let degree = this.state.degree
            if (window.location.pathname.includes('/advisor') || window.location.pathname.includes('/organizations')) {
              degree = this.state.degreeAttained
            }
            let major = this.state.major
            let pathway = this.state.pathway
            let gradYear = this.state.gradYear
            const educationStatus = this.state.educationStatus
            const education = this.state.education
            if (education && education.length > 0) {
              let selectedEducation = null
              for (let i = 1; i <= education.length; i++) {
                if (education[i - 1].isContinual) {
                  selectedEducation = education[i - 1]
                } else if (education[i - 1].endDate && education[i - 1].endDate.split(" ")) {
                  const endYear = Number(education[i - 1].endDate.split(" ")[1])
                  console.log('show endYear: ', endYear)
                  if (!selectedEducation) {
                    selectedEducation = education[i - 1]
                  } else if (selectedEducation.endDate && endYear > Number(selectedEducation.endDate.split(" ")[1])) {
                    selectedEducation = education[i - 1]
                  }
                }
              }
              console.log('we got past one level of education')
              if (!selectedEducation) {
                if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers'))) {
                  return this.setState({ errorMessage: 'Please complete the form for at least one school / certificate in the education section', isSaving: false})
                }
              } else {
                if (this.state.requirePersonalInfo && (!window.location.pathname.includes('/employers'))) {
                  if ((!selectedEducation.name || selectedEducation.name === '') || (!selectedEducation.major || selectedEducation.major === '')) {
                    return this.setState({ errorMessage: 'Please complete the form for at least one school / certificate in the education section', isSaving: false})
                  } else {
                    school = selectedEducation.name
                    degree = selectedEducation.degree
                    major = selectedEducation.major
                    if (selectedEducation.endDate && selectedEducation.endDate.split(" ")) {
                      const endYear = Number(selectedEducation.endDate.split(" ")[1])
                      gradYear = endYear
                    }
                  }
                } else {
                  school = selectedEducation.name
                  degree = selectedEducation.degree
                  major = selectedEducation.major
                  if (selectedEducation.endDate && selectedEducation.endDate.split(" ")) {
                    const endYear = Number(selectedEducation.endDate.split(" ")[1])
                    gradYear = endYear
                  }
                }
              }
            } else {
              if (this.state.requirePersonalInfo) {
                return this.setState({ errorMessage: 'Please add at least one school / certificate to education', isSaving: false})
              }
            }

            const certificates = this.state.certificates

            let race = this.state.race
            const races = this.state.races
            const selfDescribedRace = this.state.selfDescribedRace
            // change to race update
            if (this.state.allowMultipleRaces && races && races.length > 0) {
              race = races[0]
            }

            const gender = this.state.gender
            const preferredPronoun = this.state.preferredPronoun
            const language = this.state.language
            const country = this.state.country
            const veteran = this.state.veteranStatus
            const workAuthorization = this.state.workAuthorization
            const politicalAlignment = this.state.politicalAlignment
            const stateRegistration = this.state.stateRegistration
            const currentCongressionalDistrict = this.state.currentCongressionalDistrict
            const hometown = this.state.hometown
            const homeCongressionalDistrict = this.state.homeCongressionalDistrict
            const dacaStatus = this.state.dacaStatus

            const dateOfBirth = this.state.dateOfBirth
            const phoneNumber = this.state.phoneNumber
            const alternativeEmail = this.state.alternativeEmail
            const alternativePhoneNumber = this.state.alternativePhoneNumber
            const address = this.state.address
            const city = this.state.city

            const numberOfMembers = this.state.numberOfMembers
            const householdIncome = this.state.householdIncome
            const fosterYouth = this.state.fosterYouth
            const homeless = this.state.homeless
            const incarcerated = this.state.incarcerated
            const adversityList = this.state.adversityList

            const jobTitle = this.state.jobTitle
            const employerName = this.state.employerName
            const zipcode = this.state.zipcode
            const workTenure = this.state.workTenure
            const overallFit = this.state.overallFit
            const studyFields = this.state.studyFields
            const careerTrack = this.state.careerTrack
            const successDefined = this.state.successDefined

            console.log('linkedIn value check: ', linkedInURL)

            const homeNumber = this.state.homeNumber
            const lastOfSocialSecurity = this.state.lastOfSocialSecurity
            const IEPPlan = this.state.IEPPlan
            const parentName = this.state.parentName
            const parentRelationship = this.state.parentRelationship
            const parentPhone = this.state.parentPhone
            const parentEmail = this.state.parentEmail

            const emergencyContactName = this.state.emergencyContactRelationship
            const emergencyContactRelationship = this.state.emergencyContactRelationship
            const emergencyContactPhone = this.state.emergencyContactPhone
            const emergencyContactEmail = this.state.emergencyContactEmail
            const careerGoals = this.state.careerGoals
            const location = this.state.location

            const careerStatus = this.state.careerStatus
            const dreamCareer = this.state.dreamCareer
            const dreamCompany = this.state.dreamCompany
            const primaryCareerInterest = this.state.primaryCareerInterest
            const secondaryCareerInterest = this.state.secondaryCareerInterest
            const tertiaryCareerInterest = this.state.tertiaryCareerInterest

            const userObject = {
              emailId, rawPictureURL, pictureURL,
              firstNameValue, lastNameValue,
              linkedInURL, resumeURL, customWebsiteURL, instagramURL, facebookURL, twitterURL,
              videoResumeURL, headline, educationStatus, education, school,
              certificates,
              dateOfBirth, phoneNumber, alternativeEmail, alternativePhoneNumber, address, city,
              degree, major, pathway, gradYear, race, races, selfDescribedRace, gender,
              preferredPronoun, language, country, veteran, workAuthorization,
              politicalAlignment, stateRegistration, currentCongressionalDistrict, hometown, homeCongressionalDistrict, dacaStatus,
              numberOfMembers, householdIncome, fosterYouth, homeless, incarcerated, adversityList,
              jobTitle, employerName, zipcode, workTenure, overallFit,
              studyFields, careerTrack, successDefined,
              homeNumber, lastOfSocialSecurity, IEPPlan,
              parentName, parentRelationship, parentPhone, parentEmail,
              emergencyContactName, emergencyContactRelationship, emergencyContactPhone, emergencyContactEmail,
              careerGoals, location,
              careerStatus, dreamCareer, dreamCompany,
              primaryCareerInterest, secondaryCareerInterest, tertiaryCareerInterest,
              updatedAt
            }

            Axios.post('/api/users/profile/details', userObject)
            .then((response) => {

              if (response.data.success) {
                console.log('successfully saved profile')

                if (window.location.pathname.startsWith('/organizations') || (window.location.pathname.startsWith('/advisor') && this.state.roleName !== 'Mentor')) {
                  localStorage.setItem('firstName', firstNameValue)
                  localStorage.setItem('lastName', lastNameValue)
                  localStorage.setItem('pictureURL', pictureURL)
                  console.log('saved lastName: ', lastNameValue)
                }

                if (window.location.pathname.includes('/app/walkthrough')) {
                  this.props.movePage(true)
                } else {
                  this.setState({ textFormHasChanged: false, isSaving: false,
                    successMessage: 'Basic profile information saved successfully!', errorMessage: null
                  })
                }

              } else {
                console.log('profile save was not successful')

                this.setState({ textFormHasChanged: false, isSaving: false,
                    errorMessage: response.data.message, successMessage: null
                })
              }
            }).catch((error) => {
                console.log('Saving the info did not work', error);
                this.setState({ textFormHasChanged: false, isSaving: false,
                  errorMessage: error.toString(), successMessage: null
                })
            });
          }

      } else {
        console.log('testing 123')
        this.setState({ errorMessage: 'No changes detected with basic info', isSaving: false })
      }

      // if (!window.location.pathname.includes('/advisor') &&  !window.location.pathname.includes('/organizations')) {
      //
      //   if (this.state.isEditingProjectsArray.includes(true)) {
      //     const index = this.state.isEditingProjectsArray.indexOf(true) + 1
      //     const rowKey = "project|" + index
      //     this.saveProject(rowKey)
      //   }
      //   if (this.state.isEditingExperienceArray.includes(true)) {
      //     const index = this.state.isEditingExperienceArray.indexOf(true) + 1
      //     const rowKey = "experience|" + index
      //     this.saveExperience(rowKey)
      //   }
      // }
    }

    saveProject(nameKey) {
      console.log('saveProject called: ', nameKey, this.state.projectHasChangedArray)

      this.setState({ isSaving: true, projectErrorMessage: null, projectSuccessMessage: null })

      const nameArray = nameKey.split("|")
      const index = Number(nameArray[1]) - 1
      console.log('show nameArray: ', nameArray, index)

      if (!this.state.projectHasChangedArray[index]) {
        console.log('no changes detected on project')
        let isEditingProjectsArray = this.state.isEditingProjectsArray
        isEditingProjectsArray[index] = false
        this.setState({ projectErrorMessage: 'No changes detected in project section', isEditingProjectsArray })
      } else {
        console.log('about to save')
        for (let i = 1; i <= this.state.projects.length; i++) {

          if (this.state.projectHasChangedArray[i - 1]) {
            // console.log('about to save 2')
            if (!this.state.projects[i - 1].name || this.state.projects[i - 1].name === '') {
              this.setState({ projectErrorMessage: 'please add a project name', isSaving: false })
            } else if (!this.state.projects[i - 1].url || this.state.projects[i - 1].url === '') {
              this.setState({ projectErrorMessage: 'please add a url / web link for this project', isSaving: false})
            } else if (!this.state.projects[i - 1].url.includes("http")) {
              this.setState({ projectErrorMessage: 'please add a valid url', isSaving: false})
            } else if (!this.state.projects[i - 1].category || this.state.projects[i - 1].category === '') {
              this.setState({ projectErrorMessage: 'please add a category for this project', isSaving: false})
            } else if (!this.state.projects[i - 1].startDate || this.state.projects[i - 1].startDate === '') {
              this.setState({ projectErrorMessage: 'please indicate when you started working on this project', isSaving: false})
            } else if (!this.state.projects[i - 1].endDate && !this.state.projects[i - 1].isContinual) {
              this.setState({ projectErrorMessage: 'please indicate when you stopped working on this project', isSaving: false})
            } else if (this.state.projects[i - 1].endDate === '' && !this.state.projects[i - 1].isContinual) {
              this.setState({ projectErrorMessage: 'please indicate when you stopped working on this project', isSaving: false})
            } else if (!this.state.projects[i - 1].hours || this.state.projects[i - 1].hours === '') {
              this.setState({ projectErrorMessage: 'please add the number of hours you worked on this project', isSaving: false})
            } else {
              console.log('for real this time')

              const emailId = this.state.emailId
              const userFirstName = this.state.cuFirstName
              const userLastName = this.state.cuLastName
              const userPic = this.state.pictureURL
              const userUsername = this.state.username

              const _id = this.state.projects[i - 1]._id
              const name = this.state.projects[i - 1].name
              const url = this.state.projects[i - 1].url
              const category = this.state.projects[i - 1].category
              const description = this.state.projects[i - 1].description
              const startDate = this.state.projects[i - 1].startDate
              let endDate = this.state.projects[i - 1].endDate
              const isContinual = this.state.projects[i - 1].isContinual

              if (isContinual) {

                const currentMonth = new Date().getMonth()
                const year = new Date().getFullYear()

                let month = ''

                if (currentMonth === 0) {
                  month = 'January'
                } else if (currentMonth === 1) {
                  month = 'February'
                } else if (currentMonth === 2) {
                  month = 'March'
                } else if (currentMonth === 3) {
                  month = 'April'
                } else if (currentMonth === 4) {
                  month = 'May'
                } else if (currentMonth === 5) {
                  month = 'June'
                } else if (currentMonth === 6) {
                  month = 'July'
                } else if (currentMonth === 7) {
                  month = 'August'
                } else if (currentMonth === 8) {
                  month = 'September'
                } else if (currentMonth === 9) {
                  month = 'October'
                } else if (currentMonth === 10) {
                  month = 'November'
                } else if (currentMonth === 11) {
                  month = 'December'
                }

                endDate = month + ' ' + year
              }

              const collaborators = this.state.projects[i - 1].collaborators
              let collaboratorCount = this.state.projects[i - 1].collaboratorCount
              if (this.state.activeOrg !== 'c2c' && collaborators) {
                collaboratorCount = collaborators.length
              }

              const hours = this.state.projects[i - 1].hours
              const totalHours = this.state.projects[i - 1].totalHours
              const focus = this.state.projects[i - 1].focus
              const skillTags = this.state.projects[i - 1].skillTags
              //const industryTags = this.state.projects[i - 1].industryTags
              const jobFunction = this.state.projects[i - 1].jobFunction
              const industry = this.state.projects[i - 1].industry
              console.log('test 2')
              const orgCode = this.state.activeOrg

              const imageURL = this.state.selectedProject.imageURL
              const videoURL = this.state.selectedProject.videoURL
              const careerPath = this.state.selectedProject.careerPath
              let metrics = null
              if (this.state.selectedProject.metricSet) {
                const posts = this.state.posts
                const follows = this.state.follows
                metrics = { name: this.state.selectedProject.metricSet, values: { posts, follows }}

              }
              let publicExtent = 'Private'
              if (this.state.publicProfile && this.state.publicProfileExtent === 'Public') {
                publicExtent = 'Public'
              } else if (this.state.publicProfile && this.state.publicProfileExtent === 'Only Connections and Members') {
                publicExtent = 'Members Only'
              }

              const index = i - 1

              const projectObject = {
                _id, emailId, userFirstName, userLastName, userPic, userUsername, name, url, category, description, startDate, endDate, isContinual, collaborators, collaboratorCount,
                hours, totalHours, focus, skillTags, jobFunction, industry, orgCode, imageURL, videoURL,careerPath,  metrics, publicExtent
              }

              Axios.post('/api/projects', projectObject)
              .then((response) => {
                console.log('test 4')
                if (response.data.success) {
                  //save values
                  console.log('Project save worked ', response.data);
                  //report whether values were successfully saved

                  let projectHasChangedArray = this.state.projectHasChangedArray
                  projectHasChangedArray[index] = false

                  let isEditingProjectsArray = this.state.isEditingProjectsArray
                  isEditingProjectsArray[index] = false

                  this.setState({
                    projectHasChangedArray, isEditingProjectsArray,
                    projectSuccessMessage: 'Projects saved successfully!', projectErrorMessage: null,
                    isSaving: false
                  })

                  if (window.location.pathname.includes('/apply')) {
                    this.props.passData('project', projectObject, index)
                  }

                } else {
                  console.log('project did not save successfully')
                  this.setState({ projectErrorMessage: response.data.message, projectSuccessMessage: null, isSaving: false })
                }

              }).catch((error) => {
                  console.log('Project save did not work', error);
                  this.setState({ projectErrorMessage: error.toString(), projectSuccessMessage: null, isSaving: false })
              });
            }
          }
        }
      }
    }

    saveExperience(nameKey) {
      console.log('saveExperience', nameKey)

      this.setState({ experienceErrorMessage: null, experienceSuccessMessage: null, isSaving: true })

      const nameArray = nameKey.split("|")
      const index = Number(nameArray[1]) - 1

      if (!this.state.experienceHasChangedArray[index]) {

        let isEditingExperienceArray = this.state.isEditingExperienceArray
        isEditingExperienceArray[index] = false
        this.setState({ experienceErrorMessage: 'No changes detected in experience section', isEditingExperienceArray, isSaving: false })

      } else {

        for (let i = 1; i <= this.state.experience.length; i++) {

          if (this.state.experienceHasChangedArray[i - 1]) {
            // console.log('try to save experience')
            if (!this.state.experience[i - 1].jobTitle || this.state.experience[i - 1].jobTitle === '') {
              this.setState({ experienceErrorMessage: 'please add a job title for your experience', isSaving: false })
            } else if (!this.state.experience[i - 1].employerName || this.state.experience[i - 1].employerName === '') {
              this.setState({ experienceErrorMessage: 'please add an employer name for your experience', isSaving: false })
            } else if (!this.state.experience[i - 1].jobFunction || this.state.experience[i - 1].jobFunction === '') {
              this.setState({ experienceErrorMessage: 'please add a job function for your experience', isSaving: false })
            } else if (!this.state.experience[i - 1].industry || this.state.experience[i - 1].industry  === '') {
              this.setState({ experienceErrorMessage: 'please add an industry for your experience', isSaving: false })
            } else if (!this.state.experience[i - 1].wasPaid || this.state.experience[i - 1].wasPaid  === '') {
              this.setState({ experienceErrorMessage: 'please indicate whether your experience was paid', isSaving: false })
            } else if (!this.state.experience[i - 1].startDate || this.state.experience[i - 1].startDate  === '') {
              this.setState({ experienceErrorMessage: 'please add your start date for the experience', isSaving: false })
            } else if (!this.state.experience[i - 1].endDate && !this.state.experience[i - 1].isContinual) {
              this.setState({ experienceErrorMessage: 'please add your end date for the experience', isSaving: false })
            } else if (this.state.experience[i - 1].endDate === '' && !this.state.experience[i - 1].isContinual) {
              this.setState({ experienceErrorMessage: 'please add your end date for the experience', isSaving: false })
            } else if (!this.state.experience[i - 1].hoursPerWeek || this.state.experience[i - 1].hoursPerWeek  === '') {
              this.setState({ experienceErrorMessage: 'please indicate the average number of hours per week you worked', isSaving: false })
            } else if (!this.state.experience[i - 1].overallFit || this.state.experience[i - 1].overallFit  === '') {
              this.setState({ experienceErrorMessage: 'please indicate how you feel your experience was an overall fit', isSaving: false })
            } else {
              // console.log('actually saving experience')
              const emailId = this.state.emailId
              const _id = this.state.experience[i - 1]._id
              const jobTitle = this.state.experience[i - 1].jobTitle
              const employerName = this.state.experience[i - 1].employerName
              const startDate = this.state.experience[i - 1].startDate
              let endDate = this.state.experience[i - 1].endDate
              const isContinual = this.state.experience[i - 1].isContinual

              if (isContinual) {

                const currentMonth = new Date().getMonth()
                const year = new Date().getFullYear()

                let month = ''

                if (currentMonth === 0) {
                  month = 'January'
                } else if (currentMonth === 1) {
                  month = 'February'
                } else if (currentMonth === 2) {
                  month = 'March'
                } else if (currentMonth === 3) {
                  month = 'April'
                } else if (currentMonth === 4) {
                  month = 'May'
                } else if (currentMonth === 5) {
                  month = 'June'
                } else if (currentMonth === 6) {
                  month = 'July'
                } else if (currentMonth === 7) {
                  month = 'August'
                } else if (currentMonth === 8) {
                  month = 'September'
                } else if (currentMonth === 9) {
                  month = 'October'
                } else if (currentMonth === 10) {
                  month = 'November'
                } else if (currentMonth === 11) {
                  month = 'December'
                }

                endDate = month + ' ' + year
              }

              const jobFunction = this.state.experience[i - 1].jobFunction
              const industry = this.state.experience[i - 1].industry
              // const pay = this.state.experience[i - 1].pay
              const wasPaid = this.state.experience[i - 1].wasPaid
              const hoursPerWeek = this.state.experience[i - 1].hoursPerWeek
              const skillTags = this.state.experience[i - 1].skillTags

              const supervisorFirstName = this.state.experience[i - 1].supervisorFirstName
              const supervisorLastName = this.state.experience[i - 1].supervisorLastName
              const supervisorTitle = this.state.experience[i - 1].supervisorTitle
              const supervisorEmail = this.state.experience[i - 1].supervisorEmail

              const description = this.state.experience[i - 1].description

              const workInterest = this.state.experience[i - 1].workInterest
              const workSkill = this.state.experience[i - 1].workSkill
              const teamInterest = this.state.experience[i - 1].teamInterest
              const employerInterest = this.state.experience[i - 1].employerInterest
              const payInterest = this.state.experience[i - 1].payInterest
              const overallFit = this.state.experience[i - 1].overallFit

              const index = i - 1

              const orgCode = this.state.activeOrg

              const createdAt = new Date()
              const updatedAt = new Date()

              const experienceObject = {
                _id, emailId, jobTitle, employerName, startDate, endDate, isContinual, jobFunction, industry, wasPaid, hoursPerWeek,
                skillTags, supervisorFirstName, supervisorLastName, supervisorTitle, supervisorEmail, description,
                workInterest, workSkill, teamInterest, employerInterest, payInterest, overallFit, orgCode,
                createdAt, updatedAt
              }

              Axios.post('/api/experience', experienceObject)
              .then((response) => {

                if (response.data.success) {
                  //save values
                  console.log('Experience save worked worked', response.data);
                  //report whether values were successfully saved

                  let experienceHasChangedArray = this.state.experienceHasChangedArray
                  experienceHasChangedArray[index] = false

                  let isEditingExperienceArray = this.state.isEditingExperienceArray
                  isEditingExperienceArray[index] = false

                  this.setState({
                    experienceHasChangedArray, isEditingExperienceArray,
                    experienceSuccessMessage: 'Experience saved successfully!',
                    experienceErrorMessage: null, isSaving: false
                  })

                  if (window.location.pathname.includes('/apply')) {
                    this.props.passData('experience', experienceObject, index)
                  }

                } else {
                  console.log('experience did not save successfully')
                  this.setState({ experienceErrorMessage: response.data.message,
                    experienceSuccessMessage: null, isSaving: false
                  })
                }

              }).catch((error) => {
                  console.log('Experience save did not work', error);
                  this.setState({ experienceErrorMessage: error.toString(),
                    experienceSuccessMessage: null, isSaving: false
                  })
              });
            }
          }
        }
      }
    }

    saveExtras(nameKey, type) {
      console.log('saveExtras', nameKey)

      this.setState({ extraErrorMessage: null, extraSuccessMessage: null, isSaving: true,
        awardErrorMessage: null, awardSuccessMessage: null
      })

      const nameArray = nameKey.split("|")
      const index = Number(nameArray[1]) - 1

      if (!this.state.extracurricularHasChangedArray[index] && !this.state.awardHasChangedArray[index]) {

        if (type === 'extracurricular') {
          let isEditingExtracurricularArray = this.state.isEditingExtracurricularArray
          isEditingExtracurricularArray[index] = false
          this.setState({ extraErrorMessage: 'No changes detected in extracurriculars section', isEditingExtracurricularArray, isSaving: false })
        } else if (type === 'award') {
          let isEditingAwardArray = this.state.isEditingAwardArray
          isEditingAwardArray[index] = false
          this.setState({ extraErrorMessage: 'No changes detected in awards section', isEditingAwardArray, isSaving: false })
        }

      } else {

        if (type === 'extracurricular') {
          for (let i = 1; i <= this.state.extracurriculars.length; i++) {

            if (this.state.extracurricularHasChangedArray[i - 1]) {

              if (!this.state.extracurriculars[i - 1].activityName || this.state.extracurriculars[i - 1].activityName === '') {
                this.setState({ extraErrorMessage: 'please add a name for your extracurricular', isSaving: false })
              } else if (!this.state.extracurriculars[i - 1].roleName || this.state.extracurriculars[i - 1].roleName === '') {
                this.setState({ extraErrorMessage: 'please add your role with the extracurricular', isSaving: false})
              } else if (!this.state.extracurriculars[i - 1].startDate || this.state.extracurriculars[i - 1].startDate === '') {
                this.setState({ extraErrorMessage: 'please add a start date for your extracurricular', isSaving: false})
              } else if (!this.state.extracurriculars[i - 1].endDate && !this.state.extracurriculars[i - 1].isContinual) {
                this.setState({ extraErrorMessage: 'please add your end date for the extracurricular', isSaving: false})
              } else if (this.state.extracurriculars[i - 1].endDate === '' && !this.state.extracurriculars[i - 1].isContinual) {
                this.setState({ extraErrorMessage: 'please add your end date for the extracurricular', isSaving: false})
              } else if (!this.state.extracurriculars[i - 1].hoursPerWeek || this.state.extracurriculars[i - 1].hoursPerWeek  === '') {
                this.setState({ extraErrorMessage: 'please indicate the average number of hours per week you did this extracurricular', isSaving: false})
              } else if (!this.state.extracurriculars[i - 1].description || this.state.extracurriculars[i - 1].description  === '') {
                this.setState({ extraErrorMessage: 'please add a description for the extracurricular', isSaving: false})
              } else {

                const type = 'Extracurricular'
                const emailId = this.state.emailId
                const _id = this.state.extracurriculars[i - 1]._id
                const activityName = this.state.extracurriculars[i - 1].activityName
                const roleName = this.state.extracurriculars[i - 1].roleName
                const startDate = this.state.extracurriculars[i - 1].startDate
                let endDate = this.state.extracurriculars[i - 1].endDate
                const isContinual = this.state.extracurriculars[i - 1].isContinual

                if (isContinual) {

                  const currentMonth = new Date().getMonth()
                  const year = new Date().getFullYear()

                  let month = ''

                  if (currentMonth === 0) {
                    month = 'January'
                  } else if (currentMonth === 1) {
                    month = 'February'
                  } else if (currentMonth === 2) {
                    month = 'March'
                  } else if (currentMonth === 3) {
                    month = 'April'
                  } else if (currentMonth === 4) {
                    month = 'May'
                  } else if (currentMonth === 5) {
                    month = 'June'
                  } else if (currentMonth === 6) {
                    month = 'July'
                  } else if (currentMonth === 7) {
                    month = 'August'
                  } else if (currentMonth === 8) {
                    month = 'September'
                  } else if (currentMonth === 9) {
                    month = 'October'
                  } else if (currentMonth === 10) {
                    month = 'November'
                  } else if (currentMonth === 11) {
                    month = 'December'
                  }

                  endDate = month + ' ' + year
                }

                const hoursPerWeek = this.state.extracurriculars[i - 1].hoursPerWeek
                const description = this.state.extracurriculars[i - 1].description

                const index = i - 1

                const orgCode = this.state.activeOrg

                const createdAt = new Date()
                const updatedAt = new Date()

                const extraObject = {
                  _id, type, emailId, activityName, roleName, startDate, endDate, isContinual, hoursPerWeek, description,
                  orgCode, createdAt, updatedAt
                }

                Axios.post('/api/extras', extraObject)
                .then((response) => {

                  if (response.data.success) {
                    //save values
                    console.log('Extracurricular save worked worked', response.data);
                    //report whether values were successfully saved

                    let extracurricularHasChangedArray = this.state.extracurricularHasChangedArray
                    extracurricularHasChangedArray[index] = false

                    let isEditingExtracurricularArray = this.state.isEditingExtracurricularArray
                    isEditingExtracurricularArray[index] = false

                    this.setState({
                      extracurricularHasChangedArray, isEditingExtracurricularArray,
                      extraSuccessMessage: 'Extracurricular saved successfully!', isSaving: false
                    })

                    if (window.location.pathname.includes('/apply')) {
                      this.props.passData('extra', extraObject, index)
                    }

                  } else {
                    console.log('extracurricular did not save successfully')
                    this.setState({ extraErrorMessage: response.data.message, isSaving: false })
                  }

                }).catch((error) => {
                    console.log('Extracurricular save did not work', error);
                    this.setState({ extraErrorMessage: error.toString(), isSaving: false })
                });
              }
            }
          }
        } else if (type === 'award') {

          for (let i = 1; i <= this.state.awards.length; i++) {
            if (this.state.awardHasChangedArray[i - 1]) {

              if (!this.state.awards[i - 1].name || this.state.awards[i - 1].name === '') {
                this.setState({ awardErrorMessage: 'please add a name for your award', isSaving: false })
              } else if (!this.state.awards[i - 1].awardDate || this.state.awards[i - 1].awardDate === '') {
                this.setState({ awardErrorMessage: 'please add the date you awarded this', isSaving: false })
              } else if (!this.state.awards[i - 1].description || this.state.awards[i - 1].description === '') {
                this.setState({ awardErrorMessage: 'please add a description of your award', isSaving: false })
              } else {

                const type = 'Award'
                const emailId = this.state.emailId
                const _id = this.state.awards[i - 1]._id
                const name = this.state.awards[i - 1].name
                const awardDate = this.state.awards[i - 1].awardDate
                const description = this.state.awards[i - 1].description

                const index = i - 1

                const orgCode = this.state.activeOrg

                const createdAt = new Date()
                const updatedAt = new Date()

                const extraObject = {
                  _id, type, emailId, name, awardDate, description, orgCode,
                  createdAt, updatedAt
                }

                Axios.post('/api/extras', extraObject)
                .then((response) => {

                  if (response.data.success) {
                    //save values
                    console.log('Award save worked worked', response.data);
                    //report whether values were successfully saved

                    let awardHasChangedArray = this.state.awardHasChangedArray
                    awardHasChangedArray[index] = false

                    let isEditingAwardArray = this.state.isEditingAwardArray
                    isEditingAwardArray[index] = false

                    this.setState({
                      awardHasChangedArray, isEditingAwardArray,
                      awardSuccessMessage: 'Award saved successfully!', isSaving: false
                    })

                    if (window.location.pathname.includes('/apply')) {
                      this.props.passData('extra', extraObject, index)
                    }

                  } else {
                    console.log('award did not save successfully')
                    this.setState({ awardErrorMessage: response.data.message, isSaving: false })
                  }

                }).catch((error) => {
                    console.log('Award save did not work', error);
                    this.setState({ awardErrorMessage: error.toString(), isSaving: false })
                });
              }
            }
          }
        } else {
          console.log('what happened?')
          this.setState({ isSaving: false })
        }
      }
    }

    // old version
    // addItem(type) {
    //   console.log('addItem called', type)
    //
    //   if (type === 'projects') {
    //     let projects = this.state.projects
    //     if (this.state.dateOptions[this.state.dateOptions.length - 1]) {
    //       projects.push({ startDate: this.state.dateOptions[this.state.dateOptions.length - 1].value, endDate: this.state.dateOptions[this.state.dateOptions.length - 1].value})
    //     } else {
    //       projects.push([])
    //     }
    //
    //     let isEditingProjectsArray = this.state.isEditingProjectsArray
    //     for (let i = 1; i <= isEditingProjectsArray.length; i++) {
    //
    //       isEditingProjectsArray[i - 1] = false
    //     }
    //
    //     isEditingProjectsArray.push(true)
    //
    //     let projectHasChangedArray = this.state.projectHasChangedArray
    //     projectHasChangedArray.push(false)
    //
    //     this.setState({ projects, isEditingProjectsArray, projectHasChangedArray })
    //
    //   } else if (type === 'experience') {
    //     let experience = this.state.experience
    //     if (this.state.dateOptions[this.state.dateOptions.length - 1]) {
    //       experience.push({ startDate: this.state.dateOptions[this.state.dateOptions.length - 1].value, endDate: this.state.dateOptions[this.state.dateOptions.length - 1].value})
    //     } else {
    //       experience.push([])
    //     }
    //
    //     let isEditingExperienceArray = this.state.isEditingExperienceArray
    //     for (let i = 1; i <= isEditingExperienceArray.length; i++) {
    //       isEditingExperienceArray[i - 1] = false
    //     }
    //     isEditingExperienceArray.push(true)
    //
    //     let experienceHasChangedArray = this.state.experienceHasChangedArray
    //     experienceHasChangedArray.push(false)
    //
    //     this.setState({ experience, isEditingExperienceArray, experienceHasChangedArray })
    //
    //   } else if (type === 'extracurricular') {
    //     let extracurriculars = this.state.extracurriculars
    //     if (this.state.dateOptions[this.state.dateOptions.length - 1]) {
    //       extracurriculars.push({ startDate: this.state.dateOptions[this.state.dateOptions.length - 1].value, endDate: this.state.dateOptions[this.state.dateOptions.length - 1].value})
    //     } else {
    //       extracurriculars.push([])
    //     }
    //
    //     let isEditingExtracurricularArray = this.state.isEditingExtracurricularArray
    //     for (let i = 1; i <= isEditingExtracurricularArray.length; i++) {
    //       isEditingExtracurricularArray[i - 1] = false
    //     }
    //     isEditingExtracurricularArray.push(true)
    //
    //     let extracurricularHasChangedArray = this.state.extracurricularHasChangedArray
    //     extracurricularHasChangedArray.push(false)
    //
    //     this.setState({ extracurriculars, isEditingExtracurricularArray, extracurricularHasChangedArray })
    //   } else if (type === 'award') {
    //     let awards = this.state.awards
    //     if (this.state.dateOptions[this.state.dateOptions.length - 1]) {
    //       awards.push({ startDate: this.state.dateOptions[this.state.dateOptions.length - 1].value, endDate: this.state.dateOptions[this.state.dateOptions.length - 1].value})
    //     } else {
    //       awards.push([])
    //     }
    //
    //     let isEditingAwardArray = this.state.isEditingAwardArray
    //     for (let i = 1; i <= isEditingAwardArray.length; i++) {
    //       isEditingAwardArray[i - 1] = false
    //     }
    //     isEditingAwardArray.push(true)
    //
    //     let awardHasChangedArray = this.state.awardHasChangedArray
    //     awardHasChangedArray.push(false)
    //
    //     this.setState({ awards, isEditingAwardArray, awardHasChangedArray })
    //   } else if (type === 'education') {
    //
    //     let education = this.state.education
    //     if (education) {
    //       education.push({ name: '' })
    //     } else {
    //       education = [{ name: '' }]
    //     }
    //
    //     this.setState({ education })
    //
    //   } else {
    //     console.log('there was an error')
    //   }
    // }

    addItem(type, index) {
      console.log('addItem called', type, index)

      if (type === 'projects') {
        let projects = this.state.projects
        if (this.state.dateOptions[this.state.dateOptions.length - 1]) {
          projects.unshift({ startDate: this.state.dateOptions[this.state.dateOptions.length - 1].value, endDate: this.state.dateOptions[this.state.dateOptions.length - 1].value})
        } else {
          projects.unshift([])
        }

        let isEditingProjectsArray = this.state.isEditingProjectsArray
        for (let i = 1; i <= isEditingProjectsArray.length; i++) {

          isEditingProjectsArray[i - 1] = false
        }

        isEditingProjectsArray.unshift(true)

        let projectHasChangedArray = this.state.projectHasChangedArray
        projectHasChangedArray.unshift(false)

        this.setState({ projects, isEditingProjectsArray, projectHasChangedArray })

      } else if (type === 'experience') {
        let experience = this.state.experience
        if (this.state.dateOptions[this.state.dateOptions.length - 1]) {
          experience.unshift({ startDate: this.state.dateOptions[this.state.dateOptions.length - 1].value, endDate: this.state.dateOptions[this.state.dateOptions.length - 1].value})
        } else {
          experience.unshift([])
        }

        let isEditingExperienceArray = this.state.isEditingExperienceArray
        for (let i = 1; i <= isEditingExperienceArray.length; i++) {
          isEditingExperienceArray[i - 1] = false
        }
        isEditingExperienceArray.unshift(true)

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray.unshift(false)

        this.setState({ experience, isEditingExperienceArray, experienceHasChangedArray })

      } else if (type === 'extracurricular') {
        let extracurriculars = this.state.extracurriculars
        if (this.state.dateOptions[this.state.dateOptions.length - 1]) {
          extracurriculars.unshift({ startDate: this.state.dateOptions[this.state.dateOptions.length - 1].value, endDate: this.state.dateOptions[this.state.dateOptions.length - 1].value})
        } else {
          extracurriculars.unshift([])
        }

        let isEditingExtracurricularArray = this.state.isEditingExtracurricularArray
        for (let i = 1; i <= isEditingExtracurricularArray.length; i++) {
          isEditingExtracurricularArray[i - 1] = false
        }
        isEditingExtracurricularArray.unshift(true)

        let extracurricularHasChangedArray = this.state.extracurricularHasChangedArray
        extracurricularHasChangedArray.unshift(false)

        this.setState({ extracurriculars, isEditingExtracurricularArray, extracurricularHasChangedArray })
      } else if (type === 'award') {
        let awards = this.state.awards
        if (this.state.dateOptions[this.state.dateOptions.length - 1]) {
          awards.unshift({ startDate: this.state.dateOptions[this.state.dateOptions.length - 1].value, endDate: this.state.dateOptions[this.state.dateOptions.length - 1].value})
        } else {
          awards.unshift([])
        }

        let isEditingAwardArray = this.state.isEditingAwardArray
        for (let i = 1; i <= isEditingAwardArray.length; i++) {
          isEditingAwardArray[i - 1] = false
        }
        isEditingAwardArray.unshift(true)

        let awardHasChangedArray = this.state.awardHasChangedArray
        awardHasChangedArray.unshift(false)

        this.setState({ awards, isEditingAwardArray, awardHasChangedArray })
      } else if (type === 'education') {

        let education = this.state.education
        if (education) {
          education.unshift({ name: '' })
        } else {
          education = [{ name: '' }]
        }

        this.setState({ education })

      } else if (type === 'certificate') {
        console.log('adding a certificate')
        let certificates = this.state.certificates
        if (certificates) {
          certificates.unshift({ name: '', createdAt: new Date(), updatedAt: new Date() })
        } else {
          certificates = [{ name: '', createdAt: new Date(), updatedAt: new Date() }]
        }

        this.setState({ certificates })
      } else if (type === 'function') {

        let certificates = this.state.certificates
        // console.log('what are certificates: ', certificates)
        if (certificates[index]) {
          let functions = certificates[index].functions
          let addItem = true
          if (functions) {
            if (functions.includes(certificates[index].workFunction)) {
              addItem = false
            } else {
              functions.unshift(certificates[index].workFunction)
            }
          } else {
            functions = [certificates[index].workFunction]
            // console.log('a1', functions)
          }

          if (addItem) {
            certificates[index]['functions'] = functions
            certificates[index]['workFunction'] = null
            // console.log('show functions: ', certificates[index], functions)
            this.setState({ certificates })
          }
        }

      } else if (type === 'pathway') {

        let certificates = this.state.certificates

        if (certificates[index]) {
          let pathways = certificates[index].pathways
          let addItem = true
          if (pathways) {
            if (pathways.includes(certificates[index].pathway)) {
              addItem = false
            } else {
              pathways.unshift(certificates[index].pathway)
            }
          } else {
            pathways = [certificates[index].pathway]
          }

          // console.log('a1: ', certificates, pathways)
          if (addItem) {
            certificates[index]['pathways'] = pathways
            certificates[index]['pathway'] = null
            console.log('a2: ', certificates)
            this.setState({ certificates })
          }
        }

      } else {
        console.log('there was an error')
      }
    }

    finishedEditing = (index, saved) => {
      console.log('finishedEditing called', index, saved)

      let isEditingProjectsArray = this.state.isEditingProjectsArray
      isEditingProjectsArray[index] = false

      if (saved) {
        const successMessage = 'Projects saved successfully!'

        this.setState({ isEditingProjectsArray, successMessage })
      } else {
        const errorMessage = 'No changes detected in project section'

        this.setState({ isEditingProjectsArray, errorMessage })
      }
    }

    renderProjects() {
      console.log('renderProjects called')

      let rows = []

      for (let i = 1; i <= this.state.projects.length; i++) {

        const index = i - 1
        const rowKey = 'project|' + i.toString()

        if (this.state.isEditingProjectsArray[i - 1]) {
          rows.push(
            <div key={rowKey}>
              {i > 1 && <div><div className="spacer"/><div className="half-spacer"/></div>}
              <EditProject selectedProject={this.state.projects[i - 1]} selectedIndex={index}
                projectCategoryOptions={this.state.projectCategoryOptions} dateOptions={this.state.dateOptions}
                collaboratorOptions={this.state.collaboratorOptions}  hourOptions={this.state.hourOptions}
                functionOptions={this.state.functionOptions}  industryOptions={this.state.industryOptions}
                finishedEditing={this.finishedEditing} userPic={this.state.pictureURL} passData={this.passData}
                publicProfile={this.state.publicProfile} publicProfileExtent={this.state.publicProfileExtent}
                passProjectState={this.passProjectState}
              />
            </div>
          )
        } else {
          // console.log('testing before hours ', hourArray)
          let hoursShorthand = 'N/A'

          let hourArray = this.state.projects[i - 1].hours
          if (hourArray) {
            if (hourArray.includes('<')) {
              hoursShorthand = hourArray.replace("<","").replace(" ","")
            } else if (hourArray.includes('+')) {
              hoursShorthand = hourArray.replace("+","")
            } else {
              hourArray = this.state.projects[i - 1].hours.split(' - ')
              let firstValue = Number(hourArray[0])
              let lastValue = Number(hourArray[1])
              console.log('f & l:', firstValue, lastValue)
              hoursShorthand = (firstValue + lastValue) / 2
            }
          }

          const event = { target: { name: "isEditingProjectsArray|" + index, value: ''}}

          rows.push(
            <div key={rowKey}>
              <div className="fixed-column-70 right-padding">
                <p numberOfLines={1} className="heading-text-2 cta-color">{hoursShorthand}</p>
                <p numberOfLines={1} className="description-text-1">hours</p>
              </div>
              <div className="float-left right-padding calc-column-offset-150">
                <p className="heading-text-5">{this.state.projects[i - 1].name}</p>
                <p className="description-text-1">{this.state.projects[i - 1].startDate} - {this.state.projects[i - 1].endDate}</p>

                <div className="float-left top-margin-5 bottom-margin">
                  <div className="spacer" />

                  <div className="float-left">
                    <button className="btn bordered-background-button-2 cta-border-color float-left clear-margin" type="button" onClick={() => this.prepareProjectDetails(null,index) }>
                      <div className="float-left right-margin">
                        <div>
                          <img src={detailsIconBlue} alt="GC" className="image-25-fit" />
                        </div>
                      </div>
                      <div className="float-left">
                        <label className="description-text-2 cta-color left-text">View Details</label>
                      </div>
                    </button>
                    <div className="clear"/>
                  </div>

                  <div className="float-left">
                    <div className="vertical-spacer" />

                    <button className="btn bordered-background-button-2 cta-border-color float-left clear-margin" type="button" onClick={() => this.prepareProjectDetails('suggestions',index) }>
                      <div className="float-left right-margin">
                        <div>
                          <img src={feedbackIconBlue} alt="Feedback icon logo" className="image-25-fit" />
                        </div>
                      </div>
                      <div className="float-left">
                        <label className="description-text-2 cta-color">View Suggestions & Feedback</label>
                      </div>
                    </button>
                    <div className="clear"/>
                  </div>

                  <div className="clear"/>
                </div>
              </div>

              {((this.state.projects[i - 1].emailId === this.state.emailId) || (!this.state.projects[i - 1].emailId)) ? (
                <div className="fixed-column-80">
                  <div className="fixed-column-40">
                    <button type="button" onClick={() => this.formChangeHandler(event)} className="background-button right-padding" name={"isEditingProjectsArray|" + index}>
                      <img src={editIconGrey} className="image-auto-20" alt="GC" />
                    </button>
                  </div>
                  <div className="fixed-column-40">
                    <button type="button" onClick={() => this.toggleConfirmDelete('project', index)} className="background-button">
                      <img src={closeIcon} className={(this.state.projects[i - 1].confirmDelete) ? "image-auto-20 wash-out" : "image-auto-20"} alt="GC" />
                    </button>
                  </div>
                  <div className="clear" />
                </div>
              ) : (
                <div className="fixed-column-80">
                  <div className="tag-container-inactive top-margin">
                    <p className="description-text-3 half-bold-text">Collab</p>
                  </div>
                </div>
              )}

              <div className="clear"/>

              {(this.state.projects[i - 1].confirmDelete) && (
                <div className="top-padding-20">
                  {this.renderConfirmDeleteWidget('project', index, true)}
                </div>
              )}

              <div className="spacer" /><div className="half-spacer" />
            </div>
          )
        }
      }

      return rows
    }

    passData(name, value, index) {
      console.log('passData', name, value, index)

      this.props.passData(name, value, index)

    }

    passProjectState(stateObject, index) {
      console.log('passProjectState called', stateObject, index)

      let projectHasChangedArray = this.state.projectHasChangedArray
      projectHasChangedArray[index] = true
      stateObject['projectHasChangedArray'] = projectHasChangedArray
      // console.log('umm so new stateObject: ', stateObject, projectHasChangedArray)
      this.setState(stateObject)

    }

    inviteCollaborators(index) {
      console.log('inviteCollaborators called', index)

      if (this.state.collaboratorEmail && this.state.collaboratorEmail !== '') {

        this.setState({ collaboratorErrorMessage: null })

        if (!this.state.collaboratorEmail.includes('@')) {
          this.setState({ collaboratorErrorMessage: 'Please add a valid email' })
        } else {
          //check if user is on GC

          const email = this.state.collaboratorEmail
          let collaboratorEmail = ''

          let projectHasChangedArray = this.state.projectHasChangedArray
          projectHasChangedArray[index] = true

          Axios.get('/api/users/profile/details/' + email)
          .then((response) => {
            if (response.data.success) {

              const responseData = response.data

              const pictureURL = responseData.user.pictureURL
              const firstName = responseData.user.firstName
              const lastName = responseData.user.lastName
              const roleName = responseData.user.roleName

              let collaborators = this.state.projects[index].collaborators
              if (collaborators) {
                collaborators.push({ pictureURL, firstName, lastName, email, roleName, joined: true })
              } else {
                collaborators = [{ pictureURL, firstName, lastName, email, roleName, joined: true }]
              }

              let projects = this.state.projects
              projects[index]['collaborators'] = collaborators

              this.setState({ projects, collaboratorEmail, projectHasChangedArray })

            } else {

              let collaborators = this.state.projects[index].collaborators
              if (collaborators) {
                collaborators.push({ pictureURL: '', firstName: 'Collaborator', lastName: '#' + collaborators.length, email, roleName: 'Student', joined: false })
              } else {
                collaborators = [{ pictureURL: '', firstName: 'Collaborator', lastName: '#1', email, roleName: 'Student', joined: false }]
              }

              let projects = this.state.projects
              projects[index]['collaborators'] = collaborators

              this.setState({ projects, collaboratorEmail, projectHasChangedArray })
            }
          }).catch((error) => {
              console.log('Profile pic fetch did not work', error);
          });

        }

      }

    }

    renderExperience() {
      console.log('renderExperience called')

      let rows = []

      for (let i = 1; i <= this.state.experience.length; i++) {

        const rowKey = 'experience|' + i.toString()
        const index = i - 1

        if (this.state.isEditingExperienceArray[i - 1]) {

          rows.push(
            <div key={rowKey}>
              {i > 1 && <div><div className="spacer" /><div className="half-spacer" /></div>}
              <div className="flex-container row-direction">
                <div className="flex-75">
                  {(this.state.experience[i - 1].jobTitle && this.state.experience[i - 1].jobTitle !== '') ? (
                    <p className="heading-text-5">Edit {this.state.experience[i - 1].jobTitle}</p>
                  ) : (
                    <p className="heading-text-5">Edit experience below</p>
                  )}
                </div>
                <div className="flex-25 right-text right-padding-20">
                  <button type="button" onClick={() => this.saveExperience(rowKey)} className="background-button clear-margin">
                    <div>
                      <p className="heading-text-5 cta-color">Done</p>
                    </div>
                  </button>
                </div>
              </div>
              <div className="spacer" /><div className="half-spacer" />

              <div className="edit-profile-row">
                <div className="name-container">
                  <label className="profile-label">Job Title<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="text" placeholder="Job Title" name={"jobTitle|" + index} value={this.state.experience[i - 1].jobTitle} onChange={this.formChangeHandler} />
                </div>
                <div className="name-container">
                  <label className="profile-label">Employer Name<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="text" placeholder="Employer Name" name={"employerName|" + index} value={this.state.experience[i - 1].employerName} onChange={this.formChangeHandler} />
                </div>
                <div className="clear" />
              </div>

              <div className="edit-profile-row">
                <label className="profile-label">Are you still working here?</label>
                <Switch
                  onChange={(change) => this.changeContinual(index, change,'experience')}
                  checked={this.state.experience[i - 1].isContinual}
                  id="normal-switch"
                />
              </div>

              <div className="edit-profile-row">
                <div className="name-container">
                  <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
                  <select name={"experienceStartDate|" + index} className="dropdown" value={this.state.experience[i - 1].startDate} onChange={this.formChangeHandler}>
                      {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                  </select>
                </div>

                {(this.state.experience[i - 1].isContinual) ? (
                  <div className="name-container">
                    {(!this.state.isMobile) && (
                      <div>
                        <div className="spacer"/><div className="spacer"/><div className="spacer"/><div className="spacer"/>
                      </div>
                    )}
                    <p className="heading-text-5">Still Working On This</p>
                  </div>
                ) : (
                  <div className="name-container">
                    <label className="profile-label">End Date<label className="error-color bold-text">*</label></label>
                    <select name={"experienceEndDate|" + index} className="dropdown" value={this.state.experience[i - 1].endDate} onChange={this.formChangeHandler}>
                        {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                    </select>
                  </div>
                )}

                <div className="clear" />
              </div>

              <div className="edit-profile-row">
                <div className="name-container">
                  <div className="float-left">
                    <label className="profile-label">Job Function<label className="error-color bold-text">*</label></label>
                  </div>
                  <div>
                    <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                    <div className="float-left left-margin noti-bubble-info-7-9">
                      <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showGrade: false, showJobFunction: true, showIndustry: false, showProjectDetail: false, skillTagsInfo: false, showSettings: false, showBirthdate: false })}>
                        <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                      </a>
                    </div>

                  </div>
                  <div className="clear" />

                  <select name={"jobFunction|" + index} className="dropdown" value={this.state.experience[i - 1].jobFunction} onChange={this.formChangeHandler}>
                      {this.state.functionOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                  </select>
                </div>
                <div className="name-container">
                  <div className="float-left">
                    <label className="profile-label">Job Industry<label className="error-color bold-text">*</label></label>
                  </div>
                  <div>
                    <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                    <div className="float-left left-margin noti-bubble-info-7-9">
                      <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showGrade: false, showJobFunction: false, showIndustry: true, showProjectDetail: false, skillTagsInfo: false, showSettings: false, showBirthdate: false })}>
                        <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                      </a>
                    </div>

                  </div>
                  <div className="clear" />

                  <select name={"jobIndustry|" + index} className="dropdown" value={this.state.experience[i - 1].industry} onChange={this.formChangeHandler}>
                      {this.state.industryOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                  </select>
                </div>
                <div className="clear" />
              </div>

              <div className="edit-profile-row">
                <div className="name-container">
                  <label className="profile-label">Were you paid?<label className="error-color bold-text">*</label></label>
                  <select name={"wasPaid|" + index} className="dropdown" value={this.state.experience[i - 1].wasPaid} onChange={this.formChangeHandler}>
                      {this.state.binaryOptions.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>
                <div className="name-container">
                  <label className="profile-label">Hours per Week<label className="error-color bold-text">*</label></label>
                  <select name={"hoursPerWeek|" + index} className="dropdown" value={this.state.experience[i - 1].hoursPerWeek} onChange={this.formChangeHandler}>
                      {this.state.hoursPerWeekOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                  </select>
                </div>
                <div className="clear" />
              </div>

              <div className="edit-profile-row">
                <div>
                  <div className="float-left">
                    <label className="profile-label">Skill Tags</label>
                  </div>
                  <div>
                    <div className="half-spacer" /><div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                    <div className="float-left left-margin noti-bubble-info-7-9">
                      <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showGrade: false, showJobFunction: false, showIndustry: false, showProjectDetail: false, skillTagsInfo: true, showSettings: false, showBirthdate: false })}>
                        <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                      </a>
                    </div>

                  </div>
                  <div className="clear" />
                </div>
                <input className="text-field" type="text" placeholder="add skills you acquired separated by commas" name={"experienceSkillTags|" + index} value={this.state.experience[i - 1].skillTags} onChange={this.formChangeHandler} />
              </div>

              {(this.state.activeOrg === 'exp') && (
                <div>
                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">Supervisor First Name<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Supervisor First Name" name={"experienceSupervisorFirstName|" + index} value={this.state.experience[i - 1].supervisorFirstName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="name-container">
                      <label className="profile-label">Supervisor Last Name<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Supervisor Last Name" name={"experienceSupervisorLastName|" + index} value={this.state.experience[i - 1].supervisorLastName} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear"/>
                  </div>

                  <div className="edit-profile-row">
                    <div className="name-container">
                      <label className="profile-label">Supervisor Title<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Supervisor Title" name={"experienceSupervisorTitle|" + index} value={this.state.experience[i - 1].supervisorTitle} onChange={this.formChangeHandler} />
                    </div>
                    <div className="name-container">
                      <label className="profile-label">Supervisor Email<label className="error-color bold-text">*</label></label>
                      <input className="text-field" type="text" placeholder="Add your Supervisor's Email" name={"experienceSupervisorEmail|" + index} value={this.state.experience[i - 1].supervisorEmail} onChange={this.formChangeHandler} />
                    </div>
                    <div className="clear"/>
                  </div>

                </div>
              )}

              <div className="edit-profile-row">
                <label className="profile-label">Description of Your Work<label className="error-color bold-text">*</label></label>
                <textarea className="text-field" type="text" placeholder="Add a description" name={"experienceDescription|" + index} value={this.state.experience[i - 1].description} onChange={this.formChangeHandler} />
              </div>

              <div className="edit-profile-row">
                <div className="name-container">
                  <label className="profile-label">Did you like the work?</label>
                  <select name={"workInterest|" + index} className="dropdown" value={this.state.experience[i - 1].workInterest} onChange={this.formChangeHandler}>
                    <option value=""></option>
                    <option value="5">I'm Super Passionate About This</option>
                    <option value="4">I Like It a Lot</option>
                    <option value="3">It Was Okay</option>
                    <option value="2">Not My Cup of Tea</option>
                    <option value="1">I Just Did it For the Money</option>
                  </select>
                  <label className="description-text-2">Note: this answer is not shared with employers; it's used for career advising.</label>
                </div>
                <div className="name-container">
                  <label className="profile-label">Do you think you are skilled in this work?</label>
                  <select name={"workSkill|" + index} className="dropdown" value={this.state.experience[i - 1].workSkill} onChange={this.formChangeHandler}>
                    <option value=""></option>
                    <option value="5">I am and will be world-class</option>
                    <option value="4">I think I'm top 10% compared to my peers</option>
                    <option value="3">I'm average</option>
                    <option value="2">I have a lot of work to do compared to peers</option>
                    <option value="1">I don't think this is my calling</option>
                  </select>
                  <label className="description-text-2">Note: this answer is not shared with employers; it's used for career advising.</label>
                </div>
                <div className="clear" />
              </div>

              <div className="edit-profile-row">
                <div className="name-container">
                  <label className="profile-label">Did you like the team?</label>
                  <select name={"teamInterest|" + index} className="dropdown" value={this.state.experience[i - 1].teamInterest} onChange={this.formChangeHandler}>
                    <option value=""></option>
                    <option value="5">The team felt like friends and/or family</option>
                    <option value="4">The team was a good fit</option>
                    <option value="3">The team was a mixed bag</option>
                    <option value="2">The team didn't mesh well with me</option>
                    <option value="1">Avoiding teams like this at all costs</option>
                  </select>
                  <label className="description-text-2">Note: this answer is not shared with employers; it's used for career advising.</label>
                </div>
                <div className="name-container">
                  <label className="profile-label">Did you like the employer?</label>
                  <select name={"employerInterest|" + index} className="dropdown" value={this.state.experience[i - 1].employerInterest} onChange={this.formChangeHandler}>
                    <option value=""></option>
                    <option value="5">Yes</option>
                    <option value="3">Not sure</option>
                    <option value="1">No</option>
                  </select>
                  <label className="description-text-2">Note: this answer is not shared with employers; it's used for career advising.</label>
                </div>
                <div className="clear" />
              </div>

              <div className="edit-profile-row">
                <div className="name-container">
                  <label className="profile-label">Would the pay be acceptable if full-time?</label>
                  <select name={"payInterest|" + index} className="dropdown" value={this.state.experience[i - 1].payInterest} onChange={this.formChangeHandler}>
                    <option value=""></option>
                    <option value="5">Initial pay and career trajectory pay provide a solid cushion</option>
                    <option value="4">Initial pay and career trajectory pay provide be comfortable</option>
                    <option value="3">Initial pay and career trajectory pay provide are doable</option>
                    <option value="2">Initial pay and career trajectory pay are a little low</option>
                    <option value="1">Initial pay and career trajectory pay are likely far too low</option>
                  </select>
                  <label className="description-text-2">Note: this answer is not shared with employers; it's used for career advising.</label>
                </div>
                <div className="name-container">
                  <label className="profile-label">What do you rate the overall fit?<label className="error-color bold-text">*</label></label>
                  <select name={"overallFit|" + index} className="dropdown" value={this.state.experience[i - 1].overallFit} onChange={this.formChangeHandler}>
                    <option value=""></option>
                    <option value="5">Perfect</option>
                    <option value="4">Great</option>
                    <option value="3">Good</option>
                    <option value="2">Not Ideal</option>
                    <option value="1">Avoiding employers like this at all costs</option>
                  </select>
                  <label className="description-text-2">Note: this answer is not shared with employers; it's used for career advising.</label>
                </div>
                <div className="clear"/>
              </div>

              {(window.location.pathname.includes('/apply')) ? (
                <div>
                  {this.state.experienceErrorMessage !== '' && <p className="description-text-2 error-color row-5">{this.state.experienceErrorMessage}</p>}
                  <button type="button" onClick={() => this.saveExperience(rowKey)} className="btn btn-secondary">Save Experience</button>
                </div>
              ) : (
                <div>
                  {this.state.experienceErrorMessage !== '' && <p className="description-text-2 error-color row-5">{this.state.experienceErrorMessage}</p>}
                  <div className="row-10">
                    <button type="button" onClick={() => this.saveExperience(rowKey)} className="btn btn-secondary">Save Experience & Collapse</button>
                  </div>
                </div>
              )}

              <div className="spacer" />
            </div>
          )

        } else {

          const event = { target: { name: "isEditingExperienceArray|" + index, value: ''}}

          rows.push(
            <div key={rowKey}>
              <div className="fixed-column-70 right-padding">
                <p numberOfLines={1} className="heading-text-2 cta-color">{this.state.experience[i - 1].overallFit}</p>
                <p numberOfLines={1} className="description-text-2">of 5 match</p>
              </div>
              <div className="calc-column-offset-150 float-left right-padding">
                <p className="heading-text-5">{this.state.experience[i - 1].jobTitle}</p>
                <p className="description-text-1">{this.state.experience[i - 1].employerName}</p>
                <p className="description-text-1">{this.state.experience[i - 1].startDate} - {this.state.experience[i - 1].endDate}</p>
              </div>
              <div className="fixed-column-80">
                <button type="button" onClick={() => this.formChangeHandler(event)} className="background-button right-padding-20" name={"isEditingExperienceArray|" + index}>
                  <img src={editIconGrey} className="image-auto-20" alt="Compass edit icon icon" />
                </button>
                <button type="button" onClick={() => this.toggleConfirmDelete('experience', index)} className="background-button">
                  <img src={closeIcon} className="image-auto-20" alt="GC" />
                </button>
              </div>
              <div className="clear"/>

              {(this.state.experience[i - 1].confirmDelete) && (
                <div className="top-padding-20">
                  {this.renderConfirmDeleteWidget('experience', index, true)}
                </div>
              )}
              <div className="spacer" /><div className="half-spacer" />
            </div>
          )
        }
      }

      return rows
    }

    renderExtras(type) {
      console.log('renderExtras called', type)

      let rows = []

      if (type === 'extracurriculars') {
        console.log('in extras')
        for (let i = 1; i <= this.state.extracurriculars.length; i++) {

          const rowKey = 'extracurricular|' + i.toString()
          const index = i - 1

          if (this.state.isEditingExtracurricularArray[i - 1]) {

            rows.push(
              <div key={rowKey}>
                {i > 1 && <div><div className="spacer" /><div className="half-spacer" /></div>}
                <div className="flex-container row-direction">
                  <div className="flex-75">
                    {(this.state.extracurriculars[i - 1].name && this.state.extracurriculars[i - 1].name !== '') ? (
                      <p className="heading-text-5">Edit {this.state.extracurriculars[i - 1].name}</p>
                    ) : (
                      <p className="heading-text-5">Edit extracurricular below</p>
                    )}
                  </div>
                  <div className="flex-25 right-text right-padding-20">
                    <button type="button" onClick={() => this.saveExtras(rowKey,"extracurricular")} className="background-button clear-margin">
                      <div>
                        <p className="heading-text-5 cta-color">Done</p>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="spacer" /><div className="half-spacer" />

                <div className="edit-profile-row">
                  <div className="name-container">
                    <label className="profile-label">Activity Name<label className="error-color bold-text">*</label></label>
                    <input className="text-field" type="text" placeholder="Activity Name" name={"activityName|" + index} value={this.state.extracurriculars[i - 1].activityName} onChange={this.formChangeHandler} />
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Your Role<label className="error-color bold-text">*</label></label>
                    <input className="text-field" type="text" placeholder="Your Role" name={"roleName|" + index} value={this.state.extracurriculars[i - 1].roleName} onChange={this.formChangeHandler} />
                  </div>
                  <div className="clear" />
                </div>

                <div className="edit-profile-row">
                  <div className="name-container">
                    <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
                    <select name={"extracurricularStartDate|" + index} className="dropdown" value={this.state.extracurriculars[i - 1].startDate} onChange={this.formChangeHandler}>
                        {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                    </select>
                  </div>

                  {(this.state.extracurriculars[i - 1].isContinual) ? (
                    <div className="name-container">
                      {(!this.state.isMobile) && (
                        <div>
                          <div className="spacer"/><div className="spacer"/><div className="spacer"/><div className="spacer"/>
                        </div>
                      )}
                      <p className="heading-text-5">Still Working On This</p>
                    </div>
                  ) : (
                    <div className="name-container">
                      <label className="profile-label">End Date<label className="error-color bold-text">*</label></label>
                      <select name={"extracurricularEndDate|" + index} className="dropdown" value={this.state.extracurriculars[i - 1].endDate} onChange={this.formChangeHandler}>
                          {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                      </select>
                    </div>
                  )}

                  <div className="clear" />
                </div>

                <div className="edit-profile-row">
                  <div className="name-container">
                    <label className="profile-label">Hours per Week<label className="error-color bold-text">*</label></label>
                    <select name={"extracurricularHoursPerWeek|" + index} className="dropdown" value={this.state.extracurriculars[i - 1].hoursPerWeek} onChange={this.formChangeHandler}>
                        {this.state.hoursPerWeekOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                    </select>
                  </div>
                  <div className="clear" />
                </div>

                <div className="edit-profile-row">
                  <label className="profile-label">Description<label className="error-color bold-text">*</label></label>
                  <textarea className="text-field" name={"extracurricularDescription|" + index} placeholder="Add a description of your extracurricular.." value={this.state.extracurriculars[i - 1].description} onChange={this.formChangeHandler}/>
                </div>

                {(window.location.pathname.includes('/apply')) ? (
                  <div>
                    {this.state.extraErrorMessage !== '' && <p className="description-text-2 error-color row-5">{this.state.extraErrorMessage}</p>}
                    <button type="button" onClick={() => this.saveExtras(rowKey,"extracurricular")} className="btn btn-secondary">Save Extracurricular</button>
                  </div>
                ) : (
                  <div>
                    {this.state.extraErrorMessage !== '' && <p className="description-text-2 error-color row-5">{this.state.extraErrorMessage}</p>}
                    <div className="row-10">
                      <button type="button" onClick={() => this.saveExtras(rowKey,"extracurricular")} className="btn btn-secondary">Save Extracurricular & Collapse</button>
                    </div>
                  </div>
                )}

                <div className="spacer" />
              </div>
            )

          } else {

            const event = { target: { name: "isEditingExtracurricularArray|" + index, value: ''}}

            rows.push(
              <div key={rowKey}>
                <div className="fixed-column-70 right-padding">
                  <img src={reachIcon} alt="GC" className="image-auto-50" />
                </div>
                <div className="calc-column-offset-150 float-left right-padding">
                  <p className="heading-text-5">{this.state.extracurriculars[i - 1].activityName}</p>
                  <p className="description-text-1">{this.state.extracurriculars[i - 1].roleName}</p>
                  <p className="description-text-1">{this.state.extracurriculars[i - 1].startDate} - {this.state.extracurriculars[i - 1].endDate}</p>
                </div>
                <div className="fixed-column-80">
                  <button type="button" onClick={() => this.formChangeHandler(event)} className="background-button right-padding-20" name={"isEditingExtracurricularArray|" + index}>
                    <img src={editIconGrey} className="image-auto-20" alt="Compass edit icon icon" />
                  </button>
                  <button type="button" onClick={() => this.toggleConfirmDelete('extracurricular', index, true)} className="background-button">
                    <img src={closeIcon} className="image-auto-20" alt="Compass close icon icon" />
                  </button>
                </div>
                <div className="clear"/>

                {(this.state.extracurriculars[i - 1].confirmDelete) && (
                  <div className="top-padding-20">
                    {this.renderConfirmDeleteWidget('extracurricular', index, true)}
                  </div>
                )}
                <div className="spacer" /><div className="half-spacer" />
              </div>
            )
          }
        }
      } else if (type === 'awards') {
        for (let i = 1; i <= this.state.awards.length; i++) {

          const rowKey = 'award|' + i.toString()
          const index = i - 1

          if (this.state.isEditingAwardArray[i - 1]) {

            rows.push(
              <div key={rowKey}>
                {i > 1 && <div><div className="spacer" /><div className="half-spacer" /></div>}
                <div className="flex-container row-direction">
                  <div className="flex-75">
                    {(this.state.awards[i - 1].name && this.state.awards[i - 1].name !== '') ? (
                      <p className="heading-text-5">Edit {this.state.awards[i - 1].name}</p>
                    ) : (
                      <p className="heading-text-5">Edit award below</p>
                    )}
                  </div>
                  <div className="flex-25 right-text right-padding-20">
                    <button type="button" onClick={() => this.saveExtras(rowKey,"award")} className="background-button clear-margin">
                      <div>
                        <p className="heading-text-5 cta-color">Done</p>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="spacer" /><div className="half-spacer" />

                <div className="edit-profile-row">
                  <div className="name-container">
                    <label className="profile-label">Name of Award<label className="error-color bold-text">*</label></label>
                    <input className="text-field" type="text" placeholder="Award Name" name={"awardName|" + index} value={this.state.awards[i - 1].name} onChange={this.formChangeHandler} />
                  </div>
                  <div className="name-container">
                    <label className="profile-label">Date Awarded<label className="error-color bold-text">*</label></label>
                    <input type="date" className="date-picker white-background" name={"awardDate|" + index} value={this.state.awards[i - 1].awardDate} onChange={this.formChangeHandler}></input>
                  </div>
                  <div className="clear" />
                </div>

                <div className="edit-profile-row">
                  <label className="profile-label">Description of the Award<label className="error-color bold-text">*</label></label>
                  <textarea className="text-field" name={"awardDescription|" + index} placeholder="Add a description of your award.." value={this.state.awards[i - 1].description} onChange={this.formChangeHandler}/>
                </div>

                {(window.location.pathname.includes('/apply')) ? (
                  <div>
                    {this.state.awardErrorMessage !== '' && <p className="description-text-2 error-color row-5">{this.state.awardErrorMessage}</p>}
                    <button type="button" onClick={() => this.saveExtras(rowKey,"award")} className="btn btn-secondary">Save Award</button>
                  </div>
                ) : (
                  <div>
                    {this.state.awardErrorMessage !== '' && <p className="description-text-2 error-color row-5">{this.state.awardErrorMessage}</p>}
                    <div className="row-10">
                      <button type="button" onClick={() => this.saveExtras(rowKey,"award")} className="btn btn-secondary">Save Award & Collapse</button>
                    </div>
                  </div>
                )}

                <div className="spacer" />
              </div>
            )

          } else {

            const event = { target: { name: "isEditingAwardArray|" + index, value: ''}}

            rows.push(
              <div key={rowKey}>
                <div className="fixed-column-70 right-padding">
                  <img src={prizeIcon} alt="GC" className="image-auto-50" />
                </div>
                <div className="calc-column-offset-150 float-left right-padding">
                  <p className="heading-text-5">{this.state.awards[i - 1].name}</p>
                  <p className="description-text-1">{this.state.awards[i - 1].awardDate}</p>
                </div>
                <div className="fixed-column-80">
                  <button type="button" onClick={() => this.formChangeHandler(event)} className="background-button right-padding-20" name={"isEditingAwardArray|" + index}>
                    <img src={editIconGrey} className="image-auto-20" alt="Compass edit icon icon" />
                  </button>
                  <button type="button" onClick={() => this.toggleConfirmDelete('award', index, true)} className="background-button">
                    <img src={closeIcon} className="image-auto-20" alt="Compass close icon icon" />
                  </button>
                </div>
                <div className="clear"/>

                {(this.state.awards[i - 1].confirmDelete) && (
                  <div className="top-padding-20">
                    {this.renderConfirmDeleteWidget('award', index, true)}
                  </div>
                )}
                <div className="spacer" /><div className="half-spacer" />
              </div>
            )
          }
        }
      }

      return rows
    }

    changeContinual(index, change, type) {
      console.log('changeContinual called ', index, change, type)

      if (type === 'project') {
        // let projects = this.state.projects
        // projects[index]['isContinual'] = change
        //
        // let projectHasChangedArray = this.state.projectHasChangedArray
        // projectHasChangedArray[index] = true
        //
        // this.setState({ projects, projectHasChangedArray })
      } else if (type === 'education') {
        let education = this.state.education
        education[index]['isContinual'] = change
        this.setState({ education, textFormHasChanged: true })
      } else if (type === 'graduated') {
        let education = this.state.education
        education[index][type] = change
        this.setState({ education, textFormHasChanged: true })
      } else {
        // experience
        let experience = this.state.experience
        experience[index]['isContinual'] = change

        let experienceHasChangedArray = this.state.experienceHasChangedArray
        experienceHasChangedArray[index] = true

        this.setState({ experience, experienceHasChangedArray })
      }
    }

    deleteItem(type, index) {
      console.log('deleteItem called', type, index)

      if (type === 'project') {
        let projects = this.state.projects
        let deleteId = projects[index]._id

        Axios.put('/api/projects', { deleteId })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Project delete worked ', response.data);

            projects.splice(index, 1)
            this.setState({ projects, successMessage: 'Projects saved successfully!' })

          } else {
            console.log('project delete did not save successfully')
            this.setState({
                errorMessage: response.data.message,
            })
          }

        }).catch((error) => {
            console.log('Project save did not work', error);
        });

      } else if (type === 'experience') {
        let experience = this.state.experience
        let deleteId = experience[index]._id

        Axios.put('/api/experience', { deleteId })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Experience delete worked ', response.data);

            experience.splice(index, 1)
            this.setState({ experience, successMessage: 'Projects saved successfully!' })

          } else {
            console.log('experience delete did not save successfully')
            this.setState({
                errorMessage: response.data.message,
            })
          }

        }).catch((error) => {
            console.log('Experience save did not work', error);
        });
      } else if (type === 'extracurricular' || type === 'award') {
        let extras = []
        if (type === 'extracurricular') {
          extras = this.state.extracurriculars
        } else if (type === 'award') {
          extras = this.state.awards
        }
        let deleteId = extras[index]._id

        Axios.put('/api/extras', { deleteId })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Extra delete worked ', response.data);

            if (type === 'extracurricular') {
              extras.splice(index, 1)
              const extracurriculars = extras
              this.setState({ extracurriculars, successMessage: 'Extra saved successfully!' })
            } else if (type === 'award') {
              extras.splice(index, 1)
              const awards = extras
              this.setState({ awards, successMessage: 'Extra saved successfully!' })
            }

          } else {
            console.log('experience delete did not save successfully')
            this.setState({
                errorMessage: response.data.message,
            })
          }

        }).catch((error) => {
            console.log('Experience save did not work', error);
        });
      } else if (type === 'education') {
        let education = this.state.education
        education.splice(index,1)
        this.setState({ education })
      } else if (type === 'certificate') {
        let certificates = this.state.certificates
        certificates.splice(index,1)
        this.setState({ certificates })
      } else if (type === 'resume') {

        if (this.state.resumes[index].source === 'Native') {
          console.log('delete from database')

          const _id = this.state.resumes[index]._id

          let docLink = '/api/resumes/' + _id
          if (this.state.showDoc) {
            docLink = '/api/documents/' + _id
          }

          Axios.delete(docLink)
          .then((response) => {
            console.log('tried to delete', response.data)
            if (response.data.success) {
              //save values
              console.log('Resume delete worked');

              // let resumeFileName = ''
              // this.setState({ resumes, resumeFileNames, resumeFileName, successMessage: "Resume was successfully deleted!", confirmDelete: false })
              let resumes = this.state.resumes
              let resumeNames = this.state.resumeNames
              resumes.splice(index,1)
              resumeNames.splice(index,1)

              this.setState({ serverSuccessMessageResume: 'File was deleted successfully', resumes, resumeNames })

              let primaryURLIsIncluded = false
              for (let i = 1; i <= resumes.length; i++) {
                if (resumes[i - 1].url === this.state.resumeURLValue) {
                  primaryURLIsIncluded = true
                }
              }

              if (!primaryURLIsIncluded) {
                let resumeURL = null
                if (resumes[0] && resumes[0].url) {
                  resumeURL = resumes[0].url
                }

                // save resumeURL
                const emailId = this.state.emailId
                const updatedAt = new Date()

                // put in function
                const userObject = { emailId, resumeURL, updatedAt }
                this.updatePrimaryResume(userObject, resumeURL)

              }

            } else {
              console.error('there was an error deleting the resume');
              this.setState({ serverErrorMessageResume: response.data.message })
            }
          }).catch((error) => {
              console.log('The deleting did not work', error);
              this.setState({ serverErrorMessageResume: error.toString() })
          });
        } else {
          console.log('delete from aws')

          const emailId = this.state.emailId
          const originalURL = this.state.resumes[index].url
          const deleteArray = this.state.resumes[index].url.split("amazonaws.com/")
          console.log('show deleteArrary: ', deleteArray)
          const deleteKey = deleteArray[1].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
          console.log('show deleteKey: ', deleteKey)

          Axios.put('/api/file', { emailId, originalURL, deleteKey })
          .then((response) => {
            console.log('tried to delete', response.data)
            if (response.data.success) {
              //save values
              console.log('File delete worked');

              let resumes = this.state.resumes
              let resumeNames = this.state.resumeNames
              resumes.splice(index,1)
              resumeNames.splice(index,1)

              this.setState({ serverSuccessMessageResume: 'File was deleted successfully', resumes, resumeNames })

              let primaryURLIsIncluded = false
              for (let i = 1; i <= resumes.length; i++) {
                if (resumes[i - 1].url === this.state.resumeURLValue) {
                  primaryURLIsIncluded = true
                }
              }

              if (!primaryURLIsIncluded) {
                let resumeURL = null
                if (resumes[0] && resumes[0].url) {
                  resumeURL = resumes[0].url
                }

                // save resumeURL
                const emailId = this.state.emailId
                const updatedAt = new Date()

                // put in function
                const userObject = { emailId, resumeURL, updatedAt }
                this.updatePrimaryResume(userObject, resumeURL)

              }

            } else {
              console.error('there was an error saving the file');
              this.setState({ serverErrorMessageResume: response.data.message })
            }
          }).catch((error) => {
              console.log('The saving did not work', error);
              this.setState({ serverErrorMessageResume: error.toString() })
          });
        }
      }
    }

    // renderCollaborators(passedIndex) {
    //   console.log('renderCollaborators called', passedIndex)
    //
    //   let rows = []
    //   let collaborators = this.state.projects[passedIndex].collaborators
    //   if (collaborators) {
    //     for (let i = 1; i <= collaborators.length; i++) {
    //
    //       const index = i - 1
    //
    //       rows.push(
    //         <div key={"collaborator" + i.toString()}>
    //           <div className="spacer" /><div className="half-pacer" />
    //
    //           <div className="fixed-column-50">
    //             <img src={collaborators[i - 1].pictureURL ? collaborators[i - 1].pictureURL : profileIconBig} alt="pic" className="profile-thumbnail-2"/>
    //           </div>
    //           <div className="calc-column-offset-100 left-padding">
    //             <p>{collaborators[i - 1].firstName} {collaborators[i - 1].lastName} ({collaborators[i - 1].email})</p>
    //             <div className="half-spacer" />
    //             {(collaborators[i - 1].joined) ? (
    //               <p className="description-text-2">{collaborators[i - 1].roleName}</p>
    //             ) : (
    //               <p className="description-text-2">(This user has not joined Guided Compass)</p>
    //             )}
    //           </div>
    //           <div className="fixed-column-50">
    //               <div className="spacer" />
    //             <a className="background-link" onClick={() => this.removeItem(passedIndex, index)}>
    //               <img src={xIcon} alt="pic" className="image-auto-20"/>
    //             </a>
    //           </div>
    //           <div className="clear" />
    //         </div>
    //       )
    //     }
    //   }
    //
    //   return rows
    // }

    removeItem(index1, index2) {
      console.log('removeItem called', index1, index2)

      // collaborators
      let projects = this.state.projects
      let collaborators = this.state.projects[index1].collaborators
      collaborators.splice(index2, 1)
      projects[index1]['collaborators'] = collaborators
      this.setState({ projects })
    }

    closeModal() {

      this.setState({ modalIsOpen: false, showPublicProfileExtentInfo: false,
        showGrade: false, showJobFunction: false, showIndustry: false, showProjectDetail: false,
        skillTagsInfo: false, showSettings: false, showBirthdate: false, showEstimatedHoursDefinition: false
      });

      // remove object
      localStorage.removeItem('objectId')
    }

    imgError(image) {
      console.log('imgError called: ', image, image.target, image.target.error)
      // window.onerror()

    }

    searchSchools(searchString) {
      console.log('searchSchools ', searchString)

      this.setState({ schoolsAreLoading: true })

      if (searchString === '') {
        const schoolOptions = []
        this.setState({ schoolOptions, schoolsAreLoading: false })
      } else {
        Axios.get('/api/schools/search', { params: { searchString } })
        .then((response) => {
          console.log('Schools search query attempted', response.data);

            if (response.data.success) {
              console.log('schools search query worked')

              const schoolOptions = response.data.schools
              this.setState({ schoolOptions, schoolsAreLoading: false });

            } else {
              console.log('school search query did not work', response.data.message)
              this.setState({ schoolsAreLoading: false })
            }

        }).catch((error) => {
            console.log('School search query did not work for some reason', error);
            this.setState({ schoolsAreLoading: false })
        });
      }
    }

    optionClicked(optionIndex, type, value) {
      console.log('optionClicked called', optionIndex, type)

      if (type === 'adversityList') {
        let adversityList = this.state.adversityList
        if (adversityList && adversityList.length > 0) {
          if (adversityList.includes(this.state.adversityListOptions[optionIndex])) {
            const removeIndex = adversityList.indexOf(this.state.adversityListOptions[optionIndex])
            adversityList.splice(removeIndex, 1)

          } else {
            adversityList.push(this.state.adversityListOptions[optionIndex])
          }
        } else {
          adversityList = [this.state.adversityListOptions[optionIndex]]
        }

        this.setState({ adversityList, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData(type, adversityList, null, 'basic')
        }

      } else if (type === 'project') {
        if (value && value !== '' && value !== 'Select a Project' && !this.state.publicProjects.includes(value)) {

          let publicProjects = this.state.publicProjects
          publicProjects.push(value)
          this.setState({ publicProjects, selectedProject: 'Select a Project' })
        }
      } else if (type === 'goal') {
        if (value && value !== '' && value !== 'Select a Goal' && !this.state.publicGoals.includes(value)) {
          let publicGoals = this.state.publicGoals
          publicGoals.push(value)
          this.setState({ publicGoals, selectedGoal: 'Select a Goal' })
        }
      } else if (type === 'passion') {
        if (value && value !== '' && value !== 'Select a Passion' && !this.state.publicPassions.includes(value)) {
          let publicPassions = this.state.publicPassions
          publicPassions.push(value)
          this.setState({ publicPassions, selectedPassion: 'Select a Passion' })
        }
      } else if (type === 'assessment' && value !== 'Select an Assessment') {
        if (value && value !== '' && value !== 'Select an Assessment' && !this.state.publicAssessments.includes(value)) {
          let publicAssessments = this.state.publicAssessments
          publicAssessments.push(value)
          this.setState({ publicAssessments, selectedAssessment: 'Select an Assessment' })
        }
      } else if (type === 'endorsement' && value !== 'Select an Endorsement') {
        if (value && value !== '' && value !== 'Select an Endorsement' && !this.state.publicEndorsements.includes(value)) {
          let publicEndorsements = this.state.publicEndorsements
          publicEndorsements.push(value)
          this.setState({ publicEndorsements, selectedEndorsement: 'Select an Endorsement' })
        }
      } else if (type === 'school') {
        const schoolOptions = []
        let school = this.state.schoolOptions[optionIndex]
        this.setState({ schoolOptions, school })
      } else if (type.includes('education|')) {
        console.log('in school 1')
        const index = Number(type.split("|")[2])
        let education = this.state.education
        console.log('in school 2', education, index, optionIndex)
        education[index]['name'] = this.state.schoolOptions[optionIndex]
        const schoolOptions = []
        console.log('in school 3')
        this.setState({ education, schoolOptions })

      } else if (type === 'resume') {
        console.log('show selectedResume: ', this.state.selectedResume)
        let publicResumeName = this.state.selectedResume
        this.setState({ publicResumeName })
      } else if (type === 'race') {
        let races = this.state.races
        if (races && races.length > 0) {
          if (races.includes(this.state.raceOptions[optionIndex])) {
            const removeIndex = races.indexOf(this.state.raceOptions[optionIndex])
            races.splice(removeIndex, 1)

          } else {
            races.push(this.state.raceOptions[optionIndex])
          }
        } else {
          races = [this.state.raceOptions[optionIndex]]
        }

        this.setState({ races, textFormHasChanged: true })
        if (window.location.pathname.includes('/apply')) {
          this.props.passData('races', races, null, 'basic')
        }
      }

    }

    renderTags(type, passedArray, parentIndex) {
      console.log('renderTags called: ', type, passedArray, parentIndex)

      if (type === 'resume') {
        if (this.state.publicResumeName) {
          passedArray = [this.state.publicResumeName]
        } else {
          passedArray = []
        }
      }

      // if (type === 'project') {
        return (
          <div key={type + "|0"}>
            <div className="spacer" />
            {passedArray.map((value, optionIndex) =>
              <div key={type + "|" + optionIndex} className="float-left">
                <div className="close-button-container-1" >
                  <button className="background-button" type="button" onClick={() => this.removeTag(type, optionIndex, parentIndex)}>
                    <img src={deniedIcon} alt="Compass target icon" className="image-auto-20" />
                  </button>
                </div>
                <div className="float-left right-padding-5">
                  <div className="half-spacer" />
                  <div className="tag-container-inactive">
                    <p className="description-text-2">{value}</p>
                  </div>
                  <div className="half-spacer" />
                </div>
              </div>
            )}


          </div>
        )
      // }
    }

    removeTag(type, index, parentIndex) {
      console.log('removeTag called', type, index, parentIndex)

      if (type === 'project') {
        let publicProjects = this.state.publicProjects
        publicProjects.splice(index, 1)
        this.setState({ publicProjects })
      } else if (type === 'goal') {
        let publicGoals = this.state.publicGoals
        publicGoals.splice(index, 1)
        this.setState({ publicGoals })
      } else if (type === 'passion') {
        let publicPassions = this.state.publicPassions
        publicPassions.splice(index, 1)
        this.setState({ publicPassions })
      } else if (type === 'assessment') {
        let publicAssessments = this.state.publicAssessments
        publicAssessments.splice(index, 1)
        this.setState({ publicAssessments })
      } else if (type === 'endorsement') {
        let publicEndorsements = this.state.publicEndorsements
        publicEndorsements.splice(index, 1)
        this.setState({ publicEndorsements })
      } else if (type === 'resume') {
        let publicResumeName = null
        this.setState({ publicResumeName })
      } else if (type === 'function') {
        if (this.state.certificates[parentIndex]) {
          let certificates = this.state.certificates
          let functions = certificates[parentIndex].functions
          functions.splice(index, 1)
          this.setState({ functions })
        }
      } else if (type === 'pathway') {
        if (this.state.certificates[parentIndex]) {
          let certificates = this.state.certificates
          let pathways = certificates[parentIndex].pathways
          pathways.splice(index, 1)
          this.setState({ pathways })
        }
      }
    }

    itemSelected(item) {
      console.log('itemSelected called')

      let careerGoals = this.state.careerGoals
      if (careerGoals.includes(item)) {
        const index = careerGoals.indexOf(item)
        careerGoals.splice(index,1)
      } else {
        careerGoals.push(item)
      }

      this.setState({ careerGoals, textFormHasChanged: true })
    }

    prepareProjectDetails(suggestionsOnly,index) {
      console.log('prepareProjectDetails called')

      const emailId = this.state.emailId
      const itemId = this.state.projects[index]._id

      Axios.get('/api/suggestions', { params: { emailId, itemId } })
       .then((response) => {
         console.log('query for suggestions worked');

         if (response.data.success) {

           console.log('suggestion results: ', response.data)
           const suggestions = response.data.suggestions
           this.setState({ suggestions })

           if (suggestionsOnly) {
             this.setState({ showGrade: true, modalIsOpen: true, selectedIndex: index, suggestions, showJobFunction: false, showIndustry: false, showProjectDetail: false, skillTagsInfo: false, showSettings: false, showBirthdate: false })
           } else {
             this.setState({ modalIsOpen: true, showProjectDetail: true, showGrade: false, suggestions, selectedIndex: index, showJobFunction: false, showIndustry: false, skillTagsInfo: false, showSettings: false, showBirthdate: false })
           }

         } else {
           console.log('no suggestions', response.data)

           if (suggestionsOnly) {
             this.setState({ showGrade: true, modalIsOpen: true, selectedIndex: index, showJobFunction: false, showIndustry: false, showProjectDetail: false, skillTagsInfo: false, showSettings: false, showBirthdate: false })
           } else {
             this.setState({ modalIsOpen: true, showProjectDetail: true, showGrade: false, selectedIndex: index, showJobFunction: false, showIndustry: false, skillTagsInfo: false, showSettings: false, showBirthdate: false })
           }
         }

     }).catch((error) => {
       console.log('query for assessment results did not work', error);
     })
    }

    renderProfilePic() {
      console.log('renderProfilePic called')

      return (
        <div key="renderProfilePic" className="row-10">
          <div>
            <div className="row-10">
              <div className="upload-image">
                <div className="width-150">
                  <div className="relative-position">
                    <label for="profilePic" className="profile-pic-button">
                      <img src={
                        this.state.profilePicImage ? ( this.state.profilePicImage )
                        : this.state.pictureURL ? ( this.state.pictureURL )
                        : this.state.profilePicPath ? ( this.state.profilePicPath )
                        : ( addProfilePhotoIcon)}
                      alt="GC" for="profilePic" className={(this.state.profilePicImage || this.state.profilePicPath || this.state.pictureURL) ? "profile-image-largish" : "profile-image-largish-squared"}/>
                      {(this.state.profilePicImage || this.state.profilePicPath || this.state.pictureURL) && (
                        <div className="bottom-right-overlay">
                          <div className="bottom-right-subcontainer">
                            <img src={addIcon} alt="Compass add icon" className="image-auto-18 center-item"/>
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                    </label>
                    <input type="file" id="profilePic" name="profilePic" onChange={this.formChangeHandler} accept="image/*" />
                  </div>
                </div>

                <div className="clear" />
                <div className="spacer" />
                <p className="description-text-color description-text-2">Dimensions: 150 x 150</p>

                {(this.state.oauthUid) && (
                  <div>
                    <p className="error-color top-margin description-text-2">UID: {this.state.oauthUid}</p>
                  </div>
                )}

                { (this.state.serverSuccessProfilePic) ? (
                  <p className="success-message">{this.state.serverSuccessMessageProfilePic}</p>
                ) : (
                  <p className="error-message">{this.state.serverErrorMessageProfilePic}</p>
                )}
              </div>

            </div>

          </div>
        </div>
      )
    }

    renderProfileBasics() {
      console.log('renderProfileBasics called')

      return (
        <div key="renderProfileBasics">
          <div className="row-10">
            <div className="container-left">
              <label className="profile-label">First Name<label className="error-color bold-text">*</label></label>
              <input className="text-field" type="text" placeholder="First Name" name="firstName" value={this.state.firstNameValue} onChange={this.formChangeHandler} />
            </div>
            <div className="container-right">
              <label className="profile-label">Last Name<label className="error-color bold-text">*</label></label>
              <input className="text-field" type="text" placeholder="Last Name" name="lastName" value={this.state.lastNameValue} onChange={this.formChangeHandler}/>
            </div>
            <div className="clear" />
          </div>

          {(!window.location.pathname.includes('/apply')) && (
            <div className="row-10">
              <div className="container-left">
                <label className="profile-label">Headline (70 chars max)</label>
                <input className="text-field" type="text" maxlength={70} placeholder="A sentence that describes who you are or what you're doing..." name="headline" value={this.state.headline} onChange={this.formChangeHandler} />
              </div>
              <div className="container-right">
                <label className="profile-label">Current Location</label>
                <input className="text-field" type="text" placeholder="Los Angeles, CA..." name="location" value={this.state.location} onChange={this.formChangeHandler}/>
              </div>
              <div className="clear" />
            </div>
          )}

          {((window.location.pathname.includes('/employers')) || (window.location.pathname.includes('/advisor') && this.state.roleName === 'Mentor')) && (
            <div className="row-10">
              <div className="container-left">
                <label className="profile-label">Current Job Title</label>
                <input className="text-field" type="text" placeholder="Job Title" name="jobTitle" value={this.state.jobTitle} onChange={this.formChangeHandler}/>
              </div>
              <div className="container-right">
                <label className="profile-label">Employer Name{(this.state.activeOrg === 'c2c') && " Or Congressional Office"}</label>
                <input className="text-field" type="text" placeholder="Employer Name" name="employerName" value={this.state.employerName} onChange={this.formChangeHandler}/>
              </div>
              <div className="clear" />
            </div>
          )}

          {((!window.location.pathname.includes('/advisor')) || (window.location.pathname.includes('/advisor') && this.state.roleName === 'Mentor')) && (
            <div>
              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">LinkedIn URL</label>
                  <input className="text-field" type="text" placeholder="https://www.linkedin.com" name="linkedInURL" value={this.state.linkedInURL} onChange={this.formChangeHandler} />
                  {(this.state.linkedInURL && this.state.linkedInURL !== '' && !this.state.linkedInURL.startsWith('http')) && (
                    <div>
                      <p className="error-message">Please start your link with http</p>
                    </div>
                  )}
                </div>
                <div className="container-right">
                  <label className="profile-label">Portfolio URL / Personal Website</label>
                  <input className="text-field" type="text" placeholder="https://www.guidedcompass/joeschmo/profile" name="customWebsiteURL" value={this.state.customWebsiteURL} onChange={this.formChangeHandler}/>
                  <div className="clear" />

                  {/*<p className="description-text-3 row-5">If you make your profile public, you can use it as your portfolio</p>*/}

                  {(this.state.customWebsiteURL && this.state.customWebsiteURL !== '' && !this.state.customWebsiteURL.startsWith('http')) && (
                    <div>
                      <p className="error-message">Please start your link with http</p>
                    </div>
                  )}
                </div>
                <div className="clear" />
              </div>

              {(this.state.activeOrg === 'fosternation') && (
                <div>
                  <div className="row-10">
                    <div className="container-left">
                    <label className="profile-label">Instagram URL</label>
                    <input className="text-field" type="text" placeholder="e.g., Instagram URL" name="instagramURL" value={this.state.instagramURL} onChange={this.formChangeHandler}/>
                    </div>
                    <div className="container-right">
                      <label className="profile-label">Facebook URL</label>
                      <input className="text-field" type="text" placeholder="e.g., Facebook URL" name="facebookURL" value={this.state.facebookURL} onChange={this.formChangeHandler}/>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="row-10">
                    <div className="container-left">
                      <label className="profile-label">Twitter URL</label>
                      <input className="text-field" type="text" placeholder="e.g., Twitter URL" name="twitterURL" value={this.state.twitterURL} onChange={this.formChangeHandler}/>
                    </div>
                    <div className="container-right">

                    </div>
                    <div className="clear" />
                  </div>
                </div>
              )}
            </div>
          )}

        </div>
      )
    }

    renderResumeModule() {
      console.log('renderResumeModule called')

      return (
        <div key="renderResumeModule">
          <div className="spacer" /><div className="spacer" />
          <hr />

          <div className="edit-profile-row">
            <div className="spacer"/><div className="half-spacer"/>
            <div className="float-left">
              <p className="heading-text-3">Resumes</p>
            </div>
            <div className="float-left left-padding">
              <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
              {(this.state.disableUploadingResume && window.location.pathname.startsWith('/app')) ? (
                <div>
                  <Link className="background-button" to={(window.location.pathname.startsWith('/app')) ? '/app/builders' : '/advisor/builders'}>
                    <div className="padding-7 standard-border circle-radius">
                      <img src={addIcon} alt="GC" className="image-auto-12"/>
                    </div>
                  </Link>
                </div>
              )  : (
                <div>
                  <label for="resumeUpload" className="background-button padding-7 standard-border circle-radius">
                    <img src={addIcon} alt="GC" className="image-auto-12"/>
                  </label>

                  <input type="file" id="resumeUpload" name="resume" onChange={this.formChangeHandler} accept="application/pdf" />
                </div>
              )}
            </div>
            <div className="clear" />

            {(window.location.pathname.startsWith('/advisor') || (!this.state.disableUploadingResume && window.location.pathname.startsWith('/app'))) && (
              <div className="top-padding">
                <p className="description-text-2 row-5">(Only PDF files are accepted. Please convert Word files to PDF.)</p>
              </div>
            )}

            {(!window.location.pathname.includes('/app/walkthrough') && window.location.pathname.includes('/app') && !this.state.disableUploadingResume) && (
              <div className="row-10">
                <Link className="secret-link" target="_blank" to={'/app/builders'}>
                  <div className="fixed-column-30">
                    <div className="half-spacer" /><div className="mini-spacer" />
                    <img src={skillsIcon} alt="GC" className="image-auto-15" />
                  </div>
                  <div className="calc-column-offset-30">
                    <p className="description-text-3 cta-color bold-text row-5">Need help? Use our resume builder. <label className="cta-color bold-text heading-text-6 left-padding-5">>></label></p>
                  </div>
                  <div className="clear" />
                </Link>
              </div>
            )}

          </div>

          <div className="top-padding-5 bottom-padding">
            {(this.state.resumes && this.state.resumes.length > 0) ? (
              <div>
                {this.state.resumes.map((item, optionIndex) =>
                  <div key={item.url}>
                    <div className="bottom-padding-20">
                      <div className="calc-column-offset-60">
                        <label className="right-padding">{optionIndex + 1}.</label>
                        <a href={item.url} target="_blank">{this.state.resumeNames[optionIndex]}</a>
                      </div>
                      <div className="fixed-column-30">
                        <button type="button" className="background-button full-width right-text" onClick={() => this.markResume(item)}>
                          <img src={(item.url === this.state.resumeURLValue) ? checkboxChecked : checkboxEmpty} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={(item.url === this.state.resumeURLValue) ? "This resume is marked as the default resume" : "Mark as the default resume"}/>
                        </button>
                      </div>
                      <div className="fixed-column-30">
                        <button type="button" className="background-button full-width right-text" onClick={() => this.toggleConfirmDelete('resume', optionIndex)}>
                          <img src={deleteIconDark} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Delete resume"/>
                        </button>
                      </div>
                      <div className="clear" />
                      {(item.confirmDelete) && (
                        <div className="top-padding-20">
                          {this.renderConfirmDeleteWidget('resume', optionIndex)}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p className="error-color description-text-2">No resumes have been uploaded yet</p>
              </div>
            )}
          </div>

          {(this.state.serverSuccessMessageResume && this.state.serverSuccessMessageResume !== '') && <p className="description-text-2 cta-color">{this.state.serverSuccessMessageResume}</p>}
          {(this.state.serverErrorMessageResume && this.state.serverErrorMessageResume !== '') && <p className="description-text-2 cta-color">{this.state.serverErrorMessageResume}</p>}
        </div>
      )
    }

    renderProfileDetails() {
      console.log('renderProfileDetails called')

      return (
        <div key="renderProfileDetails">
          <div className="edit-profile-row">
            <div className="calc-column-offset-50">
              <div className="spacer" /><div className="half-spacer" />
              <p className="heading-text-3">Projects</p>
              <div className="half-spacer" />
              <p className="profile-descriptor">Add school, personal or professional projects relevant to employment. Examples include research papers, design projects, engineering projects, science projects, and business case studies.</p>

              {(this.state.projects.length > 0 && this.state.publicProfile) && (
                <div className="row-5 description-text-2">
                  <p>Your portfolio: <a href={"https://www.guidedcompass.com/" + this.state.username + "/projects"} target="_blank">https://www.guidedcompass.com/{this.state.username}/projects</a></p>
                </div>
              )}
            </div>
            <div className="fixed-column-50 right-padding top-margin-20">
              <button onClick={() => this.addItem('projects')} className="background-button" type="button">
                <img src={addIcon} alt="Compass add icon" className="add-icon"/>
              </button>
            </div>
            <div className="clear"/>
          </div>

          {(this.state.projectErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.projectErrorMessage}</p>}
          {(this.state.projectSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.projectSuccessMessage}</p>}

          {(this.state.projects.length > 0) && (
            <div className="edit-profile-row">
              {this.renderProjects()}
            </div>
          )}

          {(!window.location.pathname.includes('/problem-platform')) && (
            <div>
              <div className="spacer" /><div className="spacer" />
              <hr />

              <div className="edit-profile-row">
                <div className="calc-column-offset-50">
                  <div className="spacer" /><div className="half-spacer" />
                  <p className="heading-text-3">Experience</p>
                  <div className="half-spacer" />
                  <p className="profile-descriptor">{(this.state.activeOrg === 'c2c') ? "Provide information about current or past jobs and experiences relevant to interning on Capitol Hill. This should include any leadership, political, government, customer service, or volunteer experiences from your resume that you want to highlight." : "Provide information about current or past jobs and experiences relevant to your target internships. This should include any leadership, volunteer, or paid experiences from your resume that you want to highlight."}</p>
                </div>
                <div className="fixed-column-50 right-padding top-margin-20">
                  <button onClick={() => this.addItem('experience')} className="background-button" type="button">
                    <img src={addIcon} alt="Compass add icon" className="add-icon"/>
                  </button>
                </div>
                <div className="clear"/>
              </div>

              {(this.state.experienceErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.experienceErrorMessage}</p>}
              {(this.state.experienceSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.experienceSuccessMessage}</p>}

              {(this.state.experience.length > 0) && (
                <div className="edit-profile-row">
                  {this.renderExperience()}
                </div>
              )}
            </div>
          )}

          <div className="spacer" /><div className="spacer" />
          <hr />

          <div className="edit-profile-row">
            <div className="calc-column-offset-50">
              <div className="spacer" /><div className="half-spacer" />
              <p className="heading-text-3">Extracurriculars</p>
              <div className="half-spacer" />
              <p className="profile-descriptor">List all activities, clubs, or teams that you are / were involved in.</p>
            </div>
            <div className="fixed-column-50 right-padding top-margin-20">
              <button onClick={() => this.addItem('extracurricular')} className="background-button" type="button">
                <img src={addIcon} alt="Compass add icon" className="add-icon"/>
              </button>
            </div>
            <div className="clear"/>
          </div>

          {(this.state.extraErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.extraErrorMessage}</p>}
          {(this.state.extraSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.extraSuccessMessage}</p>}

          {(this.state.extracurriculars.length > 0) && (
            <div className="edit-profile-row">
              {this.renderExtras('extracurriculars')}
            </div>
          )}

          <div className="spacer" /><div className="spacer" />
          <hr />

          <div className="edit-profile-row">
            <div className="calc-column-offset-50">
              <div className="spacer" /><div className="half-spacer" />
              <p className="heading-text-3">Awards</p>
              <div className="half-spacer" />
              <p className="profile-descriptor">List any awards or special recognition earned in high school (Student of the Month, Perfect Attendance, Honor Roll, Citizenship, etc.) and the year received.</p>
            </div>
            <div className="fixed-column-50 right-padding top-margin-20">
              <button onClick={() => this.addItem('award')} className="background-button" type="button">
                <img src={addIcon} alt="Compass add icon" className="add-icon"/>
              </button>
            </div>
            <div className="clear"/>
          </div>

          {(this.state.awardErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.awardErrorMessage}</p>}
          {(this.state.awardSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.awardSuccessMessage}</p>}

          {(this.state.awards.length > 0) && (
            <div className="edit-profile-row">
              {this.renderExtras('awards')}
            </div>
          )}

        </div>
      )
    }

    renderProfilePrivacyPreferences() {
      console.log('renderProfilePrivacyPreferences called')

      return (
        <div key="renderProfilePrivacyPreferences">
          <div className="edit-profile-row">

            {(!window.location.pathname.includes('/advisor')) && (
              <div>
                <p className="row-10">Making your profile visible allows you to use the link as a portfolio link, add the link to your resume, connect with others, get feedback from mentors, and get shortlisted by employers for work opportunities.*</p>
                <p className="row-10 description-text-2">* Note that this is separate from applying to opportunities. In that case, only your application is shared with the reviewers.</p>
              </div>
            )}

            <div>
              <div className="row-20">
                <div className="spacer"/><div className="half-spacer"/>
                <p className="heading-text-4">Can Others View Your Profile?</p>

                <div className="row-10">
                  <div className="float-left right-padding">
                    <Switch
                      onChange={(change) => this.switchPreferences(change)}
                      checked={this.state.publicProfile}
                      id="normal-switch"
                      disabled={this.state.isSaving}
                    />
                  </div>

                  <div className="float-left top-margin-3">{(this.state.publicProfile) ? <label className="description-text-2 cta-color bold-text">(Open)</label> : <label className="description-text-2">(Private)</label>}</div>

                  <div className="clear"/>
                </div>

                {(this.state.publicPreferencesErrorMessage) && <p className="description-text-2 error-color">{this.state.publicPreferencesErrorMessage}</p>}
                {(this.state.publicPreferencesSuccessMessage) && <p className="description-text-2 cta-color">{this.state.publicPreferencesSuccessMessage}</p>}

                {(this.state.publicProfile) && (
                  <div className="row-10">

                    <div className="row-10">
                      <div>
                        <div className="float-left">
                          <p className="heading-text-5">Who Can View Your Profile?</p>
                        </div>
                        <div>
                          <div className="float-left left-margin noti-bubble-info-7-9">
                            <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showPublicProfileExtentInfo: true })}>
                              <img src={questionMarkBlue} alt="Guided Compass dashboard icon" className="image-auto-14 center-item" />
                            </a>
                          </div>

                        </div>
                        <div className="clear" />
                      </div>

                      <div className="spacer" />
                      <select name="publicProfileExtent" className="dropdown" value={this.state.publicProfileExtent} onChange={this.formChangeHandler}>
                        {this.state.publicProfileExtentOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                      <div className="spacer" />
                    </div>

                    <div className="row-10">
                      <div className="container-left wrap-word wrap-text">
                        <p className="heading-text-5">Profile Link (Within Portal)</p>
                        {(window.location.pathname.includes('/advisor')) ? (
                          <a href={window.location.protocol + "//" + window.location.host + "/advisor/profile/" + this.state.username} target="_blank">{window.location.protocol + "//" + window.location.host + "/advisor/profile/" + this.state.username}</a>
                        ) : (
                          <a href={window.location.protocol + "//" + window.location.host + "/app/profile/" + this.state.username} target="_blank">{window.location.protocol + "//" + window.location.host + "/app/profile/" + this.state.username}</a>
                        )}

                        <div className="spacer" />
                      </div>
                      {(this.state.publicProfileExtent === 'Public') && (
                        <div className="container-right wrap-word wrap-text">
                          <p className="heading-text-5">Public Profile Link</p>
                          <a href={window.location.protocol + "//" + window.location.host + "/" + this.state.username + "/profile"} target="_blank">{window.location.protocol + "//" + window.location.host + "/" + this.state.username + "/profile"}</a>
                          <div className="spacer" />
                        </div>
                      )}
                      <div className="clear" />
                    </div>

                    {(!window.location.pathname.includes('/app/walkthrough')) && (
                      <div className="row-10">
                        <div className="bottom-padding">
                          <p className="heading-text-5">Headline</p>
                        </div>
                        <input className="text-field" type="text" placeholder="Headline" name="headline" value={this.state.headline} onChange={this.formChangeHandler} />
                      </div>
                    )}

                    <div className="spacer" />

                    <div className="top-padding">
                      <p>What would you like public?</p>
                    </div>

                    <div className="row-10">
                      <div className="row-5">
                        <div className="calc-column-offset-100-static">
                          <p className="top-padding">1. Posts</p>
                        </div>
                        <div className="fixed-column-100">
                          <select name="postPublicPreference" className="dropdown" value={this.state.postPublicPreference} onChange={this.formChangeHandler}>
                            {['','All','None'].map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                        </div>
                        <div className="clear" />

                        {(this.state.postPublicPreference === 'Some') && (
                          <div>
                            {(!this.state.postOptions || this.state.postOptions.length === 0) ? (
                              <div>
                                <p className="error-color">You have no posts to publicize. Add a post to the news feed.</p>
                              </div>
                            ) : (
                              <div className="calc-column-offset-220"><div className="half-spacer" />
                                <div className="fixed-column-320 right-margin-5">
                                  <select name="selectedPost" className="dropdown" value={this.state.selectedPost} onChange={this.formChangeHandler}>
                                    {this.state.postOptions.map(value => <option key={value.title} value={value.title}>{value.title}</option>)}
                                  </select>
                                </div>
                                <div className="fixed-column-100">
                                  <button className="btn btn-squarish" onClick={() => this.optionClicked(null, 'post', this.state.selectedPost)}>Add</button>
                                </div>
                                <div className="clear" />
                              </div>
                            )}

                            <div className="clear" />
                            {this.renderTags('post', this.state.publicPosts)}
                            <div className="clear" />
                          </div>
                        )}
                      </div>

                      <div className="row-5">
                        <div className="calc-column-offset-100-static">
                          <p className="top-padding">2. Projects</p>
                        </div>
                        <div className="fixed-column-100">
                          <select name="projectPublicPreference" className="dropdown" value={this.state.projectPublicPreference} onChange={this.formChangeHandler}>
                            {this.state.publicPreferenceOptions.map(value => <option key={value} value={value}>{value}</option>)}
                          </select>
                        </div>
                        <div className="clear" />

                        {(this.state.projectPublicPreference !== 'None') && (
                          <div className="row-10 description-text-2 bold-text">
                            <label className="right-padding">Portfolio Link:</label>
                            <a href={window.location.protocol + "//" + window.location.host + "/" + this.state.username + "/projects"} target="_blank">{window.location.protocol + "//" + window.location.host + "/" + this.state.username + "/projects"}</a>
                          </div>
                        )}

                        {(this.state.projectPublicPreference === 'Some') && (
                          <div>
                            {(!this.state.projects || this.state.projects.length === 0) ? (
                              <div>
                                <p className="error-color">You have no projects to publicize. Add projects below.</p>
                              </div>
                            ) : (
                              <div className="calc-column-offset-220"><div className="half-spacer" />
                                <div className="fixed-column-320 right-margin-5">
                                  <select name="selectedProject" className="dropdown" value={this.state.selectedProject} onChange={this.formChangeHandler}>
                                    {this.state.projectOptions.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                                  </select>
                                </div>
                                <div className="fixed-column-100">
                                  <button className="btn btn-squarish" onClick={() => this.optionClicked(null, 'project', this.state.selectedProject)}>Add</button>
                                </div>
                                <div className="clear" />
                              </div>
                            )}

                            <div className="clear" />
                            {this.renderTags('project', this.state.publicProjects)}
                            <div className="clear" />
                          </div>
                        )}
                      </div>

                      <div className="row-5">
                        <div>
                          <div className="calc-column-offset-100-static">
                            <p className="top-padding">3. Goals</p>
                          </div>
                          <div className="fixed-column-100">
                            <select name="goalPublicPreference" className="dropdown" value={this.state.goalPublicPreference} onChange={this.formChangeHandler}>
                              {this.state.publicPreferenceOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="clear" />
                        </div>

                        {(this.state.goalPublicPreference === 'Some') && (
                          <div>
                            {(!this.state.goalOptions || this.state.goalOptions.length === 0) ? (
                              <div>
                                <p className="error-color">You have no goals to publicize. Add goals in the career goals tab.</p>
                              </div>
                            ) : (
                              <div className="calc-column-offset-220"><div className="half-spacer" />
                                <div className="fixed-column-320 right-margin-5">
                                  <select name="selectedGoal" className="dropdown" value={this.state.selectedGoal} onChange={this.formChangeHandler}>
                                    {this.state.goalOptions.map(value => <option key={value.title} value={value.title}>{value.title}</option>)}
                                  </select>
                                </div>
                                <div className="fixed-column-100">
                                  <button className="btn btn-squarish" onClick={() => this.optionClicked(null, 'goal', this.state.selectedGoal)}>Add</button>
                                </div>
                                <div className="clear" />
                              </div>
                            )}

                            <div className="clear" />
                            {this.renderTags('goal', this.state.publicGoals)}
                            <div className="clear" />
                          </div>
                        )}
                      </div>

                      <div className="row-5">
                        <div>
                          <div className="calc-column-offset-100-static">
                            <p className="top-padding">4. Passions</p>
                          </div>
                          <div className="fixed-column-100">
                            <select name="passionPublicPreference" className="dropdown" value={this.state.passionPublicPreference} onChange={this.formChangeHandler}>
                              {this.state.publicPreferenceOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="clear" />
                        </div>

                        {(this.state.passionPublicPreference === 'Some') && (
                          <div>
                            {(!this.state.passionOptions || this.state.passionOptions.length === 0) ? (
                              <div>
                                <p className="error-color">You have no passions to publicize. Add passions in the career goals tab.</p>
                              </div>
                            ) : (
                              <div className="calc-column-offset-220"><div className="half-spacer" />
                                <div className="fixed-column-320 right-margin-5">
                                  <select name="selectedPassion" className="dropdown" value={this.state.selectedPassion} onChange={this.formChangeHandler}>
                                    {this.state.passionOptions.map(value => <option key={value.passionTitle} value={value.passionTitle}>{value.passionTitle}</option>)}
                                  </select>
                                </div>
                                <div className="fixed-column-100">
                                  <button className="btn btn-squarish" onClick={() => this.optionClicked(null, 'passion', this.state.selectedPassion)}>Add</button>
                                </div>
                                <div className="clear" />
                              </div>
                            )}

                            <div className="clear" />
                            {this.renderTags('passion', this.state.publicPassions)}
                            <div className="clear" />
                          </div>
                        )}
                      </div>

                      <div className="row-5">
                        <div>
                          <div className="calc-column-offset-100-static">
                            <p className="top-padding">5. Career Assessments</p>
                          </div>
                          <div className="fixed-column-100">
                            <select name="assessmentPublicPreference" className="dropdown" value={this.state.assessmentPublicPreference} onChange={this.formChangeHandler}>
                              {this.state.publicPreferenceOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="clear" />
                        </div>

                        {(this.state.assessmentPublicPreference === 'Some') && (
                          <div>
                            {(!this.state.assessmentOptions || this.state.assessmentOptions.length === 0) ? (
                              <div>
                                <p className="error-color">You have no assessment results to publicize. Take assessments in the assessments tab.</p>
                              </div>
                            ) : (
                              <div className="calc-column-offset-220"><div className="half-spacer" />
                                <div className="fixed-column-320 right-margin-5">
                                  <select name="selectedAssessment" className="dropdown" value={this.state.selectedAssessment} onChange={this.formChangeHandler}>
                                    {this.state.assessmentOptions.map(value => <option key={value} value={value}>{value}</option>)}
                                  </select>
                                </div>
                                <div className="fixed-column-100">
                                  <button className="btn btn-squarish" onClick={() => this.optionClicked(null, 'assessment', this.state.selectedAssessment)}>Add</button>
                                </div>
                                <div className="clear" />
                              </div>
                            )}

                            <div className="clear" />
                            {this.renderTags('assessment', this.state.publicAssessments)}
                            <div className="clear" />
                          </div>
                        )}
                      </div>

                      <div className="row-5">
                        <div>
                          <div className="calc-column-offset-100-static">
                            <p className="top-padding">6. Skill & Knowledge Endorsements</p>
                          </div>
                          <div className="fixed-column-100">
                            <select name="endorsementPublicPreference" className="dropdown" value={this.state.endorsementPublicPreference} onChange={this.formChangeHandler}>
                              {this.state.publicPreferenceOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          </div>
                          <div className="clear" />
                        </div>

                        {(this.state.endorsementPublicPreference === 'Some') && (
                          <div>
                            {(!this.state.endorsementOptions || this.state.endorsementOptions.length === 0) ? (
                              <div>
                                <p className="error-color">You have no skill endorsements to publicize. Request endorsements in the endorsements tab.</p>
                              </div>
                            ) : (
                              <div className="calc-column-offset-220"><div className="half-spacer" />
                                <div className="fixed-column-320 right-margin-5">
                                  <select name="selectedEndorsement" className="dropdown" value={this.state.selectedEndorsement} onChange={this.formChangeHandler}>
                                    {this.state.endorsementOptions.map(value => <option key={value.senderEmail} value={(value.senderEmail && value.createdAt) ? 'From ' + value.senderFirstName + ' ' + value.senderLastName + ' - ' + value.createdAt.substring(0,10) : value.senderFirstName}>{(value.senderEmail && value.createdAt) ? 'From ' + value.senderFirstName + ' ' + value.senderLastName + ' - ' + value.createdAt.substring(0,10) : value.senderFirstName}</option>)}
                                  </select>
                                </div>
                                <div className="fixed-column-100">
                                  <button className="btn btn-squarish" onClick={() => this.optionClicked(null, 'endorsement', this.state.selectedEndorsement)}>Add</button>
                                </div>
                                <div className="clear" />
                              </div>
                            )}

                            <div className="clear" />
                            {this.renderTags('endorsement', this.state.publicEndorsements)}
                            <div className="clear" />
                          </div>
                        )}
                      </div>

                      {(!this.state.remoteAuth) && (
                        <div className="row-5">
                          <div>
                            <div className="calc-column-offset-100-static">
                              <p className="top-padding">7. Primary Resume</p>
                            </div>
                            <div className="fixed-column-100">
                              <select name="resumePublicPreference" className="dropdown" value={this.state.resumePublicPreference} onChange={this.formChangeHandler}>
                                {['','Yes','No'].map(value => <option key={value} value={value}>{value}</option>)}
                              </select>
                            </div>
                            <div className="clear" />
                          </div>

                          {(this.state.resumePublicPreference === 'Yes') && (
                            <div>
                              {(!this.state.resumes || this.state.resumes.length === 0) ? (
                                <div>
                                  <p className="error-color">You have no resumes to publicize. Add resumes in the "Basic Info" section of your profile or by using the Resume Builder.</p>
                                </div>
                              ) : (
                                <div className="calc-column-offset-220"><div className="half-spacer" />
                                  <div className="fixed-column-320 right-margin-5">
                                    <select name="selectedResume" className="dropdown" value={this.state.selectedResume} onChange={this.formChangeHandler}>
                                      {this.state.resumeNames.map(value => <option key={value} value={value}>{value}</option>)}
                                    </select>
                                  </div>
                                  <div className="fixed-column-100">
                                    <button className="btn btn-squarish" onClick={() => this.optionClicked(null, 'resume', this.state.selectedResume)}>Add</button>
                                  </div>
                                  <div className="clear" />
                                </div>
                              )}

                              <div className="clear" />
                              {this.renderTags('resume', null)}
                              <div className="clear" />
                            </div>
                          )}
                        </div>
                      )}

                    </div>

                    <div className="row-10">
                      <div>
                        <p>Automatically Included:</p>
                      </div>

                      <div className="row-10">
                        <ul className="public-profile-checkmarks">
                          <li><img src={checkmarkIcon} className="image-auto-20 center-item" alt="GC" /><p className="description-text-2 bold-text top-margin-3">Portfolio Link</p></li>
                          <li><img src={checkmarkIcon} className="image-auto-20 center-item" alt="GC" /><p className="description-text-2 bold-text top-margin-3">LinkedIn</p></li>
                        </ul>
                      </div>
                    </div>

                    {(this.state.publicPreferencesErrorMessage) && <p className="description-text-2 error-color">{this.state.publicPreferencesErrorMessage}</p>}
                    {(this.state.publicPreferencesSuccessMessage) && <p className="description-text-2 cta-color">{this.state.publicPreferencesSuccessMessage}</p>}

                    {(!window.location.pathname.includes('/app/walkthrough')) && (
                      <div className="row-10">
                        <button className="btn btn-secondary" disabled={this.state.isSaving} onClick={() => this.savePreferences(false, false)}>Save Profile Visibility Preferences</button>
                      </div>
                    )}

                  </div>
                )}

                {(window.location.pathname.includes('/app/walkthrough')) && (
                  <div className="full-width top-margin-30">
                    <hr />

                    <div className="full-width top-margin-30">
                      <div className="container-left-static left-text">
                        <button className="btn btn-primary medium-background standard-border" onClick={() => this.props.movePage(false)}>Back</button>
                      </div>

                      <div className="container-right-static right-text">
                        <button className="btn btn-primary" disabled={this.state.isSaving} onClick={() => this.savePreferences(false, false, true)}>{(this.state.showModule) ? "Next" : "Submit & Get Started"} </button>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }

    configureModal() {
      console.log('configureModal called')

      return (
        <div key="configureModal">
         {(this.state.showProjectDetail) ? (
           <div>
             <ProjectDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen}
               selectedProject={this.state.projects[this.state.selectedIndex]} orgCode={this.state.activeOrg}
               private={true} suggestions={this.state.suggestions}
             />
           </div>
         ) : (
           <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            className="modal"
            overlayClassName="modal-overlay"
            contentLabel="Example Modal"
            ariaHideApp={false}
          >

           {(this.state.showGrade) && (
             <div key="gradeProject" className="full-width padding-20">
              {(window.location.pathname.includes('/advisor')) ? (
                <div>
                  {(this.state.projects) && (
                    <div>
                      <p className="heading-text-2">Feedback</p>
                      <p className="top-padding">{this.state.projects[this.state.selectedIndex].name}</p>

                      <div className="spacer" /><div className="spacer" />

                      {this.state.projects[this.state.selectedIndex].grades.map((value, index) =>
                        <div key={value}>
                          <div className="row-10">
                            {(value.isTransparent) ? (
                              <div>
                                <div>
                                  <div className="fixed-column-60 heading-text-2 cta-color half-bold-text">
                                    {(value.grade) ? (
                                      <p>{value.grade}</p>
                                    ) : (
                                      <p>N/A</p>
                                    )}
                                  </div>
                                  <div className="calc-column-offset-60">
                                    <p className="description-text-2">{value.contributorFirstName} {value.contributorLastName}</p>
                                    <p className="heading-text-6">{value.feedback}</p>
                                  </div>
                                  <div className="clear" />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="fixed-column-60 heading-text-2">
                                  <div className="half-spacer" />
                                  <img src={confidentialityIcon} alt="Feedback icon logo" className="image-auto-40" />
                                </div>
                                <div className="calc-column-offset-60">
                                  <p>This feedback has been marked confidential by {value.contributorFirstName} {value.contributorLastName}. They need to unlock this feedback for you to view.</p>
                                </div>
                                <div className="clear" />
                              </div>
                            )}
                          </div>

                          <div className="spacer"/><div className="spacer"/>
                          <hr className="clear-margin clear-padding" />

                        </div>
                      )}

                      <div className="spacer"/><div className="spacer"/><div className="spacer"/><div className="spacer"/>
                      <p className="description-text-3 half-bold-text cta-color">Note: You may view feedback by clicking the feedback icon under each of your projects. The feedback icon will not be visible if you have not received feedback for that project.</p>
                      <div className="spacer"/><div className="spacer"/>

                      <div className="row-20">
                       <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {(this.state.projects) && (
                    <div>
                      <p className="heading-text-2">Suggestions, Feedback, and Resources</p>
                      <p className="top-padding">for {this.state.projects[this.state.selectedIndex].name}</p>

                      <div className="spacer" /><div className="spacer" />

                      {(this.state.suggestions && this.state.suggestions.length > 0) ? (
                        <div className="top-padding-20">
                          {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                          {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                          {this.state.suggestions.map((item, optionIndex) =>
                            <div key={item}>
                              <SubRenderSuggestion history={this.props.navigate}
                                suggestion={item} index={optionIndex}
                                inModal={null} sideNav={null} suggestions={this.state.suggestions}
                                closeModal={this.closeModal}
                              />
                            </div>
                          )}
                          <div className="clear" />
                        </div>
                      ) : (
                        <div className="row-20">
                          <p className="error-color">You have no suggestions yet. Share with others to crowdsource suggestions.</p>

                          {(this.state.editExisting && !this.state.remoteAuth) && (
                            <div className="row-10">

                              {(this.state.publicProfile && this.state.publicProfileExtent && this.state.publicPreferences && this.state.publicPreferences[2] && (this.state.publicPreferences[2].value === 'All' || this.state.publicPreferences[2].value === 'Some')) ? (
                                <div className="standard-border padding-40">
                                  <p className="top-margin">Share this link to crowdsource resources for this goal: <a href={window.location.protocol + "//" + window.location.host + '/goals/' + this.state.goalId} target="_blank" rel="noopener noreferrer">{window.location.protocol + "//" + window.location.host + '/goals/' + this.state.goalId}</a>. If you want to make your goal private, you can do so <Link to="/app/edit-profile/public-preferences">here</Link>.</p>
                                </div>
                              ) : (
                                <div className="standard-border padding-40">
                                  <p className="top-margin">To crowdsource resources for this goal, make it public <Link to="/app/edit-profile/public-preferences">here</Link>, then share this link: <a href={window.location.protocol + "//" + window.location.host + '/goals/' + this.state.goalId} target="_blank" rel="noopener noreferrer">{window.location.protocol + "//" + window.location.host + '/goals/' + this.state.goalId}</a>.</p>
                                </div>
                              )}
                            </div>
                          )}

                        </div>
                      )}

                      {(this.state.projects[this.state.selectedIndex].grades && this.state.projects[this.state.selectedIndex].grades.length > 0) && (
                        <div>
                          <p className="heading-text-5">Grades & Feedback</p>
                          {this.state.projects[this.state.selectedIndex].grades.map((value, index) =>
                            <div key={value}>
                              <div className="row-10">
                                {(value.isTransparent) ? (
                                  <div>
                                    <div>
                                      <div className="fixed-column-60 heading-text-2 cta-color half-bold-text">
                                        {(value.grade) ? (
                                          <p>{value.grade}</p>
                                        ) : (
                                          <p>N/A</p>
                                        )}
                                      </div>
                                      <div className="calc-column-offset-60">
                                        <p className="description-text-2">{value.contributorFirstName} {value.contributorLastName}</p>
                                        <p className="heading-text-6">{value.feedback}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="fixed-column-60 heading-text-2">
                                      <div className="half-spacer" />
                                      <img src={confidentialityIcon} alt="Feedback icon logo" className="image-auto-40" />
                                    </div>
                                    <div className="calc-column-offset-60">
                                      <p>This feedback has been marked confidential by {value.contributorFirstName} {value.contributorLastName}. They need to unlock this feedback for you to view.</p>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                )}
                              </div>

                              <div className="spacer"/><div className="spacer"/>
                              <hr className="clear-margin clear-padding" />

                            </div>
                          )}
                        </div>
                      )}

                      <div className="spacer"/><div className="spacer"/>
                    </div>
                  )}

                  <div className="row-20">
                   <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                  </div>
                </div>
              )}
             </div>
           )}

           {(this.state.showJobFunction) && (
             <div key="showJobFunction" className="full-width padding-20">
               <p className="heading-text-2">Job Function</p>
               <div className="spacer" />
               <p>We define <label className="half-bold-text cta-color">job functions</label> as a category of work that requires similar skills. It can be thought of as synonymous with "departments" within a company. Functions can be the same across different industries. Examples of functions include sales, marketing, finance, engineering, and design.</p>

               <div className="row-20">
                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
               </div>
             </div>
           )}

           {(this.state.showIndustry) && (
             <div key="showIndustry" className="full-width padding-20">
               <p className="heading-text-2">Industry</p>
               <div className="spacer" />
               <p>We define <label className="half-bold-text cta-color">industry</label> as a category of companies that are related based on their primary business activitiees. Companies are generally grouped by their sources of revenue. For example, Nike would fall under "Fashion & Apparel" and Netflix would fall under "Other Entertainment".</p>

               <div className="row-20">
                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
               </div>
             </div>
           )}

           {(this.state.skillTagsInfo) && (
             <div key="showIndustry" className="full-width padding-20">
               <p className="heading-text-2">Skill Tags Info</p>
               <div className="spacer" />
               <p><label className="half-bold-text cta-color">Skill Tags</label> allow you to list the skills related to your experience separated by commas. For example, for design experience, you may want to tag wireframing, Adobe Photoshop, and flow chart. This allows the reviewer to better understand your skills and allows you to receive better recommendations.</p>

               <div className="row-20">
                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
               </div>
             </div>
           )}

           {(this.state.showPublicProfileExtentInfo) && (
             <div key="showIndustry" className="full-width padding-20">
               <p className="heading-text-2">Who Can See Your Profile?</p>
               <div className="spacer" />

               <div className="row-10">
                 <p><label className="half-bold-text cta-color">Only Connections</label> means that only those who you are connected with on Guided Compass can view the items you select as public.</p>
               </div>

               <div className="row-10">
                 <p><label className="half-bold-text cta-color">Only Connections and Members</label> means that only those who you are either connected with on Guided Compass or those who are part of the {this.state.orgName} community can view the items you select as public.</p>
               </div>

               <div className="row-10">
                 <p><label className="half-bold-text cta-color">Public</label> means that not only connections and the {this.state.orgName} community can view the items you select as public, others can view as well. Just share your public profile link with whomever you like. This also means that the Guided Compass team can share your public profile with employers.</p>
               </div>

               <div className="row-20">
                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
               </div>
             </div>
           )}

           {(this.state.showSettings) && (
             <div key="showIndustry" className="full-width padding-20">
               <p className="heading-text-2">Settings</p>
               <div className="spacer" />

               <div>
                 <EditSubscription />
               </div>

               <div>
                 <SwitchOrgs
                   emailId={this.state.emailId} activeOrg={this.state.activeOrg} myOrgs={this.state.myOrgs}
                   sharePartners={this.state.sharePartners} roleName={this.state.roleName}
                   academies={this.state.academies} academyCodes={this.state.academyCodes}
                   accountCode={this.state.accountCode}
                 />
               </div>

               <div className="row-10">
                 <button className="btn btn-secondary" onClick={() => signOut(
                   this.state.email, this.state.activeOrg, this.state.orgFocus,
                   this.state.accountCode, this.state.roleName, this.props.navigate
                 )}>Sign Out</button>
               </div>


             </div>
           )}

           {(this.state.showBirthdate) && (
             <div key="showIndustry" className="full-width padding-20">
               <p className="heading-text-2">Are you over 18?</p>
               <div className="spacer" />
               <p>You must be over 18 to set your profile to {this.state.tempPublicProfileExtent}.</p>

               <div className="row-15">
                 <div className="container-left">
                   <label className="profile-label">Date of Birth</label>
                   <input type="date" className="date-picker white-background" min={convertDateToString(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),"rawDateForInput")} max={convertDateToString(new Date(new Date().getFullYear() - 12, new Date().getMonth(), new Date().getDate()),"rawDateForInput")} name="dateOfBirth" value={this.state.dateOfBirth} onChange={this.formChangeHandler}></input>
                   {(this.state.dateOfBirth && this.state.dateOfBirth !== '' && this.testBirthdate(this.state.dateOfBirth)) && (
                     <div>
                       <p className="error-message">Please add a valid birthdate</p>
                     </div>
                   )}
                 </div>
                 <div className="clear" />
               </div>

               {(this.state.publicPreferencesErrorMessage) && <p className="description-text-2 error-color">{this.state.publicPreferencesErrorMessage}</p>}

               <div className="row-20">
                 <div className="float-left right-padding">
                   <button className="btn btn-primary" onClick={() => this.verifyLegalAge()}>Make Profile Public</button>
                 </div>
                 <div className="float-left">
                   <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                 </div>
                 <div className="clear" />

               </div>
             </div>
           )}

           {(this.state.showEstimatedHoursDefinition) && (
             <div key="showJobFunction" className="full-width padding-20">
               <p className="heading-text-2">Average Estimated Hours Definition</p>
               <div className="spacer" /><div className="spacer" /><div className="spacer" />
               <p>On average, how many hours do people need to put in to receive the certificate, badge, or certification? This is meant to gauge difficulty.</p>

               <p className="top-padding-20">Do NOT put the number of hours that you, specifically, took to receive the certificate, badge, or certification.</p>

               <div className="spacer" /><div className="spacer" />
               <div className="row-20">
                <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
               </div>
             </div>
           )}

          </Modal>
         )}

        </div>
      )
    }

    testBirthdate(dateOfBirth) {
      console.log('testBirthdate called', dateOfBirth)

      let dateIsInvalid = true
      if (dateOfBirth && convertStringToDate(dateOfBirth,"dateOnly")) {

        const birthYear = convertStringToDate(dateOfBirth,"dateOnly").getFullYear()
        const currentYear = new Date().getFullYear()
        console.log('compare birthdates: ', birthYear, currentYear)
        if ((currentYear - birthYear > 5) && (currentYear - birthYear < 100)) {
          // date is valid
          dateIsInvalid = false
          // console.log('---> ', birthYear, new Date().getFullYear())
        }
      }

      return dateIsInvalid

    }

    renderEducation() {
      console.log('renderEducation called')

      return (
        <div key="renderEducation">
          <div>
            <div className="spacer" /><div className="spacer" />
            <hr />
            <div className="edit-profile-row">
              <div className="spacer"/><div className="half-spacer"/>
              <div className="float-left">
                <p className="heading-text-3">Education (Overview)</p>
              </div>
              <div className="float-left left-padding">
                <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                <button onClick={() => this.addItem('education')} className="background-button padding-7 standard-border circle-radius" type="button">
                  <img src={addIcon} alt="Compass add icon" className="image-auto-12"/>
                </button>
              </div>
              <div className="clear" />

            </div>
          </div>

          {(this.state.requirePersonalInfo || this.state.activeOrg === 'fosternation') && (
            <div className="row-10">
              <label className="profile-label">Education Status: I am currently earning a<label className="error-color bold-text">*</label></label>
              <select name={"educationStatus"} className="dropdown" value={this.state.educationStatus} onChange={this.formChangeHandler}>
                  {this.state.educationStatusOptions.map(value => <option key={value} value={value}>{value}</option>)}
              </select>
            </div>
          )}

          {(this.state.showEducationArray) ? (
            <div>
              {(this.state.education) && (
                <div>
                  {this.state.education.map((item, optionIndex) =>
                    <div key={optionIndex}>
                      <div className="spacer" />
                      <div className="row-10">
                        <div>
                          <div className="calc-column-offset-20">
                            <p className="heading-text-6">Education #{optionIndex + 1}{(item.name) && ": " + item.name}</p>
                          </div>
                          <div className="fixed-column-20">
                            <button type="button" className="background-button full-width right-text" onClick={() => this.toggleConfirmDelete('education', optionIndex, true)}>
                              <img src={deleteIconDark} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Delete education entry"/>
                            </button>
                          </div>
                          <div className="clear" />

                          {(item.confirmDelete) && (
                            <div className="top-padding-20">
                              {this.renderConfirmDeleteWidget('education', optionIndex, true)}
                            </div>
                          )}
                        </div>
                        <ReactTooltip id="tooltip-placeholder-id" />
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">{(this.state.activeOrg === 'dpscd') ? "Current School Name" : "Current / Latest School Name"}<label className="error-color bold-text">*</label></label>
                          {(this.state.activeOrg === 'exp') ? (
                            <select name={"education|name|" + optionIndex} className="dropdown" value={item.name} onChange={this.formChangeHandler}>
                                {this.state.schoolOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          ) : (
                            <input className="text-field" type="text" placeholder="e.g. Compass High School" name={"education|name|" + optionIndex} value={item.name} onChange={this.formChangeHandler}/>
                          )}

                          {(this.state.schoolsAreLoading) ? (
                            <div className="flex-container flex-center full-space">
                              <div>
                                <div className="super-spacer" />

                                <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                                <div className="spacer" /><div className="spacer" /><div className="spacer" />
                                <p className="center-text cta-color bold-text">Searching...</p>

                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="clear" />
                              <div className="spacer"/>

                              {(this.state.schoolOptions && this.state.schoolOptions.length > 0) && (
                                <div className="full-width">
                                  <div className="spacer"/>
                                  {this.state.schoolOptions.map((value2, optionIndex2) =>
                                    <div key={value2._id} className="bottom-padding">
                                      {(value2 && value2 !== '') && (
                                        <div className="left-text bottom-margin-5 full-width standard-color">
                                          <button type="button" className="background-button left-text" onClick={() => this.optionClicked(optionIndex2, "education|name|" + optionIndex)}>
                                            <div className="full-width">
                                              <div className="fixed-column-30">
                                                <img src={courseIconDark} alt="GC" className="image-auto-22" />
                                              </div>
                                              <div className="calc-column-offset-30">
                                                <p className="cta-color">{value2}</p>
                                              </div>
                                              <div className="clear" />
                                            </div>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="container-right">
                          {(!this.state.orgDegree) && (
                            <div>
                              <label className="profile-label">Degree Type<label className="error-color bold-text">*</label></label>
                              <select name={"education|degree|" + optionIndex} className="dropdown" value={item.degree} onChange={this.formChangeHandler}>
                                  {this.state.degreeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                              </select>
                            </div>
                          )}
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          {(this.state.activeOrg === 'dpscd') ? (
                            <div>
                              <label className="profile-label">Career Pathway<label className="error-color bold-text">*</label></label>
                              <select name={"education|pathway|" + optionIndex} className="dropdown" value={item.pathway} onChange={this.formChangeHandler}>
                                {this.state.pathwayOptions.map(value => <option key={value.name} value={(value.name && value.name !== '') && value.name + ' | ' + value.school}>{(value.name && value.name !== '') && value.name + ' | ' + value.school}</option>)}
                              </select>
                            </div>
                          ) : (
                            <div>
                              <label className="profile-label">School Major / Pathway<label className="error-color bold-text">*</label></label>
                              {(this.state.activeOrg === 'exp') ? (
                                <select name={"education|major|" + optionIndex} className="dropdown" value={item.major} onChange={this.formChangeHandler}>
                                    {this.state.pathwayOptions.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                                </select>
                              ) : (
                                <input className="text-field" type="text" placeholder="e.g. Business Management" name={"education|major|" + optionIndex} value={item.major} onChange={this.formChangeHandler}/>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="container-right">
                          <label className="profile-label">Location</label>
                          <input className="text-field" type="text" placeholder="(e.g., Los Angeles, CA)" name={"education|location|" + optionIndex} value={item.location} onChange={this.formChangeHandler} />
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="edit-profile-row">
                        <div className="container-left">
                          <label className="profile-label">Did you graduate?</label>
                          <Switch
                            onChange={(change) => this.changeContinual(optionIndex, change,'graduated')}
                            checked={item.graduated}
                            id="normal-switch"
                          />
                        </div>
                        {(!item.graduated) && (
                          <div className="container-right">
                            <label className="profile-label">Are you still at this school?</label>
                            <Switch
                              onChange={(change) => this.changeContinual(optionIndex, change,'education')}
                              checked={item.isContinual}
                              id="normal-switch"
                            />
                          </div>
                        )}

                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          <label className="profile-label">Start Date<label className="error-color bold-text">*</label></label>
                          <select name={"education|startDate|" + optionIndex} className="dropdown" value={item.startDate} onChange={this.formChangeHandler}>
                              {this.state.dateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                          </select>
                        </div>
                        <div className="container-right">
                          <label className="profile-label">Grad Year / End Date<label className="error-color bold-text">*</label></label>
                          {(this.state.activeOrg === 'exp' || this.state.orgFocus === 'School' || this.state.orgFocus === 'Academy') ? (
                            <select name={"education|gradYear|" + optionIndex} className="dropdown" value={item.gradYear} onChange={this.formChangeHandler}>
                                {this.state.gradYearOptions.map(value => <option key={value} value={value}>{value}</option>)}
                            </select>
                          ) : (
                            <select name={"education|endDate|" + optionIndex} className="dropdown" value={item.endDate} onChange={this.formChangeHandler}>
                                {this.state.educationDateOptions.map(value => <option key={value.value} value={value.value}>{value.value}</option>)}
                            </select>
                          )}
                        </div>

                        <div className="clear" />
                      </div>

                      <div className="row-30">
                        <label className="profile-label">Summary / Noteworthy Accomplishments</label>
                        <textarea className="text-field" name={"education|summary|" + optionIndex} placeholder={"(e.g., our 4-person, 3-month group project at Washington High School won the " + new Date().getFullYear() + " annual pitch competition)"} value={item.summary} onChange={this.formChangeHandler}/>
                      </div>

                      {(this.state.education[optionIndex + 1]) && (
                        <div className="row-10 horizontal-padding-2">
                          <hr />
                        </div>
                      )}

                    </div>
                  )}
                </div>
              )}

              {(window.location.pathname.includes('/apply')) && (
                <div className="top-margin-20">
                  <hr />
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">{(this.state.activeOrg === 'dpscd') ? "Current School Name" : "Current / Latest School Name"}<label className="error-color bold-text">*</label></label>
                  {(this.state.activeOrg === 'exp') ? (
                    <select name="schoolName" className="dropdown" value={this.state.schoolName} onChange={this.formChangeHandler}>
                        {this.state.schoolOptions.map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                  ) : (
                    <input className="text-field" type="text" placeholder="e.g. Compass High School" name="schoolName" value={this.state.schoolName} onChange={this.formChangeHandler}/>
                  )}

                  {(this.state.schoolsAreLoading) ? (
                    <div className="flex-container flex-center full-space">
                      <div>
                        <div className="super-spacer" />

                        <img src={loadingGIF} alt="Compass loading gif icon" className="image-auto-80 center-horizontally"/>
                        <div className="spacer" /><div className="spacer" /><div className="spacer" />
                        <p className="center-text cta-color bold-text">Searching...</p>

                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="clear" />
                      <div className="spacer"/>

                      {(this.state.schoolOptions && this.state.schoolOptions.length > 0) && (
                        <div className="full-width">
                          <div className="spacer"/>
                          {this.state.schoolOptions.map((value, optionIndex) =>
                            <div key={value._id} className="bottom-padding">
                              {(value && value !== '') && (
                                <div className="left-text bottom-margin-5 full-width standard-color">
                                  <button className="background-button left-text" onClick={() => this.optionClicked(optionIndex, 'school')}>
                                    <div className="full-width">
                                      <div className="fixed-column-30">
                                        <img src={courseIconDark} alt="GC" className="image-auto-22" />
                                      </div>
                                      <div className="calc-column-offset-30">
                                        <p className="cta-color">{value}</p>
                                      </div>
                                      <div className="clear" />
                                    </div>
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="container-right">
                  {(!this.state.orgDegree) && (
                    <div>
                      <label className="profile-label">Degree Type<label className="error-color bold-text">*</label></label>
                      {console.log('show degree: ', this.state.degree, this.state.degreeOptions)}
                      <select name="degree" className="dropdown" value={this.state.degree} onChange={this.formChangeHandler}>
                          {this.state.degreeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                      </select>
                    </div>
                  )}
                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <div className="container-left">
                  {(this.state.activeOrg === 'dpscd') ? (
                    <div>
                      <label className="profile-label">Career Pathway<label className="error-color bold-text">*</label></label>
                      <select name="pathway" className="dropdown" value={this.state.pathway} onChange={this.formChangeHandler}>
                        {this.state.pathwayOptions.map(value => <option key={value.name} value={(value.name && value.name !== '') && value.name + ' | ' + value.school}>{(value.name && value.name !== '') && value.name + ' | ' + value.school}</option>)}
                      </select>
                    </div>
                  ) : (
                    <div>
                      <label className="profile-label">School Major / Pathway<label className="error-color bold-text">*</label></label>
                      {(this.state.activeOrg === 'exp') ? (
                        <select name="major" className="dropdown" value={this.state.major} onChange={this.formChangeHandler}>
                            {this.state.pathwayOptions.map(value => <option key={value.name} value={value.name}>{value.name}</option>)}
                        </select>
                      ) : (
                        <input className="text-field" type="text" placeholder="e.g. Business Management" name="major" value={this.state.major} onChange={this.formChangeHandler}/>
                      )}
                    </div>
                  )}
                </div>
                <div className="container-right">
                  <label className="profile-label">Grad Year<label className="error-color bold-text">*</label></label>
                  {(this.state.activeOrg === 'exp' || this.state.orgFocus === 'School' || this.state.orgFocus === 'Academy') ? (
                    <select name="gradYear" className="dropdown" value={this.state.gradYear} onChange={this.formChangeHandler}>
                        {this.state.gradYearOptions.map(value => <option key={value} value={value}>{value}</option>)}
                    </select>
                  ) : (
                    <input className="number-field" type="number" placeholder="e.g. Grad Year" name="gradYear" value={this.state.gradYear} onChange={this.formChangeHandler}/>
                  )}
                </div>
                <div className="clear" />
              </div>
            </div>
          )}
        </div>
      )
    }

    renderCertifications() {
      console.log('renderCertifications called')

      return (
        <div key="renderCertifications">
          <div>
            <div className="spacer" /><div className="spacer" />
            <hr />
            <div className="edit-profile-row">
              <div className="spacer"/><div className="half-spacer"/>
              <div className="float-left">
                <p className="heading-text-3">Certificates, Badges, & Certifications</p>
              </div>
              <div className="float-left left-padding">
                <div className="mini-spacer" /><div className="mini-spacer" /><div className="mini-spacer" />
                <button onClick={() => this.addItem('certificate')} className="background-button padding-7 standard-border circle-radius" type="button">
                  <img src={addIcon} alt="Compass add icon" className="image-auto-12"/>
                </button>
              </div>
              <div className="clear" />
            </div>

            <div>
              {(this.state.certificates) && (
                <div>
                  {this.state.certificates.map((item, optionIndex) =>
                    <div key={optionIndex}>
                      <div className="spacer" />
                      <div className="row-10">
                        <div>
                          <div className="calc-column-offset-20">
                            <p className="heading-text-6">Certificate #{optionIndex + 1}{(item.name) && ": " + item.name}</p>
                          </div>
                          <div className="fixed-column-20">
                            <button type="button" className="background-button full-width right-text" onClick={() => this.toggleConfirmDelete('certificate', optionIndex, true)}>
                              <img src={deleteIconDark} alt="GC" className="image-auto-15 pin-right" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Delete certificate entry"/>
                            </button>
                          </div>
                          <div className="clear" />

                          {(item.confirmDelete) && (
                            <div className="top-padding-20">
                              {this.renderConfirmDeleteWidget('certificate', optionIndex, true)}
                            </div>
                          )}
                        </div>
                        <ReactTooltip id="tooltip-placeholder-id" />
                      </div>

                      {(this.state.certificateOptions && this.state.certificateOptions.length > 1) && (
                        <div className="row-10">
                          <div className="container-left">
                            <p className="profile-label">Select an option from {this.state.orgName}'s list <label className="error-color">*</label></p>
                            <select name={"certificate|addCertificateMethod|" + optionIndex} value={item.addCertificateMethod} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.addCertificateMethodOptions.map(value =>
                                <option key={value} value={value}>{value}</option>
                              )}
                            </select>
                          </div>
                          {(item.addCertificateMethod === 'Yes, select from options') && (
                            <div className="container-right">
                              <p className="profile-label">Select a certificate / badge <label className="error-color">*</label></p>
                              <select name={"certificate|certificateOption|" + optionIndex} value={item.certificateOption} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.certificateOptions.map(value =>
                                  <option key={value.name} value={value.name}>{value.name}</option>
                                )}
                              </select>
                            </div>
                          )}
                          <div className="clear" />
                        </div>
                      )}

                      <div className="row-10">
                        <div className="container-left">
                          <p className="profile-label">Name <label className="error-color">*</label></p>
                          <input type="text" className="text-field" placeholder="(e.g., Adobe Photoshop)..." name={"certificate|name|" + optionIndex} value={item.name} onChange={this.formChangeHandler}></input>
                        </div>

                        <div className="container-right">
                          <p className="profile-label">Type <label className="error-color">*</label></p>
                          <select name={"certificate|degreeType|" + optionIndex} value={item.degreeType} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.certificateDegreeTypeOptions.map(value =>
                              <option key={value} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          <p className="profile-label">Issuer Name<label className="error-color">*</label></p>
                          <input type="text" className="text-field" placeholder="(e.g., Harvard University)..." name={"certificate|schoolName|" + optionIndex} value={item.schoolName} onChange={this.formChangeHandler}></input>
                        </div>

                        <div className="container-right">
                          <p className="profile-label">Issuer Website<label className="error-color">*</label></p>
                          <input type="text" className="text-field" placeholder="(e.g., https://www.harvard.edu)..." name={"certificate|schoolURL|" + optionIndex} value={item.schoolURL} onChange={this.formChangeHandler}></input>
                          {(item.schoolURL && item.schoolURL !== '' && !item.schoolURL.startsWith('http')) && (
                            <div>
                              <p className="error-message">Please start your link with http</p>
                            </div>
                          )}
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          <div>
                            <div className="calc-column-offset-30">
                              <p className="profile-label">Avg Estimated Hours to Achieve <label className="error-color">*</label></p>
                            </div>
                            <div className="fixed-column-30">
                              <div className="noti-bubble-info-7-9">
                                <a className="background-link" onClick={() => this.setState({ modalIsOpen: true, showEstimatedHoursDefinition: true })}>
                                  <img src={questionMarkBlue} alt="GC" className="image-auto-14 center-item" />
                                </a>
                              </div>
                            </div>
                            <div className="clear" />
                          </div>

                          <select name={"certificate|estimatedHours|" + optionIndex} value={item.estimatedHours} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.estimatedHoursOptions.map(value =>
                              <option key={value} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                        <div className="container-right">
                          <p className="profile-label">Industry</p>
                          <select name={"certificate|industry|" + optionIndex} value={item.industry} onChange={this.formChangeHandler} className="dropdown">
                            {this.state.industryOptions.map(value =>
                              <option key={value.value} value={value.value}>{value.value}</option>
                            )}
                          </select>
                        </div>
                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          <p className="profile-label">Work Functions</p>
                          <div className="calc-column-offset-70">
                            <select name={"certificate|workFunction|" + optionIndex} value={item.workFunction} onChange={this.formChangeHandler} className="dropdown">
                              {this.state.functionOptions.map(value =>
                                <option key={value.value} value={value.value}>{value.value}</option>
                              )}
                            </select>
                          </div>
                          <div className="fixed-column-70 left-padding">
                            <button className="btn btn-squarish pin-right" type="button" onClick={() => this.addItem('function',optionIndex)}>Add</button>
                          </div>
                          <div className="clear" />

                          {(item.functions) && (
                            <div>
                              {this.renderTags('function', item.functions, optionIndex)}
                            </div>
                          )}

                        </div>
                        {(this.state.newPathwayOptions && this.state.newPathwayOptions.length > 0) && (
                          <div className="container-right">
                            <p className="profile-label">Pathways</p>
                            <div className="calc-column-offset-70">
                              <select name={"certificate|pathway|" + optionIndex} value={item.pathway} onChange={this.formChangeHandler} className="dropdown">
                                {this.state.newPathwayOptions.map(value =>
                                  <option key={value.title} value={value.title}>{value.title}</option>
                                )}
                              </select>
                            </div>
                            <div className="fixed-column-70 left-padding">
                              <button className="btn btn-squarish pin-right" type="button" onClick={() => this.addItem('pathway',optionIndex)}>Add</button>
                            </div>
                            <div className="clear" />

                            {(item.pathways) && (
                              <div>
                                {this.renderTags('pathway', item.pathways, optionIndex)}
                              </div>
                            )}

                          </div>
                        )}

                        <div className="clear" />
                      </div>

                      <div className="row-10">
                        <div className="container-left">
                          <p className="profile-label">Are you still working on this {(item.degreeType) ? item.degreeType : "Certificate"}?</p>
                          <Switch
                            onChange={(change) => this.switchPreferences(change,'certificate',optionIndex)}
                            checked={item.isContinual}
                            id="normal-switch"
                            disabled={this.state.isSaving}
                          />
                        </div>
                        <div className="container-right">
                          <p className="profile-label">Did you complete this {(item.degreeType) ? item.degreeType : "Certificate"}?</p>
                          <Switch
                            onChange={(change) => this.switchPreferences(change,'complete',optionIndex)}
                            checked={item.isCompleted}
                            id="normal-switch"
                            disabled={this.state.isSaving}
                          />
                        </div>
                        <div className="clear" />
                      </div>

                      {(item.isCompleted) && (
                        <div className="row-10">
                          <div className="container-left">
                            <p className="profile-label">Date Issued</p>
                            <input type="date" className="date-picker white-background" name={"certificate|dateIssued|" + optionIndex} value={item.dateIssued} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="container-right">
                            <p className="profile-label">Link to {(item.degreeType) ? item.degreeType : "Certificate"}</p>
                            <input type="text" className="text-field" placeholder="(e.g., https://www.certificate.com/123)..." name={"certificate|url|" + optionIndex} value={item.url} onChange={this.formChangeHandler}></input>
                          </div>
                          <div className="clear" />
                        </div>
                      )}

                      <div className="row-10">
                        <p className="profile-label">Description <label className="error-color">*</label></p>
                        <textarea type="text" className="text-field" placeholder="Add a description of the certificate, badge, or certification..." name={"certificate|description|" + optionIndex} value={item.description} onChange={this.formChangeHandler}></textarea>
                      </div>
                      {/*
                      <div className="row-10">
                        <p className="profile-label">Upload the Certificate <label className="error-color">*</label></p>
                        <textarea type="text" className="text-field" placeholder="Add a description of the certificate, badge, or certification..." name={"certificate|description|" + optionIndex} value={item.description} onChange={this.formChangeHandler}></textarea>
                      </div>*/}

                      {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                      {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}

                      {(this.state.certificates[optionIndex + 1]) && (
                        <div className="row-10 horizontal-padding-2">
                          <hr />
                        </div>
                      )}

                    </div>
                  )}
                </div>
              )}

              {(window.location.pathname.includes('/apply')) && (
                <div className="top-margin-20">
                  <hr />
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }

    renderCareerInfo() {
      console.log('renderCareerInfo called')

      return (
        <div key="renderCareerInfo">
          {(this.state.activeOrg === 'fosternation') && (
            <div>
              <div className="spacer" /><div className="spacer" />
              <hr />

              <div className="edit-profile-row">
                <div className="spacer"/><div className="half-spacer"/>
                <p className="heading-text-3">Career Info</p>
              </div>

              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Career Status</label>
                  <input className="text-field" type="text" placeholder="e.g. working..." name="careerStatus" value={this.state.careerStatus} onChange={this.formChangeHandler}/>
                </div>
                <div className="container-right">
                  <label className="profile-label">Dream Career</label>
                  <input className="text-field" type="text" placeholder="e.g. professional base jumper" name="dreamCareer" value={this.state.dreamCareer} onChange={this.formChangeHandler}/>
                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Dream Company</label>
                  <input className="text-field" type="text" placeholder="e.g. Google..." name="dreamCompany" value={this.state.dreamCompany} onChange={this.formChangeHandler}/>
                </div>
                <div className="container-right">
                  <label className="profile-label">Primary Career Interest</label>
                  <input className="text-field" type="text" placeholder="e.g. software engineering..." name="primaryCareerInterest" value={this.state.primaryCareerInterest} onChange={this.formChangeHandler}/>
                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Secondary Career Interest</label>
                  <input className="text-field" type="text" placeholder="e.g. ux/ui designer..." name="secondaryCareerInterest" value={this.state.secondaryCareerInterest} onChange={this.formChangeHandler}/>
                </div>
                <div className="container-right">
                  <label className="profile-label">Tertiary Career Interest</label>
                  <input className="text-field" type="text" placeholder="e.g. marketing manager..." name="tertiaryCareerInterest" value={this.state.tertiaryCareerInterest} onChange={this.formChangeHandler}/>
                </div>
                <div className="clear" />
              </div>
            </div>
          )}
        </div>
      )
    }

    renderPersonalInfo() {
      console.log('renderPersonalInfo called')

      return (
        <div key="renderPersonalInfo">
          <div className="edit-profile-row">
            <div className="spacer"/><div className="half-spacer"/>
            <p className="heading-text-3">Self-Identification / Personal Info</p>
            <p className="profile-descriptor top-margin">This private information is included for program reporting and opportunity matching purposes. Employers, teachers, and fellow students do not see this information. For more detail, please review our <Link to="/privacy-policy" target="_blank" rel="opopener noreferrer">Privacy Policy</Link> or reach out to us with questions.</p>
          </div>

          <div className="edit-profile-row">
            <div className="container-left">
              <label className="profile-label">Street Address{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
              <input className="text-field" type="text" placeholder="e.g. Home Address" name="address" value={this.state.address} onChange={this.formChangeHandler}/>
            </div>
            <div className="container-right">
              <label className="profile-label">City{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
              <input className="text-field" type="text" placeholder="e.g. City" name="city" value={this.state.city} onChange={this.formChangeHandler}/>
            </div>
            <div className="clear" />
          </div>

          <div className="edit-profile-row">
            <div className="container-left">
              <label className="profile-label">Zip Code{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
              <input className="text-field" type="text" placeholder="e.g. Zip Code" name="zipcode" value={this.state.zipcode} onChange={this.formChangeHandler}/>
            </div>
            <div className="container-right">
              <label className="profile-label">Mobile Phone{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
              <input className="text-field" type="text" placeholder="555-555-5555" name="phoneNumber" value={this.state.phoneNumber} onChange={this.formChangeHandler} maxlength="14" />
            </div>
            <div className="clear" />
          </div>

          {(this.state.includeAlternativeContacts) && (
            <div className="row-10">
              <div className="container-left">
                <label className="profile-label">Alternative Email</label>
                <input className="text-field" type="text" placeholder="e.g. jon@doe.com" name="alternativeEmail" value={this.state.alternativeEmail} onChange={this.formChangeHandler}/>
              </div>
              <div className="container-right">
                <label className="profile-label">Alternative Phone Number</label>
                <input className="text-field" type="text" placeholder="555-555-5555" name="alternativePhoneNumber" value={this.state.alternativePhoneNumber} onChange={this.formChangeHandler} maxlength="14"/>
              </div>
              <div className="clear" />
            </div>
          )}

          <div className="edit-profile-row">
            <div className="container-left">
              <label className="profile-label">Date of Birth{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
              <input type="date" className="date-picker white-background" min={convertDateToString(new Date(new Date().getFullYear() - 100, new Date().getMonth(), new Date().getDate()),"rawDateForInput")} max={convertDateToString(new Date(new Date().getFullYear() - 12, new Date().getMonth(), new Date().getDate()),"rawDateForInput")} name="dateOfBirth" value={this.state.dateOfBirth} onChange={this.formChangeHandler}></input>
              {(this.state.dateOfBirth && this.state.dateOfBirth !== '' && this.testBirthdate(this.state.dateOfBirth)) && (
                <div>
                  <p className="error-message">Please add a valid birthdate</p>
                </div>
              )}
            </div>
            {(!this.state.remoteAuth && this.state.activeOrg !== 'unite-la') && (
              <div className="container-right">
                <label className="profile-label">Veteran Status{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>

                <select name="veteranStatus" className="dropdown" value={this.state.veteranStatus} onChange={this.formChangeHandler}>
                    {this.state.veteranStatusOptions.map(value => <option key={value} value={value}>{value}</option>)}
                </select>
              </div>
            )}
            <div className="clear" />
          </div>

          {(this.state.activeOrg === 'exp') && (
            <div>
              <div className="edit-profile-row">
                <div className="container-left">
                  <label className="profile-label">Last 4 Digits of Social Security Number{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                  <input className="text-field" type="text" placeholder="e.g. Last 4 Digits" name="lastOfSocialSecurity" value={this.state.lastOfSocialSecurity} onChange={this.formChangeHandler}/>
                </div>
                <div className="container-right">
                  <label className="profile-label">IEP or 504 Plan{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                  <input className="text-field" type="text" placeholder="e.g. IEP or 504 Plan" name="IEPPlan" value={this.state.IEPPlan} onChange={this.formChangeHandler}/>
                </div>
                <div className="clear" />
              </div>

              <div className="contrasting-container-1">
                <div className="edit-profile-row">
                  <p className="heading-text-4">Parent/Guardian Information</p>
                </div>

                <div className="edit-profile-row">
                  <div className="container-left">
                    <label className="profile-label">Parent/Guardian First Name and Last Name{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                    <input className="text-field" type="text" placeholder="e.g. Parent/Guardian Name" name="parentName" value={this.state.parentName} onChange={this.formChangeHandler}/>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Parent/Guardian Relationship to Student{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                    <input className="text-field" type="text" placeholder="e.g. Parent/Guardian Relationship" name="parentRelationship" value={this.state.parentRelationship} onChange={this.formChangeHandler}/>
                  </div>
                  <div className="clear" />
                </div>

                <div className="edit-profile-row">
                  <div className="container-left">
                    <label className="profile-label">Parent/Guardian Phone Number{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                    <input className="text-field" type="text" placeholder="555-555-5555" name="parentPhone" value={this.state.parentPhone} onChange={this.formChangeHandler}  maxlength="14"/>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Parent/Guardian Email Address{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                    <input className="text-field" type="text" placeholder="e.g. Parent Email" name="parentEmail" value={this.state.parentEmail} onChange={this.formChangeHandler}/>
                  </div>
                  <div className="clear" />
                </div>
              </div>

              <div className="contrasting-container-2">
                <div className="edit-profile-row">
                  <p className="heading-text-4">Emergency Contact Information</p>
                </div>

                <div className="edit-profile-row">
                  <div className="container-left">
                    <label className="profile-label">Emergency Contact First Name and Last Name{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                    <input className="text-field" type="text" placeholder="e.g. Emergency Contact Name" name="emergencyContactName" value={this.state.emergencyContactName} onChange={this.formChangeHandler}/>
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Emergency Contact Relationship to Student{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                    <input className="text-field" type="text" placeholder="e.g. Emergency Contact Relationship" name="emergencyContactRelationship" value={this.state.emergencyContactRelationship} onChange={this.formChangeHandler}/>
                  </div>
                  <div className="clear" />
                </div>

                <div className="edit-profile-row">
                  <div className="container-left">
                    <label className="profile-label">Emergency Contact Phone Number{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                    <input className="text-field" type="text" placeholder="555-555-5555" name="emergencyContactPhone" value={this.state.emergencyContactPhone} onChange={this.formChangeHandler} maxlength="14" />
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Emergency Contact Email Address{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                    <input className="text-field" type="text" placeholder="e.g. Emergency Contact Email" name="emergencyContactEmail" value={this.state.emergencyContactEmail} onChange={this.formChangeHandler}/>
                  </div>
                  <div className="clear" />
                </div>
              </div>

            </div>
          )}

          {(this.state.allowMultipleRaces) ? (
            <div>
              <div className="row-10">
                <label className="profile-label">Race{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                {this.state.raceOptions.map((value, optionIndex) =>
                  <div key={value + optionIndex}>
                    {(optionIndex > 0) && (
                      <div className="float-left right-padding top-padding">
                        {(this.state.races && this.state.races.includes(value)) ? (
                          <button type="button" className="background-button selected-tag-container-1" onClick={() => this.optionClicked(optionIndex,'race')}>
                            <div>
                              <div className="float-left">
                                <p className="description-text-2 white-text nowrap">{value}</p>
                              </div>
                            </div>
                          </button>
                        ) : (
                          <button type="button" className="background-button unselected-tag-container-1" onClick={() => this.optionClicked(optionIndex,'race')}>
                            <div>
                              <div className="float-left">
                                <p className="description-text-2 nowrap">{value}</p>
                              </div>
                            </div>
                          </button>
                        )}
                      </div>
                    )}

                  </div>
                )}
                <div className="clear" />
              </div>
              {(this.state.races && this.state.races.includes('Prefer to self-describe')) ? (
                <div className="row-10">
                  <label className="profile-label">Please describe your race{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                  <input className="text-field" type="text" placeholder="e.g., Black and Latino" name="selfDescribedRace" value={this.state.selfDescribedRace} onChange={this.formChangeHandler}/>
                </div>
              ) : (
                <div />
              )}

              <div className="clear" />
            </div>
          ) : (
            <div className="edit-profile-row">
              <div className="container-left">
                <label className="profile-label">Race{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                <select name="race" className="dropdown" value={this.state.race} onChange={this.formChangeHandler}>
                    {this.state.raceOptions.map(value => <option key={value} value={value}>{value}</option>)}
                </select>
              </div>
              {(this.state.race === 'Prefer to self-describe') && (
                <div className="container-right">
                  <label className="profile-label">Please describe your race{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                  <input className="text-field" type="text" placeholder="e.g., Black and Latino" name="selfDescribedRace" value={this.state.selfDescribedRace} onChange={this.formChangeHandler}/>
                </div>
              )}

              <div className="clear" />
            </div>
          )}

          <div className="edit-profile-row">
            <div className="container-left">
              <label className="profile-label">Gender{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
              <select name="gender" className="dropdown" value={this.state.gender} onChange={this.formChangeHandler}>
                  {this.state.genderOptions.map(value => <option key={value} value={value}>{value}</option>)}
              </select>
            </div>
            <div className="container-right">
              <label className="profile-label">Authorized to work in the U.S.?{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
              {(this.state.activeOrg === 'exp') && (
                <label className="description-text-2 row-5">Note: You will not be automatically rejected from the program if you are not authorized.</label>
              )}
              <div className="clear" />

              <select name="workAuthorization" className="dropdown" value={this.state.workAuthorization} onChange={this.formChangeHandler}>
                  {this.state.workAuthorizationOptions.map(value => <option key={value} value={value}>{value}</option>)}
              </select>
            </div>

            <div className="clear" />
          </div>

          {(this.state.activeOrg === 'fosternation') && (
            <div>
              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Preferred Pronoun</label>
                  <select name="preferredPronoun" className="preferredPronoun" value={this.state.preferredPronoun} onChange={this.formChangeHandler}>
                    {this.state.preferredPronounOptions.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>
                <div className="container-right">
                  <label className="profile-label">Language</label>
                  <input className="text-field" type="text" placeholder="e.g., English" name="language" value={this.state.language} onChange={this.formChangeHandler}/>
                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Country</label>
                  <input className="text-field" type="text" placeholder="e.g., U.S." name="country" value={this.state.country} onChange={this.formChangeHandler}/>
                </div>
                <div className="container-right">
                </div>
                <div className="clear" />
              </div>
            </div>
          )}

          {(this.state.activeOrg === 'c2c') && (
            <div className="edit-profile-row">
              <label className="profile-label">Do you have DACA status?{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
              <select name="dacaStatus" className="dropdown" value={this.state.dacaStatus} onChange={this.formChangeHandler}>
                  {this.state.binaryOptions.map(value => <option key={value} value={value}>{value}</option>)}
              </select>
            </div>
          )}

          {(this.state.requirePersonalInfo || this.state.activeOrg === 'guidedcompass') && (
            <div>
              <div className="row-10">
                <div className="container-left">
                  <label className="profile-label">Number of Members in Household{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                  <select name="numberOfMembers" className="dropdown" value={this.state.numberOfMembers} onChange={this.formChangeHandler}>
                      {this.state.numberOfMembersOptions.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>
                <div className="container-right">
                  <label className="profile-label">Estimated Household Income{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                  <select name="householdIncome" className="dropdown" value={this.state.householdIncome} onChange={this.formChangeHandler}>
                      {this.state.householdIncomeOptions.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>
                <div className="clear" />
              </div>
              {/*
              <div className="edit-profile-row">
                <div className="container-left">
                  <label className="profile-label">Have you ever been a foster youth?{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                  <select name="fosterYouth" className="dropdown" value={this.state.fosterYouth} onChange={this.formChangeHandler}>
                      {this.state.fosterYouthOptions.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>
                <div className="container-right">
                  <label className="profile-label">Are you currently or formerly homeless?{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                  <select name="homeless" className="dropdown" value={this.state.homeless} onChange={this.formChangeHandler}>
                      {this.state.homelessOptions.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>
                <div className="clear" />
              </div>

              <div className="edit-profile-row">
                <div className="container-left">
                  <label className="profile-label">Were you previously incarcerated?{(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>
                  <select name="incarcerated" className="dropdown" value={this.state.incarcerated} onChange={this.formChangeHandler}>
                      {this.state.incarceratedOptions.map(value => <option key={value} value={value}>{value}</option>)}
                  </select>
                </div>
                <div className="clear" />
              </div>*/}

              <div className="edit-profile-row">
                <label className="profile-label clear-margin clear-padding">Designate all that apply. (Background Information) {(this.state.requirePersonalInfo) && <label className="error-color bold-text"> *</label>}</label>

                {this.state.adversityListOptions.map((value, optionIndex) =>
                  <div key={value + optionIndex}>
                    <div className="float-left right-padding top-padding">
                      {(this.state.adversityList && this.state.adversityList.includes(value)) ? (
                        <button type="button" className="background-button selected-tag-container-1" onClick={() => this.optionClicked(optionIndex,'adversityList')}>
                          <div>
                            <div className="float-left">
                              <p className="description-text-2 white-text nowrap">{value}</p>
                            </div>
                          </div>
                        </button>
                      ) : (
                        <button type="button" className="background-button unselected-tag-container-1" onClick={() => this.optionClicked(optionIndex,'adversityList')}>
                          <div>
                            <div className="float-left">
                              <p className="description-text-2 nowrap">{value}</p>
                            </div>
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                )}
                <div className="clear" />
              </div>
            </div>
          )}

          {(this.state.activeOrg === 'c2c') && (
            <div>

              <div className="clear" />
              <div className="spacer" /><div className="spacer" />
              <hr />

              <div className="edit-profile-row">
                  <div className="spacer"/><div className="half-spacer"/>
                  <p className="heading-text-3">Political Information</p>
              </div>

              <div className="edit-profile-row">
                <label className="profile-label">Political Alignment<label className="error-color bold-text">*</label></label>
                <select name="politicalAlignment" className="dropdown" value={this.state.politicalAlignment} onChange={this.formChangeHandler}>
                    {this.state.politicalAlignmentOptions.map(value => <option key={value} value={value}>{value}</option>)}
                </select>
              </div>

              <div className="edit-profile-row">
                <label className="profile-label">U.S. State Registered to Vote<label className="error-color bold-text">*</label></label>
                <select name="stateRegistration" className="dropdown" value={this.state.stateRegistration} onChange={this.formChangeHandler}>
                    {this.state.registrationOptions.map(value => <option key={value} value={value}>{value}</option>)}
                </select>
              </div>

              <div className="edit-profile-row">
                  <label className="profile-label">Current Congressional District<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="text" placeholder="e.g. 12" name="currentCongressionalDistrict" value={this.state.currentCongressionalDistrict} onChange={this.formChangeHandler}/>
              </div>

              <div className="edit-profile-row">
                <label className="profile-label">Hometown (U.S. State)<label className="error-color bold-text">*</label></label>
                <select name="hometown" className="dropdown" value={this.state.hometown} onChange={this.formChangeHandler}>
                    {this.state.hometownOptions.map(value => <option key={value} value={value}>{value}</option>)}
                </select>
              </div>

              <div className="edit-profile-row">
                  <label className="profile-label">Hometown Congressional District<label className="error-color bold-text">*</label></label>
                  <input className="text-field" type="text" placeholder="e.g. 12" name="homeCongressionalDistrict" value={this.state.homeCongressionalDistrict} onChange={this.formChangeHandler}/>
              </div>

            </div>
          )}
        </div>
      )
    }

    markResume(item) {
      console.log('markResume called')

      if (item !== this.state.resumeURLValue) {
        console.log('save changes')

        const emailId = this.state.emailId
        const resumeURL = item
        const updatedAt = new Date()

        const userObject = { emailId, resumeURL, updatedAt }
        this.updatePrimaryResume(userObject, resumeURL)

      }
    }

    updatePrimaryResume(userObject, resumeURLValue) {
      console.log('updatePrimaryResume called')

      Axios.post('/api/users/profile/details', userObject)
      .then((response) => {

        if (response.data.success) {
          //save values
          console.log('User update worked', response.data);
          this.setState({ resumeURLValue })

        } else {
          console.error('there was an error saving to user profile');
        }
      }).catch((error) => {
          console.log('Saving to user profile did not work', error);
      });
    }

    toggleConfirmDelete(type, index) {
      console.log('toggleConfirmDelete called', type, index)

      if (type === 'resume') {
        let resumes = this.state.resumes
        resumes[index]['confirmDelete'] = !this.state.resumes[index].confirmDelete
        this.setState({ resumes })
      } else if (type === 'project') {
        let projects = this.state.projects
        projects[index]['confirmDelete'] = !this.state.projects[index].confirmDelete
        this.setState({ projects })
      } else if (type === 'experience') {
        let experience = this.state.experience
        experience[index]['confirmDelete'] = !this.state.experience[index].confirmDelete
        this.setState({ experience })
      } else if (type === 'extracurricular') {
        let extracurriculars = this.state.extracurriculars
        extracurriculars[index]['confirmDelete'] = !this.state.extracurriculars[index].confirmDelete
        this.setState({ extracurriculars })
      } else if (type === 'award') {
        let awards = this.state.awards
        awards[index]['confirmDelete'] = !this.state.awards[index].confirmDelete
        this.setState({ awards })
      } else if (type === 'education') {
        let education = this.state.education
        education[index]['confirmDelete'] = !this.state.education[index].confirmDelete
        this.setState({ education })
      } else if (type === 'certificate') {
        let certificates = this.state.certificates
        certificates[index]['confirmDelete'] = !this.state.certificates[index].confirmDelete
        this.setState({ certificates })
      }
    }

    renderConfirmDeleteWidget(type, index, rightJustify) {
      console.log('renderConfirmDeleteWidget called', type, index, rightJustify)

      return (
        <div key="renderConfirmDeleteWidget">
          <div className={(rightJustify) ? "float-right text-right" : ""}>
            <p className={(rightJustify) ? "row-10 description-text-2 text-right" : "row-10 description-text-2"}>Are you sure you want to delete this?</p>
            <button type="button" className={(rightJustify) ? "btn btn-squarish error-background-color error-border" : "btn btn-squarish error-background-color error-border right-margin-20"} onClick={() => this.deleteItem(type, index)}>Yes, Delete</button>
            <button type="button" className={(rightJustify) ? "btn btn-squarish white-background cta-color left-margin-20" : "btn btn-squarish white-background cta-color"} onClick={() => this.toggleConfirmDelete(type, index)}>Cancel</button>
          </div>
          <div className="clear" />
        </div>
      )
    }

    render() {

      let problemPlatformTeacher = false
      let problemPlatformMentor = false
      if (window.location.pathname.includes('/problem-platform')) {
        if (this.state.roleName === 'Teacher') {
          problemPlatformTeacher = true
        }
        if (this.state.roleName === 'Mentor') {
          problemPlatformMentor = true
        }
      }

      if (window.location.pathname.includes('/advisor') || ((window.location.pathname.includes('/organizations') && !this.props.passedProfile) && !window.location.pathname.includes('/apply')) || problemPlatformTeacher || problemPlatformMentor) {

        return (
          <div>
              <div className="super-spacer"/>

              <div>
                <div className="calc-column-offset-50">
                  {(window.location.pathname.includes('/basic')) && (
                    <div>
                      <p className="heading-text-2">{(this.state.showDetailsInBasic) ? "Edit Profile Info" : "Edit Basic Info"}</p>
                    </div>
                  )}
                  {(window.location.pathname.includes('detail')) && (
                    <div>
                      <p className="heading-text-2">Edit Projects, Experience, & Other Details</p>
                    </div>
                  )}
                  {(window.location.pathname.includes('/public')) && (
                    <div>
                      <p className="heading-text-2">Profile Visibility Preferences</p>
                    </div>
                  )}
                </div>

                {(window.location.pathname.includes('/problem-platform')) && (
                  <div className="fixed-column-50">
                    <div className="half-spacer" />
                    <button type="button" className="background-button" onClick={() => this.setState({ modalIsOpen: true, showProjectDetail: false, showGrade: false, showJobFunction: false, showIndustry: false, skillTagsInfo: false, showSettings: true, showBirthdate: false })}>
                      <img src={settingsIcon} alt="GC" className="image-auto-30" />
                    </button>
                  </div>
                )}

                <div className="clear" />
              </div>

              {(window.location.pathname.includes('/problem-platform')) && (
                <div>
                  {(this.state.roleName === 'Student') ? (
                    <p className="description-text-2">Profile Type: Student / Career-Seeker</p>
                  ) : (
                    <p className="description-text-2">Profile Type: {this.state.roleName}</p>
                  )}
                </div>
              )}

              <form >
                  {(window.location.pathname.includes('basic') || this.props.passedType === 'Basic') && (
                    <div>
                      {this.renderProfilePic()}
                      {this.renderProfileBasics()}

                      {(this.state.roleName === 'Mentor') ? (
                        <div>
                          {this.renderResumeModule()}
                          {/*
                          <div className="spacer" /><div className="spacer" />
                          <hr />*/}

                          {this.renderEducation()}
                          {this.renderCertifications()}
                          {this.renderCareerInfo()}

                          <div className="spacer" /><div className="spacer" />
                          <hr />

                          {this.renderProfileDetails()}

                          <div className="spacer" /><div className="spacer" />
                          <hr />

                          {this.renderPersonalInfo()}

                          <div className="spacer" /><div className="spacer" />
                          <hr />

                          <div className="spacer" /><div className="spacer" />

                        </div>
                      ) : (
                        <div>
                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Role Name</label>
                              <p>{this.state.roleName}</p>
                            </div>
                            {(this.state.orgName) ? (
                              <div className="container-right">
                                <label className="profile-label">Org Name</label>
                                <p>{this.state.orgName}</p>
                              </div>
                            ) : (
                              <div className="container-right">
                                <label className="profile-label">School Name</label>
                                <p>{this.state.schoolName}</p>
                              </div>
                            )}

                            <div className="clear" />
                          </div>

                          {(this.state.schoolDistrict) && (
                            <div className="row-10">
                              <div className="container-left">
                                <label className="profile-label">School District</label>
                                <p>{this.state.schoolDistrict}</p>
                              </div>
                              <div className="clear" />
                              <div className="spacer" /><div className="spacer" />
                            </div>
                          )}

                          <div className="row-10">
                            <label className="profile-label">Define Success with Guided Compass</label>
                            <textarea className="text-field" type="text" placeholder="How would you define success using Guided Compass? What do you want out of it? What should students get out of it?" name="successDefined" value={this.state.successDefined} onChange={this.formChangeHandler} />
                          </div>

                          <div className="spacer" /><div className="spacer" />


                        </div>
                      )}
                    </div>
                  )}

                  {(window.location.pathname.includes('detail') || this.props.passedType === 'Details') && (
                    <div>
                      {this.renderProfileDetails()}
                    </div>
                  )}

                  {(window.location.pathname.includes('public') || this.props.passedType === 'Public') && (
                    <div>
                      {this.renderProfilePrivacyPreferences()}
                    </div>
                  )}
              </form>



              {(!window.location.pathname.includes('public') && this.props.passedType !== 'Public') && (
                <div>
                  {(this.state.errorMessage) && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                  {(this.state.successMessage) && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}
                  {(this.state.serverErrorMessageProfilePic) && <p className="error-color description-text-2 row-5">{this.state.serverErrorMessageProfilePic}</p>}
                  {(this.state.serverSuccessMessageProfilePic) && <p className="cta-color description-text-2 row-5">{this.state.serverSuccessMessageProfilePic}</p>}
                  {(this.state.projectErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.projectErrorMessage}</p>}
                  {(this.state.projectSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.projectSuccessMessage}</p>}
                  {(this.state.experienceErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.experienceErrorMessage}</p>}
                  {(this.state.experienceSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.experienceSuccessMessage}</p>}
                  {(this.state.extraErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.extraErrorMessage}</p>}
                  {(this.state.extraSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.extraSuccessMessage}</p>}
                  {(this.state.awardErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.awardErrorMessage}</p>}
                  {(this.state.awardSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.awardSuccessMessage}</p>}

                  <button onClick={this.handleSubmit} className="btn btn-primary">Save Changes</button>

                </div>
              )}

              {this.configureModal()}
          </div>
        )
      } else if (window.location.pathname.includes('/employers')) {
        return (
          <div>
            <div className="spacer"/><div className="spacer"/><div className="spacer"/><div className="spacer"/>
            <p className="heading-text-2">{(this.state.showDetailsInBasic) ? "Edit Profile Info" : "Edit Basic Info"}</p>

            {(window.location.pathname.includes('basic') || this.props.passedType === 'Basic') && (
              <div>
                <form>
                  {this.renderProfilePic()}
                  {this.renderProfileBasics()}

                  <div className="spacer" /><div className="spacer" />

                  {(this.state.errorMessage) && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                  {(this.state.successMessage) && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}
                  {(this.state.serverErrorMessageProfilePic) && <p className="error-color description-text-2 row-5">{this.state.serverErrorMessageProfilePic}</p>}
                  {(this.state.serverSuccessMessageProfilePic) && <p className="cta-color description-text-2 row-5">{this.state.serverSuccessMessageProfilePic}</p>}

                  <div className="row-10">
                    <button onClick={this.handleSubmit} type="button" className="btn btn-primary">Save Profile Changes</button>
                  </div>

                </form>
              </div>
            )}
          </div>
        )
      } else {

        return (
            <div>
                <div>
                    {(!window.location.pathname.includes('/apply')) && (
                      <div>
                        <div className="spacer"/><div className="spacer"/><div className="spacer"/><div className="spacer"/>

                        <div className="calc-column-offset-50">
                          {(window.location.pathname.includes('/basic')) && (
                            <div>
                              <p className="heading-text-2">{(this.state.showDetailsInBasic) ? "Edit Profile Info" : "Edit Basic Info"}</p>
                            </div>
                          )}
                          {(window.location.pathname.includes('detail')) && (
                            <div>
                              <p className="heading-text-2">Edit Projects, Experience, & Other Details</p>
                            </div>
                          )}
                          {(window.location.pathname.includes('/public')) && (
                            <div>
                              <p className="heading-text-2">Profile Visibility Preferences</p>
                            </div>
                          )}
                        </div>

                        {(window.location.pathname.includes('/problem-platform')) && (
                          <div className="fixed-column-50">
                            <div className="half-spacer" />
                            <button type="button" className="background-button" onClick={() => this.setState({ modalIsOpen: true, showProjectDetail: false, showGrade: false, showJobFunction: false, showIndustry: false, skillTagsInfo: false, showSettings: true, showBirthdate: false })}>
                              <img src={settingsIcon} alt="GC" className="image-auto-30" />
                            </button>
                          </div>
                        )}

                        <div className="clear" />
                      </div>
                    )}

                    {(window.location.pathname.includes('/problem-platform')) && (
                      <div>
                        {(this.state.roleName === 'Student') ? (
                          <p className="description-text-2">Profile Type: Student / Career-Seeker</p>
                        ) : (
                          <p className="description-text-2">Profile Type: {this.state.roleName}</p>
                        )}
                      </div>
                    )}

                </div>

                {(window.location.pathname.includes('basic') || this.props.passedType === 'Basic') && (
                  <div>

                    {(window.location.pathname.includes('/app/walkthrough')) && (
                      <div className="row-10">
                        <div className="calc-column-offset-80">
                          <p className="heading-text-2">Who you are</p>
                        </div>
                        <div className="fixed-column-80">
                          <button className="background-button top-margin-5 row-5 cta-underline" onClick={() => this.handleSubmit()}>
                            <div className="float-right top-margin-3">
                              <img src={arrowIcon} alt="GC" className="image-auto-14" />
                            </div>
                            <div className="float-right right-padding">
                              <p className="cta-color bold-text description-text-1">Next</p>
                            </div>

                            <div className="clear" />
                          </button>
                        </div>
                        <div className="clear" />

                        {this.state.errorMessage !== '' && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                        {(this.state.successMessage) && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}
                        {(this.state.serverSuccessMessageProfilePic) && <p className="success-message">{this.state.serverSuccessMessageProfilePic}</p>}
                        {(this.state.serverErrorMessageProfilePic) && <p className="success-message">{this.state.serverErrorMessageProfilePic}</p>}
                      </div>
                    )}

                    <form>
                      {this.renderProfilePic()}
                      {this.renderProfileBasics()}

                      {(!window.location.pathname.includes('/problem-platform') && !window.location.pathname.includes('/apply')) && (
                        <div>
                          {this.renderResumeModule()}
                        </div>
                      )}

                      {(this.state.activeOrg === 'yearup') && (
                        <div>
                          <div className="spacer" /><div className="spacer" />
                          <hr />

                          <div className="edit-profile-row">
                            <div className="spacer"/><div className="half-spacer"/>
                            <div className="float-left">
                              <p className="heading-text-3">Video Resume</p>
                            </div>
                            <div className="clear" />
                          </div>

                          <div className="row-10">
                            <div className="container-left">
                              <label className="profile-label">Add a link to your video</label>
                              <input className="text-field" type="text" placeholder="https..." name="videoResumeURL" value={this.state.videoResumeURL} onChange={this.formChangeHandler} />
                              {(this.state.videoResumeURL && this.state.videoResumeURL !== '' && !this.state.videoResumeURL.startsWith('http')) && (
                                <div>
                                  <p className="error-message">Please start your link with http</p>
                                </div>
                              )}
                            </div>
                            <div className="clear" />
                          </div>
                        </div>
                      )}

                      {this.renderEducation()}
                      {this.renderCertifications()}
                      {this.renderCareerInfo()}

                      {(this.state.showDetailsInBasic && !window.location.pathname.includes('/apply') && !window.location.pathname.includes('/walkthrough')) && (
                        <div>
                          <div className="spacer" /><div className="spacer" />
                          <hr />
                          {this.renderProfileDetails()}
                        </div>
                      )}

                      {(!this.state.remoteAuth && !window.location.pathname.includes('/problem-platform')) && (
                        <div>

                          {((window.location.pathname.includes('/app/walkthrough') && this.state.requirePersonalInfo) || !window.location.pathname.includes('/app/walkthrough')) && (
                            <div>
                              <div className="spacer" /><div className="spacer" />
                              <hr />

                              {this.renderPersonalInfo()}

                            </div>
                          )}
                        </div>
                      )}

                      <div className="clear" />

                      {/*
                      {(!window.location.pathname.includes('/problem-platform') && !window.location.pathname.includes('/apply') && this.state.activeOrg === 'guidedcompass') && (
                        <div>
                          <div className="spacer" /><div className="spacer" />
                          <hr />

                          <div className="edit-profile-row">
                            <div className="spacer"/><div className="half-spacer"/>
                            <p className="heading-text-3">Select Your Career Goals</p>
                            <p>Select whichever career goals are your primary focus today.</p>
                          </div>

                          <div className="edit-profile-row">
                            {this.state.careerGoalOptions.map((value, optionIndex) =>
                              <div key={optionIndex} className="float-left">
                                <button type="button" className="background-button" onClick={() => this.itemSelected(value)}>
                                  <div className="float-left right-padding-5">
                                    <div className="half-spacer" />
                                    <div className={(this.state.careerGoals.includes(value)) ? "tag-container-active" : "tag-container-inactive"}>
                                      <p className="description-text-2">{value}</p>
                                    </div>
                                    <div className="half-spacer" />
                                  </div>
                                </button>
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        </div>
                      )}*/}

                      {(!window.location.pathname.includes('/apply')) && (
                        <div>
                          <div className="spacer" /><div className="spacer" />

                          {(this.state.errorMessage) && <p className="error-color description-text-2 row-5">{this.state.errorMessage}</p>}
                          {(this.state.successMessage) && <p className="cta-color description-text-2 row-5">{this.state.successMessage}</p>}
                          {(this.state.serverErrorMessageProfilePic) && <p className="error-color description-text-2 row-5">{this.state.serverErrorMessageProfilePic}</p>}
                          {(this.state.serverSuccessMessageProfilePic) && <p className="cta-color description-text-2 row-5">{this.state.serverSuccessMessageProfilePic}</p>}
                          {(this.state.projectErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.projectErrorMessage}</p>}
                          {(this.state.projectSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.projectSuccessMessage}</p>}
                          {(this.state.experienceErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.experienceErrorMessage}</p>}
                          {(this.state.experienceSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.experienceSuccessMessage}</p>}
                          {(this.state.extraErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.extraErrorMessage}</p>}
                          {(this.state.extraSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.extraSuccessMessage}</p>}
                          {(this.state.awardErrorMessage) && <p className="error-color description-text-2 row-5">{this.state.awardErrorMessage}</p>}
                          {(this.state.awardSuccessMessage) && <p className="cta-color description-text-2 row-5">{this.state.awardSuccessMessage}</p>}

                          {(window.location.pathname.includes('/app/walkthrough')) ? (
                            <div className="full-width top-margin">

                              <hr />

                              <div className="full-width top-margin-50">
                                <div className="container-left-static left-text">
                                  <button className="btn btn-primary medium-background standard-border" onClick={() => this.props.movePage(false)}>Back</button>
                                </div>
                                <div className="container-right-static right-text">
                                  <button className="btn btn-primary" type="button" onClick={() => this.handleSubmit()}>Next step</button>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          ) : (
                            <div className="row-10">
                              <button onClick={this.handleSubmit} type="button" className="btn btn-primary">Save Profile Changes</button>
                            </div>
                          )}

                        </div>
                      )}

                    </form>
                  </div>
                )}

                {(window.location.pathname.includes('detail') || this.props.passedType === 'Details') && (
                  <div>
                    {this.renderProfileDetails()}
                  </div>
                )}

                {(window.location.pathname.includes('public') || this.props.passedType === 'Public') && (
                  <div>

                    {(!window.location.pathname.includes('/problem-platform') && !window.location.pathname.includes('/advisor') && !window.location.pathname.includes('/organizations')) && (
                      <div>
                        {this.renderProfilePrivacyPreferences()}
                      </div>
                    )}
                  </div>
                )}

                {this.configureModal()}
            </div>
        )
      }
    }
}

export default withRouter(EditProfileDetails);
