import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Modal from 'react-modal';
import { CircularProgressbar } from 'react-circular-progressbar';;
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  EmailShareButton,FacebookShareButton,LinkedinShareButton, PinterestShareButton,RedditShareButton,
  TwitterShareButton,WhatsappShareButton,WorkplaceShareButton,

  EmailIcon,FacebookIcon,LinkedinIcon, PinterestIcon,RedditIcon,TwitterIcon,WhatsappIcon, WorkplaceIcon
} from "react-share";
import ProjectDetails from '../../components/Subcomponents/ProjectDetails';
import AssessmentResultsDetails from '../../components/Subcomponents/AssessmentResultsDetails';
import EndorsementDetails from '../../components/Common/EndorsementDetails';
import SubSendMessage from '../../components/Common/SendMessage';
import SubComments from '../Common/Comments';
import SubRenderPosts from '../Common/RenderPosts';
import SubGoalDetails from '../Common/GoalDetails';
import SubRenderProjects from '../Common/RenderProjects';
import SubRenderGoals from '../Common/RenderGoals';
import SubReferSomeone from '../Common/ReferSomeone';
import withRouter from '../Functions/WithRouter';
import {convertDateToString} from '../Functions/ConvertDateToString';
import {requestConnection} from '../Services/FriendRoutes';

const defaultProfileImage = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-big.png';
const resumeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resume-icon-dark.png';
const linkedinIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/linkedin-icon-dark.png';
const websiteIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/website-icon-dark.png';
const facebookIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/facebook-icon-dark.png';
const instagramIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/instagram-icon-dark.png';
const twitterIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/twitter-icon-dark.png';
const passionIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/passion-icon-dark.png";
const messageIconWhite = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/message-icon-white.png";
const hireIconBlue = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hire-icon-blue.png";
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const pinIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/pin-icon.png';
const experienceIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/experience-icon.png";
const educationIcon = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/education-icon.png";
const favoritesIconDark = "https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/favorites-icon-dark.png";
const trendsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/trends-icon-dark.png';
const likeIconBlue = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/like-icon-blue.png';
const likeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/like-icon-dark.png';
const commentIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/comment-icon-dark.png';
const shareIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/share-icon-dark.png';
const sendIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/send-icon-dark.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const careerMatchesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/career-matches-icon-dark.png';
const projectsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/projects-icon-dark.png';
const menuIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/menu-icon-dark.png';
const reportIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/report-icon-dark.png';
const hideIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/hide-icon-dark.png';
const dropdownArrow = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/dropdown-arrow.png';
// const defaultProfileBackgroundImage = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/default-profile-background-image.png';
const targetIconOrange = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon-orange.png';
const targetIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/target-icon.png';
const softwareDeveloperIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/software-developer.png';
const abilitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/abilities-icon-dark.png';
const socialIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/social-icon-dark.png';
const skillsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/skills-icon.png';
const interestsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/interests-icon-dark.png';
const endorsementIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/endorsement-icon-dark.png';
// const deniedIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/denied-icon.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
// const endorsementIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/endorsement-icon-dark.png';
const newsFeedIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/news-feed-icon-dark.png';

class ExternalProfile extends Component {
    constructor(props) {
        super(props)

        this.state = {
          includeIconLabels: true,
          profileOptions: [],
          viewIndex: 0,
          selectedIndex: 0,

          posts: [],
          projects: [],
          goals: [],
          passions: [],
          assessments: [],
          endorsements: [],
          favorites: [],
          newFavorites: [],
          friends: [],
          selectedPeople: [],
          selectedLinks: [],
          selectedTimes: [],
          selectedProjects: [],
          selectedCareers: [],

          linkCategoryOptions: ['','Video','Event','Course','Article','Report','Job Opportunity','Other']
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.returnCount = this.returnCount.bind(this)
        this.closeModal = this.closeModal.bind(this)

        this.passData = this.passData.bind(this)

        this.calculateMatchScore = this.calculateMatchScore.bind(this)

        this.followPerson = this.followPerson.bind(this)
        this.acceptRequest = this.acceptRequest.bind(this)
        this.testActiveFriendship = this.testActiveFriendship.bind(this)

        this.renderPost = this.renderPost.bind(this)
        this.calculateWidth = this.calculateWidth.bind(this)
        this.renderOriginalPost = this.renderOriginalPost.bind(this)
        this.selectAnswer = this.selectAnswer.bind(this)
        this.retrieveComments = this.retrieveComments.bind(this)
        this.retrieveLikes = this.retrieveLikes.bind(this)
        this.togglePostMenu = this.togglePostMenu.bind(this)
        this.renderShareButtons = this.renderShareButtons.bind(this)

        this.renderTaggedItem = this.renderTaggedItem.bind(this)
        this.calculateAge = this.calculateAge.bind(this)
        this.renderProfileBasics = this.renderProfileBasics.bind(this)

    }

    componentDidMount() {
      //see if user is logged in
      console.log('profilePage just mounted')

      let emailId = localStorage.getItem('email');
      const username = this.props.username
      this.retrieveData(username, emailId)

    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called in SubExternalProfile', this.props, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode) {
        console.log('t0 will update')
        let emailId = localStorage.getItem('email');
        const username = this.props.username
        this.retrieveData(username, emailId)
      // } else if (this.props.employers && this.props.employers.length !== prevProps.employers.length) {
      //   this.retrieveData()
      } else if (this.props.username !== prevProps.username) {
        let emailId = localStorage.getItem('email');
        const username = this.props.username
        this.retrieveData(username, emailId)
      }
    }

    retrieveData(username, emailId) {
      console.log('retrieveData called in SubExternalProfile', username)

      const pageSource = this.props.pageSource
      const matchScore = this.props.matchScore

      const cuFirstName = localStorage.getItem('firstName');
      const cuLastName = localStorage.getItem('lastName');
      const activeOrg = localStorage.getItem('activeOrg');
      const orgFocus = localStorage.getItem('orgFocus');
      const roleName = localStorage.getItem('roleName');
      let accountCode = localStorage.getItem('accountCode');
      // console.log('show orgName --: ', orgName, roleName, accountCode)

      // roleName = "Student"
      // accountCode = "novelly"
      let employerAccount = false
      if (roleName === 'Employee' && accountCode) {
        employerAccount = true
        // accountCode = null
      } else {
        accountCode = null
      }

      let profileOptions = ['All','About','Posts','Projects','Goals','Passions','Assessments','Endorsements']

      this.setState({ emailId, cuFirstName, cuLastName, activeOrg, orgFocus, roleName, username, profileOptions, pageSource,
        matchScore, employerAccount
      })

      if (username) {
        console.log('show username: ', username)
        Axios.get('/api/users/profile/details/temporary/' + username)
        .then((response) => {
          if (response.data) {

            console.log('User details fetch worked', response.data)
            if (response.data.success) {

              let publicProfile = response.data.user.publicProfile
              let publicProfileExtent = response.data.user.publicProfileExtent
              let publicPreferences = response.data.user.publicPreferences
              const profileData = response.data.user
              const profileEmail = response.data.user.email
              const myOrgs = response.data.user.myOrgs
              let viewableProfile = false

              let underaged = false
              const legalDOB = new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate(), 0, 0, 0)
              // console.log('legalDOB here', legalDOB)
              if (profileData.dateOfBirth && new Date(profileData.dateOfBirth) && new Date(profileData.dateOfBirth) > legalDOB) {
                underaged = true
              }

              if (orgFocus && orgFocus !== 'Placement' && pageSource === 'portal') {
                publicProfile = true
                viewableProfile = true
              } else if (publicProfile) {
                viewableProfile = true
                if (underaged) {
                  viewableProfile = false
                }
              } else {
                if (underaged) {
                  viewableProfile = false
                }
              }

              let postPublicPreference = null
              let publicPosts = []
              let projectPublicPreference = null
              let publicProjects = []
              let goalPublicPreference = null
              let publicGoals = []
              let passionPublicPreference = null
              let publicPassions = []
              let assessmentPublicPreference = null
              let publicAssessments = []
              let endorsementPublicPreference = null
              let publicEndorsements = []
              let resumePublicPreference = null
              let publicResume = null

              if (publicPreferences) {
                for (let i = 1; i <= publicPreferences.length; i++) {
                  for (let i = 1; i <= publicPreferences.length; i++) {
                    if (publicPreferences[i - 1].name === 'Post') {
                      postPublicPreference = publicPreferences[i - 1].value
                      publicPosts = publicPreferences[i - 1].publicItems
                    } else if (publicPreferences[i - 1].name === 'Project') {
                      projectPublicPreference = publicPreferences[i - 1].value
                      publicProjects = publicPreferences[i - 1].publicItems
                    } else if (publicPreferences[i - 1].name === 'Goal') {
                      goalPublicPreference = publicPreferences[i - 1].value
                      publicGoals = publicPreferences[i - 1].publicItems
                    } else if (publicPreferences[i - 1].name === 'Passion') {
                      passionPublicPreference = publicPreferences[i - 1].value
                      publicPassions = publicPreferences[i - 1].publicItems
                    } else if (publicPreferences[i - 1].name === 'Assessment') {
                      assessmentPublicPreference = publicPreferences[i - 1].value
                      publicAssessments = publicPreferences[i - 1].publicItems
                    } else if (publicPreferences[i - 1].name === 'Endorsement') {
                      endorsementPublicPreference = publicPreferences[i - 1].value
                      publicEndorsements = publicPreferences[i - 1].publicItems
                    } else if (publicPreferences[i - 1].name === 'Resume') {
                      resumePublicPreference = publicPreferences[i - 1].value
                      // if (publicPreferences[i - 1].publicItems && publicPreferences[i - 1].publicItems.length > 0) {
                      //   publicResume = publicPreferences[i - 1].publicItems[0]
                      // }
                      if (profileData.resumeURL) {
                        publicResume = profileData.resumeURL
                      }
                    }
                  }
                }
              }
              console.log('-----', publicResume)
              if (orgFocus && orgFocus !== 'Placement' && pageSource === 'portal') {
                postPublicPreference = 'All'
                projectPublicPreference = 'All'
                goalPublicPreference = 'All'
                passionPublicPreference = 'All'
                assessmentPublicPreference = 'All'
                endorsementPublicPreference = 'All'
                resumePublicPreference = 'Yes'
              }

              const privateItems = ['Sexual Orientation']
              let semiPrivateItems = []
              let profileBasics = []
              if (profileData.myOrgs && profileData.myOrgs.includes('fosternation') && activeOrg === 'fosternation') {

                semiPrivateItems = ['Career Status','Phone Number','City','State','Zip Code','Gender','Race','Education Status','Career Status']
                const publicItems = []
                // code changes
                // public: linkedIn, jobTitle, industry, employerName (mentors only), dreamCareer, primaryCareerInterest
                // only connections (general): phone, city, state, zip code, gender, race, transportation, educationInfo, careerInfo, secondaryCareerInterest,
                // only connections (career-seeker only): age

                profileBasics.push({ name: 'Email', value: profileData.email })

                if (profileData.dateOfBirth && profileData.roleName !== 'Mentor') {
                  profileBasics.push({ name: 'Age', value: this.calculateAge(profileData.dateOfBirth) })
                }

                if (profileData.city && !privateItems.includes('City')) {
                  profileBasics.push({ name: 'City', value: profileData.city })
                }
                if (profileData.state) {
                  profileBasics.push({ name: 'State', value: profileData.state })
                }
                if (profileData.zipcode) {
                  profileBasics.push({ name: 'Zip Code', value: profileData.zipcode })
                }
                // if (profileData.gradYear) {
                //   profileBasics.push({ name: 'Grad Year', value: profileData.gradYear })
                // }
                // if (profileData.language) {
                //   profileBasics.push({ name: 'Language', value: profileData.language })
                // }
                if (profileData.dreamCareer) {
                  profileBasics.push({ name: 'Dream Career', value: profileData.dreamCareer })
                }
                if (profileData.primaryCareerInterest) {
                  profileBasics.push({ name: 'Primary Career Interest', value: profileData.primaryCareerInterest })
                }
                // if (profileData.secondaryCareerInterest) {
                //   profileBasics.push({ name: 'Secondary Career Interest', value: profileData.secondaryCareerInterest })
                // }
                // if (profileData.tertiaryCareerInterest) {
                //   profileBasics.push({ name: 'Tertiary Career Interest', value: profileData.tertiaryCareerInterest })
                // }
                if (profileData.gender && !privateItems.includes('Gender')) {
                  profileBasics.push({ name: 'Gender', value: profileData.gender })
                }
                if (profileData.race) {
                  profileBasics.push({ name: 'Race', value: profileData.preferredPronoun })
                }
                if (profileData.preferredPronoun) {
                  profileBasics.push({ name: 'Preferred Pronoun', value: profileData.preferredPronoun })
                }
                if (profileData.phoneNumber) {
                  profileBasics.push({ name: 'Phone Number', value: profileData.phoneNumber })
                }

                if (profileData.educationStatus && profileData.roleName !== 'Mentor') {
                  profileBasics.push({ name: 'Education Status', value: profileData.educationStatus })
                }
                if (profileData.careerStatus && profileData.roleName !== 'Mentor') {
                  profileBasics.push({ name: 'Career Status', value: profileData.careerStatus })
                }
                if (profileData.linkedInURL) {
                  profileBasics.push({ name: 'Linked In', value: profileData.linkedInURL })
                }
                // if (profileData.instagramURL) {
                //   profileBasics.push({ name: 'Instagram', value: profileData.instagramURL })
                // }
                // if (profileData.facebookURL) {
                //   profileBasics.push({ name: 'Facebook', value: profileData.facebookURL })
                // }
                // if (profileData.twitterURL) {
                //   profileBasics.push({ name: 'Twitter', value: profileData.twitterURL })
                // }
                // if (profileData.hobbies) {
                //   profileBasics.push({ name: 'Hobbies', value: profileData.hobbies })
                // }
                if (profileData.jobTitle) {
                  profileBasics.push({ name: 'Job Title', value: profileData.jobTitle })
                }
                if (profileData.industry) {
                  profileBasics.push({ name: 'Employer Name', value: profileData.employerName })
                }
                if (profileData.industry) {
                  profileBasics.push({ name: 'Industry', value: profileData.industry })
                }
              } else {
                if (profileData.location) {
                  profileBasics.push({ name: 'Location', value: profileData.location })
                }

                if (profileData.educationStatus) {
                  profileBasics.push({ name: 'Education Status', value: profileData.educationStatus })
                }
                if (profileData.careerStatus) {
                  profileBasics.push({ name: 'Career Status', value: profileData.careerStatus })
                }
                if (profileData.linkedInURL) {
                  profileBasics.push({ name: 'Linked In', value: profileData.linkedInURL })
                }
                if (profileData.instagramURL) {
                  profileBasics.push({ name: 'Instagram', value: profileData.instagramURL })
                }
                if (profileData.facebookURL) {
                  profileBasics.push({ name: 'Facebook', value: profileData.facebookURL })
                }
                if (profileData.twitterURL) {
                  profileBasics.push({ name: 'Twitter', value: profileData.twitterURL })
                }
                if (profileData.hobbies) {
                  profileBasics.push({ name: 'Hobbies', value: profileData.hobbies })
                }
                if (profileData.industry) {
                  profileBasics.push({ name: 'Industry', value: profileData.industry })
                }
              }

              if (profileData.customFields && profileData.customFields.length > 0) {
                for (let i = 1; i <= profileData.customFields.length; i++) {
                  if (profileData.customFields[i - 1].isPublic) {
                    let profileFieldName = profileData.customFields[i - 1].question
                    if (profileData.customFields[i - 1].displayName) {
                      profileFieldName = profileData.customFields[i - 1].displayName
                    }

                    console.log('did we get here: ',  i, profileFieldName, profileData.customFields[i - 1].answer)
                    profileBasics.push({ name: profileFieldName, value: profileData.customFields[i - 1].answer })
                  }

                  if (profileData.customFields[i - 1].isPublic && profileData.customFields[i - 1].publicExtent === 'Only Connections') {
                    if (!semiPrivateItems.includes(profileData.customFields[i - 1].displayName)) {
                      semiPrivateItems.push(profileData.customFields[i - 1].displayName)
                    }
                  }
                  // let profileFieldName = profileData.customFields[i - 1].question
                  // if (profileData.customFields[i - 1].displayName) {
                  //   profileFieldName = profileData.customFields[i - 1].displayName
                  // }
                  // console.log('did we get here: ',  i, profileFieldName, profileData.customFields[i - 1].answer)
                  // profileBasics.push({ name: profileFieldName, value: profileData.customFields[i - 1].answer })

                  // if (!privateItems.includes(profileData.customFields[i - 1].question)) {
                  //   console.log('wtf happened: ',  i, profileFieldName, profileData.customFields[i - 1].answer)
                  //   profileBasics.push({ name: profileFieldName, value: profileData.customFields[i - 1].answer })
                  // }
                }
              }
              console.log('show profile basics: ', profileBasics)

              this.setState({ profileData, publicProfile, viewableProfile, publicPreferences, postPublicPreference,
                projectPublicPreference, goalPublicPreference, passionPublicPreference, assessmentPublicPreference,
                endorsementPublicPreference, resumePublicPreference, publicResume, profileBasics, underaged,
                semiPrivateItems
              })

              const self = this
              function checkPairing() {
                console.log('checkPairing called')

                Axios.get('/api/partners', { params: { partnerEmail: emailId, pairingEmail: profileData.email, active: true } })
                .then((response) => {
                  console.log('Partner query attempted', response.data);

                  if (response.data.success) {
                    console.log('partner query worked')

                    viewableProfile = true
                    self.setState({ viewableProfile, paired: true })

                  } else {
                    console.log('query for partners query did not work', response.data.message)
                  }

                }).catch((error) => {
                    console.log('Partners query did not work for some reason', error);
                });
              }

              Axios.get('/api/friends', { params: { orgCode: activeOrg, emailId } })
              .then((response) => {
                console.log('Friends query attempted', response.data);

                  if (response.data.success) {
                    console.log('friends query worked', publicProfile)

                    const friends = response.data.friends

                    if (publicProfile) {
                      if (!publicProfileExtent || publicProfileExtent === 'Public') {
                        viewableProfile = true
                      } else if (publicProfileExtent === 'Only Connections') {
                        console.log('are we about to test?!')
                        if (this.testActiveFriendship(friends)) {
                          viewableProfile = true
                        }
                      } else if (publicProfileExtent === 'Only Connections and Members') {
                        if (this.testActiveFriendship(friends) || (profileData.myOrgs && profileData.myOrgs.includes(activeOrg))) {
                          viewableProfile = true
                        }
                      }
                    }

                    this.setState({ friends, viewableProfile })

                    if (this.props.accept) {
                      this.acceptRequest(friends, profileData)
                    }

                    if (!viewableProfile) {
                      checkPairing()
                    }

                  } else {
                    console.log('friends query did not work', response.data.message)
                    checkPairing()
                  }

              }).catch((error) => {
                  console.log('Friends query did not work for some reason', error);
              })

              if (postPublicPreference === 'All' || postPublicPreference === 'Some') {
                Axios.get('/api/group-posts', { params: { emailId: profileEmail, onlyCUPosts: true } })
                .then((response) => {
                  console.log('Group posts query attempted', response.data);

                    if (response.data.success) {
                      console.log('successfully retrieved posts')

                      if (response.data.groupPosts) {

                        let posts = []
                        if (postPublicPreference === 'Some') {
                          for (let i = 1; i <= response.data.groupPosts.length; i++) {
                            for (let j = 1; j <= publicPosts.length; j++) {
                              if (response.data.groupPosts[i - 1].name === publicPosts[j - 1]) {
                                posts.push(i - 1)
                              }
                            }
                          }
                        } else {
                          posts = response.data.groupPosts
                        }

                        this.setState({ posts })

                      }

                    } else {
                      console.log('no post data found', response.data.message)
                    }

                }).catch((error) => {
                    console.log('Post query did not work', error);
                });
              }

              if (projectPublicPreference === 'All' || projectPublicPreference === 'Some') {
                Axios.get('/api/projects', { params: { emailId: profileEmail, includeCollaborations: true } })
                .then((response) => {
                  console.log('Projects query attempted', response.data);

                    if (response.data.success) {
                      console.log('successfully retrieved projects')

                      if (response.data.projects) {

                        let projects = []
                        if (projectPublicPreference === 'Some') {
                          for (let i = 1; i <= response.data.projects.length; i++) {
                            for (let j = 1; j <= publicProjects.length; j++) {
                              if (response.data.projects[i - 1].name === publicProjects[j - 1]) {
                                projects.push(i - 1)
                              }
                            }
                          }
                        } else {
                          projects = response.data.projects
                        }

                        this.setState({ projects })

                      }

                    } else {
                      console.log('no project data found', response.data.message)
                    }

                }).catch((error) => {
                    console.log('Project query did not work', error);
                });
              }

              // retrieve orgs
              if (myOrgs) {
                Axios.get('/api/org/details', { params: { orgCodes: myOrgs } })
                .then((response) => {
                    console.log('Org details worked', response.data);

                    if (response.data.success) {
                      const orgs = response.data.orgs
                      this.setState({ orgs })
                    }


                }).catch((error) => {
                    console.log('Org query did not work', error);
                });
              }

              if (goalPublicPreference === 'All' || goalPublicPreference === 'Some') {
                Axios.get('/api/logs/goals', { params: { emailId: profileEmail } })
                .then((response) => {

                    if (response.data.success) {
                      console.log('Goals received query worked', response.data);

                      let goals = []
                      if (goalPublicPreference === 'Some') {
                        for (let i = 1; i <= response.data.goals.length; i++) {
                          for (let j = 1; j <= publicGoals.length; j++) {
                            if (response.data.goals[i - 1].title === publicGoals[j - 1]) {
                              goals.push(response.data.goals[i - 1])
                            }
                          }
                        }
                      } else {
                        goals = response.data.goals
                      }

                      this.setState({ goals })

                    } else {
                      console.log('no goal data found', response.data.message)
                    }

                }).catch((error) => {
                    console.log('Goal query did not work', error);
                });
              }

              if (passionPublicPreference === 'All' || passionPublicPreference === 'Some') {
                Axios.get('/api/logs/passions', { params: { emailId: profileEmail } })
                .then((response) => {

                    if (response.data.success) {
                      console.log('Passions received query worked', response.data);

                      let passions = []
                      if (passionPublicPreference === 'Some') {
                        for (let i = 1; i <= response.data.passions.length; i++) {
                          for (let j = 1; j <= publicPassions.length; j++) {
                            if (response.data.passions[i - 1].passionTitle === publicPassions[j - 1]) {
                              passions.push(response.data.passions[i - 1])
                            }
                          }
                        }
                      } else {
                        passions = response.data.passions
                      }

                      this.setState({ passions })

                    } else {
                      console.log('no passion data found', response.data.message)
                    }

                }).catch((error) => {
                    console.log('Passion query did not work', error);
                });
              }

              if (assessmentPublicPreference === 'All' || assessmentPublicPreference === 'Some') {
                Axios.get('/api/assessment/results', { params: { emailId: profileEmail } })
                 .then((response) => {
                   console.log('query for assessment results worked');

                   if (response.data.success) {

                     console.log('actual assessment results', response.data)

                     if (response.data.results) {

                       let assessments = []
                       const updatedAt = response.data.results.updatedAt
                       if (response.data.results.workPreferenceAnswers && response.data.results.workPreferenceAnswers.length > 0) {
                         const description = 'My specific preferences on where, how, and when I work'
                         profileData['workPreferences'] = response.data.results.workPreferenceAnswers

                         if (assessmentPublicPreference === 'All') {
                           assessments.push({ category: 'Work Preferences', description, updatedAt, results: response.data.results.workPreferenceAnswers })
                         } else if (assessmentPublicPreference === 'Some'){
                           if (publicAssessments.includes('Work Preferences')) {
                             assessments.push({ category: 'Work Preferences', description, updatedAt, results: response.data.results.workPreferenceAnswers })
                           }
                         }
                       }
                       if (response.data.results.interestScores && response.data.results.interestScores.length > 0) {
                         const description = 'My strong interest inventory assessment on what type of work I may like'
                         profileData['interests'] = response.data.results.interestScores

                         if (assessmentPublicPreference === 'All') {
                           assessments.push({ category: 'Interests', description, updatedAt, results: response.data.results.interestScores })
                         } else if (assessmentPublicPreference === 'Some'){
                           if (publicAssessments.includes('Interests')) {
                             assessments.push({ category: 'Interests', description, updatedAt, results: response.data.results.interestScores })
                           }
                         }
                       }
                       if (response.data.results.skillsScores && response.data.results.skillsScores.length > 0) {
                         const description = "Skills I think I'm good at and need work at based on my pathways of interest"
                         profileData['skills'] = response.data.results.skillsScores

                         if (assessmentPublicPreference === 'All') {
                           assessments.push({ category: 'Skills', description, updatedAt, results: response.data.results.skillsScores })
                         } else if (assessmentPublicPreference === 'Some'){
                           if (publicAssessments.includes('Skills')) {
                             assessments.push({ category: 'Skills', description, updatedAt, results: response.data.results.skillsScores })
                           }
                         }
                       }
                       if (response.data.results.personalityScores) {
                         const description = "Five factor personality assessment"
                         profileData['personality'] = response.data.results.personalityScores

                         if (assessmentPublicPreference === 'All') {
                           assessments.push({ category: 'Personality', description, updatedAt, results: response.data.results.personalityScores })
                         } else if (assessmentPublicPreference === 'Some'){
                           if (publicAssessments.includes('Personality')) {
                             assessments.push({ category: 'Personality', description, updatedAt, results: response.data.results.personalityScores })
                           }
                         }
                       }
                       if (response.data.results.topGravitateValues && response.data.results.topGravitateValues.length > 0 && response.data.results.topEmployerValues && response.data.results.topEmployerValues.length > 0) {
                         const description = 'The people and employers I gravitate towards'
                         profileData['values'] = { topGravitateValues: response.data.results.topGravitateValues, topEmployerValues: response.data.results.topEmployerValues }

                         if (assessmentPublicPreference === 'All') {
                           assessments.push({ category: 'Values', description, updatedAt, results: { topGravitateValues: response.data.results.topGravitateValues, topEmployerValues: response.data.results.topEmployerValues }})
                         } else if (assessmentPublicPreference === 'Some'){
                           if (publicAssessments.includes('Values')) {
                             assessments.push({ category: 'Values', description, updatedAt, results: { topGravitateValues: response.data.results.topGravitateValues, topEmployerValues: response.data.results.topEmployerValues }})
                           }
                         }
                       }

                       this.setState({ assessments });
                       // this.calculateMatchScore(selectedMentor, cuProfile)
                     }

                   } else {
                     console.log('error response', response.data)

                     this.setState({ resultsErrorMessage: response.data.message })
                   }

               }).catch((error) => {
                   console.log('query for assessment results did not work', error);
               })
              }

              if (endorsementPublicPreference === 'All' || endorsementPublicPreference === 'Some') {
                // retrieve endorsements
                Axios.get('/api/story', { params: { emailId: profileEmail } })
                .then((response) => {
                    console.log('Story query worked', response.data);

                    if (response.data.success) {

                      let endorsements = []
                      // let selectedIndex = null
                      if (endorsementPublicPreference === 'Some') {
                        for (let i = 1; i <= response.data.stories.length; i++) {
                          for (let j = 1; j <= publicEndorsements.length; j++) {
                            const compareTerm = response.data.stories[i - 1].senderFirstName + ' ' + response.data.stories[i - 1].senderLastName + ' - ' + response.data.stories[i - 1].createdAt.substring(0,10)
                            if (compareTerm === publicEndorsements[j - 1]) {
                              endorsements.push(i - 1)
                            }
                          }
                        }
                      } else {
                        endorsements = response.data.stories
                      }

                      this.setState({ endorsements })

                    }

                }).catch((error) => {
                    console.log('Story query did not work', error);
                });
              }

              Axios.get('/api/experience', { params: { emailId: profileEmail } })
              .then((response) => {
                console.log('Experience query attempted', response.data);

                  if (response.data.success) {
                    console.log('successfully retrieved experience')

                    if (response.data.experience) {

                      const experience = response.data.experience
                      this.setState({ experience })

                    }

                  } else {
                    console.log('no experience data found', response.data.message)
                  }

              }).catch((error) => {
                  console.log('Experience query did not work', error);
              });

              Axios.get('/api/extras', { params: { emailId: profileEmail } })
              .then((response) => {
                console.log('Extras query attempted', response.data);

                if (response.data.success) {
                  console.log('successfully retrieved extras')

                  if (response.data.extras) {

                    const extras = response.data.extras
                    if (extras.length > 0) {
                      console.log('the array is greater than 0')

                      let extracurriculars = []
                      let awards = []

                      for (let i = 0; i < extras.length; i++) {
                        if (extras[i].type === 'Extracurricular') {
                          extracurriculars.push(extras[i])
                        } else if (extras[i].type === 'Award') {
                          awards.push(extras[i])
                        }
                      }

                      this.setState({ extracurriculars, awards })

                    }
                  }

                } else {
                  console.log('no experience data found', response.data.message)
                }

              }).catch((error) => {
                  console.log('Experience query did not work', error);
              });

              Axios.get('/api/users/profile/details', { params: { email: emailId } })
              .then((response) => {
                console.log('User details query 1 attempted', response.data);

                if (response.data.success) {
                   console.log('successfully retrieved user details')

                   let cuProfile = {}
                   cuProfile['firstName'] = response.data.user.firstName
                   cuProfile['lastName'] = response.data.user.lastName
                   cuProfile['email'] = response.data.user.email
                   cuProfile['zipcode'] = response.data.user.zipcode

                   // pulling these out for followPerson()
                   const pictureURL = response.data.user.pictureURL
                   const headline = response.data.user.headline

                   const linkedInURL = response.data.user.linkedInURL
                   const jobTitle = response.data.user.jobTitle
                   const employerName = response.data.user.employerName

                   let schoolName = response.data.user.school
                   if (response.data.user.education && response.data.user.education.length > 0) {
                     schoolName = response.data.user.education[0].name
                     if (response.data.user.education.length > 1) {
                       for (let i = 1; i <= response.data.user.education.length; i++) {
                         if (response.data.user.education[i - 1] && response.data.user.education[i - 1].isContinual) {
                           schoolName = response.data.user.education[i - 1].name
                         }
                       }
                     }
                   }

                   const notificationPreferences = response.data.user.notificationPreferences

                   this.setState({ cuProfile, pictureURL, headline, linkedInURL, jobTitle, employerName,
                     schoolName, notificationPreferences
                   });

                   Axios.get('/api/assessment/results', { params: { emailId } })
                    .then((response2) => {
                      console.log('query for assessment results worked');

                      if (response2.data.success) {

                        console.log('actual assessment results', response2.data)

                        // let profile = { firstName: cuFirstName, lastName: cuLastName, email: emailId }
                        cuProfile['workPreferences'] = response2.data.results.workPreferenceAnswers
                        cuProfile['interests'] = response2.data.results.interestScores
                        cuProfile['personality'] = response2.data.results.personalityScores
                        cuProfile['skills'] = response2.data.results.newSkillAnswers
                        cuProfile['gravitateValues'] = response2.data.results.topGravitateValues
                        cuProfile['employerValues'] = response2.data.results.topEmployerValues

                        this.calculateMatchScore(profileData, cuProfile)

                      } else {
                        console.log('no assessment results', response2.data)
                      }

                  }).catch((error) => {
                    console.log('query for assessment results did not work', error);
                  })

                } else {
                 console.log('no user details data found', response.data.message)
                }

              }).catch((error) => {
                 console.log('User details query did not work', error);
              });

              if (this.props.id && window.location.pathname.includes('/endorsements')) {
                Axios.get('/api/story/byid', { params: { _id: this.props.id } })
                .then((response) => {
                    console.log('Story query worked', response.data);

                    if (response.data.success) {

                      const selectedEndorsement = response.data.endorsementRequest
                      this.setState({ modalIsOpen: true, selectedIndex: null, showEndorsementDetail: true, selectedEndorsement })

                    }

                }).catch((error) => {
                    console.log('Story query did not work', error);
                });
              }


            } else {
              console.log('there was an error', response.data.message)
            }

          }
        }).catch((error) => {
            console.log('User details fetch did not work', error);
        });

        Axios.get('/api/favorites', { params: { emailId } })
       .then((response) => {
         console.log('Favorites query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved favorites')

           const favorites = response.data.favorites
           let newFavorites = []
           if (response.data.newFavorites) {
             newFavorites = response.data.newFavorites
           }

           this.setState({ favorites, newFavorites })

         } else {
           console.log('no favorites data found', response.data.message)
         }

       }).catch((error) => {
           console.log('Favorites query did not work', error);
       });
      }
    }

    calculateMatchScore(selectedMentor,cuProfile) {
      console.log('calculateMatchScore called', selectedMentor, cuProfile)

      let roleNames = ['Mentor']
      let orgCode = null
      let onlyPics = false

      this.setState({ animating: true })

      Axios.put('/api/members/matches', { profile: cuProfile, roleNames, orgCode, onlyPics, memberId: selectedMentor._id })
      .then((response) => {
        console.log('Opportunity matches attempted', response.data);

          if (response.data.success) {
            console.log('opportunity match query worked')

            let matchScore = 0
            if (response.data.members && response.data.members.length > 0) {
              matchScore = response.data.members[0].matchScore
            }

            this.setState({ animating: false, selectedMentor, matchScore })

          } else {
            console.log('Opportunity match did not work', response.data.message)
            this.setState({ animating: false, matchingView: true, errorMessage: 'there was an error: ' + response.data.message, selectedMentor })
          }

      }).catch((error) => {
          console.log('Opportunity match did not work for some reason', error);
          this.setState({ animating: false, matchingView: true, errorMessage: 'there was an error', selectedMentor })
      });
    }

    handleScroll(event) {

      // if (event.target.scrollLeft + event.target.clientWidth === event.target.scrollWidth) {
      //   console.log('end of scroll')
      // } else if (event.target.scrollLeft < 3) {
      //   console.log('beginning of scroll')
      // }
    }

    returnCount(value) {
      console.log('returnCount called', value)

      if (value === 'All') {
        let allCount = 0
        if (this.state.posts) {
          allCount = allCount + this.state.posts.length
        }
        if (this.state.projects) {
          allCount = allCount + this.state.projects.length
        }
        if (this.state.passions) {
          allCount = allCount + this.state.passions.length
        }
        if (this.state.goals) {
          allCount = allCount + this.state.goals.length
        }
        if (this.state.assessments) {
          allCount = allCount + this.state.assessments.length
        }
        if (this.state.endorsements) {
          allCount = allCount + this.state.endorsements.length
        }

        return allCount
      } else if (value === 'Posts') {
        return this.state.posts.length
      } else if (value === 'Projects') {
        return this.state.projects.length
      } else if (value === 'Goals') {
        return this.state.goals.length
      } else if (value === 'Passions') {
        return this.state.passions.length
      } else if (value === 'Assessments') {
        return this.state.assessments.length
      } else if (value === 'Endorsements') {
        return this.state.endorsements.length
      }
    }

    calculateAge(dateOfBirth) {
      console.log('calculateAge called ', dateOfBirth)

      let age = ''
      if (dateOfBirth && new Date(dateOfBirth)) {
        // difference between dates rounded to the nearest year

        let diff = new Date().getTime() - new Date(dateOfBirth).getTime()
        console.log('diff1: ', diff)
        diff = diff / 1000
        console.log('diff2: ', diff)
        diff = diff / (60 * 60 * 24)
        console.log('diff3: ', diff)
        diff = diff / (365.25)
        console.log('diff4: ', diff)
        age = Math.floor(diff)
      } else if (dateOfBirth && dateOfBirth.split("/") && dateOfBirth.split("/").length === 3) {
        let month = Number(dateOfBirth.split("/")[0])
        let day = Number(dateOfBirth.split("/")[1])
        let year = Number(dateOfBirth.split("/")[2])

        console.log('show date values: ', month, day, year)
      }

      return age
    }

    closeModal() {

      this.setState({ modalIsOpen: false, showProjectDetail: false, showAssessmentDetail: false, showEndorsementDetail: false,
        showMessageWidget: false, showComments: false, showShareButtons: false, showGoalDetails: false, showHelpOutWidget: false,
        showPassionDetail: false, selectedPassion: null, selectedGoal: null, showReferSomeone: false, enlargeImage: false, selectedImage: null,
        showFriendOptions: false, selectedEndorsement: null
      });

    }

    passData(passedData) {
      console.log('passData called', passedData)

      if (passedData && passedData.success) {
        const cuFirstName = passedData.user.firstName
        const cuLastName = passedData.user.lastName
        const emailId = passedData.user.email
        const activeOrg = passedData.user.activeOrg
        const roleName = passedData.user.roleName
        const username = passedData.user.username
        const orgFocus = passedData.user.orgFocus

        this.setState({ emailId, activeOrg, orgFocus, roleName, username, cuFirstName, cuLastName })

      } else if (passedData && !passedData.success) {
        this.setState({ errorMessage: passedData.message })
      }
    }

    followPerson(person) {
      console.log('followPerson called', person)

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      const senderPictureURL = this.state.pictureURL
      const senderEmail = this.state.emailId
      const senderFirstName = this.state.cuFirstName
      const senderLastName = this.state.cuLastName
      const senderUsername = this.state.username
      const senderHeadline = this.state.headline
      const recipientPictureURL = person.pictureURL
      const recipientEmail = person.email
      const recipientFirstName = person.firstName
      const recipientLastName = person.lastName
      const recipientUsername = person.username
      const recipientHeadline = person.headline
      const relationship = 'Peer'
      const orgCode = this.state.activeOrg
      const orgName = this.state.orgName

      let friend = {
        senderPictureURL, senderEmail, senderFirstName, senderLastName, senderUsername, senderHeadline,
        recipientPictureURL, recipientEmail, recipientFirstName, recipientLastName, recipientUsername, recipientHeadline,
        relationship, orgCode, orgName }

      // Axios.post('/api/friend/request', friend)
      // .then((response) => {
      //
      //   if (response.data.success) {
      //
      //     friend['active'] = false
      //     friend['friend2Email'] = recipientEmail
      //
      //     let friends = this.state.friends
      //     friends.push(friend)
      //     console.log('show friends: ', friends)
      //     this.setState({ successMessage: response.data.message, friends })
      //
      //   } else {
      //
      //     this.setState({ errorMessage: response.data.message })
      //   }
      // }).catch((error) => {
      //     console.log('Advisee request send did not work', error);
      // });

      const self = this
      async function asyncFollowPerson(unsubscribed) {
        console.log('asyncFollowPerson called')

        friend['unsubscribed'] = unsubscribed

        const returnedValue = await requestConnection(friend)
        console.log('show returnedValue: ', returnedValue)

        if (returnedValue.success) {
          friend['active'] = false
          friend['friend2Email'] = recipientEmail

          let friends = self.state.friends
          friends.push(friend)
          console.log('show friends: ', friends)
          self.setState({ successMessage: returnedValue.message, friends })
        } else {
          self.setState({ errorMessage: returnedValue.message })
        }
      }

      // pull notification preferences of the owner
      Axios.get('/api/users/profile/details', { params: { email: recipientEmail } })
      .then((response) => {
        console.log('Profile query for notipreferences attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved profile information')
           const notificationPreferences = response.data.user.notificationPreferences

           let unsubscribed = null
           if (notificationPreferences && notificationPreferences.length > 0) {
             for (let i = 1; i <= notificationPreferences.length; i++) {
               if (notificationPreferences[i - 1].slug === 'incoming-comments' && notificationPreferences[i - 1].enabled === false) {
                 unsubscribed = true
               }
             }
           }

           asyncFollowPerson(unsubscribed)

         } else {
           console.log('no profile data found', response.data.message)

           asyncFollowPerson()
         }

      }).catch((error) => {
         console.log('Profile query did not work', error);
      });
    }

    acceptRequest(friends, profileData) {
      console.log('acceptRequest called')

      this.setState({ isSaving: true, errorMessage: null, successMessage: null })

      // const friends = this.state.friends
      const friendIndex = friends.findIndex(friend => ((friend.friend1Email === this.state.emailId || friend.friend2Email === this.state.emailId) && friend.requesterEmail === profileData.email && friend.activeRequest))

      if (friendIndex) {
        let recipientEmail = friends[friendIndex].friend2Email
        let friendRequest = {
          friendshipId: friends[friendIndex]._id,
          pictureURL: friends[friendIndex].friend1PictureURL,
          firstName: friends[friendIndex].friend1FirstName,
          lastName: friends[friendIndex].friend1LastName,
          email: friends[friendIndex].friend1Email,
          username: friends[friendIndex].friend1Username,
          headline: friends[friendIndex].friend1Headline,
          active: friends[friendIndex].active,
          activeRequest: true
        }
        if (recipientEmail === this.state.emailId) {
          recipientEmail = friends[friendIndex].friend1Email
          friendRequest['pictureURL'] = friends[friendIndex].friend2PictureURL
          friendRequest['firstName'] = friends[friendIndex].friend2FirstName
          friendRequest['lastName'] = friends[friendIndex].friend2LastName
          friendRequest['email'] = friends[friendIndex].friend2Email
          friendRequest['username'] = friends[friendIndex].friend2Username
          friendRequest['headline'] = friends[friendIndex].friend2Headline
        }

        const senderFirstName = this.state.cuFirstName
        const senderLastName = this.state.cuLastName
        const senderEmail = this.state.emailId
        const senderPictureURL = this.state.pictureURL
        const senderHeadline = this.state.headline
        const senderUsername = this.state.username
        const senderJobTitle = this.state.jobTitle
        const senderEmployerName = this.state.employerName
        const senderSchoolName = this.state.schoolName

        let unsubscribed = null
        if (this.state.notificationPreferences && this.state.notificationPreferences.length > 0) {
          for (let i = 1; i <= this.state.notificationPreferences.length; i++) {
            if (this.state.notificationPreferences[i - 1].slug === 'accepted-friend-requests' && this.state.notificationPreferences[i - 1].enabled === false) {
              unsubscribed = true
            }
          }
        }
        const headerImageURL = this.state.headerImageURL

        const updateBody = {
          decidedOnRequest: true, decision: true, request: friendRequest, recipientEmail,
          senderFirstName, senderLastName, senderEmail, senderPictureURL, senderHeadline,
          senderUsername, senderJobTitle, senderEmployerName, senderSchoolName, unsubscribed, headerImageURL
        }

        Axios.put('/api/friends/update', updateBody)
        .then((response) => {
          console.log('Friends update requests attempted', response.data);

            if (response.data.success) {
              console.log('friends update request worked')

              friends[friendIndex]['active'] = true
              friends[friendIndex]['isApproved'] = false
              friends[friendIndex]['isDecided'] = true
              friends[friendIndex]['activeRequest'] = false

              this.setState({ friends, isSaving: false })

            } else {
              console.log('friends update request did not work', response.data.message)
              this.setState({ isSaving: false, errorMessage: response.data.message })
            }

        }).catch((error) => {
            console.log('Friends update request did not work for some reason', error);
        })
      } else {
        this.setState({ isSaving: false, errorMessage: 'There was an error finding the request on the front-end' })
      }

    }

    testActiveFriendship(friends) {
      console.log('testActiveFriendship called in externalProfile', friends, this.state.profileData.email)

      let friendshipIsActive = false
      const index = friends.findIndex(friend => (friend.friend1Email === this.state.profileData.email || friend.friend2Email === this.state.profileData.email) && friend.active);
      // console.log('show index: ', index)
      if (index > -1) {
        friendshipIsActive = true
      }

      if (this.state.paired) {
        friendshipIsActive = true
      }
      // console.log('is friendshipActive: ', friendshipIs)
      return friendshipIsActive

    }

    calculateWidth(item, answer) {
      console.log('calculateWidth called', item, answer)

      let width = '0%'

      let aValue = 0
      if (item.aVotes) {
        aValue = item.aVotes.length
      }

      let bValue = 0
      if (item.bVotes) {
        bValue = item.bVotes.length
      }

      let totalValue = aValue + bValue
      if (totalValue > 0) {
        if (answer === 'a') {
          width = ((aValue / (aValue + bValue)) * 100).toString() + '%'
        } else {
          width = ((bValue / (bValue + aValue)) * 100).toString() + '%'
        }
      }

      return width
    }

    renderPost(value, index, inModal) {
      console.log('renderPost called', value)

      if (value) {
        let defaultProfileItemIcon = projectsIconDark
        if (value.profileItem) {
          if (value.profileItem === 'Experience') {
            defaultProfileItemIcon = experienceIcon
          } else if (value.profileItem === 'Education') {
            defaultProfileItemIcon = educationIcon
          } else if (value.profileItem === 'Career Goal') {
            defaultProfileItemIcon = targetIconOrange
          } else if (value.profileItem === 'Passion') {
            defaultProfileItemIcon = favoritesIconDark
          }
        }

        return (
          <div key={value}>

            <div className={(!inModal) && "card-clear-padding padding-20 top-margin-20"}>
              <div>
                <Link to={'/app/profile/' + value.username} className="background-button standard-color profile-container-right calc-column-offset-30">
                  <div className="fixed-column-55">
                    {(value.roleName === 'Admin') ? (
                      <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="image-40-fit" alt="GC" />
                    ) : (
                      <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-43" alt="GC" />
                    )}
                  </div>
                  <div className="calc-column-offset-55">
                    <div className="calc-column-offset-25">
                      <p className="description-text-1 bold-text">{value.firstName} {value.lastName}</p>
                    </div>
                    {(value.pinned) && (
                      <div className="fixed-column-25 top-padding-5 left-padding">
                        <img src={pinIcon} className="image-auto-10" alt="GC" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="pinned post" />
                      </div>
                    )}
                    <div className="clear" />

                    <div className="mini-spacer" /><div className="mini-spacer" />

                    {(value.headline && value.headline !== '') ? (
                      <div>
                        <p className="description-text-3 description-text-color">{value.headline}</p>
                      </div>
                    ) : (
                      <div>
                        {(value.education && value.education[0] && value.education[0].name && value.education[0].isContinual) ? (
                          <div>
                            {console.log('show edu: ', value.education)}
                            <p className="description-text-3 description-text-color">Student @ {value.education[0].name}</p>
                          </div>
                        ) : (
                          <div>
                            <div>
                              <p className="description-text-3 description-text-color">{this.state.orgName} Member</p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <p className="description-text-4 description-text-color">{convertDateToString(value.createdAt,"daysAgo")}</p>
                  </div>
                </Link>

                <ReactTooltip id="tooltip-placeholder-id" />

                <div className="profile-modal-right">
                  <div>
                    <div className="fixed-column-55">
                      <img src={(value.pictureURL) ? value.pictureURL : profileIconDark} className="profile-thumbnail-43" alt="GC" />
                    </div>
                    <div className="calc-column-offset-55">
                      <p className="description-text-2 bold-text">{value.firstName} {value.lastName}</p>

                      {(value.headline && value.headline !== '') ? (
                        <div>
                          <p className="description-text-4 description-text-color">{value.headline}</p>
                        </div>
                      ) : (
                        <div>
                          {(value.education && value.education[0] && value.education[0].name && value.education[0].isContinual) ? (
                            <div>
                              <p className="description-text-4 description-text-color">Student @ {value.education[0].name}</p>
                            </div>
                          ) : (
                            <div>
                              <div>
                                <p className="description-text-4 description-text-color">{this.state.orgName} Member</p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      <p className="description-text-4 description-text-color">{convertDateToString(value.createdAt,"daysAgo")}</p>
                    </div>
                    <div className="clear" />
                  </div>

                  <div className="top-padding-20">
                    <Link to={'/app/messaging'} state={{ recipient: value }} className="background-button full-width"><button className="btn btn-squarish full-width">Message</button></Link>
                  </div>
                </div>

                <div className="fixed-column-30">
                  <button onClick={(value.showPostMenu) ? () => this.togglePostMenu(index) : () => this.togglePostMenu(index)} className="background-button">
                    <div className="row-5 horizontal-padding-4">
                      <img src={menuIconDark} className="image-15-auto pin-right" alt="GC" />
                    </div>
                  </button>
                  {(value.showPostMenu) && (
                    <div className="menu-bottom description-text-3">
                      {/*
                      <button className="background-button full-width left-text" disabled={(this.state.isSaving) ? true : false} onClick={() => this.unfollowPerson(value)}>
                        <div className="row-5">
                          <div className="fixed-column-25">
                            <img src={closeIconDark} alt="GC" className="image-auto-15" />
                          </div>
                          <div className="calc-column-offset-25">
                            <p>Unfollow</p>
                          </div>
                          <div className="clear" />
                        </div>
                      </button>*/}

                      {(window.location.pathname.includes('/organizations')) ? (
                        <div>
                          <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showDeletePost: true, selectedIndex: index })}>
                            <div className="row-5">
                              <div className="fixed-column-25">
                                <img src={hideIconDark} alt="GC" className="image-auto-15" />
                              </div>
                              <div className="calc-column-offset-25">
                                <p>Delete this post</p>
                              </div>
                              <div className="clear" />
                            </div>
                          </button>
                          <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showReports: true, selectedIndex: index })}>
                            <div className="row-5">
                              <div className="fixed-column-25">
                                <img src={reportIconDark} alt="GC" className="image-auto-15" />
                              </div>
                              <div className="calc-column-offset-25">
                                <p>View all reports</p>
                              </div>
                              <div className="clear" />
                            </div>
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, showShareButtons: true, selectedIndex: index })}>
                            <div className="row-5">
                              <div className="fixed-column-25">
                                <img src={shareIconDark} alt="GC" className="image-auto-15" />
                              </div>
                              <div className="calc-column-offset-25">
                                <p>Share outside of Guided Compass</p>
                              </div>
                              <div className="clear" />
                            </div>
                          </button>
                          <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, adjustFeedPreferences: true, selectedIndex: index })}>
                            <div className="row-5">
                              <div className="fixed-column-25">
                                <img src={hideIconDark} alt="GC" className="image-auto-15" />
                              </div>
                              <div className="calc-column-offset-25">
                                <p>I don't want to see this</p>
                              </div>
                              <div className="clear" />
                            </div>
                          </button>
                          <button className="background-button full-width left-text" onClick={() => this.setState({ modalIsOpen: true, reportPostView: true, selectedIndex: index })}>
                            <div className="row-5">
                              <div className="fixed-column-25">
                                <img src={reportIconDark} alt="GC" className="image-auto-15" />
                              </div>
                              <div className="calc-column-offset-25">
                                <p>Report this post</p>
                              </div>
                              <div className="clear" />
                            </div>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="clear" />
              </div>

              <div className="row-10">
                <p className={(value.postType === 'alternatives') ? "" : "description-text-2"}>{value.message}</p>
                {(value.url) && (
                  <a className="description-text-3 top-padding bold-text" href={value.url} target="_blank">{value.url}</a>
                )}
                {/*
                <button className="background-button full-width" onClick={() => this.setState({ modalIsOpen: true, showVotes: true, selectedIndex: index })}>
                  <div>
                    <div className="progress-bar-thin" >
                      <div className="filler" style={{ width: '60%' }} />
                    </div>
                  </div>
                </button>
                <p>answer selected</p>*/}

                {(value.postType === 'alternatives') && (
                  <div className="top-padding">

                    <div className="row-10">
                      <button className="background-button full-width left-text" onClick={() => this.showPollDetails(value, index)}>
                        <div>
                          <div className="float-left">
                            <p className="description-text-3 cta-color">{(value.showPollDetails) ? "Collapse Details" : "Expand Details"}</p>
                          </div>
                          <div className="float-left left-padding top-padding-5">
                            <img src={dropdownArrow} alt="GC" className="image-auto-8 pin-right" />
                          </div>
                          <div className="clear" />

                        </div>
                      </button>

                      {(value.showPollDetails) && (
                        <div>
                          <div className="row-10">
                            {/*
                            <Link to={'/app/projects/' + item._id} className="background-button standard-color full-width">
                              <div className="calc-column-offset-80">
                                <p>A: {value.aName}</p>
                              </div>
                              <div className="fixed-column-80">
                                <p className="bold-text right-text cta-color">${value.aValue}</p>
                              </div>
                              <div className="clear" />
                            </Link>*/}

                            {(value.aItem) && (
                              <div>
                                <div>
                                  {(value.comparisonType === 'Projects') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'project', 'a')}
                                    </div>
                                  )}
                                  {(value.comparisonType === 'Careers') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'career','a')}
                                    </div>
                                  )}
                                  {(value.comparisonType === 'Competencies') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'competency','a')}
                                    </div>
                                  )}
                                  {(value.comparisonType === 'Jobs') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'work','a')}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            <p className="description-text-3">{value.aCase}</p>
                          </div>

                          <div className="row-10">
                            {(value.bItem) && (
                              <div>
                                <div>
                                  {(value.comparisonType === 'Projects') && (
                                    <div>
                                      {this.renderTaggedItem(value,'project','b')}
                                    </div>
                                  )}
                                  {(value.comparisonType === 'Careers') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'career','b')}
                                    </div>
                                  )}
                                  {(value.comparisonType === 'Competencies') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'competency','b')}
                                    </div>
                                  )}
                                  {(value.comparisonType === 'Jobs') && (
                                    <div>
                                      {this.renderTaggedItem(value, 'work','b')}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            <p className="description-text-3">{value.bCase}</p>
                          </div>
                        </div>
                      )}
                    </div>

                    {((value.aVotes && value.aVotes.includes(this.state.emailId)) || (value.bVotes && value.bVotes.includes(this.state.emailId))) ? (
                      <div>
                        <button className="background-button full-width" onClick={() => this.selectAnswer(value, index,'a')}>
                          <div>
                            <div className="progress-bar-fat" >
                              <div className="filler-error" style={{ width: this.calculateWidth(value, 'a'), zIndex: -1, height: '36px' }} />
                              <div className="row-10 horizontal-padding " style={{ marginTop: '-36px'}}>
                                <div className="calc-column-offset-40 left-text">
                                  <p className="description-text-2 curtail-text">{value.aName}</p>
                                </div>
                                <div className="fixed-column-40 right-text">
                                  <p className="description-text-2 curtail-text">{this.calculateWidth(value, 'a')}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </button>
                        <button className="background-button full-width" onClick={() => this.selectAnswer(value, index,'b')}>
                          <div>
                            <div className="progress-bar-fat" >
                              <div className="filler-error" style={{ width: this.calculateWidth(value, 'b'), zIndex: -1, height: '36px' }} />
                              <div className="row-10 horizontal-padding" style={{ marginTop: '-36px'}}>
                                <div className="calc-column-offset-40 left-text">
                                  <p className="description-text-2 curtail-text">{value.bName}</p>
                                </div>
                                <div className="fixed-column-40 right-text">
                                  <p className="description-text-2 curtail-text">{this.calculateWidth(value, 'b')}</p>
                                </div>
                                <div className="clear" />
                              </div>
                            </div>
                          </div>
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button className="background-button full-width" onClick={() => this.selectAnswer(value, index,'a')}>
                          <div className="row-10 horizontal-padding cta-border">
                            <p className="description-text-2">{value.aName}</p>
                          </div>
                        </button>
                        <button className="background-button full-width" onClick={() => this.selectAnswer(value, index,'b')}>
                          <div className="row-10 horizontal-padding cta-border">
                            <p className="description-text-2">{value.bName}</p>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                )}

              </div>

              {(value.imageURL) && (
                <div className="row-10">
                  <img src={value.imageURL} alt="GC" className="image-full-auto" />
                </div>
              )}

              {(value.videoURL) && (
                <div className="row-10">
                  <div className="spacer"/>

                  <div>
                    <div className="video-container">
                      <iframe
                        title="videoLink"
                        className="video-iframe"
                        src={`${value.videoURL}`}
                        frameBorder="0"
                      />
                    </div>

                  </div>

                  <div className="clear" />
                  <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
                </div>
              )}

              {(value.profileItem) && (
                <div className="bottom-padding">
                  <div className="cta-border">
                    <Link to={'/app/profiles/' + value.profileItem.objectId} className="background-button standard-color padding-20 full-width">
                      <div className="padding-20">
                        <div className="fixed-column-60">
                          <img src={(value.profileItem.imageURL) ? value.profileItem.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="calc-column-offset-60">
                          <p>{value.profileItem.name}</p>
                          {(value.profileItem.category === 'Project') && (
                            <p className="description-text-3 description-text-color">{value.profileItem.category} | {value.profileItem.hours} Hours</p>
                          )}
                          {(value.profileItem.category === 'Experience') && (
                            <p className="description-text-3 description-text-color">{value.profileItem.startDate} - {value.profileItem.endDate}</p>
                          )}
                          {(value.profileItem.category === 'Education') && (
                            <p className="description-text-3 description-text-color">{value.profileItem.startDate} - {value.profileItem.endDate}</p>
                          )}
                          {(value.profileItem.category === 'Career Goal') && (
                            <p className="description-text-3 description-text-color">Deadline: {convertDateToString(new Date(value.profileItem.deadline),"datetime-2")}</p>
                          )}
                          {(value.profileItem.category === 'Passion') && (
                            <p className="description-text-3 description-text-color">Last Updated {value.profileItem.updatedAt}</p>
                          )}

                        </div>
                        <div className="clear" />
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {(value.opportunityTags && value.opportunityTags.length > 0) && (
                <div className="bottom-padding">
                  <div className="cta-border">
                    <Link to={'/app/opportunities/' + value.opportunityTags[0]._id} className="background-button standard-color padding-20 full-width">
                      <div className="padding-20">
                        <div className="fixed-column-60">
                          <img src={(value.opportunityTags[0].imageURL) ? value.opportunityTags[0].imageURL : opportunitiesIconDark} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="calc-column-offset-60">
                          {(value.opportunityTags[0].title) ? (
                            <p>{value.opportunityTags[0].title}</p>
                          ) : (
                            <p>{value.opportunityTags[0].name}</p>
                          )}

                          {(value.opportunityTags[0].employerName) && (
                            <p className="description-text-3 description-text-color">{value.opportunityTags[0].employerName}</p>
                          )}

                        </div>
                        <div className="clear" />
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {(value.careerTags && value.careerTags.length > 0) && (
                <div className="bottom-padding">
                  <div className="cta-border">
                    <Link to={'/app/careers/' + value.careerTags[0].name} className="background-button standard-color padding-20 full-width">
                      <div className="padding-20">
                        <div className="fixed-column-60">
                          <img src={(value.careerTags[0].imageURL) ? value.careerTags[0].imageURL : careerMatchesIconDark} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="calc-column-offset-60">
                          <p>{value.careerTags[0].name}</p>
                          <label className="description-text-3 description-text-color">{value.careerTags[0].jobFamily}</label>

                          {(value.careerTags[0].marketData) && (
                            <label className="description-text-3 description-text-color"> | ${Number(value.careerTags[0].marketData.pay).toLocaleString()} avg pay</label>
                          )}

                        </div>
                        <div className="clear" />
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {(value.trendTags && value.trendTags.length > 0) && (
                <div className="bottom-padding">
                  <div className="cta-border">
                    <Link to={'/app/exploration'} state={{ subNavSelected: 'Trends' }} className="background-button standard-color padding-20 full-width">
                      <div className="padding-20">
                        <div className="fixed-column-60">
                          <img src={(value.trendTags[0].imageURL) ? value.trendTags[0].imageURL : trendsIconDark} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="calc-column-offset-120">
                          <p>{value.trendTags[0].name}</p>
                          <label className="description-text-3 description-text-color">{value.trendTags[0].category}</label>
                        </div>

                        {(value.trendTags[0].percentChange) && (
                          <div className="fixed-column-60">
                            <p className="heading-text-3 cta-color full-width right-text">{Number(value.trendTags[0].percentChange).toFixed()}%</p>
                            <p className="description-text-5 full-width right-text">increase in U.S. jobs</p>
                          </div>
                        )}

                        <div className="clear" />
                      </div>
                    </Link>
                  </div>
                </div>
              )}

              {(value.tags && value.tags.length > 0) && (
                <div className="bottom-padding">
                  {value.tags.map((item2, index2) =>
                    <div key={index2} className="float-left right-padding top-padding">
                      <div className="tag-container-thin">
                        <p className="description-text-4">{item2}</p>
                      </div>
                    </div>
                  )}
                  <div className="clear" />
                </div>
              )}

              {(value.entityTags && value.entityTags.length > 0) && (
                <div className="top-padding">
                  {value.entityTags.map((value2, optionIndex2) =>
                    <div key={value2} className="float-left right-padding">
                      <Link className="background-button standard-color" to={'/app/profile/' + value2.username}>
                        <img src={(value2.pictureURL) ? value2.pictureURL : profileIconDark} alt="GC" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={value2.firstName + ' ' + value2.lastName} />
                      </Link>
                    </div>
                  )}
                  <div className="clear" />
                </div>
              )}

              {(value.originalPost && value.originalPost.message) && (
                <div className="cta-border padding-20">
                  {this.renderOriginalPost(value)}
                </div>
              )}

              {(value.upvotes || (value.comments && value.comments.length > 0)) && (
                <div className="bottom-padding-5">
                  <div className="fixed-column-130">
                    <button onClick={() => this.retrieveLikes(index)} className="background-button">
                      <label className="description-text-4">{(value.upvotes) ? value.upvotes.length : 0} Upvotes</label>
                    </button>
                    <label className="description-text-4 horizontal-padding-7">&#8226;</label>
                    <button onClick={() => this.retrieveComments(index)} className="background-button">
                      <label className="description-text-4">{(value.commentCount) ? value.commentCount : 0} Comments</label>
                    </button>
                  </div>

                  <div className="clear" />

                </div>
              )}

              <div className="spacer" />
              <hr />

              {(!inModal) && (
                <div className="top-padding">
                  <div className="float-left">
                    <button onClick={(e) => this.voteOnItem(e, value, 'up', index) } className="background-button">
                      <div className={(window.innerWidth > 768) ? "float-left right-padding" : "float-left right-padding-8"}>
                        <img src={(value.upvotes.includes(this.state.emailId))? likeIconBlue : likeIconDark} alt="GC" className="image-auto-18 center-horizontally" />
                      </div>
                      <div className={(window.innerWidth > 768) ? "float-left right-padding-20" : "float-left right-padding-20"}>
                        <p className={(value.upvotes.includes(this.state.emailId)) ? "description-text-2 cta-color bold-text" : "description-text-2"}>{(value.upvotes.includes(this.state.emailId)) ? "Liked" : "Like"}</p>
                      </div>
                      <div className="clear" />
                    </button>
                  </div>

                  <div className="float-left">
                    <button onClick={() => this.retrieveComments(index)} className="background-button" disabled={this.state.isLoading}>
                      <div className={(window.innerWidth > 768) ? "float-left right-padding" : "float-left right-padding-8"}>
                        <div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                        <img src={commentIconDark} alt="GC" className="image-auto-18 center-horizontally" />
                      </div>
                      <div className={(window.innerWidth > 768) ? "float-left right-padding-20" : "float-left right-padding-20"}>
                        <p className="description-text-2">Comment</p>
                      </div>
                      <div className="clear" />
                    </button>
                  </div>

                  <div className="float-left">
                    <button onClick={(value.originalPost && value.originalPost.message) ? () => this.setState({ modalIsOpen: true, sharePosting: true, originalPost: value.originalPost, selectedIndex: index }) : () => this.setState({ modalIsOpen: true, sharePosting: true, originalPost: value, selectedIndex: index })} className="background-button">
                      <div className={(window.innerWidth > 768) ? "float-left right-padding" : "float-left right-padding-8"}>
                        <img src={shareIconDark} alt="GC" className="image-auto-18 center-horizontally" />
                      </div>
                      <div className={(window.innerWidth > 768) ? "float-left right-padding-20" : "float-left right-padding-20"}>
                        <p className="description-text-2">Share</p>
                      </div>
                      <div className="clear" />
                    </button>
                  </div>
                  {(!window.location.pathname.includes('/organizations') && !window.location.pathname.includes('/advisor')) && (
                    <div className="float-left">
                      <Link className="background-button standard-color" to={'/app/messaging'} state={{ generalPost: value }}>
                        <div className={(window.innerWidth > 768) ? "float-left right-padding" : "float-left right-padding-8"}>
                          <img src={sendIconDark} alt="GC" className="image-auto-18 center-horizontally" />
                        </div>
                        <div className={(window.innerWidth > 768) ? "float-left right-padding-20" : "float-left right-padding-20"}>
                          <p className="description-text-2">Send</p>
                        </div>
                        <div className="clear" />
                      </Link>
                    </div>
                  )}

                  <div className="clear" />
                </div>
              )}
            </div>
          </div>
        )
      }
    }

    renderOriginalPost(value) {
      console.log('renderOriginalPost called', value)

      let defaultProfileItemIcon = projectsIconDark
      if (value.profileItem) {
        if (value.profileItem === 'Experience') {
          defaultProfileItemIcon = experienceIcon
        } else if (value.profileItem === 'Education') {
          defaultProfileItemIcon = educationIcon
        } else if (value.profileItem === 'Career Goal') {
          defaultProfileItemIcon = targetIconOrange
        } else if (value.profileItem === 'Passion') {
          defaultProfileItemIcon = favoritesIconDark
        }
      }

      return (
        <div key="originalPost">
          <div>
            <Link to={'/app/profile/' + value.originalPost.username} className="background-button standard-color profile-container-right calc-column-offset-30">
              <div className="fixed-column-55">
                {(value.originalPost.roleName === 'Admin') ? (
                  <img src={(value.originalPost.pictureURL) ? value.originalPost.pictureURL : profileIconDark} className="image-40-fit" alt="GC" />
                ) : (
                  <img src={(value.originalPost.pictureURL) ? value.originalPost.pictureURL : profileIconDark} className="profile-thumbnail-43" alt="GC" />
                )}
              </div>
              <div className="calc-column-offset-55">
                <div className="calc-column-offset-25">
                  <p className="description-text-1 bold-text">{value.originalPost.firstName} {value.originalPost.lastName}</p>
                </div>
                <div className="clear" />

                <div className="mini-spacer" /><div className="mini-spacer" />

                {(value.originalPost.headline && value.originalPost.headline !== '') ? (
                  <div>
                    <p className="description-text-3 description-text-color">{value.originalPost.headline}</p>
                  </div>
                ) : (
                  <div>
                    {(value.originalPost.education && value.originalPost.education[0] && value.originalPost.education[0].name && value.originalPost.education[0].isContinual) ? (
                      <div>
                        <p className="description-text-3 description-text-color">Student @ {value.originalPost.education[0].name}</p>
                      </div>
                    ) : (
                      <div>
                        <div>
                          <p className="description-text-3 description-text-color">{this.state.orgName} Member</p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <p className="description-text-4 description-text-color">{convertDateToString(value.originalPost.createdAt,"daysAgo")}</p>
              </div>
            </Link>

            <div className="fixed-column-30">

            </div>
            <div className="clear" />
          </div>

          <div className="row-10">
            <p className="description-text-2">{value.originalPost.message}</p>

            {(value.originalPost.url) && (
              <a className="description-text-3 top-padding bold-text" href={value.url} target="_blank">{value.originalPost.url}</a>
            )}
          </div>
          {(value.originalPost.imageURL) && (
            <div className="row-10">
              <img src={value.originalPost.imageURL} alt="GC" className="image-full-auto" />
            </div>
          )}

          {(value.originalPost.videoURL) && (
            <div className="row-10">
              <div className="spacer"/>

              <div>
                <div className="video-container">
                  <iframe
                    title="videoLink"
                    className="video-iframe"
                    src={`${value.originalPost.videoURL}`}
                    frameBorder="0"
                  />
                </div>

              </div>

              <div className="clear" />
              <div className="spacer"/><div className="spacer"/><div className="half-spacer"/>
            </div>
          )}

          {(value.originalPost.profileItem) && (
            <div className="bottom-padding">
              <div className="cta-border">
                <Link to={'/app/profiles/' + value.originalPost.profileItem.objectId} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-60">
                      <img src={(value.originalPost.profileItem.imageURL) ? value.originalPost.profileItem.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      <p>{value.originalPost.profileItem.name}</p>
                      {(value.originalPost.profileItem.category === 'Project') && (
                        <p className="description-text-3 description-text-color">{value.originalPost.profileItem.category} | {value.originalPost.profileItem.hours} Hours</p>
                      )}
                      {(value.originalPost.profileItem.category === 'Experience') && (
                        <p className="description-text-3 description-text-color">{value.originalPost.profileItem.startDate} - {value.originalPost.profileItem.endDate}</p>
                      )}
                      {(value.originalPost.profileItem.category === 'Education') && (
                        <p className="description-text-3 description-text-color">{value.originalPost.profileItem.startDate} - {value.originalPost.profileItem.endDate}</p>
                      )}
                      {(value.originalPost.profileItem.category === 'Career Goal') && (
                        <p className="description-text-3 description-text-color">Deadline: {convertDateToString(new Date(value.originalPost.profileItem.deadline),"datetime-2")}</p>
                      )}
                      {(value.originalPost.profileItem.category === 'Passion') && (
                        <p className="description-text-3 description-text-color">Last Updated {value.originalPost.profileItem.updatedAt}</p>
                      )}

                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          )}

          {(value.originalPost.opportunityTags && value.originalPost.opportunityTags.length > 0) && (
            <div className="bottom-padding">
              <div className="cta-border">
                <Link to={'/app/opportunities/' + value.originalPost.opportunityTags[0]._id} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-60">
                      <img src={(value.originalPost.opportunityTags[0].imageURL) ? value.originalPost.opportunityTags[0].imageURL : opportunitiesIconDark} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      {(value.originalPost.opportunityTags[0].title) ? (
                        <p>{value.originalPost.opportunityTags[0].title}</p>
                      ) : (
                        <p>{value.originalPost.opportunityTags[0].name}</p>
                      )}

                      {(value.originalPost.opportunityTags[0].employerName) && (
                        <p className="description-text-3 description-text-color">{value.originalPost.opportunityTags[0].employerName}</p>
                      )}

                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          )}

          {(value.originalPost.careerTags && value.originalPost.careerTags.length > 0) && (
            <div className="bottom-padding">
              <div className="cta-border">
                <Link to={'/app/careers/' + value.originalPost.careerTags[0].name} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-60">
                      <img src={(value.originalPost.careerTags[0].imageURL) ? value.originalPost.careerTags[0].imageURL : careerMatchesIconDark} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      <p>{value.originalPost.careerTags[0].name}</p>
                      <label className="description-text-3 description-text-color">{value.originalPost.careerTags[0].jobFamily}</label>

                      {(value.originalPost.careerTags[0].marketData) && (
                        <label className="description-text-3 description-text-color"> | ${Number(value.originalPost.careerTags[0].marketData.pay).toLocaleString()} avg pay</label>
                      )}

                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          )}

          {(value.originalPost.trendTags && value.originalPost.trendTags.length > 0) && (
            <div className="bottom-padding">
              <div className="cta-border">
                <Link to={'/app/exploration'} state={{ subNavSelected: 'Trends' }} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-60">
                      <img src={(value.originalPost.trendTags[0].imageURL) ? value.originalPost.trendTags[0].imageURL : trendsIconDark} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-120">
                      <p>{value.originalPost.trendTags[0].name}</p>
                      <label className="description-text-3 description-text-color">{value.originalPost.trendTags[0].category}</label>
                    </div>

                    {(value.originalPost.trendTags[0].percentChange) && (
                      <div className="fixed-column-60">
                        <p className="heading-text-3 cta-color full-width right-text">{Number(value.originalPost.trendTags[0].percentChange).toFixed()}%</p>
                        <p className="description-text-5 full-width right-text">increase in U.S. jobs</p>
                      </div>
                    )}

                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          )}

          {(value.originalPost.tags && value.originalPost.tags.length > 0) && (
            <div className="bottom-padding">
              {value.originalPost.tags.map((item2, index2) =>
                <div key={index2} className="float-left right-padding top-padding">
                  <div className="tag-container-thin">
                    <p className="description-text-4">{item2}</p>
                  </div>
                </div>
              )}
              <div className="clear" />
            </div>
          )}

          {(value.originalPost.entityTags && value.originalPost.entityTags.length > 0) && (
            <div className="top-padding">
              {value.originalPost.entityTags.map((value2, optionIndex2) =>
                <div key={value2} className="float-left right-padding">
                  <Link className="background-button standard-color" to={'/app/profile/' + value2.username}>
                    <img src={(value2.pictureURL) ? value2.pictureURL : profileIconDark} alt="GC" className="image-auto-20" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={value2.firstName + ' ' + value2.lastName} />
                  </Link>
                </div>
              )}
              <div className="clear" />
            </div>
          )}

        </div>
      )
    }

    selectAnswer(value, index,answer) {
      console.log('selectAnswer', answer)

      let posts = this.state.posts
      const emailId = this.state.emailId

      // do all this on the backend
      Axios.put('/api/group-post/poll-vote', {  _id: posts[index]._id, answer, emailId })
      .then((response) => {
        console.log('Poll vote attempted', response.data);

          if (response.data.success) {
            console.log('successfully recorded poll vote')

            posts[index] = response.data.groupPost
            this.setState({ posts })

          } else {
            console.log('there was an error saving the poll data', response.data.message)

          }

      }).catch((error) => {
          console.log('there was an error saving the poll data', error);

      });

    }

    retrieveComments(index) {
      console.log('retrieveComments called', index)

      let parentPostId = this.state.posts[index]._id
      // if (index || index === 0) {
      //   parentPostId = this.state.posts[index]._id
      // } else {
      //   parentPostId = this.state.passedGroupPost
      // }
      // pull comments
      Axios.get('/api/comments', { params: { parentPostId } })
      .then((response) => {
        console.log('Comments query attempted', response.data);

         if (response.data.success) {
           console.log('successfully retrieved comments')

           const comments = response.data.comments
           this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments })

         } else {
           console.log('no comments data found', response.data.message)
           this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments: [] })
         }
      }).catch((error) => {
         console.log('Comments query did not work', error);
         this.setState({ modalIsOpen: true, showComments: true, selectedIndex: index, comments: [] })
      });
    }

    retrieveLikes(index) {
      console.log('retrieveLikes called', index)

      const userIds = this.state.posts[index].upvotes
      if (userIds) {
        // pull comments
        Axios.get('/api/users', { params: { userIds } })
        .then((response) => {
          console.log('Users query attempted', response.data);

           if (response.data.success) {
             console.log('successfully retrieved users')

             const upvotes = response.data.users
             this.setState({ modalIsOpen: true, showUpvotes: true, selectedIndex: index, upvotes })

           } else {
             console.log('no upvotes data found', response.data.message)
             this.setState({ modalIsOpen: true, showUpvotes: true, selectedIndex: index, upvotes: [] })
           }
        }).catch((error) => {
           console.log('Upvotes query did not work', error);
           this.setState({ modalIsOpen: true, showUpvotes: true, selectedIndex: index, upvotes: [] })
        });
      } else {
        this.setState({ modalIsOpen: true, showUpvotes: true, selectedIndex: index, upvotes: [] })
      }
    }

    togglePostMenu(index) {
      console.log('togglePostMenu called', index)

      let posts = this.state.posts
      if (posts[index].showPostMenu) {
        posts[index]['showPostMenu'] = false
      } else {
        posts[index]['showPostMenu'] = true
      }

      this.setState({ posts })

    }

    renderShareButtons() {
      console.log('renderShareButtons called')

      const shareURL = window.location.protocol + "//" + window.location.host + "/app/social-posts/" + this.state.posts[this.state.selectedIndex]._id
      const shareTitle = 'Check Out My Post On Guided Compass!'
      const shareBody = "Guided Compass is a great forum to connect with like-minded individuals, mentors / mentees, and opportunities."

      return (
        <div key="renderShareButtons">
          <EmailShareButton url={shareURL} subject={shareTitle} body={shareBody} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Email">
            <EmailIcon size={32} round />
          </EmailShareButton>

          <FacebookShareButton url={shareURL} quote={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Facebook">
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <LinkedinShareButton url={shareURL} title={shareTitle} summary={shareBody} source={window.location.protocol + "//" + window.location.host} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="LinkedIn">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <PinterestShareButton url={shareURL} description={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Pinterest">
            <PinterestIcon size={32} round />
          </PinterestShareButton>

          <WhatsappShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Whatsapp">
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <WorkplaceShareButton url={shareURL} quote={shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Workplace by Facebook">
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>

          <TwitterShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5"  data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Twitter">
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <RedditShareButton url={shareURL} title={shareTitle} className="horizontal-margin-5" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="Reddit">
            <RedditIcon size={32} round />
          </RedditShareButton>

          <div className="clear" />

          <ReactTooltip id="tooltip-placeholder-id" />

        </div>
      )
    }

    renderTaggedItem(item, type, answer) {
      console.log('renderTaggedItem called', item, type, answer)

      let defaultProfileItemIcon = projectsIconDark
      if (type === 'project') {
        defaultProfileItemIcon = projectsIconDark
      } else if (type === 'career') {
        defaultProfileItemIcon = careerMatchesIconDark
      } else if (type === 'competency') {
        defaultProfileItemIcon = educationIcon
      } else if (type === 'work') {
        defaultProfileItemIcon = opportunitiesIconDark
      }

      let itemObject = item.aItem
      if (answer === 'b') {
        itemObject = item.bItem
      }

      if (type === 'project') {

        return (
          <div key="taggedProjectItem">
            <Link to={'/app/projects/' + itemObject._id} className="background-button standard-color full-width">
              {(answer === 'a') ? (
                <div>
                  <div className="calc-column-offset-80 heading-text-5 right-text">
                    <p>A: {item.aName}</p>
                  </div>
                  <div className="fixed-column-80 heading-text-5">
                    {(item.aValue) && (
                      <p className="bold-text right-text cta-color">${item.aValue}</p>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="fixed-column-80 heading-text-5">
                    {(item.bValue) ? (
                      <p className="bold-text cta-color">${item.bValue}</p>
                    ) : (
                      <div className="width-40 height-30" />
                    )}
                  </div>
                  <div className="calc-column-offset-80 heading-text-5">
                    <p className="full-width right-text">B: {item.bName}</p>
                  </div>
                </div>
              )}

              <div className="clear" />
            </Link>

            <div className="row-5">
              <div className="bottom-padding">
                <div className="cta-border">
                  <Link to={'/app/projects/' + itemObject._id} className={(answer === 'a') ? "background-button standard-color padding-20 full-width" : "background-button standard-color padding-20 full-width right-text"}>
                    {(answer === 'a') ? (
                      <div className="padding-20">
                        <div className="fixed-column-60">
                          <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="calc-column-offset-60">
                          <p>{itemObject.name}</p>
                          <p className="description-text-3 description-text-color">{itemObject.category} | {itemObject.hours} Hours</p>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <div className="padding-20">
                        <div className="calc-column-offset-60 right-padding">
                          <p>{itemObject.name}</p>
                          <p className="description-text-3 description-text-color">{itemObject.category} | {itemObject.hours} Hours</p>
                        </div>
                        <div className="fixed-column-60">
                          <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                        </div>
                        <div className="clear" />
                      </div>
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      } else if (type === 'work') {
        return (
          <div key="taggedWorkItem">
            <Link to={'/app/opportunities/' + itemObject._id} className="background-button standard-color padding-20 full-width">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <p className="bold-text right-text cta-color">${item.aValue}</p>
                ) : (
                  <p className="bold-text right-text cta-color">${item.bValue}</p>
                )}
              </div>
              <div className="clear" />
            </Link>

            <div className="row-5">
              <div className="cta-border">
                <Link to={'/app/opportunities/' + itemObject._id} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-50">
                      <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-40-fit" />
                    </div>
                    <div className="calc-column-offset-50">
                      {(itemObject.title) ? (
                        <p>{itemObject.title}</p>
                      ) : (
                        <p>{itemObject.name}</p>
                      )}

                      {(itemObject.employerName) && (
                        <p className="description-text-3 description-text-color">{itemObject.employerName}</p>
                      )}

                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )
      } else if (type === 'career') {
        return (
          <div key="taggedCareerItem">
            <Link to={'/app/careers/' + itemObject.name} className="background-button standard-color padding-20 full-width">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <p className="bold-text right-text cta-color">${item.aValue}</p>
                ) : (
                  <p className="bold-text right-text cta-color">${item.bValue}</p>
                )}
              </div>
              <div className="clear" />
            </Link>

            <div className="bottom-padding">
              <div className="cta-border">
                <Link to={'/app/careers/' + itemObject.name} className="background-button standard-color padding-20 full-width">
                  <div className="padding-20">
                    <div className="fixed-column-60">
                      <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      <p>{itemObject.name}</p>
                      <label className="description-text-3 description-text-color">{itemObject.jobFamily}</label>

                      {(itemObject.marketData) && (
                        <label className="description-text-3 description-text-color"> | ${Number(itemObject.marketData.pay).toLocaleString()} avg pay</label>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )
      } else if (type === 'competency') {
        return (
          <div key="taggedCompetencyItem">
            <div className="bottom-padding">
              <div className="calc-column-offset-80">
                {(answer === 'a') ? (
                  <p>A: {item.aName}</p>
                ) : (
                  <p>B: {item.bName}</p>
                )}
              </div>
              <div className="fixed-column-80">
                {(answer === 'a') ? (
                  <p className="bold-text right-text cta-color">${item.aValue}</p>
                ) : (
                  <p className="bold-text right-text cta-color">${item.bValue}</p>
                )}
              </div>
              <div className="clear" />
            </div>

            <div className="bottom-padding">
              <div className="cta-border">
                <div className="standard-color padding-20 full-width">
                  <div>
                    <div className="fixed-column-60">
                      <img src={(itemObject.imageURL) ? itemObject.imageURL : defaultProfileItemIcon} alt="GC" className="image-50-fit" />
                    </div>
                    <div className="calc-column-offset-60">
                      <p>{itemObject.name}</p>
                      <label className="description-text-3 description-text-color">{itemObject.category}</label>

                      {(itemObject.description) && (
                        <div>
                          <div className="clear" />
                          <label className="description-text-3 description-text-color">{itemObject.description}</label>
                        </div>
                      )}
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )

      }
    }

    renderProfileBasics() {
      console.log('renderProfileBasics called')

      let rows = []

      let counter = 0

      for (let i = 1; i <= this.state.profileBasics.length; i++) {
        const index = i - 1
        const item = this.state.profileBasics[i - 1]
        console.log('raw profile basic: ', item.name)
        if ((!this.state.semiPrivateItems) || (this.state.semiPrivateItems && !this.state.semiPrivateItems.includes(item.name)) || (this.state.semiPrivateItems && this.state.semiPrivateItems.includes(item.name) && this.state.paired)) {
          console.log('filtered profile basic: ', item.name)
          rows.push(
            <div key={"profileBasics" + index}>
              <div className={(counter < 2) ? "" : "top-margin-20"}>

                <div className={(counter % 2 === 1) ? "container-right" : "container-left"}>
                  <p className="description-text-3 bold-text description-text-color">{item.name}</p>
                  <p>{item.value}</p>
                </div>
                {(counter % 2 === 1) && (
                  <div className="clear" />
                )}
              </div>
            </div>
          )

          counter = counter + 1
        }
      }

      return rows

      // {this.state.profileBasics.map((item, index) =>
      //   <div key={"profileBasics" + index}>
      //     {((!this.state.semiPrivateItems) || (this.state.semiPrivateItems && !this.state.semiPrivateItems.includes(item.name)) || (this.state.semiPrivateItems && this.state.semiPrivateItems.includes(item.name) && this.testActiveFriendship(this.state.friends))) && (
      //       <div className={(index < 2) ? "" : "top-margin-20"}>
      //         <div className={(index % 2 === 1) ? "container-right" : "container-left"}>
      //           <p className="description-text-3 bold-text description-text-color">{item.name}</p>
      //           <p>{item.value}</p>
      //         </div>
      //         {(index % 2 === 1) && (
      //           <div className="clear" />
      //         )}
      //       </div>
      //     )}
      //   </div>
      // )}
    }


    render() {

      return (
        <div>
          <div>
            {(this.state.profileData) && (
              <div>
                <div className="white-background width-90-percent rounded-corners max-width-1400 medium-shadow top-margin-2-percent center-horizontally padding-20">
                  <div className="fixed-column-100 top-padding-5">
                    <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, enlargeImage: true, selectedImage:this.state.profileData.pictureURL })}>
                      <img src={(this.state.profileData.pictureURL) ? this.state.profileData.pictureURL : defaultProfileImage} alt="GC" className="profile-thumbnail-6"/>
                    </button>
                  </div>

                  <div className="calc-column-offset-160">
                    <div>
                      <div className="float-left right-padding-15">
                        <p className="heading-text-2">{this.state.profileData.firstName} {this.state.profileData.lastName}</p>
                      </div>
                      {(this.testActiveFriendship(this.state.friends)) && (
                        <div className="float-left top-padding">
                          <button className="background-button" onClick={() => this.setState({ modalIsOpen: true, showFriendOptions: true })}>
                            <img src={profileIconDark} alt="GC" className="image-25-auto float-left" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="You two are connected" />
                            <img src={checkmarkIcon} alt="GC" className="image-auto-12 float-left" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content="You two are connected" />
                            <div className="clear" />
                          </button>
                        </div>
                      )}
                      <div className="clear" />
                    </div>

                    <ReactTooltip id="tooltip-placeholder-id" />

                    <div className="description-text-1 top-margin-5">
                      {(this.state.profileData.roleName === 'Student' || this.state.profileData.roleName === 'Career-Seeker') ? (
                        <div>
                          {(this.state.profileData.jobTitle && this.state.profileData.jobTitle !== '' && this.state.profileData.jobTitle !== 'Student' && this.state.profileData.employerName) && (
                            <label>{this.state.profileData.jobTitle} @ {this.state.profileData.employerName} | </label>
                          )}
                          <label>{this.state.profileData.school} {(this.state.profileData.gradYear) ? "'" + this.state.profileData.gradYear.substring(2,4) : 'Student'}</label>
                        </div>
                      ) : (
                        <div>
                          {(this.state.profileData.jobTitle && this.state.profileData.employerName) && (
                            <p>{this.state.profileData.jobTitle} @ {this.state.profileData.employerName}</p>
                          )}
                        </div>
                      )}
                    </div>

                    {(this.state.profileData.headline) && (
                      <div className="row-5">
                        <p className="description-text-1">{this.state.profileData.headline}</p>
                      </div>
                    )}
                  </div>

                  <div className="fixed-column-60">
                    {(window.location.pathname.includes('/app/profile') && this.state.matchScore > 0) && (
                      <div>
                        <div key="match" className="width-60 top-margin" data-tooltip-id="tooltip-placeholder-id" data-tooltip-content={this.state.matchScore + "% similar to your career profile"}>
                          <CircularProgressbar
                            value={this.state.matchScore}
                            text={`${this.state.matchScore}%`}
                            styles={{
                              path: { stroke: `${getComputedStyle(document.documentElement).getPropertyValue('--primaryColor')}, ${this.state.matchScore / 100}` },
                              text: { fill: getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'), fontSize: '24px' },
                              trail: { stroke: 'transparent' }
                            }}
                          />
                        </div>
                        <ReactTooltip id="tooltip-placeholder-id" />
                      </div>
                    )}
                    {(this.state.publicProfile && !window.location.pathname.includes('/app/profile')) && (
                      <div className="fixed-column-60">
                        {(this.state.orgs && this.state.orgs.length > 0) && (
                          <div>
                            {this.state.orgs.map((value, index) =>
                              <div>
                                {(index < 2) && (
                                  <div>
                                    {(value.webLogoURIColor) && (
                                      <div className="float-left right-margin bottom-margin">
                                        <a href={value.orgURL} target="_blank" className="background-button">
                                          <img src={value.webLogoURIColor} alt="GC" className="image-60-auto" />
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                )}
                                <div className="clear" />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="clear" />

                  {(!this.state.employerAccount) && (
                    <div>
                      {((this.state.publicProfile || this.state.viewableProfile) || !window.location.pathname.includes('/app/profile') || this.state.friends.some(friend => (friend.friend1Email === this.state.profileData.email) || friend.friend2Email === this.state.profileData.email)) ? (
                        <div className="top-padding float-left">

                          {(this.state.publicProfile || this.state.viewableProfile) && (
                            <div>

                              <div className="float-left row-5">
                                <div className="profile-links">
                                  <ul>
                                    {(this.state.resumePublicPreference === 'Yes' && this.state.publicResume) && <li><a target="_blank" href={this.state.publicResume}><img src={resumeIconDark} alt="Compass resume icon" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5">RES</p>}</a></li> }
                                    {(this.state.profileData.customWebsiteURL && this.state.profileData.customWebsiteURL.includes('http')) && <li><a target="_blank" href={this.state.profileData.customWebsiteURL}><img src={websiteIconDark} alt="Compass website icon" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5">PORT</p>}</a></li>}
                                    {(this.state.profileData.linkedInURL && this.state.profileData.linkedInURL.includes('http')) && <li><a target="_blank" href={this.state.profileData.linkedInURL}><img src={linkedinIconDark} alt="GC" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5">LNK</p>}</a></li>}
                                    {(this.state.profileData.facebookURL && this.state.profileData.facebookURL.includes('http')) && <li><a target="_blank" href={this.state.profileData.facebookURL}><img src={facebookIconDark} alt="GC" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5">FB</p>}</a></li>}
                                    {(this.state.profileData.instagramURL && this.state.profileData.instagramURL.includes('http')) && <li><a target="_blank" href={this.state.profileData.instagramURL}><img src={instagramIconDark} alt="GC" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5">INS</p>}</a></li>}
                                    {(this.state.profileData.twitterURL && this.state.profileData.twitterURL.includes('http')) && <li><a target="_blank" href={this.state.profileData.twitterURL}><img src={twitterIconDark} alt="GC" className="image-auto-30"/>{(this.state.includeIconLabels) && <p className="description-text-5">TWT</p>}</a></li>}
                                  </ul>
                                </div>
                              </div>
                              <div className="clear" />
                            </div>
                          )}

                          {(this.state.emailId === this.state.profileData.email) ? (
                            <div>

                            </div>
                          ) : (
                            <div>
                              {(this.testActiveFriendship(this.state.friends)) ? (
                                <div>
                                  <div className="float-left right-padding">
                                    {(window.location.pathname.includes('/app/profile')) ? (
                                      <Link to={'/app/messaging'} state={{ recipient: this.state.profileData }} className="background-button">
                                        <button className="btn btn-squarish">
                                          <div>
                                            <div className="float-left right-padding top-padding-5">
                                              <img src={messageIconWhite} alt="GC" className="image-auto-15" />
                                            </div>
                                            <div className="float-left">
                                              <p>Message Me</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </button>
                                      </Link>
                                    ) : (
                                      <button className="btn btn-squarish" onClick={() => this.setState({ modalIsOpen: true, showProjectDetail: false, showAssessmentDetail: false, showEndorsementDetail: false, showMessageWidget: true }) }>
                                        <div>
                                          <div className="float-left right-padding top-padding-5">
                                            <img src={messageIconWhite} alt="GC" className="image-auto-15" />
                                          </div>
                                          <div className="float-left">
                                            <p>Message Me</p>
                                          </div>
                                          <div className="clear" />
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                  {(window.location.pathname.startsWith('/app/profile') || window.location.pathname.startsWith('/app/mentors')) ? (
                                    <div className="float-left">

                                    </div>
                                  ) : (
                                    <div className="float-left">
                                      <div className="float-left right-padding">
                                        <button className="btn btn-squarish-white" onClick={() => this.setState({ modalIsOpen: true, showReferSomeone: true }) }>
                                          <div>
                                            <div className="float-left right-padding top-padding-5">
                                              <img src={hireIconBlue} alt="GC" className="image-auto-15" />
                                            </div>
                                            <div className="float-left">
                                              <p>Refer Me</p>
                                            </div>
                                            <div className="clear" />
                                          </div>
                                        </button>
                                      </div>

                                      <div className="float-left">
                                        <Link to={'/endorse'} state={{ recipient: this.state.profileData }} className="background-button float-left">
                                          <button className="btn btn-squarish senary-background">
                                            <div>
                                              <div className="float-left right-padding top-padding-5">
                                                <img src={endorsementIconDark} alt="GC" className="image-auto-15" />
                                              </div>
                                              <div className="float-left">
                                                <p className="standard-color">Endorse Me</p>
                                              </div>
                                              <div className="clear" />
                                            </div>
                                          </button>
                                        </Link>
                                      </div>
                                    </div>
                                  )}

                                  <div className="clear" />
                                </div>
                              ) : (
                                <div>
                                  {(this.state.friends && this.state.friends.some(friend => friend.friend1Email === this.state.profileData.email || friend.friend2Email === this.state.profileData.email)) ? (
                                    <div>
                                      {(this.state.friends.some(friend => ((friend.friend1Email === this.state.emailId || friend.friend2Email === this.state.emailId) && friend.requesterEmail === this.state.profileData.email && friend.activeRequest))) ? (
                                        <button className="btn btn-profile description-text-3 right-margin-5" onClick={() => this.acceptRequest(this.state.friends, this.state.profileData)}>Accept</button>
                                      ) : (
                                        <button className="btn btn-profile description-text-3 right-margin-5 medium-background clear-border no-pointers" disabled={true}>Pending</button>
                                      )}
                                    </div>
                                  ) : (
                                    <div>
                                      {(this.state.emailId) ? (
                                        <button className="btn btn-profile description-text-3 right-margin-5" disabled={(this.state.isSaving) ? true : false} onClick={() => this.followPerson(this.state.profileData)}>Connect</button>
                                      ) : (
                                        <div>
                                          <div className="float-left right-padding">
                                            <button className="btn btn-squarish" onClick={() => this.setState({ modalIsOpen: true, showProjectDetail: false, showAssessmentDetail: false, showEndorsementDetail: false, showMessageWidget: true }) }>
                                              <div>
                                                <div className="float-left right-padding top-padding-5">
                                                  <img src={messageIconWhite} alt="GC" className="image-auto-15" />
                                                </div>
                                                <div className="float-left">
                                                  <p>Message Me</p>
                                                </div>
                                                <div className="clear" />
                                              </div>
                                            </button>
                                          </div>
                                          <div className="float-left right-padding">
                                            <button className="btn btn-squarish-white"onClick={() => this.setState({ modalIsOpen: true, showReferSomeone: true }) }>
                                              <div>
                                                <div className="float-left right-padding top-padding-5">
                                                  <img src={hireIconBlue} alt="GC" className="image-auto-15" />
                                                </div>
                                                <div className="float-left">
                                                  <p>Refer Me</p>
                                                </div>
                                                <div className="clear" />
                                              </div>
                                            </button>
                                          </div>
                                          {(this.props.pageSource === 'landingPage') && (
                                            <div className="float-left">
                                              <Link to={'/endorse'} state={{ recipient: this.state.profileData }} className="background-button float-left">
                                                <button className="btn btn-squarish senary-background">
                                                  <div>
                                                    <div className="float-left right-padding top-padding-5">
                                                      <img src={endorsementIconDark} alt="GC" className="image-auto-15" />
                                                    </div>
                                                    <div className="float-left">
                                                      <p className="standard-color">Endorse Me</p>
                                                    </div>
                                                    <div className="clear" />
                                                  </div>
                                                </button>
                                              </Link>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                        </div>
                      ) : (
                        <div className="top-padding">
                          {console.log('hmmm?', this.state.profileData.email)}
                          {(this.state.friends && this.state.friends.some(friend => friend.friend1Email === this.state.profileData.email || friend.friend2Email === this.state.profileData.email)) ? (
                            <div>
                              {(this.state.friends.some(friend => ((friend.friend1Email === this.state.emailId || friend.friend2Email === this.state.emailId) && friend.requesterEmail === this.state.profileData.email && friend.activeRequest))) ? (
                                <button className="btn btn-profile description-text-3 right-margin-5" onClick={() => this.acceptRequest(this.state.friends, this.state.profileData)}>Accept</button>
                              ) : (
                                <button className="btn btn-profile description-text-3 right-margin-5 medium-background clear-border no-pointers" disabled={true}>Pending</button>
                              )}
                            </div>
                          ) : (
                            <button className="btn btn-profile description-text-3 right-margin-5" disabled={(this.state.isSaving) ? true : false} onClick={() => this.followPerson(this.state.profileData)}>Connect</button>
                          )}

                          <div className="clear" />
                        </div>
                      )}
                    </div>
                  )}

                  <div className="clear" />

                </div>

                {(!this.state.viewableProfile) ? (
                  <div className="full-width">
                    <div className="super-spacer" />
                    <p className="heading-text-3 full-width center-text error-color">This profile is {(this.state.underaged) ? "restricted because (s)he is under 18" : "private"}</p>
                  </div>
                ) : (
                  <div className="full-width">

                    {(this.state.errorMessage && this.state.errorMessage !== '') && <p className="error-color center-horizontally width-90-percent max-width-1400">{this.state.errorMessage}</p>}
                    {(this.state.successMessage && this.state.successMessage !== '') && <p className="cta-color center-horizontally width-90-percent max-width-1400">{this.state.successMessage}</p>}

                    {(!window.location.pathname.includes('/projects') && !window.location.pathname.includes('/endorsements')) && (
                      <div className="carousel-2 medium-shadow" onScroll={this.handleScroll}>
                        {this.state.profileOptions.map((value, index) =>
                          <div className="display-inline">
                            {(index === this.state.viewIndex) ? (
                              <div key={value} className="selected-carousel-item-2 row-15">
                                <button key={value} className="background-button no-pointers" disabled={true} onClick={() => this.setState({ viewIndex: index })}>
                                  <label className="heading-text-6">{value}</label>
                                </button>

                              </div>
                            ) : (
                              <button key={value} className="menu-button row-15" onClick={() => this.setState({ viewIndex: index })}>
                                <label className="heading-text-6">{value}</label>
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {(!window.location.pathname.includes('/projects') && !window.location.pathname.includes('/endorsements')) && (
                      <div>
                        {(this.state.profileOptions[this.state.viewIndex] === 'All' || this.state.profileOptions[this.state.viewIndex] === 'About') && (
                          <div className="bottom-margin-20 center-horizontally width-90-percent max-width-1400" >

                            {(this.state.postPublicPreference === 'None' && this.state.profileOptions[this.state.viewIndex] === 'About') ? (
                              <div>
                                <p className="error-color">About information for this profile have been set to private</p>
                              </div>
                            ) : (
                              <div>
                                {(!this.state.hideAboutForSomeReason) && (
                                  <div>
                                    <div className="bottom-margin-20">
                                      <p className="heading-text-2">About</p>
                                    </div>

                                    <div className="card">
                                      {(this.state.profileBasics) && (
                                        <div>
                                          {this.renderProfileBasics()}
                                          <div className="clear" />
                                        </div>
                                      )}

                                      {(this.state.profileData.education && this.state.profileData.education.length > 0) && (
                                        <div className="top-margin-20">
                                          {this.state.profileData.education.map((item, index) =>
                                            <div key={"education" + index} className="top-margin-20">
                                              <div className={(index % 2 === 1) ? "container-right" : "container-left"}>
                                                <p className="description-text-3 bold-text description-text-color">Education #{index + 1}</p>
                                                <p>{item.name}</p>
                                                <p className="top-margin-5 description-text-2 bold-text">{item.degree}{(item.major) && " - " + item.major + " Major"}</p>
                                                <p className="top-margin-5 description-text-2">{(item.startDate) && item.startDate}{(item.isContinual) ? "Still Attending" : " - " + item.endDate}{(item.location) && " in " + item.location}</p>
                                                {(item.summary) && (
                                                  <p className="description-text-2 top-margin-5">{item.summary}</p>
                                                )}
                                              </div>
                                              {(index % 2 === 1) && (
                                                <div className="clear" />
                                              )}
                                            </div>
                                          )}
                                          <div className="clear" />
                                        </div>
                                      )}

                                      {(this.state.profileData.certificates && this.state.profileData.certificates.length > 0) && (
                                        <div className="top-margin-20">
                                          {this.state.profileData.certificates.map((item, index) =>
                                            <div key={"certificates" + index} className="top-margin-20">
                                              <div className={(index % 2 === 1) ? "container-right" : "container-left"}>
                                                <p className="description-text-3 bold-text description-text-color">{(this.state.degreeType) ? this.state.degreeType : "Certificate"} #{index + 1}</p>
                                                <p>{item.name}</p>
                                                {(item.schoolURL && item.schoolName) && (
                                                  <a className="top-margin-5 description-text-2 bold-text" href={item.schoolURL} target="_blank" rel="noopener noreferrer">{item.schoolName}</a>
                                                )}
                                                {(item.estimatedHours) && (
                                                  <p className="top-margin-5 description-text-2">{item.estimatedHours} Hours{(this.state.isCompleted === true) ? " | Completed" : " | Incomplete"}</p>
                                                )}
                                                {(item.dateIssued) && (
                                                  <p className="top-margin-5 description-text-2">{item.dateIssued}</p>
                                                )}
                                                {(item.description) && (
                                                  <p className="description-text-2 top-margin-5">{item.description}</p>
                                                )}
                                              </div>

                                              {(index % 2 === 1) && (
                                                <div className="clear" />
                                              )}
                                            </div>
                                          )}
                                          <div className="clear" />
                                        </div>
                                      )}

                                      {(this.state.experience && this.state.experience.length > 0) && (
                                        <div className="top-margin-20">
                                          {this.state.experience.map((item, index) =>
                                            <div key={"experience" + index} className="top-margin-20">
                                              <div className={(index % 2 === 1) ? "container-right" : "container-left"}>
                                                <p className="description-text-3 bold-text description-text-color">Experience #{index + 1}</p>
                                                <p>{item.jobTitle}</p>
                                                <p className="top-margin-5 description-text-2 bold-text">{item.employerName}</p>
                                                <p className="top-margin-5 description-text-2">{item.startDate} - {item.endDate} | {item.location}</p>

                                                {(item.hoursPerWeek) && (
                                                  <p className="top-margin-5 description-text-2">{item.hoursPerWeek}</p>
                                                )}
                                                {(item.jobFunction && item.industry) && (
                                                  <p className="top-margin-5 description-text-2">{item.jobFunction} | {item.industry}</p>
                                                )}
                                                {(item.description) && (
                                                  <p className="description-text-2 top-margin-5">{item.description}</p>
                                                )}
                                              </div>

                                              {(index % 2 === 1) && (
                                                <div className="clear" />
                                              )}
                                            </div>
                                          )}
                                          <div className="clear" />
                                        </div>
                                      )}

                                      {(this.state.extracurriculars && this.state.extracurriculars.length > 0) && (
                                        <div className="top-margin-20">
                                          {this.state.extracurriculars.map((item, index) =>
                                            <div key={"extracurricular" + index} className="top-margin-20">
                                              <div className={(index % 2 === 1) ? "container-right" : "container-left"}>
                                                <p className="description-text-3 bold-text description-text-color">Extracurricular #{index + 1}</p>
                                                <p>{item.activityName}</p>
                                                <p className="top-margin-5 description-text-2 bold-text">{item.roleName}</p>
                                                <p className="top-margin-5 description-text-2">{item.startDate} - {item.endDate} | {item.location}</p>

                                                {(item.hoursPerWeek) && (
                                                  <p className="top-margin-5 description-text-2">{item.hoursPerWeek}</p>
                                                )}
                                                {(item.description) && (
                                                  <p className="description-text-2 top-margin-5">{item.description}</p>
                                                )}
                                              </div>

                                              {(index % 2 === 1) && (
                                                <div className="clear" />
                                              )}
                                            </div>
                                          )}
                                          <div className="clear" />
                                        </div>
                                      )}

                                      {(this.state.awards && this.state.awards.length > 0) && (
                                        <div className="top-margin-20">
                                          {this.state.awards.map((item, index) =>
                                            <div key={"award" + index} className="top-margin-20">
                                              <div className={(index % 2 === 1) ? "container-right" : "container-left"}>
                                                <p className="description-text-3 bold-text description-text-color">Award #{index + 1}</p>
                                                <p>{item.name}</p>
                                                <p className="top-margin-5 description-text-2">{item.awardDate}</p>
                                                {(item.description) && (
                                                  <p className="description-text-2 top-margin-5">{item.description}</p>
                                                )}
                                              </div>

                                              {(index % 2 === 1) && (
                                                <div className="clear" />
                                              )}
                                            </div>
                                          )}
                                          <div className="clear" />
                                        </div>
                                      )}
                                    </div>

                                  </div>
                                )}
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}

                    {(!window.location.pathname.includes('/projects') && !window.location.pathname.includes('/endorsements')) && (
                      <div>
                        {(this.state.profileOptions[this.state.viewIndex] === 'All' || this.state.profileOptions[this.state.viewIndex] === 'Posts') && (
                          <div className="bottom-margin-20 center-horizontally width-90-percent max-width-1400" >

                            {(this.state.postPublicPreference === 'None' && this.state.profileOptions[this.state.viewIndex] === 'Posts') ? (
                              <div>
                                <p className="error-color">Posts for this profile have been set to private</p>
                              </div>
                            ) : (
                              <div>
                                {(this.state.posts && this.state.posts.length > 0) ? (
                                  <div>
                                    <div className="bottom-margin-20">
                                      <p className="heading-text-2">Posts</p>
                                    </div>

                                    <SubRenderPosts posts={this.state.posts} limit={(this.state.profileOptions[this.state.viewIndex] === 'All') && 3} pageSource="externalProfile" />
                                  </div>
                                ) : (
                                  <div>
                                    {(this.state.profileOptions[this.state.viewIndex] === 'Posts') && (
                                      <div>
                                        <div className="card">
                                          <img className="image-auto-50 center-horizontally" alt="GC" src={newsFeedIconDark} />
                                          <div className="spacer" /><div className="spacer" />

                                          <p className="center-text">Either posts are set to private or no posts have been added yet</p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}

                    {(!window.location.pathname.includes('/endorsements')) && (
                      <div>
                        {(this.state.profileOptions[this.state.viewIndex] === 'All' || this.state.profileOptions[this.state.viewIndex] === 'Projects') && (
                          <div className="bottom-margin-20 center-horizontally width-90-percent max-width-1400" >

                            {(this.state.projectPublicPreference === 'None' && this.state.profileOptions[this.state.viewIndex] === 'Projects') ? (
                              <div>
                                <p className="error-color">Projects for this profile have been set to private</p>
                              </div>
                            ) : (
                              <div>

                                {(this.state.projects && this.state.projects.length > 0) ? (
                                  <div>
                                    <div className="bottom-margin-20">
                                      <p className="heading-text-2">Projects</p>
                                    </div>

                                    <SubRenderProjects
                                      favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                      projects={this.state.projects} pageSource={this.props.pageSource}
                                      filterCriteriaArray={this.state.filterCriteriaArray}
                                      sortCriteriaArray={this.state.sortCriteriaArray}
                                      jobTitle={this.state.jobTitle} employerName={this.state.employerName}
                                      org={this.state.activeOrg} accountCode={this.state.accountCode}
                                      gradeOptions={this.state.gradeOptions} orgContactEmail={this.state.orgContactEmail}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    {(this.state.profileOptions[this.state.viewIndex] === 'Projects') && (
                                      <div className="card">
                                        <img className="image-auto-50 center-horizontally" alt="GC" src={projectsIconDark} />
                                        <div className="spacer" /><div className="spacer" />

                                        <p className="center-text">Either projects are set to private or no projects have been added yet</p>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}

                    {(!window.location.pathname.includes('/projects') && !window.location.pathname.includes('/endorsements')) && (
                      <div>
                        {(this.state.profileOptions[this.state.viewIndex] === 'All' || this.state.profileOptions[this.state.viewIndex] === 'Goals') && (
                          <div className="bottom-margin-20 center-horizontally width-90-percent max-width-1400">

                            {(this.state.goalPublicPreference === 'None') ? (
                              <div>
                                <p className="error-color">Goals for this profile have been set to private</p>
                              </div>
                            ) : (
                              <div>

                                {(this.state.goals && this.state.goals.length > 0) ? (
                                  <div>
                                    <div className="bottom-margin-20">
                                      <p className="heading-text-2">Goals</p>
                                    </div>

                                    <SubRenderGoals history={this.props.navigate} filteredGoals={this.state.goals}
                                      profileData={this.state.profileData} activeOrg={this.state.activeOrg}
                                      favorites={this.state.favorites} newFavorites={this.state.newFavorites}
                                      sortCriteriaArray={this.state.sortCriteriaArray} filterCriteriaArray={this.state.filterCriteriaArray}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    {(this.state.profileOptions[this.state.viewIndex] === 'Goals') && (
                                      <div className="card">
                                        <img className="image-auto-50 center-horizontally" alt="GC" src={targetIconDark} />
                                        <div className="spacer" /><div className="spacer" />

                                        <p className="center-text">Either goals are set to private or no goals have been added yet</p>
                                      </div>
                                    )}
                                  </div>
                                )}

                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}

                    {(!window.location.pathname.includes('/projects') && !window.location.pathname.includes('/endorsements')) && (
                      <div>
                        {(this.state.profileOptions[this.state.viewIndex] === 'All' || this.state.profileOptions[this.state.viewIndex] === 'Passions') && (
                          <div className="bottom-margin-20 center-horizontally width-90-percent max-width-1400">

                            {(this.state.passionPublicPreference === 'None' && this.state.profileOptions[this.state.viewIndex] === 'Passions') ? (
                              <div>
                                <p className="error-color">Passions for this profile have been set to private</p>
                              </div>
                            ) : (
                              <div>

                                {(this.state.passions && this.state.passions.length > 0) ? (
                                  <div>
                                    <div className="bottom-margin-20">
                                      <p className="heading-text-2">Passions</p>
                                    </div>

                                    {this.state.passions.map((value, index) =>
                                      <div key={"passion" + index}>
                                        {(window.innerWidth < 768) ? (
                                          <div className="standard-border rounded-corners bottom-margin-20 white-background medium-shadow">
                                            <button className="background-button full-space left-text padding-30" type="button" onClick={() => this.setState({ modalIsOpen: true, showPassionDetail: true, selectedPassion: value }) }>
                                              <div className="fixed-column-50 height-50">
                                                <div className="mini-spacer" /><div className="mini-spacer" />
                                                <img src={passionIconDark} className="image-auto-40" alt="GC" />
                                              </div>
                                              <div className="calc-column-offset-50">
                                                <p className="heading-text-4 curtail-text">{value.passionTitle}</p>
                                                <p className="description-text-1 description-text-color curtail-text">Created: {convertDateToString(new Date(value.createdAt),'datetime-2')}</p>

                                              </div>
                                              <div className="clear" />

                                              {(value.passionReason) && (
                                                <p className="description-text-1 description-text-color curtail-text top-margin">{value.passionReason}</p>
                                              )}
                                            </button>
                                          </div>
                                        ) : (
                                          <div>
                                            <div className="relative-column-30 standard-border rounded-corners bottom-margin-20 right-margin white-background medium-shadow">
                                              <button className="background-button left-text padding-30 full-space" type="button" onClick={() => this.setState({ modalIsOpen: true, showPassionDetail: true, selectedPassion: value }) }>
                                                <div className="fixed-column-50 height-50">
                                                  <div className="mini-spacer" /><div className="mini-spacer" />
                                                  <img src={passionIconDark} className="image-auto-40" alt="GC" />
                                                </div>
                                                <div className="calc-column-offset-50">
                                                  <p className="heading-text-4 curtail-text">{value.passionTitle}</p>
                                                  <p className="description-text-1 description-text-color curtail-text">Created: {convertDateToString(new Date(value.createdAt),'datetime-2')}</p>

                                                </div>
                                                <div className="clear" />

                                                {(value.passionReason) && (
                                                  <p className="description-text-1 description-text-color curtail-text top-margin">{value.passionReason}</p>
                                                )}
                                              </button>

                                            </div>
                                            {(index % 3 === 2) && (
                                              <div className="clear" />
                                            )}
                                          </div>
                                        )}

                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {(this.state.profileOptions[this.state.viewIndex] === 'Passions') && (
                                      <div className="card">
                                        <img className="image-auto-50 center-horizontally" alt="GC" src={passionIconDark} />
                                        <div className="spacer" /><div className="spacer" />

                                        <p className="center-text">Either passions are set to private or no passions have been added yet</p>
                                      </div>
                                    )}
                                  </div>
                                )}

                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}

                    {(!window.location.pathname.includes('/projects') && !window.location.pathname.includes('/endorsements')) && (
                      <div>
                        {(this.state.profileOptions[this.state.viewIndex] === 'All' || this.state.profileOptions[this.state.viewIndex] === 'Assessments') && (
                          <div className="bottom-margin-20 center-horizontally width-90-percent max-width-1400">

                            {(this.state.assessmentPublicPreference === 'None' && this.state.profileOptions[this.state.viewIndex] === 'Assessments') ? (
                              <div>
                                <p className="error-color">Career assessments for this profile have been set to private</p>
                              </div>
                            ) : (
                              <div>
                                {(this.state.assessments && this.state.assessments.length > 0) ? (
                                  <div>
                                    <div className="bottom-margin-20">
                                      <p className="heading-text-2">Assessments</p>
                                    </div>

                                    {this.state.assessments.map((value, index) =>
                                      <div key={index}>
                                        {(window.innerWidth < 768) ? (
                                          <div className="standard-border rounded-corners bottom-margin-20 white-background medium-shadow">
                                            <button className="background-button full-space padding-30 left-text" type="button" onClick={() => this.setState({ modalIsOpen: true, showProjectDetail: false, showAssessmentDetail: true, showEndorsementDetail: false, selectedIndex: index, showMessageWidget: false }) }>
                                              <div className="fixed-column-50 height-50">
                                                <div className="mini-spacer" /><div className="mini-spacer" />
                                                  {(value.category === 'Work Preferences') && (
                                                    <img src={softwareDeveloperIcon} className="image-auto-40" alt="GC" />
                                                  )}
                                                  {(value.category === 'Interests') && (
                                                    <img src={interestsIconDark} className="image-auto-40" alt="GC" />
                                                  )}
                                                  {(value.category === 'Skills') && (
                                                    <img src={skillsIconDark} className="image-auto-40" alt="GC" />
                                                  )}
                                                  {(value.category === 'Personality') && (
                                                    <img src={abilitiesIconDark} className="image-auto-40" alt="GC" />
                                                  )}
                                                  {(value.category === 'Values') && (
                                                    <img src={socialIconDark} className="image-auto-40" alt="GC" />
                                                  )}
                                              </div>
                                              <div className="calc-column-offset-50">
                                                <p className="heading-text-4 curtail-text">{value.category}</p>
                                                <p className="description-text-1 description-text-color curtail-text">Updated: {value.updatedAt}</p>

                                              </div>
                                              <div className="clear" />

                                              {(value.description) && (
                                                <p className="description-text-1 top-margin">{value.description}</p>
                                              )}
                                            </button>
                                          </div>
                                        ) : (
                                          <div>
                                            <div className="relative-column-30 standard-border rounded-corners bottom-margin-20 right-margin white-background medium-shadow">
                                              <button className="background-button left-text padding-30 full-space" type="button" onClick={() => this.setState({ modalIsOpen: true, showProjectDetail: false, showAssessmentDetail: true, showEndorsementDetail: false, selectedIndex: index, showMessageWidget: false }) }>
                                                <div className="fixed-column-50 height-50">
                                                  <div className="mini-spacer" /><div className="mini-spacer" />
                                                    {(value.category === 'Work Preferences') && (
                                                      <img src={softwareDeveloperIcon} className="image-auto-40" alt="GC" />
                                                    )}
                                                    {(value.category === 'Interests') && (
                                                      <img src={interestsIconDark} className="image-auto-40" alt="GC" />
                                                    )}
                                                    {(value.category === 'Skills') && (
                                                      <img src={skillsIconDark} className="image-auto-40" alt="GC" />
                                                    )}
                                                    {(value.category === 'Personality') && (
                                                      <img src={abilitiesIconDark} className="image-auto-40" alt="GC" />
                                                    )}
                                                    {(value.category === 'Values') && (
                                                      <img src={socialIconDark} className="image-auto-40" alt="GC" />
                                                    )}
                                                </div>
                                                <div className="calc-column-offset-50">
                                                  <p className="heading-text-4 curtail-text">{value.category}</p>
                                                  <p className="description-text-1 description-text-color curtail-text">Updated: {value.updatedAt}</p>

                                                </div>
                                                <div className="clear" />

                                                {(value.description) && (
                                                  <p className="description-text-1 top-margin">{value.description}</p>
                                                )}
                                              </button>

                                            </div>

                                            {(index % 3 === 2) && (
                                              <div className="clear" />
                                            )}
                                          </div>
                                        )}

                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {(this.state.profileOptions[this.state.viewIndex] === 'Assessments') && (
                                      <div className="card">
                                        <img className="image-auto-50 center-horizontally" alt="GC" src={skillsIconDark} />
                                        <div className="spacer" /><div className="spacer" />

                                        <p className="center-text">Either career assessments are set to private or no career assessments have been added yet</p>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}
                    {(!window.location.pathname.includes('/projects')) && (
                      <div>
                        {(this.state.profileOptions[this.state.viewIndex] === 'All' || this.state.profileOptions[this.state.viewIndex] === 'Endorsements') && (
                          <div className="bottom-margin-20 center-horizontally width-90-percent max-width-1400">

                            {(this.state.endorsementPublicPreference === 'None' && this.state.profileOptions[this.state.viewIndex] === 'Endorsements') ? (
                              <div>
                                <p className="error-color">Endorsements for this profile have been set to private</p>
                              </div>
                            ) : (
                              <div>
                                {(this.state.endorsements && this.state.endorsements.length > 0) ? (
                                  <div>
                                    <div className="bottom-margin-20">
                                      <p className="heading-text-2">Endorsements</p>
                                    </div>

                                    {this.state.endorsements.map((value, index) =>
                                      <div key={index}>
                                        {(window.innerWidth < 768) ? (
                                          <div className="standard-border rounded-corners bottom-margin-20 white-background medium-shadow">
                                            <button className="background-button full-space padding-30" type="button" onClick={() => this.setState({ modalIsOpen: true, showProjectDetail: false, showAssessmentDetail: false, showEndorsementDetail: true, selectedIndex: index, showMessageWidget: false }) }>
                                              <div className="fixed-column-50 height-50">
                                                <div className="mini-spacer" /><div className="mini-spacer" />
                                                  <img src={endorsementIconDark} className="image-auto-40" alt="GC" />
                                              </div>
                                              <div className="calc-column-offset-50">
                                                <p className="heading-text-4 curtail-text">{value.senderFirstName} {value.senderLastName}</p>
                                                <p className="description-text-1 description-text-color curtail-text">Create: {convertDateToString(new Date(value.createdAt),'datetime-2')}</p>

                                              </div>
                                              <div className="clear" />

                                              {(value.category) && (
                                                <p className="description-text-1 top-margin">{value.category}</p>
                                              )}

                                              {(value.isTransparent) ? (
                                                <div>
                                                  {(value.overallRating) && (
                                                    <div className="row-10">
                                                      <p className="description-text-2 row-3"><label className="cta-color bold-text">{value.overallRating} / 100</label> Overall Rating</p>
                                                    </div>
                                                  )}

                                                  {(value.pathway && value.pathway !== '' && value.pathway !== 'Custom') && (
                                                    <div className="row-10">
                                                      <p className="description-text-2">{value.pathway} Pathway</p>
                                                    </div>
                                                  )}
                                                </div>
                                              ) : (
                                                <div>
                                                  <p className="description-text-2">This endorsement has been marked confidential</p>
                                                </div>
                                              )}

                                              <div className="row-10">
                                                <p className="description-text-2"><label className="cta-color bold-text">{value.skillTraits.length}</label> Skills and <label className="cta-color bold-text">{value.examples.length}</label> Examples</p>
                                              </div>
                                            </button>
                                          </div>
                                        ) : (
                                          <div>
                                            <div className="relative-column-30 standard-border rounded-corners bottom-margin-20 right-margin white-background medium-shadow">
                                              <button className="background-button full-space padding-30 left-text" type="button" onClick={() => this.setState({ modalIsOpen: true, showProjectDetail: false, showAssessmentDetail: false, showEndorsementDetail: true, selectedIndex: index, showMessageWidget: false }) }>
                                                <div className="fixed-column-50 height-50">
                                                  <div className="mini-spacer" /><div className="mini-spacer" />
                                                    <img src={endorsementIconDark} className="image-auto-40" alt="GC" />
                                                </div>
                                                <div className="calc-column-offset-50">
                                                  <p className="heading-text-4 curtail-text">{value.senderFirstName} {value.senderLastName}</p>
                                                  <p className="description-text-1 description-text-color curtail-text">Create: {convertDateToString(new Date(value.createdAt),'datetime-2')}</p>

                                                </div>
                                                <div className="clear" />

                                                {(value.category) && (
                                                  <p className="description-text-1 top-margin">{value.category}</p>
                                                )}

                                                {(value.isTransparent) ? (
                                                  <div>
                                                    {(value.overallRating) && (
                                                      <div className="row-10">
                                                        <p className="description-text-2 row-3"><label className="cta-color bold-text">{value.overallRating} / 100</label> Overall Rating</p>
                                                      </div>
                                                    )}

                                                    {(value.pathway && value.pathway !== '' && value.pathway !== 'Custom') && (
                                                      <div className="row-10">
                                                        <p className="description-text-2">{value.pathway} Pathway</p>
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <p className="description-text-2">This endorsement has been marked confidential</p>
                                                  </div>
                                                )}

                                                <div className="row-10">
                                                  <p className="description-text-2"><label className="cta-color bold-text">{value.skillTraits.length}</label> Skills and <label className="cta-color bold-text">{value.examples.length}</label> Examples</p>
                                                </div>
                                              </button>

                                            </div>
                                            {(index % 3 === 2) && (
                                              <div className="clear" />
                                            )}
                                          </div>
                                        )}

                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div>

                                    {(this.state.profileOptions[this.state.viewIndex] === 'Endorsements') && (
                                      <div className="card">
                                        <img className="image-auto-50 center-horizontally" alt="GC" src={endorsementIconDark} />
                                        <div className="spacer" /><div className="spacer" />

                                        <p className="center-text">Either competency endorsements are set to private or no competency endorsements were received yet</p>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="clear" />
                          </div>
                        )}
                      </div>
                    )}

                    {(this.state.showProjectDetail) && (
                      <div>
                        <ProjectDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedProject={this.state.projects[this.state.selectedIndex]} orgCode={this.state.activeOrg} />
                      </div>
                    )}

                    {(this.state.showAssessmentDetail) && (
                      <div>
                        <AssessmentResultsDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedAssessment={this.state.assessments[this.state.selectedIndex]} />
                      </div>
                    )}

                    {(this.state.showEndorsementDetail) && (
                      <div>
                        <EndorsementDetails closeModal={this.closeModal} modalIsOpen={this.state.modalIsOpen} selectedEndorsement={(this.state.selectedEndorsement) ? this.state.selectedEndorsement : this.state.endorsements[this.state.selectedIndex]} />
                      </div>
                    )}

                    {(this.state.showGoalDetails || this.state.showHelpOutWidget) && (
                      <div>
                        <SubGoalDetails history={this.props.navigate} selectedGoal={this.state.selectedGoal} modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} showGoalDetails={this.state.showGoalDetails} showHelpOutWidget={this.state.showHelpOutWidget} profileData={this.state.profileData}/>
                     </div>
                    )}

                    {(this.state.showMessageWidget) && (
                      <Modal
                       isOpen={this.state.modalIsOpen}
                       onAfterOpen={this.afterOpenModal}
                       onRequestClose={this.closeModal}
                       className="modal"
                       overlayClassName="modal-overlay"
                       contentLabel="Example Modal"
                       ariaHideApp={false}
                     >
                       <div key="showServiceDefinitions" className="full-width padding-20">
                          <SubSendMessage profileData={this.state.profileData} history={this.props.navigate} closeModal={this.closeModal}/>
                        </div>

                     </Modal>
                    )}

                    {(this.state.showComments) && (
                      <Modal
                       isOpen={this.state.modalIsOpen}
                       onAfterOpen={this.afterOpenModal}
                       onRequestClose={this.closeModal}
                       className="modal"
                       overlayClassName="modal-overlay"
                       contentLabel="Example Modal"
                       ariaHideApp={false}
                     >
                       <div key="showPost" className="full-width">

                        {this.renderPost(this.state.posts[this.state.selectedIndex], this.state.selectedIndex, true)}

                        <div className="spacer" />

                        {(this.state.posts && this.state.activeOrg) && (
                          <SubComments selectedGroup={null} selectedGroupPost={this.state.posts[this.state.selectedIndex]} activeOrg={this.state.activeOrg} accountCode={this.state.accountCode} comments={this.state.comments} postingOrgCode={this.state.activeOrg} postingOrgName={this.state.orgName} orgContactEmail={this.state.orgContactEmail} pictureURL={this.state.pictureURL} orgLogo={this.state.orgLogo} history={this.props.navigate} pageSource={"newsFeed"} />
                        )}

                       </div>

                     </Modal>
                    )}

                    {(this.state.showShareButtons) && (
                      <Modal
                       isOpen={this.state.modalIsOpen}
                       onAfterOpen={this.afterOpenModal}
                       onRequestClose={this.closeModal}
                       className="modal"
                       overlayClassName="modal-overlay"
                       contentLabel="Example Modal"
                       ariaHideApp={false}
                     >
                       <div key="showShareButtons" className="full-width padding-20 center-text">
                          <p className="heading-text-2">Share This Post with Friends!</p>

                          <div className="top-padding-20">
                            <p>Share this link:</p>
                            <label className="bold-text cta-color">{window.location.protocol + "//" + window.location.host + "/app/social-posts/" + this.state.posts[this.state.selectedIndex]._id}</label>
                          </div>

                          <div className="spacer" />

                          <div className="top-padding-20">
                            {this.renderShareButtons()}
                          </div>

                        </div>

                     </Modal>
                    )}

                    {(this.state.showPassionDetail) && (
                      <Modal
                       isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal}
                       className="modal" overlayClassName="modal-overlay" contentLabel="Example Modal" ariaHideApp={false}
                     >
                       <div key="showPassionDetail" className="full-width padding-20 center-text">
                          <p className="heading-text-2">{this.state.selectedPassion.passionTitle}</p>

                        </div>

                     </Modal>
                    )}

                  </div>
                )}

                {(this.state.showReferSomeone) && (
                  <Modal
                   isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal}
                   className="modal" overlayClassName="modal-overlay" contentLabel="Example Modal" ariaHideApp={false}
                 >
                   <div key="showPassionDetail" className="full-width padding-20">
                     <SubReferSomeone history={this.props.navigate} selectedOpportunity={this.state.selectedOpportunity}
                       referralBonus={this.state.referralBonus} pageSource={this.state.pageSource} linkedInURL={this.state.linkedInURL}
                       jobTitle={this.state.jobTitle} employerName={this.state.employerName}
                       benchmark={null} relationshipOptions={this.state.relationshipOptions}
                       orgCode={this.state.activeOrg} orgName={null}
                       orgProgramName={null} orgContactEmail={null} placementPartners={this.state.placementPartners}
                       closeModal={this.closeModal}
                     />
                    </div>

                 </Modal>
                )}

                {(this.state.enlargeImage) && (
                  <Modal
                   isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal}
                   className="modal" overlayClassName="modal-overlay" contentLabel="Example Modal" ariaHideApp={false}
                 >
                   <div key="showPassionDetail" className="full-width padding-20">
                     <img className="full-space" alt="" src={(this.state.selectedImage) ? this.state.selectedImage : defaultProfileImage} />

                     <div className="row-10 center-text">
                      <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                     </div>
                    </div>

                 </Modal>
                )}

                {(this.state.showFriendOptions) && (
                  <Modal
                   isOpen={this.state.modalIsOpen} onAfterOpen={this.afterOpenModal} onRequestClose={this.closeModal}
                   className="modal" overlayClassName="modal-overlay" contentLabel="Example Modal" ariaHideApp={false}
                 >
                   <div key="showPassionDetail" className="full-width padding-20">
                     <p className="heading-text-2">You and {this.state.profileData.firstName} {this.state.profileData.lastName} are Connected</p>

                     <div className="spacer" /><div className="spacer" />


                     <div className="row-10 center-text">
                      <button className="btn btn-secondary" onClick={() => this.closeModal()}>Close View</button>
                     </div>
                    </div>

                 </Modal>
                )}

              </div>
            )}
          </div>

        </div>
      )
    }
}

export default withRouter(ExternalProfile);
