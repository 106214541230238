import React, {Component} from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import SubEditProfileDetails from './EditProfileDetails';
import SubAssessments from './Assessments';
import SubEndorsements from './Endorsements';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import StrictModeDroppable from '../Vendors/StrictModeDroppable';
import {signUp, signIn, signOut } from '../Services/AuthRoutes';
import withRouter from '../Functions/WithRouter';

const covidVaccineIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/covid-vaccine-icon.png';
const checkmarkIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/checkmark-icon.png';
const xIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/x-icon.png';
const arrowIndicatorIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/arrow-indicator-icon.png';
const profileIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/profile-icon-dark.png';
const resumeIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/resume-icon-dark.png';
const coverLetterIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/cover-letter-icon-dark.png';
const transcriptIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/transcript-icon.png';
const letterOfRecIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/letter-of-rec-icon.png';
const identificationIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/identification-icon.png';
const opportunitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/opportunities-icon-dark.png';
const abilitiesIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/abilities-icon-dark.png';
const endorsementIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/endorsement-icon-dark.png';
const assessmentsIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/assessments-icon-dark.png';
const referIconDark = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/refer-icon-dark.png';
const closeIcon = 'https://guidedcompass-bucket.s3.us-west-2.amazonaws.com/appImages/close-icon.png';

const reorder = (list, startIndex, endIndex) => {

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  console.log('show me result', result)


  return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 0 0`,

  // change background colour if dragging
  background: isDragging ? getComputedStyle(document.documentElement).getPropertyValue('--primaryColor') : 'white',
  border: '1px solid ' + getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),
  color: isDragging ? 'white' : getComputedStyle(document.documentElement).getPropertyValue('--primaryColor'),

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#F5F5F5' : 'transparent',
  padding: grid,
  width: null,
});

class Apply extends Component {
    constructor(props) {
        super(props)

        this.state = {
          showDoc: true,
          questionIndex: 0,
          responses: [],
          tpaResponses: [],
          dbResponses: [],
          rankingAnswerChoices: [],

          allowMultipleFiles: true,

          endorsements: [],
          projects: [],
          experience: [],
          extras: [],
          skills: [],
          requiredBasicValues: ['firstName','lastName'],
          requiredCareerAssessments: ['interests','skills'],

          isEditingProjectsArray: []
        }

        this.retrieveData = this.retrieveData.bind(this)
        this.formChangeHandler = this.formChangeHandler.bind(this)
        this.saveFile = this.saveFile.bind(this)
        this.actionTapped = this.actionTapped.bind(this)
        this.apply = this.apply.bind(this)
        this.renderTasks = this.renderTasks.bind(this)

        this.optionClicked = this.optionClicked.bind(this)
        this.switchWorkspaces = this.switchWorkspaces.bind(this)

        this.signUpUser = this.signUpUser.bind(this)
        this.signInUser = this.signInUser.bind(this)
        this.signOutUser = this.signOutUser.bind(this)

        this.renderQuestions = this.renderQuestions.bind(this)
        this.renderAnswerChoices = this.renderAnswerChoices.bind(this)
        this.renderRankingChoices = this.renderRankingChoices.bind(this)
        this.onDragEnd = this.onDragEnd.bind(this)
        this.expandSection = this.expandSection.bind(this)
        this.renderExpandedTask = this.renderExpandedTask.bind(this)
        this.renderDemoQuestions = this.renderDemoQuestions.bind(this)

        this.passData = this.passData.bind(this)

        this.checkSectionCompleteness = this.checkSectionCompleteness.bind(this)

    }

    componentDidMount() {
      document.body.style.backgroundColor = "rgba(178,186,191,0.2)";
    }

    componentDidUpdate(prevProps) {
      console.log('componentDidUpdate called ', this.props.activeOrg, prevProps)

      if (this.props.activeOrg !== prevProps.activeOrg || this.props.accountCode !== prevProps.accountCode || this.props.roleName !== prevProps.roleName) {
        this.retrieveData()
      } else if (this.props.selectedPosting && !prevProps.selectedPosting) {
        this.retrieveData()
      } else if (this.props.passedTasks && !prevProps.passedTasks) {
        this.retrieveData()
      } else if (this.props.customAssessmentResponses && !prevProps.customAssessmentResponses) {
        this.retrieveData()
      } else if (this.props.caQuestions && !prevProps.caQuestions) {
        this.retrieveData()
      } else if (this.props.application && !prevProps.application) {
        // this.retrieveData()
      }
    }

    retrieveData() {
      console.log('retrieveData called within sub apply')

      let selectedPosting = this.props.selectedPosting
      let passedTasks = this.props.passedTasks
      let customAssessmentResponses = this.props.customAssessmentResponses
      let caQuestions = this.props.caQuestions
      // let application = this.props.application
      const pageSource = this.props.pageSource
      let referralCode = this.props.referralCode

      //obtain email from localStorage
      let email = localStorage.getItem('email');
      let cuFirstName = localStorage.getItem('firstName');
      let cuLastName = localStorage.getItem('lastName');
      let username = localStorage.getItem('username');
      let activeOrg = localStorage.getItem('activeOrg');
      let orgFocus = localStorage.getItem('orgFocus');
      let remoteAuth = localStorage.getItem('remoteAuth');

      if (!activeOrg) {
        activeOrg = this.props.activeOrg
      }

      let tasks = []
      let customAssessmentIndex = null

      if (passedTasks) {
        tasks = passedTasks
        this.checkCompleteness(tasks)
      } else {

        if (selectedPosting) {

          let caIsRequired = false
          if (selectedPosting.appComponents && selectedPosting.appComponents.length > 0) {
            tasks = []

            for (let i = 1; i <= selectedPosting.appComponents.length; i++) {
              console.log('show component: ', selectedPosting.appComponents[i - 1])
              if (selectedPosting.appComponents[i - 1].name === 'Basic Profile Info' && selectedPosting.appComponents[i - 1].include) {
                tasks.push({ shorthand: 'basicInfo', name: 'Confirm Basic Profile Info', icon: profileIconDark, action: 'Import', message: '', isCompleted: false, required: selectedPosting.appComponents[i - 1].required })
              }
              if (selectedPosting.appComponents[i - 1].name === 'Profile Details' && selectedPosting.appComponents[i - 1].include) {
                tasks.push({ shorthand: 'profileDetails', name: 'Confirm Profile Details', icon: opportunitiesIconDark, action: 'Import', message: '', isCompleted: false, required: selectedPosting.appComponents[i - 1].required })
              }
              if (selectedPosting.appComponents[i - 1].name === 'Resume' && selectedPosting.appComponents[i - 1].include) {
                tasks.push({ shorthand: 'resume', name: 'Upload Resume', icon: resumeIconDark, action: 'Upload', message: '', isCompleted: false, required: selectedPosting.appComponents[i - 1].required })
              }
              if (selectedPosting.appComponents[i - 1].name === 'Cover Letter' && selectedPosting.appComponents[i - 1].include) {
                tasks.push({ shorthand: 'coverLetter', name: "Upload Cover Letter", icon: coverLetterIconDark, action: 'Upload', message: '', isCompleted: false, required: selectedPosting.appComponents[i - 1].required })
              }
              if (selectedPosting.appComponents[i - 1].name === 'Academic Transcript' && selectedPosting.appComponents[i - 1].include) {
                tasks.push({ shorthand: 'transcript', name: "Upload Transcript", icon: transcriptIcon, action: 'Upload', message: '', isCompleted: false, required: selectedPosting.appComponents[i - 1].required})
              }
              if (selectedPosting.appComponents[i - 1].name === 'Letter of Recommendation' && selectedPosting.appComponents[i - 1].include) {
                tasks.push({ shorthand: 'letterOfRecommendation', name: "Upload Letter of Recommendation", icon: letterOfRecIcon, action: 'Upload', message: '', isCompleted: false, required: selectedPosting.appComponents[i - 1].required })
              }
              if (selectedPosting.appComponents[i - 1].name === 'Identification' && selectedPosting.appComponents[i - 1].include) {
                tasks.push({ shorthand: 'identification', name: "Upload Driver's License or Student ID", icon: identificationIcon, action: 'Upload', message: '', isCompleted: false, required: false})
              }

              // if (selectedPosting.appComponents[i - 1].name === 'Portfolio Link' && selectedPosting.appComponents[i - 1].include) {
              //   tasks.push({ shorthand: 'portfolio', name: 'Add Portfolio Website URL', icon: opportunitiesIconDark, action: 'Import', message: '', isCompleted: false, required: selectedPosting.appComponents[i - 1].required })
              // }
              if (selectedPosting.appComponents[i - 1].name === 'Career Assessments' && selectedPosting.appComponents[i - 1].include) {
                tasks.push({ shorthand: 'careerAssessments', name: 'Take Career Assessments', icon: abilitiesIconDark, message: '', action: 'Import', isCompleted: false, required: selectedPosting.appComponents[i - 1].required })
              }

              if (selectedPosting.appComponents[i - 1].name === 'Endorsements' && selectedPosting.appComponents[i - 1].include) {
                tasks.push({ shorthand: 'endorsements', name: 'Confirm Endorsements', icon: endorsementIconDark, action: 'Import', message: 'Endorsements imported, but you can always request more!', isCompleted: true, required: selectedPosting.appComponents[i - 1].required })
              }

              if (selectedPosting.appComponents[i - 1].name === 'Vaccinations' && selectedPosting.appComponents[i - 1].include) {
                tasks.push({ shorthand: 'vaccinations', name: 'Confirm COVID-19 Vaccination Status', icon: covidVaccineIcon, action: 'Import', message: '', isCompleted: false, required: selectedPosting.appComponents[i - 1].required })
              }

              if (selectedPosting.appComponents[i - 1].name === 'Referral Information' && selectedPosting.appComponents[i - 1].include) {
                tasks.push({ shorthand: 'referrals', name: 'Add Information About Who Referred You', icon: referIconDark, action: 'Import', message: '', isCompleted: false, required: selectedPosting.appComponents[i - 1].required })
              }

              if (selectedPosting.appComponents[i - 1].name === 'Custom Assessment' && selectedPosting.appComponents[i - 1].include) {
                // taken care of later
                customAssessmentIndex = i - 1
                caIsRequired = selectedPosting.appComponents[i - 1].required
              }
            }
          // } else if (selectedPosting.useAppComponents) {
          //   tasks = []
          //   // console.log('show appComponents: ', selectedPosting.applicationComponents)
          //   if (selectedPosting.applicationComponents && selectedPosting.applicationComponents.includes('basicInfo')) {
          //     console.log('yo')
          //     tasks.push({ shorthand: 'basicInfo', name: 'Import Basic Info from Profile', icon: profileIconDark, action: 'Import', message: '', isCompleted: false, required: true })
          //   }
          } else {
            // tasks.push({ shorthand: 'basicInfo', name: 'Import Basic Info from Profile', icon: profileIconDark, action: 'Import', message: '', isCompleted: false, required: true })
            //
            // if (activeOrg === 'exp' && selectedPosting.postType === 'Scholarship') {
            //   // skip
            // } else {
            //   tasks.push({ shorthand: 'resume', name: 'Upload Resume', icon: resumeIconDark, action: 'Upload', message: '', isCompleted: false, required: true })
            // }
            //
            // let applicationComponents = ['portfolioLink', 'projects','coverLetter','careerAssessments','customAssessment']
            //
            // if (activeOrg === 'exp' && selectedPosting.postType === 'Internship') {
            //   tasks.push({ shorthand: 'coverLetter', name: "Upload Cover Letter", icon: coverLetterIconDark, action: 'Upload', message: '', isCompleted: false, required: true })
            // }
            //
            // // if (activeOrg !== 'c2c' && activeOrg !== 'exp') {
            // //   if (applicationComponents.includes('portfolioLink')) {
            // //     tasks.push({ shorthand: 'portfolio', name: 'Add Portfolio Website URL', icon: opportunitiesIconDark, action: 'Import', message: '', isCompleted: false, required: false })
            // //   }
            // // }
            //
            // if (activeOrg !== 'exp') {
            //   if (applicationComponents.includes('projects')) {
            //     tasks.push({ shorthand: 'profileDetails', name: 'Confirm Profile Details', icon: opportunitiesIconDark, action: 'Import', message: '', isCompleted: true, required: true })
            //   }
            //
            //   if (applicationComponents.includes('careerAssessments')) {
            //     if (activeOrg === 'exp') {
            //       tasks.push({ shorthand: 'careerAssessments', name: 'Take Career Assessments', icon: abilitiesIconDark, message: '', action: 'Import', isCompleted: false, required: false })
            //     } else {
            //       tasks.push({ shorthand: 'careerAssessments', name: 'Take Career Assessments', icon: abilitiesIconDark, message: '', action: 'Import', isCompleted: false, required: true })
            //     }
            //   }
            //
            //   tasks.push({ shorthand: 'endorsements', name: 'Confirm Endorsements', icon: endorsementIconDark, action: 'Import', message: '', isCompleted: false, required: false })
            // }
            //
            // if (activeOrg === 'exp' && selectedPosting.postType === 'Scholarship') {
            //   tasks.push({ shorthand: 'transcript', name: "Upload Transcript", icon: transcriptIcon, action: 'Upload', message: '', isCompleted: false, required: false})
            //   tasks.push({ shorthand: 'letterOfRecommendation', name: "Upload Letter of Recommendation", icon: letterOfRecIcon, action: 'Upload', message: '', isCompleted: false, required: false })
            //   // tasks.push({ shorthand: 'identification', name: "Upload Driver's License or Student ID", action: 'Upload', message: '', isCompleted: false, required: false})
            // }
          }

          console.log("c1", selectedPosting.appComponents, customAssessmentIndex, selectedPosting.customAssessmentId)
          if ((selectedPosting.appComponents && customAssessmentIndex && selectedPosting.appComponents[customAssessmentIndex].include) && selectedPosting.customAssessmentId && selectedPosting.customAssessmentId !== '') {
            console.log("c2")
            let actionTitle = "Start"
            let isCompleted = false
            let message = ''
            // if (application) {
            //   if (application.customAssessmentResults) {
            //     actionTitle = "Edit"
            //     isCompleted = true
            //     message = 'Application form completed!'
            //   } else if (application.newCustomAssessmentResults) {
            //     actionTitle = "Edit"
            //     isCompleted = true
            //     message = 'Application form completed!'
            //   }
            // }
            // console.log('is completed? ', application.newCustomAssessmentResults, customAssessmentResponses)

            tasks.push({ shorthand: 'customAssessment', name: 'Complete Application Form', icon: assessmentsIconDark, action: actionTitle, message, isCompleted, required: caIsRequired })
          }
        }
      }

      let linkPrefix = window.location.protocol + "//" + window.location.host
      if (window.location.host.startsWith('localhost')) {
        linkPrefix = 'https://www.guidedcompass.com'
      }

      const profilePath = linkPrefix + "/app/profile/" + username

      let signedIn = false
      if (email) {
        signedIn = true
      }

      let responses = []
      if (customAssessmentResponses) {
        responses = customAssessmentResponses
      }

      let tpaResponses = []
      let dbResponses = []

      let showReferralCode = false
      if (referralCode) {
        showReferralCode = true
      }

      this.setState({ activeOrg, emailId: email, cuFirstName, cuLastName, username, orgFocus, selectedPosting, tasks, profilePath,
        customAssessmentResponses, responses, caQuestions, pageSource, signedIn,
        tpaResponses, dbResponses, referralCode, showReferralCode, remoteAuth
      })

      if (selectedPosting && selectedPosting.isPromotional && !email && selectedPosting.orgCode === 'unite-la') {
        Axios.get('/api/customassessments/byid', { params: { _id: '6201b330358ba6fb1c6292af' } })
        .then((response) => {
          console.log('Query custom assessment', response.data);

            if (response.data.success) {
              console.log('successfully retrieved custom assessment')

              const customAssessment = response.data.assessment
              const questions = customAssessment.questions

              this.setState({ customAssessment, questions })

            } else {
              console.log('no assessment data found', response.data.message)
            }

        }).catch((error) => {
            console.log('Assessment query did not work', error);
        });
      }

      // Axios.get('/api/workoptions')
      // .then((response) => {
      //   console.log('Work options query tried', response.data);
      //
      //   if (response.data.success) {
      //     console.log('Work options query succeeded')
      //
      //     let genderOptions = response.data.workOptions[0].genderOptions
      //     genderOptions.unshift('')
      //     let raceOptions = response.data.workOptions[0].raceOptions
      //     raceOptions.unshift('')
      //     const basicCountOptions = ['','1','2','3','4','5','6','7','8','9','10']
      //     let lowIncomeOptions = response.data.workOptions[0].lowIncomeOptions
      //     lowIncomeOptions.unshift('')
      //     let fosterYouthOptions = response.data.workOptions[0].fosterYouthOptions
      //     fosterYouthOptions.unshift('')
      //     let homelessOptions = response.data.workOptions[0].homelessOptions
      //     homelessOptions.unshift('')
      //     let incarceratedOptions = response.data.workOptions[0].incarceratedOptions
      //     incarceratedOptions.unshift('')
      //
      //     const year = (new Date().getFullYear() - 18).toString()
      //     let month = ''
      //     if ((new Date().getMonth() + 1) >= 10) {
      //       month = (new Date().getMonth() + 1).toString()
      //     } else {
      //       month = '0' + (new Date().getMonth() + 1).toString()
      //     }
      //
      //     const day = new Date().getDate().toString()
      //     const defaultDate = year + '-' + month + '-' + day
      //
      //     let questions = [
      //       { category: 'basic', name: 'Date of Birth', type: 'Date', field: 'dateOfBirth', placeholder: 'e.g. 04/13/98', answer: defaultDate },
      //       { category: 'basic', name: 'Gender', type: 'Multiple Choice', field: 'gender', options: genderOptions },
      //       { category: 'basic', name: 'Race', type: 'Multiple Choice', field: 'race', options: raceOptions },
      //       { category: 'basic', name: 'Home Address', type: 'Short Answer', field: 'homeAddress', placeholder: 'e.g. 111 Candy Cane Lane Los Angeles, CA' },
      //       { category: 'basic', name: 'Phone Number', type: 'Short Answer', field: 'phoneNumber', placeholder: 'e.g. (323) 299-2991' },
      //       { category: 'diversity', name: 'Number of Members in Household', type: 'Multiple Choice', field: 'numberOfMembers', options: basicCountOptions },
      //       { category: 'diversity', name: 'Estimated Household Income', type: 'Multiple Choice', field: 'householdIncome', options: lowIncomeOptions },
      //       { category: 'diversity', name: 'Have you ever been a foster youth?', type: 'Multiple Choice', field: 'fosterYouth', options: fosterYouthOptions },
      //       { category: 'diversity', name: 'Are you currently or formerly homeless?', type: 'Multiple Choice', field: 'homeless', options: homelessOptions },
      //       { category: 'diversity', name: 'Were you previously incarcerated?', type: 'Multiple Choice', field: 'incarcerated', options: incarceratedOptions },
      //       { category: 'diversity', name: 'Designate all that apply.', type: 'Multiple Answer', field: 'adversity', options: ['LGBQIA','ADA','First Generation Immigrant','First Generation College Student','Low Income','None'] },
      //       { category: 'referral', name: 'Name of person who recommended you', type: 'Short Answer', field: 'referrerName', placeholder: 'e.g. John Smith' },
      //       { category: 'referral', name: 'Email of person who recommended you', type: 'Short Answer', field: 'referrerEmail', placeholder: 'e.g. johnsmith@love.org' },
      //       { category: 'referral', name: 'Name of organization that referred you', type: 'Short Answer', field: 'referrerOrg', options: [], placeholder: 'e.g. Franklin High' },
      //     ]
      //
      //     this.setState({ questions })
      //
      //   } else {
      //     console.log('no work options data found', response.data.message)
      //
      //   }
      // }).catch((error) => {
      //     console.log('query for work options did not work', error);
      // })

      if (selectedPosting) {
        // postingOrgCode, postingOrgName, postingOrgContactEmail,
        if (selectedPosting.orgCode) {
          Axios.get('/api/org', { params: { orgCode: selectedPosting.orgCode } })
          .then((response) => {
            console.log('Org info query attempted', response.data);

              if (response.data.success) {
                console.log('org info query worked for post')

                const postingOrgCode = response.data.orgInfo.orgCode
                const postingOrgName = response.data.orgInfo.orgName
                const postingOrgContactEmail = response.data.orgInfo.contactEmail
                const placementPartners = response.data.orgInfo.placementPartners
                const requirePersonalInfo = response.data.orgInfo.requirePersonalInfo
                const signUpFieldsRequired = response.data.orgInfo.signUpFieldsRequired
                const disableUploadingResume = response.data.orgInfo.disableUploadingResume

                this.setState({ postingOrgCode, postingOrgName, postingOrgContactEmail,
                  placementPartners, requirePersonalInfo, signUpFieldsRequired, disableUploadingResume
                });

              } else {
                console.log('org info query did not work', response.data.message)
              }

          }).catch((error) => {
              console.log('Org info query did not work for some reason', error);
          });
        }

        // console.log('selectedPosting?----------------', selectedPosting.title, selectedPosting.customAssessmentId)
        if (selectedPosting.customAssessmentId && selectedPosting.customAssessmentId !== '') {
          // console.log('t2: ', selectedPosting.title, selectedPosting.customAssessmentId)
          Axios.get('/api/customassessments/byid', { params: { _id: selectedPosting.customAssessmentId } })
          .then((response) => {
            console.log('Query custom assessment called in subapply', response.data);

              if (response.data.success) {
                console.log('successfully retrieved custom assessment')

                const customAssessment = response.data.assessment
                const isCustomAssessment = true
                if (!caQuestions && customAssessment) {
                  caQuestions = []
                  for (let i = 1; i <= customAssessment.questions.length; i++) {
                    if (customAssessment.questions[i - 1]) {
                      caQuestions.push(customAssessment.questions[i - 1])
                    }
                  }
                }
                // console.log('log caQuestions: ', caQuestions)

                this.setState({ isCustomAssessment, customAssessment, caQuestions })

              } else {
                console.log('no assessment data found', response.data.message)
              }

          }).catch((error) => {
              console.log('Assessment query did not work', error);
          });
        }
      }

      if (email) {

        const self = this

        function pullExperience() {
          console.log('pullExperience called')

          Axios.get('/api/experience', { params: { emailId: email } })
          .then((response) => {
            console.log('Experience query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved experience')

                if (response.data.experience) {

                  const experience = response.data.experience
                  if (experience.length > 0) {
                    console.log('the array is greater than 0')

                    const isExperience = true

                    tasks = self.state.tasks
                    for (let i = 1; i <= tasks.length; i++) {
                      if (isExperience && tasks[i - 1].shorthand === 'profileDetails') {
                        tasks[i - 1]['message'] = 'Imported projects, experience, extracurriculars, and awards!'
                        tasks[i - 1]['isCompleted'] = true
                      }
                    }
                    self.setState({
                      experience, isExperience
                    }, () => {
                      self.checkCompleteness(tasks)
                    })
                  }
                }

              } else {
                console.log('no experience data found', response.data.message)
              }

          }).catch((error) => {
              console.log('Experience query did not work', error);
          });
        }

        function pullProjects() {
          console.log('pullProjects called')

          Axios.get('/api/projects', { params: { emailId: email } })
          .then((response) => {
            console.log('Projects query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved projects')

                if (response.data.projects) {

                  const projects = response.data.projects
                  if (projects.length > 0) {
                    console.log('the array is greater than 0')

                    const isProjects = true

                    tasks = self.state.tasks
                    for (let i = 1; i <= tasks.length; i++) {
                      if (isProjects && tasks[i - 1].shorthand === 'profileDetails') {
                        tasks[i - 1]['message'] = 'Imported projects, experience, extracurriculars, and awards!'
                        tasks[i - 1]['isCompleted'] = true
                      }
                    }
                    self.setState({
                      projects, isProjects
                    }, () => {
                      self.checkCompleteness(tasks)
                    })
                  }
                }

                pullExperience()

              } else {
                console.log('no project data found', response.data.message)
                pullExperience()
              }

          }).catch((error) => {
              console.log('Project query did not work', error);
          });
        }

        function pullEndorsements() {
          console.log('pullEndorsements called')

          Axios.get('/api/story', { params: { emailId: email } })
          .then((response) => {
            console.log('Endorsements query attempted', response.data);

              if (response.data.success) {
                console.log('successfully retrieved endorsements')

                if (response.data.stories.length > 0) {
                  console.log('the array is greater than 0')

                  let isEndorsements = false
                  let endorsements = []
                  for (let i = 1; i <= response.data.stories.length; i++) {
                    if (response.data.stories[i - 1].recipientEmail === email) {
                      isEndorsements = true
                      endorsements.push(response.data.stories[i - 1])
                    }
                  }

                  tasks = self.state.tasks
                  for (let i = 1; i <= tasks.length; i++) {
                    if (isEndorsements && tasks[i - 1].shorthand === 'endorsements') {
                      tasks[i - 1]['message'] = 'Imported endorsements!'
                      tasks[i - 1]['isCompleted'] = true
                    }
                  }
                  self.setState({
                    isEndorsements, endorsements
                  }, () => {
                    self.checkCompleteness(tasks)
                  })
                }

                pullProjects()

              } else {
                console.log('no endorsements data found', response.data.message)
                pullProjects()
              }

          }).catch((error) => {
              console.log('Endorsements query did not work', error);
          });
        }

        function pullAssessmentResults() {
          console.log('pullAssessmentResults called')

          Axios.get('/api/assessment/results', { params: { emailId: email } })
          .then((response) => {
              console.log('query for assessment results worked');

              if (response.data.success) {

                console.log('actual assessment results', response.data)

                let wpData = null
                let isWPData = false
                if (response.data.results.workPreferenceAnswers) {
                  if (response.data.results.workPreferenceAnswers.length !== 0) {
                    wpData = response.data.results.workPreferenceAnswers
                    isWPData = true
                  }
                }

                let isInterestsData = false
                let interestResults = null
                if (response.data.results.interestScores) {
                  if (response.data.results.interestScores.length !== 0) {
                    isInterestsData = true
                    interestResults = response.data.results.interestScores
                  }
                }

                let isSkillsData = false
                let skillResults = null
                let skillAnswers = ''
                let newSkillAnswers = []
                if (response.data.results.skillsScores) {

                  if (response.data.results.skillsScores.length !== 0) {
                    isSkillsData = true
                    skillResults = response.data.results.skillsScores
                    skillAnswers = response.data.results.skillsAnswers
                    newSkillAnswers = response.data.results.newSkillAnswers
                  }
                }

                let isPersonalityData = false
                let personalityResults = null
                if (response.data.results.personalityScores) {
                  isPersonalityData = true
                  personalityResults = response.data.results.personalityScores
                }

                let isValuesData = false
                let topGravitateValues = []
                let topEmployerValues = []
                let valuesResults = null
                if (response.data.results.topGravitateValues && response.data.results.topEmployerValues) {
                  isValuesData = true
                  topGravitateValues = response.data.results.topGravitateValues
                  topEmployerValues = response.data.results.topEmployerValues
                  valuesResults = { topGravitateValues, topEmployerValues }
                }

                const assessments = {
                  workPreferences: wpData, interests: interestResults,
                  skills: newSkillAnswers, personality: personalityResults,
                  values: valuesResults
                }

                let requiredCareerAssessments = self.state.requiredCareerAssessments

                let ids = null
                if (selectedPosting.tracks) {
                  if (selectedPosting.tracks && selectedPosting.tracks.length > 0) {
                    console.log('track posting')
                    ids = []
                    for (let i = 1; i <= selectedPosting.tracks.length; i++) {
                      if (selectedPosting.tracks[i - 1] && selectedPosting.tracks[i - 1].benchmark) {
                        ids.push(selectedPosting.tracks[i - 1].benchmark._id)
                      }
                    }
                  }
                }

                Axios.get('/api/benchmarks/byid', { params: { _id: selectedPosting.benchmarkId, ids } })
                .then((response3) => {
                  console.log('Benchmark query attempted in subassessments', response3.data);

                  if (response3.data.success) {
                    console.log('benchmark query worked')

                    if (response3.data.benchmark && response3.data.benchmark.skills && response3.data.benchmark.skills.length > 0) {

                      const skillQuestions = response3.data.benchmark.skills
                      self.setState({ skillQuestions })

                      const isCareerAssessments = self.checkSectionCompleteness('assessment', assessments, requiredCareerAssessments, skillQuestions)

                      tasks = self.state.tasks
                      for (let i = 1; i <= tasks.length; i++) {
                        if (isCareerAssessments && tasks[i - 1].shorthand === 'careerAssessments') {
                          tasks[i - 1]['message'] = 'Career assessments were completed and imported!'
                          tasks[i - 1]['isCompleted'] = true
                        }
                      }

                      self.setState({ isWPData, isInterestsData, isSkillsData, isPersonalityData, isValuesData, resultsId: response.data.results._id,
                        wpData, interestResults, skillAnswers, newSkillAnswers, skillResults, personalityResults, isCareerAssessments,
                        valuesResults, topGravitateValues, topEmployerValues
                      }, () => {
                        self.checkCompleteness(tasks)
                      });

                      pullEndorsements()

                    } else if (response3.data.benchmarks) {
                      console.log('multiple benchmarks', response3.data.benchmarks)

                      let skillQuestions = []
                      let rawSkills = []
                      // let skillQuestions = []
                      for (let i = 1; i <= response3.data.benchmarks.length; i++) {
                        if (response3.data.benchmarks[i - 1] && response3.data.benchmarks[i - 1].skills) {

                          let tempSkills = response3.data.benchmarks[i - 1].skills
                          if (tempSkills && tempSkills.length > 0) {
                            // console.log('inners 2')
                            for (let j = 1; j <= tempSkills.length; j++) {
                              if (!rawSkills.includes(tempSkills[j - 1].title)) {
                               skillQuestions.push({
                                 name: tempSkills[j - 1].title, skillType: tempSkills[j - 1].skillType,
                                 benchmarkTitle: response3.data.benchmarks[i - 1].title, benchmarkFunction: response3.data.benchmarks[i - 1].jobFunction,
                                 score: tempSkills[j - 1].score, weight: tempSkills[j - 1].weight
                               })
                               rawSkills.push(tempSkills[j - 1].title)
                              } else {
                                console.log('not include', tempSkills[j - 1].title)
                              }
                            }
                          }
                        }
                      }

                      self.setState({ skillQuestions })

                      const isCareerAssessments = self.checkSectionCompleteness('assessment', assessments, requiredCareerAssessments, skillQuestions, true)
                      // console.log('is c: ', isCareerAssessments, assessments, requiredCareerAssessments, skillQuestions)

                      tasks = self.state.tasks
                      for (let i = 1; i <= tasks.length; i++) {
                        if (isCareerAssessments && tasks[i - 1].shorthand === 'careerAssessments') {
                          tasks[i - 1]['message'] = 'Career assessments were completed and imported!'
                          tasks[i - 1]['isCompleted'] = true
                        }
                      }

                      self.setState({ isWPData, isInterestsData, isSkillsData, isPersonalityData, isValuesData, resultsId: response.data.results._id,
                        wpData, interestResults, skillAnswers, newSkillAnswers, skillResults, personalityResults, isCareerAssessments,
                        valuesResults, topGravitateValues, topEmployerValues
                      }, () => {
                        self.checkCompleteness(tasks)
                      });

                      pullEndorsements()

                    }

                  } else {
                    console.log('benchmark query did not work', response3.data.message)

                    const isCareerAssessments = self.checkSectionCompleteness('assessment', assessments, requiredCareerAssessments)

                    tasks = self.state.tasks
                    for (let i = 1; i <= tasks.length; i++) {
                      if (isCareerAssessments && tasks[i - 1].shorthand === 'careerAssessments') {
                        tasks[i - 1]['message'] = 'Career assessments were completed and imported!'
                        tasks[i - 1]['isCompleted'] = true
                      }
                    }

                    self.setState({ isWPData, isInterestsData, isSkillsData, isPersonalityData, isValuesData, resultsId: response.data.results._id,
                      wpData, interestResults, skillAnswers, newSkillAnswers, skillResults, personalityResults, isCareerAssessments,
                      valuesResults, topGravitateValues, topEmployerValues
                    }, () => {
                      self.checkCompleteness(tasks)
                    });

                    pullEndorsements()
                  }

                }).catch((error) => {
                    console.log('Benchmark query did not work for some reason', error);

                    const isCareerAssessments = self.checkSectionCompleteness('assessment', assessments, requiredCareerAssessments)

                    tasks = self.state.tasks
                    for (let i = 1; i <= tasks.length; i++) {
                      if (isCareerAssessments && tasks[i - 1].shorthand === 'careerAssessments') {
                        tasks[i - 1]['message'] = 'Career assessments were completed and imported!'
                        tasks[i - 1]['isCompleted'] = true
                      }
                    }

                    self.setState({ isWPData, isInterestsData, isSkillsData, isPersonalityData, isValuesData, resultsId: response.data.results._id,
                      wpData, interestResults, skillAnswers, newSkillAnswers, skillResults, personalityResults, isCareerAssessments,
                      valuesResults, topGravitateValues, topEmployerValues
                    }, () => {
                      self.checkCompleteness(tasks)
                    });

                    pullEndorsements()
                });

              } else {
                console.log('error response for assessments', response.data)
                self.setState({ resultsErrorMessage: response.data.message })
                pullEndorsements()
              }

          }).catch((error) => {
              console.log('query for assessment results did not work', error);
          })
        }

        const fetchDetailsURL = '/api/users/profile/details/' + email
        Axios.get(fetchDetailsURL)
        .then((response) => {
          if (response.data) {
            console.log('successfully query user data!')

            let firstName = ''
            if (response.data.user.firstName) {
              firstName = response.data.user.firstName
            }

            let lastName = ''
            if (response.data.user.lastName) {
              lastName = response.data.user.lastName
            }

            let phoneNumber = ''
            if (response.data.user.phoneNumber) {
              phoneNumber = response.data.user.phoneNumber
            }

            const educationStatus = response.data.user.educationStatus

            let education = null
            if (response.data.user.education) {
              education = response.data.user.education
            }

            let certificates = null
            if (response.data.user.certificates) {
              certificates = response.data.user.certificates
            }

            let schoolName = ''
            if (response.data.user.school) {
              schoolName = response.data.user.school
            }

            let degree = ''
            if (response.data.user.degree) {
              degree = response.data.user.degree
            }

            let major = ''
            if (response.data.user.major) {
              major = response.data.user.major
            }

            let gradYear = ''
            if (response.data.user.gradYear) {
              gradYear = response.data.user.gradYear
            }

            let zipcode = ''
            if (response.data.user.zipcode) {
              zipcode = response.data.user.zipcode
            }

            let city = ''
            if (response.data.user.city) {
              city = response.data.user.city
            }

            const alternativeEmail = response.data.user.alternativeEmail
            const alternativePhoneNumber = response.data.user.alternativePhoneNumber

            const dateOfBirth = response.data.user.dateOfBirth
            const pathway = response.data.user.pathway
            const address = response.data.user.address
            const race = response.data.user.race
            const races = response.data.user.races
            const selfDescribedRace = response.data.user.selfDescribedRace
            const gender = response.data.user.gender
            const veteran = response.data.user.veteran
            const workAuthorization = response.data.user.workAuthorization
            const numberOfMembers = response.data.user.numberOfMembers
            const householdIncome = response.data.user.householdIncome
            const fosterYouth = response.data.user.fosterYouth
            const homeless = response.data.user.homeless
            const incarcerated = response.data.user.incarcerated
            const adversityList = response.data.user.adversityList

            let basicInfo = response.data.user
            // let basicInfo = { firstName, lastName, schoolName, gradYear }
            // if (education) {
            //   basicInfo = { firstName, lastName, schoolName, gradYear }
            // }

            let requiredBasicValues = this.state.requiredBasicValues

            const isBasicInfo = this.checkSectionCompleteness('basicInfo', basicInfo, requiredBasicValues)

            let isPoliticalInfo = false
            let politicalAlignment = ''
            let stateRegistration = ''
            let currentCongressionalDistrict = ''
            let hometown = ''
            let homeCongressionalDistrict = ''
            let dacaStatus = ''

            if (activeOrg === 'c2c') {
              if (response.data.user.politicalAlignment) {
                politicalAlignment = response.data.user.politicalAlignment
              }

              if (response.data.user.stateRegistration) {
                stateRegistration = response.data.user.stateRegistration
              }

              if (response.data.user.currentCongressionalDistrict) {
                currentCongressionalDistrict = response.data.user.currentCongressionalDistrict
              }

              if (response.data.user.hometown) {
                hometown = response.data.user.hometown
              }

              if (response.data.user.homeCongressionalDistrict) {
                homeCongressionalDistrict = response.data.user.homeCongressionalDistrict
              }

              if (response.data.user.dacaStatus) {
                dacaStatus = response.data.user.dacaStatus
              }

              if (politicalAlignment !== '' && stateRegistration !== '' && currentCongressionalDistrict !== '' && hometown !== ''&& homeCongressionalDistrict !== '' && dacaStatus !== '') {
                isPoliticalInfo = true
              }
            }

            let isResume = false
            let resumeURL = ''
            let resumeName = ''
            // let resumes  = response.data.user.resumes
            let resumes = []
            let resumeNames = []
            if ((response.data.user.resumeURL && response.data.user.resumeURL !== '') || (response.data.user.resumes && response.data.user.resumes.length > 0)) {
              isResume = true
            }
            console.log('yeah resume?', isResume)

            let isCoverLetter = false
            let coverLetterURL = ''
            if (response.data.user.coverLetterURL && response.data.user.coverLetterURL.split("%7C")[2]) {
              isCoverLetter = true
            }

            let isLOR = false
            let letterOfRecommendationURL = ''
            if (response.data.user.letterOfRecommendationURL && response.data.user.letterOfRecommendationURL.split("%7C")[2]) {
              isLOR = true
            }

            let isID = false
            let identificationURL = ''
            if (response.data.user.identificationURL && response.data.user.identificationURL.split("%7C")[2]) {
              isID = true
            }

            let isTranscript = false
            let transcriptURL = ''
            if (response.data.user.transcriptURL && response.data.user.transcriptURL.split("%7C")[2]) {
              isTranscript = true
            }

            let linkedInURL = ''
            if (response.data.user.linkedInURL) {
              linkedInURL = response.data.user.linkedInURL
            }

            let isPortfolio = false
            let customWebsiteURL = ''
            if (response.data.user.customWebsiteURL) {
              isPortfolio = true
              customWebsiteURL = response.data.user.customWebsiteURL
            }

            const videoResumeURL = response.data.user.videoResumeURL

            const pictureURL = response.data.user.pictureURL
            const isVaccinated = response.data.user.isVaccinated

            tasks = this.state.tasks
            for (let i = 1; i <= tasks.length; i++) {
              if (isBasicInfo && tasks[i - 1].shorthand === 'basicInfo') {
                tasks[i - 1]['message'] = 'Basic info completed!'
                tasks[i - 1]['isCompleted'] = true
              }
              if (isResume && tasks[i - 1].shorthand === 'resume') {

                resumeURL = response.data.user.resumeURL

                if (resumeURL.split("%7C")[2]) {
                  resumeName = resumeURL.split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
                } else {
                  resumeName = response.data.user.firstName + ' ' + response.data.user.lastName + ' Resume.pdf'
                }

                if (response.data.user.resumes && response.data.user.resumes.length > 0) {
                  for (let i = 1; i <= response.data.user.resumes.length; i++) {
                    if (response.data.user.resumes[i - 1].split("%7C")[2]) {
                      resumeNames.push(response.data.user.resumes[i - 1].split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+"))
                    } else {
                      resumeNames.push('Resume File #' + i)
                    }
                    resumes.push({ url: response.data.user.resumes[i - 1], source: 'Upload' })
                  }
                  // resumeURL = response.data.user.resumes[resumes.length - 1]
                  // resumeName = resumeNames[resumeNames.length - 1]


                } else {
                  if (resumeURL) {
                    resumes.push({ url: resumeURL, source: 'Upload' })
                    resumeNames.push(resumeName)
                  }
                }

                tasks[i - 1]['message'] = resumeName
                tasks[i - 1]['isCompleted'] = true
                console.log('show me resumename: ', resumeName)
              }
              if (isCoverLetter && tasks[i - 1].shorthand === 'coverLetter') {
                coverLetterURL = response.data.user.coverLetterURL
                let coverLetterName = response.data.user.coverLetterURL.split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
                tasks[i - 1]['message'] = coverLetterName
                tasks[i - 1]['isCompleted'] = true
              }
              if (isLOR && tasks[i - 1].shorthand === 'letterOfRecommendation') {
                letterOfRecommendationURL = response.data.user.letterOfRecommendationURL
                let lorName = response.data.user.letterOfRecommendationURL.split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
                tasks[i - 1]['message'] = lorName
                tasks[i - 1]['isCompleted'] = true
              }
              if (isID && tasks[i - 1].shorthand === 'identification') {
                identificationURL = response.data.user.identificationURL
                let idName = response.data.user.identificationURL.split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
                tasks[i - 1]['message'] = idName
                tasks[i - 1]['isCompleted'] = true
              }
              if (isTranscript && tasks[i - 1].shorthand === 'transcript') {
                transcriptURL = response.data.user.transcriptURL
                let transcriptName = response.data.user.transcriptURL.split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
                tasks[i - 1]['message'] = transcriptName
                tasks[i - 1]['isCompleted'] = true
              }
              if (isVaccinated && tasks[i - 1].shorthand === 'vaccinations') {
                tasks[i - 1]['message'] = 'Vaccination completed!'
                tasks[i - 1]['isCompleted'] = true
              }
              // if (addedReferralInfo && tasks[i - 1].shorthand === 'referrals') {
              //   tasks[i - 1]['message'] = 'Referral information completed!'
              //   tasks[i - 1]['isCompleted'] = true
              // }
            }

            const publicProfile = response.data.user.publicProfile
            const publicProfileExtent = response.data.user.publicProfileExtent

            this.setState({ isBasicInfo, isPoliticalInfo, isResume, resumeURL, resumeName, resumes, resumeNames,
              coverLetterURL, letterOfRecommendationURL, identificationURL,
              isTranscript, transcriptURL,
              tasks, firstName, lastName, phoneNumber,
              alternativeEmail, alternativePhoneNumber,
              educationStatus, education, schoolName, major, degree, gradYear, linkedInURL, customWebsiteURL,
              certificates,
              videoResumeURL, isPortfolio, pictureURL, zipcode, city,
              dateOfBirth, address, pathway, race, races, selfDescribedRace,
              gender, veteran, workAuthorization,
              numberOfMembers, householdIncome, fosterYouth, homeless, incarcerated, adversityList,
              politicalAlignment, stateRegistration, currentCongressionalDistrict, hometown, homeCongressionalDistrict,
              dacaStatus,
              publicProfile, publicProfileExtent, isVaccinated
            }, () => {
              this.checkCompleteness(tasks)
            })

            function pullApplicationDetails(resumes, rbResumes) {
              console.log('pullApplicationDetails called')

              Axios.get('/api/applications/bypost', { params: { email, postingId: selectedPosting._id } })
              .then((response) => {
                console.log('Applications query attempted', response.data);

                  if (response.data.success) {
                    console.log('successfully retrieved applications')

                    if (response.data.applications.length > 0) {
                      console.log('the array is greater than 0')

                      const application = response.data.applications[0]

                      let newCustomAssessmentResponses = null
                      let referrerEmail = null
                      // let showReferralCode = false
                      let postingReferrerName = null
                      let postingReferrerEmail = null
                      let postingReferrerJobTitle = null
                      let postingReferrerOrg = null

                      if (application) {

                        postingReferrerName = application.postingReferrerName
                        postingReferrerEmail = application.postingReferrerEmail
                        postingReferrerJobTitle = application.postingReferrerJobTitle
                        postingReferrerOrg = application.postingReferrerOrg
                        console.log('yoo1', postingReferrerName)
                        if (application.customAssessmentResults && !self.props.customAssessmentResponses) {
                          console.log('pass application customAssessmentResponses')
                          customAssessmentResponses = application.customAssessmentResults
                        }

                        if (application.newCustomAssessmentResults && !self.props.customAssessmentResponses) {
                          newCustomAssessmentResponses = application.newCustomAssessmentResults
                        }

                        if (application.thirdPartyAssessmentResponses) {
                          for (let i = 1; i <= application.thirdPartyAssessmentResponses.length; i++) {
                            tpaResponses.push(application.thirdPartyAssessmentResponses[i - 1].answer)
                          }
                        }
                        if (application.dealBreakerResponses) {
                          for (let i = 1; i <= application.dealBreakerResponses.length; i++) {
                            dbResponses.push(application.dealBreakerResponses[i - 1].answer)
                          }
                        }

                        referralCode = application.referralCode
                        referrerEmail = application.referrerEmail

                        // if existing application, load info from that
                        if (application.firstName && application.email) {
                          console.log('update everything but custom assessment results: ', application)

                          if (resumeURL) {
                            resumeURL = application.resumeURL

                            if (resumeURL.split("%7C")[2]) {
                              resumeName = resumeURL.split("%7C")[2].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
                            } else {
                              // resumeName = 'Resume uploaded'
                              resumeName = application.firstName + ' ' + application.lastName + ' Resume.pdf'
                            }
                            console.log('t0 ----', resumeURL)
                            if (rbResumes && rbResumes.length > 0) {

                              let slug = 'resumes'
                              if (self.state.showDoc) {
                                slug = 'documents'
                              }

                              for (let i = 1; i <= rbResumes.length; i++) {
                                if (rbResumes[i - 1] && linkPrefix + "/" + slug + "/preview/" + rbResumes[i - 1]._id === resumeURL) {
                                  resumeName = rbResumes[i - 1].fileName.replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")

                                }
                              }
                            }

                            for (let i = 1; i <= tasks.length; i++) {
                              if (isResume && tasks[i - 1].shorthand === 'resume') {
                                tasks[i - 1]['message'] = resumeName
                                tasks[i - 1]['isCompleted'] = true
                              }
                            }
                          }

                          for (let i = 1; i <= tasks.length; i++) {
                            if ((postingReferrerName && postingReferrerEmail && postingReferrerJobTitle && postingReferrerOrg) && tasks[i - 1].shorthand === 'referrals') {
                              tasks[i - 1]['message'] = 'Referral information completed!'
                              tasks[i - 1]['isCompleted'] = true
                            }
                          }
                        }

                        if ((selectedPosting.appComponents && customAssessmentIndex && selectedPosting.appComponents[customAssessmentIndex].include) && selectedPosting.customAssessmentId && selectedPosting.customAssessmentId !== '') {

                          let actionTitle = "Start"
                          let isCompleted = false
                          let message = ''
                          if (application.customAssessmentResults) {
                            actionTitle = "Edit"
                            isCompleted = true
                            message = 'Application form completed!'
                          } else if (application.newCustomAssessmentResults) {
                            actionTitle = "Edit"
                            isCompleted = true
                            message = 'Application form completed!'
                          }

                          if (tasks) {
                            const tempIndex = tasks.findIndex(t => t.shorthand === 'customAssessment')
                            if (tempIndex > -1) {
                              tasks[tempIndex] = { shorthand: 'customAssessment', name: 'Complete Application Form', icon: assessmentsIconDark, action: actionTitle, message, isCompleted, required: true }
                            } else {
                              tasks.push({ shorthand: 'customAssessment', name: 'Complete Application Form', icon: assessmentsIconDark, action: actionTitle, message, isCompleted, required: true })
                            }
                          }
                        }
                      }
                      // console.log('g3', customAssessmentResponses, newCustomAssessmentResponses)

                      if (customAssessmentResponses) {
                        responses = customAssessmentResponses
                      }
                      console.log('yoo2', postingReferrerName)
                      self.setState({ application, customAssessmentResponses, newCustomAssessmentResponses,
                        showReferralCode, referralCode, referrerEmail,
                        postingReferrerName, postingReferrerEmail, postingReferrerJobTitle, postingReferrerOrg,
                        resumeURL, resumeName, tasks,
                        tpaResponses, dbResponses, responses
                      })

                    }

                  } else {
                    console.log('no applications data found', response.data.message)
                  }

              }).catch((error) => {
                  console.log('Applications query did not work', error);
              });
            }

            let docLink = '/api/resumes'
            if (this.state.showDoc) {
              docLink = '/api/documents'
            }

            Axios.get(docLink, { params: { emailId: email } })
           .then((response2) => {
             console.log('Resumes query attempted', response2.data);

             if (response2.data.success) {
               console.log('successfully retrieved resumes')

               let docs = response2.data.resumes
               let slug = 'resumes'
               if (this.state.showDoc) {
                 docs = response2.data.documents
                 slug = 'documents'
               }

               if (docs) {
                 for (let i = 0; i < docs.length; i++) {
                   if ((docs[i].docType === 'Resume' && this.state.showDoc) || !this.state.showDoc) {
                     resumes.push({ url: linkPrefix + "/" + slug + "/preview/" + docs[i]._id, source: 'Native', _id: docs[i]._id })
                     resumeNames.push(docs[i].fileName.replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+"))
                     if (linkPrefix + "/" + slug + "/preview/" + docs[i]._id === resumeURL) {
                       resumeName = docs[i].fileName.replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
                     }
                   }
                 }
               }

               this.setState({ resumes, resumeNames, resumeName })
               pullApplicationDetails(resumes, docs)

             } else {
               console.log('no resumes data found', response.data.message)
               pullApplicationDetails(resumes)
             }

           }).catch((error) => {
               console.log('Resumes query did not work', error);
           });

            Axios.get('/api/benchmarks/byid', { params: { _id: selectedPosting.benchmarkId } })
            .then((response3) => {
              console.log('Benchmark query attempted in subapply', response3.data);

              if (response3.data.success) {
                console.log('benchmark query worked in subapply')

                let tasks = this.state.tasks
                const thirdPartyAssessments = response3.data.benchmark.thirdPartyAssessments
                if (response3.data.benchmark.thirdPartyAssessmentWeight && response3.data.benchmark.thirdPartyAssessments && response3.data.benchmark.thirdPartyAssessments.length > 0) {
                  for (let i = 1; i <= selectedPosting.appComponents.length; i++) {
                    if (selectedPosting.appComponents[i - 1].name === 'Third Party Assessments' && selectedPosting.appComponents[i - 1].include) {
                      let taskIndex = this.state.tasks.length - 1
                      if (taskIndex < 0) {
                        taskIndex = 0
                      }

                      if (tpaResponses && tpaResponses.length === thirdPartyAssessments.length) {
                        tasks.splice(taskIndex,0,{ shorthand: 'thirdPartyAssessments', name: 'Third Party Assessments Scores', icon: assessmentsIconDark, message: 'Form completed', action: 'Edit', isCompleted: true, required: selectedPosting.appComponents[i - 1].required })
                      } else {
                        tasks.splice(taskIndex,0,{ shorthand: 'thirdPartyAssessments', name: 'Third Party Assessments Scores', icon: assessmentsIconDark, message: '', action: 'Start', isCompleted: false, required: selectedPosting.appComponents[i - 1].required })
                      }
                    }
                  }
                }

                const dealBreakers = response3.data.benchmark.dealBreakers
                if (response3.data.benchmark.dealBreakers && response3.data.benchmark.dealBreakers.length > 0) {
                  for (let i = 1; i <= selectedPosting.appComponents.length; i++) {
                    if (selectedPosting.appComponents[i - 1].name === 'Screening Questions (Deal Breakers)' && selectedPosting.appComponents[i - 1].include) {
                      let taskIndex = this.state.tasks.length - 1
                      if (taskIndex < 0) {
                        taskIndex = 0
                      }

                      if (dbResponses && dbResponses.length === dealBreakers.length) {
                        tasks.splice(taskIndex,0,{ shorthand: 'dealBreakers', name: 'Screening Questions', icon: assessmentsIconDark, message: 'Form completed', action: 'Edit', isCompleted: true, required: selectedPosting.appComponents[i - 1].required })
                      } else {
                        tasks.splice(taskIndex,0,{ shorthand: 'dealBreakers', name: 'Screening Questions', icon: assessmentsIconDark, message: '', action: 'Start', isCompleted: false, required: selectedPosting.appComponents[i - 1].required })
                      }
                    }
                  }
                }

                this.setState({ tasks, thirdPartyAssessments, dealBreakers })
                pullAssessmentResults()

              } else {
                console.log('benchmark query did not wor in subapply', response3.data.message)
                pullAssessmentResults()
              }

            }).catch((error) => {
                console.log('Benchmark query did not work for some reason', error);
            });
          }
        }).catch((error) => {
            console.log('Profile pic fetch did not work', error);
        });

        Axios.get('/api/extras', { params: { emailId: email } })
        .then((response) => {
          console.log('Extras query attempted', response.data);

            if (response.data.success) {
              console.log('successfully retrieved extras')

              if (response.data.extras) {

                const extras = response.data.extras
                if (extras.length > 0) {
                  console.log('the array is greater than 0')

                  const isExtras = true
                  this.setState({ extras, isExtras })

                }
              }

            } else {
              console.log('no extras data found', response.data.message)
            }

        }).catch((error) => {
            console.log('Extras query did not work', error);
        });
      }
    }

    actionTapped(index, isCompleted, answer) {
      console.log('actionTapped called ', index, this.state.tasks[index].shorthand, this.state.isBasicInfo)

      let tasks = this.state.tasks
      if (this.state.tasks[index].shorthand === 'basicInfo') {
        if (this.state.isBasicInfo) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'Name, school, and grad year successfully imported from profile!'
        } else if (isCompleted) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'All required basic info has been added!'
        } else if (isCompleted === false) {
          tasks[index]['isCompleted'] = false
          tasks[index]['message'] = 'Please add all required basic information.'
        } else {
          tasks[index]['isCompleted'] = false
          tasks[index]['message'] = 'Please add your name, school, grad year, and other info into profile to import.'
        }

        this.setState({ tasks })
        this.checkCompleteness(tasks)
      } else if (this.state.tasks[index].shorthand === 'resume') {
        if (this.state.isResume) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'Resume successfully imported from profile!'
        } else {
          tasks[index]['isCompleted'] = false
          tasks[index]['message'] = 'Please add your resume to your profile to import.'
        }
        this.setState({ tasks })
        this.checkCompleteness(tasks)
      } else if (this.state.tasks[index].shorthand === 'portfolio') {
        if (this.state.isPortfolio) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'Portfolio link successfully imported!'
        } else {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'No portfolio link found. This is not required, but it helps your application.'
        }
        this.setState({ tasks })
        this.checkCompleteness(tasks)
      } else if (this.state.tasks[index].shorthand === 'projects') {
        if (this.state.isProjects) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'Projects & experience successfully imported from profile!'
        } else {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = "Either projects and experience are missing from your profile. That's okay, you are not required to add them, but they help!"
        }
        this.setState({ tasks })
        this.checkCompleteness(tasks)
      } else if (this.state.tasks[index].shorthand === 'coverLetter') {
        // tasks[index]['isCompleted'] = true
        // this.setState({ tasks })
      } else if (this.state.tasks[index].shorthand === 'careerAssessments') {
        if (this.state.isCareerAssessments) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'Career assessments are complete!'
        } else if (isCompleted) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'All required career assessments have been completed!'
        } else if (isCompleted === false) {
          tasks[index]['isCompleted'] = false
          tasks[index]['message'] = 'Please take all required career assessments.'
        } else {
          tasks[index]['isCompleted'] = false
          tasks[index]['message'] = 'Take the career asssessments in the assessments tab to import.'
        }
        this.setState({ tasks })
        this.checkCompleteness(tasks)

      } else if (this.state.tasks[index].shorthand === 'endorsements') {
        if (this.state.isEndorsements) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'Teacher and mentor endosements successfully imported!'
        } else {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'No endorsements found. These are not required but they help your application.'
        }
        this.setState({ tasks })
        this.checkCompleteness(tasks)
      } else if (this.state.tasks[index].shorthand === 'coverLetter') {
        if (this.state.coverLetter) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'Cover letter successfully uploaded!'
        } else {
          tasks[index]['isCompleted'] = false
          tasks[index]['message'] = 'There was an error uploading your cover letter.'
        }
        this.setState({ tasks })
        this.checkCompleteness(tasks)
      } else if (this.state.tasks[index].shorthand === 'letterOfRecommendation') {
        if (this.state.letterOfRecommendation) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'Letter of recommendation successfully uploaded!'
        } else {
          tasks[index]['isCompleted'] = false
          tasks[index]['message'] = 'There was an error uploading your letter of recommendation.'
        }
        this.setState({ tasks })
        this.checkCompleteness(tasks)
      } else if (this.state.tasks[index].shorthand === 'identification') {
        if (this.state.identification) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'Identification successfully uploaded!'
        } else {
          tasks[index]['isCompleted'] = false
          tasks[index]['message'] = 'There was an error uploading your identification.'
        }
        this.setState({ tasks })
        this.checkCompleteness(tasks)
      } else if (this.state.tasks[index].shorthand === 'transcript') {
        if (this.state.transcript) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'Transcript successfully uploaded!'
        } else {
          tasks[index]['isCompleted'] = false
          tasks[index]['message'] = 'There was an error uploading your transcript.'
        }
        this.setState({ tasks })
        this.checkCompleteness(tasks)
      } else if (this.state.tasks[index].shorthand === 'vaccinations') {
        if (answer) {
          this.setState({ isVaccinated: true, basicFormHasChanged: true })
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'Vaccination question successfully completed!'
        } else {
          this.setState({ isVaccinated: false, basicFormHasChanged: true })
          tasks[index]['isCompleted'] = false
          tasks[index]['message'] = 'You must be vaccinated or be exempt from vaccination to submit for this role!'
        }
        this.setState({ tasks })
        this.checkCompleteness(tasks)

      } else if (this.state.tasks[index].shorthand === 'referrals') {
        if (answer) {
          tasks[index]['isCompleted'] = true
          tasks[index]['message'] = 'Information on who referred you successfully completed!'
        } else {
          tasks[index]['isCompleted'] = false
          tasks[index]['message'] = 'Please complete all of the fields.'
        }
        this.setState({ tasks })
        this.checkCompleteness(tasks)

      } else {

      }
    }

    formChangeHandler = event => {
      console.log('show data: ', event.target.name)

      if (event.target.name === 'resumeURL') {
        this.setState({ resumeURL: event.target.value })
      } else if (event.target.name === 'resume' || event.target.name === 'coverLetter' || event.target.name === 'letterOfRecommendation' || event.target.name === 'identification' || event.target.name === 'transcript') {
        console.log('show event name 1: ', event.target.files[0])

        if (event.target.files[0]) {

          if(event.target.files[0].size > 2 * 1024 * 1024) {
            console.log('file is too big')

            let tasks = this.state.tasks
            const errorMessage = 'File must be less than 2MB.'
            for (let i = 1; i <= tasks.length; i++) {
              if (tasks[i - 1].shorthand === event.target.name) {
                tasks[i - 1]['isCompleted'] = false
                // tasks[i - 1]['message'] = event.target.name.charAt(0).toUpperCase() + event.target.name.slice(1) + ' was successfully uploaded'
                tasks[i - 1]['message'] = errorMessage
              }
            }

            this.setState({ tasks })

          } else {
            console.log('file is small enough', event.target.files[0].size)

            let reader = new FileReader();
            reader.onload = (e) => {
              if (event.target) {
                console.log('show event name 2: ', event.target.name)
                if (event.target.name === 'resume') {
                  this.setState({ resume: e.target.result });
                } else if (event.target.name === 'coverLetter') {
                  this.setState({ coverLetter: e.target.result });
                } else if (event.target.name === 'letterOfRecommendation') {
                  this.setState({ letterOfRecommendation: e.target.result });
                } else if (event.target.name === 'identification') {
                  this.setState({ identification: e.target.result });
                } else if (event.target.name === 'transcript') {
                  this.setState({ transcript: e.target.result });
                }
                console.log('how do i access the image', e.target.result)
              }
            };
            reader.readAsDataURL(event.target.files[0]);
            // this.setState({ profilePicFile: event.target.files[0], profilePicHasChanged: true })
            this.saveFile(event.target.name, event.target.files[0])

            let tasks = this.state.tasks
            for (let i = 1; i <= tasks.length; i++) {
              if (tasks[i - 1].shorthand === event.target.name) {
                tasks[i - 1]['isCompleted'] = true
                // tasks[i - 1]['message'] = event.target.name.charAt(0).toUpperCase() + event.target.name.slice(1) + ' was successfully uploaded'
                tasks[i - 1]['message'] = event.target.files[0].name
              }
            }

            this.setState({ tasks })
          }
        }
      } else if (event.target.name === 'resumeName') {
        const resumeName = event.target.value
        const index = this.state.resumeNames.indexOf(event.target.value)
        const resumeURL = this.state.resumes[index].url
        console.log('deets: ', resumeName, index, resumeURL, this.state.resumeNames, this.state.resumes)

        let tasks = this.state.tasks
        for (let i = 1; i <= tasks.length; i++) {
          if (tasks[i - 1].shorthand === 'resume') {
            tasks[i - 1]['isCompleted'] = true
            tasks[i - 1]['message'] = resumeName
          }
        }
        // console.log('dkjdf____', resumeURL)
        this.setState({ resumeName, resumeURL, tasks })
      } else if (event.target.name === 'firstName') {
        this.setState({ firstName: event.target.value })
      } else if (event.target.name === 'lastName') {
        this.setState({ lastName: event.target.value })
      } else if (event.target.name === 'email') {
        this.setState({ emailId: event.target.value })
      } else if (event.target.name === 'password') {
        this.setState({ password: event.target.value })
      } else if (event.target.name === 'schoolName') {
        this.setState({ schoolName: event.target.value })
      } else if (event.target.name === 'major') {
        this.setState({ major: event.target.value })
      } else if (event.target.name === 'gradYear') {
        this.setState({ gradYear: event.target.value })
      } else if (event.target.name === 'linkedInURL') {
        this.setState({ linkedInURL: event.target.value })
      } else if (event.target.name === 'customWebsiteURL') {
        this.setState({ customWebsiteURL: event.target.value })
      } else if (event.target.name === 'phoneNumber') {
        this.setState({ phoneNumber: event.target.value })
      } else if (event.target.name === 'shortAnswer') {
        let responses = this.state.responses
        if (event.target.value === '') {
          responses[this.state.questionIndex] = null
          this.setState({ selectedAnswer: event.target.value, responses })
        } else {
          responses[this.state.questionIndex] = event.target.value
          this.setState({ selectedAnswer: event.target.value, responses })
        }

      } else if (event.target.name === 'longAnswer') {
        let responses = this.state.responses
        if (event.target.value === '') {
          responses[this.state.questionIndex] = null
          this.setState({ selectedAnswer: event.target.value, responses })
        } else {
          responses[this.state.questionIndex] = event.target.value
          this.setState({ selectedAnswer: event.target.value, responses })
        }
      } else if (event.target.name === 'email') {
        let responses = this.state.responses
        if (event.target.value === '') {
          responses[this.state.questionIndex] = null
          this.setState({ selectedAnswer: event.target.value, responses })
        } else {
          responses[this.state.questionIndex] = event.target.value
          this.setState({ selectedAnswer: event.target.value, responses })
        }
      } else if (event.target.name === 'birthdate') {
        let responses = this.state.responses
        if (event.target.value === '') {
          responses[this.state.questionIndex] = null
          this.setState({ selectedAnswer: event.target.value, responses })
        } else {
          responses[this.state.questionIndex] = event.target.value
          this.setState({ selectedAnswer: event.target.value, responses })
        }

      } else if (event.target.name === 'multipleChoice') {
        let responses = this.state.responses
        responses[this.state.questionIndex] = event.target.value
        this.setState({ multipleChoice: event.target.value, responses })
      } else if (event.target.name === 'multipleAnswer') {
        let responses = this.state.responses
        let thisResponseArray = this.state.responses[this.state.questionIndex]

        if (Array.isArray(thisResponseArray)) {
          if (thisResponseArray.includes(event.target.value)) {
            let index = thisResponseArray.indexOf(event.target.value);
            if (index > -1) {
              thisResponseArray.splice(index, 1);
            }
          } else {

            thisResponseArray.push(event.target.value)

          }
        } else {
          thisResponseArray = []
          thisResponseArray.push(event.target.value)
        }

        responses[this.state.questionIndex] = thisResponseArray
        this.setState({ selectedAnswer: thisResponseArray, responses })
      } else if (event.target.name === 'boolean') {
        let responses = this.state.responses
        responses[this.state.questionIndex] = event.target.value
        this.setState({ selectedAnswer: event.target.value, responses })
      } else if (event.target.name.includes('listedAnswer')) {
        let responses = this.state.responses
        if (event.target.name.split("|")[3] === 'thirdPartyAssessments') {
          responses = this.state.tpaResponses
        } else if (event.target.name.split("|")[3] === 'dealBreakers') {
          responses = this.state.dbResponses
          console.log('in dbResponses 1', responses)
        }

        const type = event.target.name.split('|')[1]
        const index = event.target.name.split('|')[2]

        if (type === 'boolean') {
          responses[index] = event.target.value
          this.setState({ responses })
        } else if (type === 'multipleChoice') {
          responses[index] = event.target.value
          if (event.target.name.split("|")[3] === 'thirdPartyAssessments') {
            this.setState({ tpaResponses: responses })
          } else if (event.target.name.split("|")[3] === 'dealBreakers') {
            this.setState({ dbResponses: responses })
          } else {
            this.setState({ responses })
          }
        } else if (type === 'multipleAnswer') {
          let thisResponseArray = this.state.responses[index]

          if (Array.isArray(thisResponseArray)) {
            if (thisResponseArray.includes(event.target.value)) {
              let index = thisResponseArray.indexOf(event.target.value);
              if (index > -1) {
                thisResponseArray.splice(index, 1);
              }
            } else {

              thisResponseArray.push(event.target.value)

            }
          } else {
            thisResponseArray = []
            thisResponseArray.push(event.target.value)
          }

          responses[index] = thisResponseArray
          if (event.target.name.split("|")[3] === 'thirdPartyAssessments') {
            this.setState({ tpaResponses: responses })
          } else if (event.target.name.split("|")[3] === 'dealBreakers') {
            this.setState({ dbResponses: responses })
          } else {
            this.setState({ responses })
          }

        } else if (type === 'linearScale') {
          console.log('linearScale called here')
          responses[index] = event.target.value
          if (event.target.name.split("|")[3] === 'thirdPartyAssessments') {
            this.setState({ tpaResponses: responses })
          } else if (event.target.name.split("|")[3] === 'dealBreakers') {
            this.setState({ dbResponses: responses })
          } else {
            this.setState({ responses })
          }

        } else {
          if (event.target.value === '') {
            responses[index] = null
            if (event.target.name.split("|")[3] === 'thirdPartyAssessments') {
              this.setState({ tpaResponses: responses })
            } else if (event.target.name.split("|")[3] === 'dealBreakers') {
              this.setState({ dbResponses: responses })
            } else {
              this.setState({ responses })
            }
          } else {
            responses[index] = event.target.value
            if (event.target.name.split("|")[3] === 'thirdPartyAssessments') {
              this.setState({ tpaResponses: responses })
            } else if (event.target.name.split("|")[3] === 'dealBreakers') {
              this.setState({ dbResponses: responses })
            } else {
              this.setState({ responses })
            }
          }
        }

        let tasks = this.state.tasks
        let testShorthand = 'customAssessment'
        let testQuestions = []
        if (event.target.name.split("|")[3] === 'thirdPartyAssessments') {
          testShorthand = 'thirdPartyAssessments'
          testQuestions = this.state.thirdPartyAssessments
        } else if (event.target.name.split("|")[3] === 'dealBreakers') {
          testShorthand = 'dealBreakers'
          testQuestions = this.state.dealBreakers
        } else {
          if (this.state.customAssessment) {
            testQuestions = this.state.customAssessment.questions
          }
        }

        for (let i = 1; i <= tasks.length; i++) {
          if (tasks[i - 1].shorthand === testShorthand) {
            let responseCounter = 0
            for (let j = 1; j <= testQuestions.length; j++) {
              if (responses[j - 1] && responses[j - 1] !== '') {
                responseCounter = responseCounter + 1
              }
            }

            if (responseCounter === testQuestions.length) {
              tasks[i - 1]['isCompleted'] = true
              tasks[i - 1]['message'] = 'Form has been completed!'
            }
          }
        }

        this.checkCompleteness(tasks)
      } else if (event.target.name.includes('question')) {
        const index = Number(event.target.name.split('|')[1])
        for (let i = 1; i <= this.state.questions.length; i++) {
          console.log('compare indices: ', index, i - 1, typeof index)
          if (index === i - 1) {
            let questions = this.state.questions
            questions[index]['answer'] = event.target.value
            console.log('show questions after form: ', questions)
            this.setState({ questions, formHasChanged: true })
          }
        }
      } else if (event.target.name.includes('education|')) {
        const name = event.target.name.split("|")[1]

        let education = this.state.education
        if (education && education[0]) {
          education[0][name] = event.target.value
        } else {
          education = [{}]
          education[0][name] = event.target.value
        }

        this.setState({ education })
      } else if (event.target.name === 'postingReferrerName' || event.target.name === 'postingReferrerEmail' || event.target.name === 'postingReferrerJobTitle' || event.target.name === 'postingReferrerOrg') {
        // check completeness for referrals task
        let tasks = this.state.tasks
        let postingReferrerName = this.state.postingReferrerName
        let postingReferrerEmail = this.state.postingReferrerEmail
        let postingReferrerJobTitle = this.state.postingReferrerOrg
        let postingReferrerOrg = this.state.postingReferrerOrg
        if (event.target.name === 'postingReferrerName') {
          postingReferrerName = event.target.value
        } else if (event.target.name === 'postingReferrerEmail') {
          postingReferrerEmail = event.target.value
        } else if (event.target.name === 'postingReferrerJobTitle') {
          postingReferrerJobTitle = event.target.value
        } else if (event.target.name === 'postingReferrerOrg') {
          postingReferrerOrg = event.target.value
        }

        for (let i = 1; i <= tasks.length; i++) {
          if (tasks[i - 1].shorthand === 'referrals') {
            if (postingReferrerName && postingReferrerEmail && postingReferrerJobTitle && postingReferrerOrg) {

              tasks[i - 1]['isCompleted'] = true
              tasks[i - 1]['message'] = 'Information on who referred you successfully completed!'
            } else {
              tasks[i - 1]['isCompleted'] = false
              tasks[i - 1]['message'] = 'Please complete all the fields'
            }
          }
        }

        this.setState({ tasks })
        this.checkCompleteness(tasks)
        this.setState({ [event.target.name]: event.target.value })

      } else {

        this.setState({ [event.target.name]: event.target.value })
      }
    }

    saveFile(category, passedFile) {
      console.log('saveFile called', category, passedFile)

      // 2.16 MB

      const emailId = this.state.emailId
      const fileName = passedFile.name
      const originalName = category + '|' + emailId + '|' + fileName + '|' + new Date()

      let fileData = new FormData();
      // const fileName = 'profileImage'
      // const fileName = 'newFile'
      fileData.append('baseFileName', passedFile, originalName)

      fetch("/api/file-upload", {
          mode: 'no-cors',
          method: "POST",
          body: fileData
      }).then(function (res) {
        console.log('what is the response');

        if (res.ok) {

          const serverSuccessResume = true
          const serverSuccessMessageResume = category.charAt(0).toUpperCase() + category.slice(1) + ' saved successfully!'
          if (category === 'resume') {
            this.setState({ serverSuccessResume, serverSuccessMessageResume, resumeFile: passedFile, resumeName: fileName })
          } else if (category === 'coverLetter') {
            this.setState({ serverSuccessResume, serverSuccessMessageResume, coverLetterFile: passedFile, coverLetterName: fileName })
          } else if (category === 'letterOfRecommendation') {
            this.setState({ serverSuccessResume, serverSuccessMessageResume, lorFile: passedFile, lorName: fileName })
          } else if (category === 'identification') {
            this.setState({ serverSuccessResume, serverSuccessMessageResume, idFile: passedFile, idName: fileName })
          } else if (category === 'transcript') {
            this.setState({ serverSuccessResume, serverSuccessMessageResume, transcriptFile: passedFile, transcriptName: fileName })
          }

          const self = this

          res.json()
          .then(function(data) {
            console.log('show data: ', data)
            let newFilePath = data.filePath
            console.log('show filePath: ', newFilePath)

            let existingFilePath = null
            if (category === 'resume') {
              existingFilePath = self.state.resumeURL
            } else if (category === 'coverLetter') {
              existingFilePath = self.state.coverLetterURL
            } else if (category === 'letterOfRecommendation') {
              existingFilePath = self.state.letterOfRecommendationURL
            } else if (category === 'identification') {
              existingFilePath = self.state.identificationURL
            } else if (category === 'transcript') {
              existingFilePath = self.state.transcriptURL
            }

            // remove existing file
            if (existingFilePath && !self.state.allowMultipleFiles) {
              const deleteArray = existingFilePath.split("amazonaws.com/")
              console.log('show deleteArray: ', deleteArray)
              if (deleteArray[1]) {
                const deleteKey = deleteArray[1].replace(/%7C/g,"|").replace(/%40/g,"@").replace(/\+/gi,' ').replace(/%3A/g,":").replace(/%20/g," ").replace(/%28/g,"(").replace(/%29/g,")").replace(/%2B/g,"+")
                // console.log('show deleteKey: ', deleteKey, 'profilePic|creightontaylor+14@gmail.com|Tue Sep 08 2020 20:39:52 GMT-0700 (Pacific Daylight Time)')

                // profilePic|Ccreightontaylor 14@gmail.com|CTue%20Sep%2008%202020%2020:25:28%20GMT-0700%20%28Pacific%20Daylight%20Time%29
                Axios.put('/api/file/', { deleteKey })
                .then((response) => {
                  console.log('tried to delete', response.data)
                  if (response.data.success) {
                    //save values
                    console.log('File delete worked');

                    const serverPostSuccess = true
                    const serverSuccessMessage = 'File was saved successfully'

                    if (category === 'resume') {
                      self.setState({ serverPostSuccess, serverSuccessMessage, resumeURL: newFilePath })
                    } else if (category === 'coverLetter') {
                      self.setState({ serverPostSuccess, serverSuccessMessage, coverLetterURL: newFilePath })
                    } else if (category === 'letterOfRecommendation') {
                      self.setState({ serverPostSuccess, serverSuccessMessage, letterOfRecommendationURL: newFilePath })
                    } else if (category === 'identification') {
                      self.setState({ serverPostSuccess, serverSuccessMessage, identificationURL: newFilePath  })
                    } else if (category === 'transcript') {
                      self.setState({ serverPostSuccess, serverSuccessMessage, transcriptURL: newFilePath  })
                    }

                  } else {
                    console.error('there was an error saving the file');
                    self.setState({
                      serverPostSuccess: false,
                      serverErrorMessage: response.data.message,
                    })
                  }
                }).catch((error) => {
                    console.log('The saving did not work', error);
                    self.setState({
                      serverPostSuccess: false,
                      serverErrorMessage: error,
                    })
                });
              } else {
                console.log('save new file', category, newFilePath)

                const serverPostSuccess = true
                const serverSuccessMessage = 'File was saved successfully'

                if (category === 'resume') {
                  self.setState({ serverPostSuccess, serverSuccessMessage, resumeURL: newFilePath })

                  let tasks = self.state.tasks
                  for (let i = 1; i <= tasks.length; i++) {
                    if (tasks[i - 1].shorthand === category) {
                      tasks[i - 1]['isCompleted'] = true
                      tasks[i - 1]['message'] = fileName
                    }
                  }
                  self.checkCompleteness(tasks)

                } else if (category === 'coverLetter') {
                  self.setState({ serverPostSuccess, serverSuccessMessage, coverLetterURL: newFilePath })

                  let tasks = self.state.tasks
                  for (let i = 1; i <= tasks.length; i++) {
                    if (tasks[i - 1].shorthand === category) {
                      tasks[i - 1]['isCompleted'] = true
                      tasks[i - 1]['message'] = fileName
                    }
                  }
                  self.checkCompleteness(tasks)
                } else if (category === 'letterOfRecommendation') {
                  self.setState({ serverPostSuccess, serverSuccessMessage, letterOfRecommendationURL: newFilePath })

                  let tasks = self.state.tasks
                  for (let i = 1; i <= tasks.length; i++) {
                    if (tasks[i - 1].shorthand === category) {
                      tasks[i - 1]['isCompleted'] = true
                      tasks[i - 1]['message'] = fileName
                    }
                  }
                  self.checkCompleteness(tasks)
                } else if (category === 'identification') {
                  self.setState({ serverPostSuccess, serverSuccessMessage, identificationURL: newFilePath  })

                  let tasks = self.state.tasks
                  for (let i = 1; i <= tasks.length; i++) {
                    if (tasks[i - 1].shorthand === category) {
                      tasks[i - 1]['isCompleted'] = true
                      tasks[i - 1]['message'] = fileName
                    }
                  }
                  self.checkCompleteness(tasks)
                } else if (category === 'transcript') {
                  self.setState({ serverPostSuccess, serverSuccessMessage, transcriptURL: newFilePath  })

                  let tasks = self.state.tasks
                  for (let i = 1; i <= tasks.length; i++) {
                    if (tasks[i - 1].shorthand === category) {
                      tasks[i - 1]['isCompleted'] = true
                      tasks[i - 1]['message'] = fileName
                    }
                  }
                  self.checkCompleteness(tasks)
                }
              }
            } else {
              console.log('no existing file')

              const serverPostSuccess = true
              const serverSuccessMessage = 'File was saved successfully'

              if (category === 'resume') {
                self.setState({ serverPostSuccess, serverSuccessMessage, resumeURL: newFilePath })
                let tasks = self.state.tasks
                for (let i = 1; i <= tasks.length; i++) {
                  if (tasks[i - 1].shorthand === category) {
                    tasks[i - 1]['isCompleted'] = true
                    tasks[i - 1]['message'] = fileName
                  }
                }
                self.checkCompleteness(tasks)
              } else if (category === 'coverLetter') {
                self.setState({ serverPostSuccess, serverSuccessMessage, coverLetterURL: newFilePath })
                let tasks = self.state.tasks
                for (let i = 1; i <= tasks.length; i++) {
                  if (tasks[i - 1].shorthand === category) {
                    tasks[i - 1]['isCompleted'] = true
                    tasks[i - 1]['message'] = fileName
                  }
                }
                self.checkCompleteness(tasks)
              } else if (category === 'letterOfRecommendation') {
                self.setState({ serverPostSuccess, serverSuccessMessage, letterOfRecommendationURL: newFilePath })
                let tasks = self.state.tasks
                for (let i = 1; i <= tasks.length; i++) {
                  if (tasks[i - 1].shorthand === category) {
                    tasks[i - 1]['isCompleted'] = true
                    tasks[i - 1]['message'] = fileName
                  }
                }
                self.checkCompleteness(tasks)
              } else if (category === 'identification') {
                self.setState({ serverPostSuccess, serverSuccessMessage, identificationURL: newFilePath  })
                let tasks = self.state.tasks
                for (let i = 1; i <= tasks.length; i++) {
                  if (tasks[i - 1].shorthand === category) {
                    tasks[i - 1]['isCompleted'] = true
                    tasks[i - 1]['message'] = fileName
                  }
                }
                self.checkCompleteness(tasks)
              } else if (category === 'transcript') {
                self.setState({ serverPostSuccess, serverSuccessMessage, transcriptURL: newFilePath  })
                let tasks = self.state.tasks
                for (let i = 1; i <= tasks.length; i++) {
                  if (tasks[i - 1].shorthand === category) {
                    tasks[i - 1]['isCompleted'] = true
                    tasks[i - 1]['message'] = fileName
                  }
                }
                self.checkCompleteness(tasks)
              }
            }
          })

        } else if (res.status === 401) {
          //unauthorized
          this.setState({
              serverSuccessProfilePic: false,
              serverErrorMessageProfilePic: 'There was an error saving profile pic: Unauthorized save.'
          })
        }
      }.bind(this), function (e) {
        //there was an error
        this.setState({
            serverSuccessProfilePic: false,
            serverErrorMessageProfilePic: 'There was an error saving profile pic:' + e
        })
      }.bind(this));
    }

    async signUpUser() {
        console.log('signUpUser called')

        const firstName = this.state.firstName
        const lastName = this.state.lastName
        let email = this.state.emailId
        const password = this.state.password
        let activeOrg = this.state.activeOrg
        if (!activeOrg && this.state.selectedPosting && this.state.selectedPosting.orgCode) {
          activeOrg = this.state.selectedPosting.orgCode
        }
        let roleName = 'Student'

        if (!firstName || firstName === '') {
          this.setState({ serverErrorMessage: 'please enter your first name' })
        } else if (!lastName || lastName === '') {
          this.setState({ serverErrorMessage: 'please enter your last name' })
        } else if (!email || email === '') {
          this.setState({ serverErrorMessage: 'please enter your email' })
        } else if (!email.includes('@')) {
          this.setState({ serverErrorMessage: 'email invalid. please enter a valid email' })
        } else if (!password || password === '') {
          this.setState({ serverErrorMessage: 'please enter a password' })
        } else if (!activeOrg || activeOrg === '') {
          this.setState({ serverErrorMessage: 'please add the code affiliated with a Guided Compass partner' })
        } else if (!roleName || roleName === '') {
          this.setState({ serverErrorMessage: 'please enter your role name' })
        } else if (password.length < 7) {
          this.setState({ serverErrorMessage: 'please enter a password over 6 characters' })
        } else {

            if (this.state.questions && this.state.questions.length > 0) {
              for (let i = 1; i <= this.state.questions.length; i++) {
                const item = this.state.questions[i - 1]
                if (item.required) {
                  // console.log('are we still looping questions? ', item)

                  // multiple answer is array
                  if (item.questionType === 'Multiple Answer' && (!this.state[item.shortname] || this.state[item.shortname].length === 0)) {
                    return this.setState({ serverErrorMessage: 'Please add answer(s) for ' + item.name })
                  } else if (!item.shortname.includes("|") && (!this.state[item.shortname] || !this.state[item.shortname] === '')) {
                    return this.setState({ serverErrorMessage: 'Please add an answer for ' + item.name })
                  } else if (item.shortname.includes("|")) {
                    if (!this.state[item.shortname.split("|")[0]] || this.state[item.shortname.split("|")[0]].length === 0) {
                      return this.setState({ serverErrorMessage: 'Please add answer(s) for the education fields' })
                    } else if (!this.state[item.shortname.split("|")[0]][0][item.shortname.split("|")[1]]) {
                      return this.setState({ serverErrorMessage: 'Please add answer(s) for each of the education fields' })
                    }
                  }
                }
              }
            }

            //we will assume username is unique for now
            let combinedNames = firstName + lastName
            let username = combinedNames.toLowerCase();

            this.setState({ isWaiting: true, username })

            Axios.get('/api/org', { params: { orgCode: activeOrg } })
            .then(async(response) => {
              console.log('Org info query attempted', response.data);

                if (response.data.success) {
                  console.log('org info query worked')

                  email = email.toLowerCase()
                  const orgName = response.data.orgInfo.orgName
                  const orgFocus = response.data.orgInfo.orgFocus
                  let accountCode = ''

                  let benefits = undefined
                  if (roleName && roleName.toLowerCase() === 'Student') {
                    benefits = this.state.studentBenefits
                  } else if (roleName && roleName.toLowerCase() === 'career-seeker') {
                    roleName = 'Student'
                    benefits = this.state.studentBenefits
                  }

                  if (benefits) {
                    for (let i = 1; i <= benefits.length; i++) {
                      benefits[i - 1]['detail'] = benefits[i - 1].detail.replace(/{{orgName}}/g,orgName)
                    }
                  }

                  const jobTitle = this.state.jobTitle
                  const employerName = this.state.employerName
                  const otherRoleName = null

                  const mfaEnabled = null
                  const requestAccess = null
                  const pathway = null

                  Axios.get('/api/emails', { params: { orgCode: activeOrg } })
                  .then(async(response) => {
                    console.log('Emails query attempted', response.data);

                      if (response.data.success) {
                        console.log('emails query worked')

                        const studentBenefits = response.data.benefits[0].studentBenefits
                        const careerSeekerBenefits = response.data.benefits[0].studentBenefits
                        const teacherBenefits = response.data.benefits[0].teacherBenefits
                        const mentorBenefits = response.data.benefits[0].mentorBenefits
                        const employerBenefits = response.data.benefits[0].employerBenefits

                        const phoneNumber = this.state.phoneNumber
                        const alternativePhoneNumber = this.state.alternativePhoneNumber
                        const alternativeEmail = this.state.alternativeEmail
                        let subscribedForReminders = true
                        let schoolName = this.state.schoolName
                        let gradYear = this.state.gradYear
                        let degree = this.state.degree
                        let major = this.state.major
                        const dateOfBirth = this.state.dateOfBirth
                        const gender = this.state.gender
                        const races = this.state.races
                        const selfDescribedRace = this.state.selfDescribedRace
                        const address = this.state.address
                        const zipcode = this.state.zipcode
                        const city = this.state.city
                        const numberOfMembers = this.state.numberOfMembers
                        const householdIncome = this.state.householdIncome
                        const workAuthorization = this.state.workAuthorization
                        const adversityList = this.state.adversityList
                        const educationStatus = this.state.educationStatus
                        let education = null
                        if (this.state.education && this.state.education[0]) {
                          education = [{ temp: '' }]
                          education[0]['name'] = this.state.education[0].name
                          education[0]['endDate'] = this.state.education[0].endDate
                          education[0]['degree'] = this.state.education[0].degree
                          education[0]['major'] = this.state.education[0].major

                          schoolName = this.state.education[0].name
                          gradYear = this.state.education[0].endDate
                          degree = this.state.education[0].degree
                          major = this.state.education[0].major
                        }

                        const referrerName = this.state.referrerName
                        const referrerEmail = this.state.referrerEmail
                        const referrerOrg = this.state.referrerOrg

                        const returnedValue = await signUp(firstName, lastName, email, password, gradYear, jobTitle, employerName, activeOrg, roleName, otherRoleName, null, null, schoolName, null, accountCode, studentBenefits, teacherBenefits, mentorBenefits, employerBenefits, mfaEnabled, null, null, null, null, null, null, null, phoneNumber, alternativePhoneNumber, alternativeEmail, subscribedForReminders,degree,major,dateOfBirth,gender,races,selfDescribedRace,address,zipcode,numberOfMembers, householdIncome, workAuthorization, adversityList, educationStatus, education, referrerName, referrerEmail, referrerOrg,requestAccess,pathway, city)

                        if (returnedValue && returnedValue.success) {
                          console.log('sign up was successful: ', returnedValue)

                          this.setState({ signedIn: true, orgFocus, roleName, serverErrorMessage: null })
                          this.retrieveData()

                        } else if (returnedValue && returnedValue.message) {
                          console.log('sign up error: ', returnedValue)
                          this.setState({ serverErrorMessage: returnedValue.message })
                        } else {

                          this.setState({ serverErrorMessage: returnedValue.error.message })
                        }

                      } else {
                        console.log('emails query did not work', response.data.message)
                      }

                  }).catch((error) => {
                      console.log('Emails query did not work for some reason', error);
                  });

                } else {
                  console.log('org info query did not work', response.data.message)

                  this.setState({ serverErrorMessage: 'There was an error finding the organization' })
                }

            }).catch((error) => {
                console.log('Org info query did not work for some reason', error);
            });
        }

    }

    async signInUser() {
        console.log('subSignIn called in subApply: ', this.state)

        const email = this.state.emailId
        const password = this.state.password

        if (email === '') {
          this.setState({ serverErrorMessage: 'please enter your email' })
        } else if (password === '') {
          this.setState({ serverErrorMessage: 'please enter your password' })
        } else {

          this.setState({ isWaiting: true })

          const returnedValue = await signIn(email, password)
          if (returnedValue && returnedValue.success) {
            console.log('show returnedValue 1: ', returnedValue)
            // this.queryInfo(this.state.email)

            if (!this.state.orgFocus || this.state.orgFocus === '' || !this.state.orgCode || this.state.orgCode === '') {
              // pull org information
              if (this.state.path && this.state.path.includes('/employers')) {
                this.completeSignIn(email, returnedValue, this.state.orgCode, this.state.orgFocus)
              } else {
                console.log('pull org info')
                const orgCode = returnedValue.user.activeOrg
                Axios.get('/api/org', { params: { orgCode} })
                .then((response) => {
                  console.log('Org info query attempted for orgFocus', response.data);

                    if (response.data.success) {
                      console.log('org info query worked for orgFocus')

                      const orgFocus = response.data.orgInfo.orgFocus

                      this.completeSignIn(email, returnedValue, orgCode, orgFocus)

                    } else {
                      console.log('org info query did not work', response.data.message)
                    }

                }).catch((error) => {
                    console.log('Org info query did not work for some reason', error);
                });
              }

            } else {
              this.completeSignIn(email, returnedValue, this.state.orgCode, this.state.orgFocus)
            }
          } else if (returnedValue && returnedValue.message) {
            console.log('show returnedValue 3: ', returnedValue)
            this.setState({ serverErrorMessage: returnedValue.message })
          } else if (returnedValue && returnedValue.error) {
            console.log('show returnedValue 4: ', returnedValue)
            this.setState({ serverErrorMessage: returnedValue.error.message })
          } else {
            console.log('show returnedValue 5: ', returnedValue)
            // this.setState({ isSaving: false, errorMessage: returnedValue.message })

            this.setState({ serverErrorMessage: 'an unknown error has occurred' })
          }
        }
    }

    completeSignIn(email, responseData,orgCode, orgFocus) {
      console.log('completeSignIn called', email, orgCode, orgFocus)

      localStorage.setItem('email', email)
      localStorage.setItem('username', responseData.user.username)
      localStorage.setItem('firstName', responseData.user.firstName)
      localStorage.setItem('lastName', responseData.user.lastName)
      localStorage.setItem('pathway', responseData.user.pathway)
      localStorage.setItem('unreadNotificationsCount', 0)

      if (responseData.user.workMode === true) {
        localStorage.setItem('workMode', 'true')
      } else {
        localStorage.setItem('workMode', 'false')
      }

      if (responseData.user.isAdvisor) {
        localStorage.setItem('isAdvisor', 'true')
      } else {
        localStorage.setItem('isAdvisor', 'false')
        localStorage.setItem('isAdvisee', 'true')
      }

      if (responseData.user.orgAffiliation) {
        if (responseData.user.orgAffiliation === 'admin') {
          console.log('user is an admin')
          localStorage.setItem('orgAffiliation', 'admin')
        } else {
          console.log('user is not an admin')
          localStorage.setItem('orgAffiliation', '')
        }
      } else {
        console.log('no orgAffiliation found')
        localStorage.setItem('orgAffiliation', '')
      }
      if (responseData.user.myOrgs) {
        localStorage.setItem('myOrgs', JSON.stringify(responseData.user.myOrgs))
      }

      if (responseData.user.activeOrg) {
        localStorage.setItem('activeOrg', responseData.user.activeOrg)
        if (orgFocus && orgFocus !== '') {
          localStorage.setItem('orgFocus', orgFocus)
        }
      }
      console.log('show roleName on signin: ', responseData.user.roleName)
      if (responseData.user.roleName) {
        localStorage.setItem('roleName', responseData.user.roleName)
      }

      this.setState({ signedIn: true, orgFocus, roleName: responseData.user.roleName,
        resumeURL: responseData.user.resumeURL, firstName: responseData.user.firstName, lastName: responseData.user.lastName,
        serverErrorMessage: null
      })

      this.retrieveData()

    }

    async signOutUser() {
      console.log('signOutUser called')

      const returnedValue = await signOut(
        this.state.emailId, null, null, null, null, this.props.navigate, true
      )

      if (returnedValue && returnedValue.success) {
        console.log('show returnedValue 1: ', returnedValue)
        // this.queryInfo(this.state.email)

        this.setState({ signedIn: false })

      } else if (returnedValue && returnedValue.message) {
        return { success: false, message: returnedValue.message }
      } else {
        console.log('show returnedValue 2: ', returnedValue)
        // return { serverErrorMessage: error }
      }
    }

    checkSectionCompleteness(type, value, requiredComponents, skillQuestions, initialLoad) {
      console.log('checkSectionCompleteness called', type, value, requiredComponents, skillQuestions, initialLoad)

      let isCompleted = false
      if (type === 'basicInfo') {

        // const requiredBasicValues = this.state.requiredBasicValues

        let pointTracker = 0
        let incrementalPoint = (100 / requiredComponents.length) + 1

        for (let i = 1; i <= requiredComponents.length; i++) {
          // console.log('compare basicValues: ', requiredBasicValues[i - 1], value.firstName, incrementalPoint)
          if (requiredComponents[i - 1] === 'firstName' && value.firstName && value.firstName !== '') {
            pointTracker = pointTracker + incrementalPoint
          } else if (requiredComponents[i - 1] === 'lastName' && value.lastName && value.lastName !== '') {
            pointTracker = pointTracker + incrementalPoint
          } else if ((requiredComponents[i - 1] === 'schoolName' && value.schoolName && value.schoolName !== '') || requiredComponents[i - 1] === 'school' && value.school && value.school !== '') {
            pointTracker = pointTracker + incrementalPoint
          } else if (requiredComponents[i - 1] === 'gradYear' && value.gradYear && value.gradYear !== '') {
            pointTracker = pointTracker + incrementalPoint
          }
        }

        // console.log('what is pointTracker for basic info: ', pointTracker)
        if (pointTracker > 100) {
          isCompleted = true
        }

      } else if (type === 'assessment') {
        console.log('show value: ', value)

        // console.log('is c: ', isCareerAssessments, assessments, requiredCareerAssessments, skillQuestions)

        let pointTracker = 0
        let incrementalPoint = (100 / this.state.requiredCareerAssessments.length) + 1
        console.log('t1: ', incrementalPoint)

        for (let i = 1; i <= requiredComponents.length; i++) {
          if (value) {
            if (requiredComponents[i - 1] === 'work preferences' && value.workPreferences && value.workPreferences.length > 0) {
              pointTracker = pointTracker + incrementalPoint
            }
            if (requiredComponents[i - 1] === 'interests' && value.interests && value.interests.length > 0) {
              pointTracker = pointTracker + incrementalPoint
              console.log('t2: ', pointTracker)
            }
            if (requiredComponents[i - 1] === 'skills' && value.skills && value.skills.length > 0) {
              console.log('in skill check', value.skills, this.state.skillQuestions)

              if (this.state.skillQuestions && !initialLoad) {
                skillQuestions = this.state.skillQuestions
              }
              console.log('show skillQuestions up in apply: ', skillQuestions, this.state.skillQuestions)

              let skillsStatus = 'Complete'
              if (skillQuestions && skillQuestions.length > 0) {
                console.log('t1')
                for (let i = 1; i <= skillQuestions.length; i++) {
                  console.log('t2', value.skills, skillQuestions[i - 1])
                  if (value.skills.some(sa => sa.name === skillQuestions[i - 1].title)) {
                    // console.log('compare the questions: ', skillQuestions[i - 1])
                  } else if (value.skills.some(sa => sa.name === skillQuestions[i - 1].name)) {
                  } else {
                    skillsStatus = 'Incomplete'
                    console.log('what missed here: ', i, skillQuestions[i - 1])
                  }
                }
              }

              if (skillsStatus === 'Complete') {
                pointTracker = pointTracker + incrementalPoint
              }
              console.log('t3: ', pointTracker, skillsStatus)

            }
            if (requiredComponents[i - 1] === 'personality' && value.personality && value.personality.fiveFactors) {
              pointTracker = pointTracker + incrementalPoint
            }
            if (requiredComponents[i - 1] === 'values' && value.values && value.topGravitateValues && value.topGravitateValues.length > 0) {
              pointTracker = pointTracker + incrementalPoint
            }
          }
        }
        console.log('t4: ', pointTracker)
        if (pointTracker > 100) {
          isCompleted = true
        }
      }

      return isCompleted
    }

    checkCompleteness(tasks) {
      console.log('checkCompleteness called ', tasks)

      if (this.state.pageSource === 'landingPage' && this.state.selectedPosting.isPromotional) {
        console.log('on planding page')
        if (this.state.signedIn) {
          // let applicationComplete = true
          // if (this.state.customAssessment && this.state.customAssessment.questions.length === this.state.responses.length) {
          //   this.setState({ applicationComplete })
          // } else if (!this.state.customAssessment) {
          //   this.setState({ applicationComplete })
          // }
          let applicationComplete = true
          if (tasks) {
            for (let i = 1; i <= tasks.length; i++) {
              console.log('compare task value: ', tasks[i - 1].shorthand, tasks[i - 1].isCompleted, tasks[i - 1].required)
              if (!tasks[i - 1].isCompleted && tasks[i - 1].required) {
                applicationComplete = false
              }
            }
          } else {
            console.log("something's wrong")
          }

          this.setState({ applicationComplete })
        }
      } else {
        console.log('checking...')
        let applicationComplete = true
        if (tasks) {
          for (let i = 1; i <= tasks.length; i++) {
            console.log('compare task value: ', tasks[i - 1].shorthand, tasks[i - 1].isCompleted, tasks[i - 1].required)
            if (!tasks[i - 1].isCompleted && tasks[i - 1].required) {
              applicationComplete = false
            }
          }
        } else {
          console.log("something's wrong")
        }

        this.setState({ applicationComplete })
      }
    }

    apply() {
      console.log('apply called')

      if (this.state.applicationComplete && this.state.emailId) {

        this.setState({ isSaving: true, errorMessage: null, successMessage: null })

        let errorSuffix = " under \"Confirm Basic Profile Info\""
        if (this.state.signUpFieldsRequired && this.state.signUpFieldsRequired.length > 0) {
          for (let i = 1; i <= this.state.signUpFieldsRequired.length; i++) {
            console.log('l1')
            const item = this.state.signUpFieldsRequired[i - 1]

            if (item.required) {
              console.log('l2')
              // multiple answer is array
              if (item.questionType === 'Multiple Answer' && (!this.state[item.shorthand] || this.state[item.shorthand].length === 0)) {
                return this.setState({ errorMessage: 'Please add answer(s) for ' + item.name + errorSuffix, isSaving: false })
              } else if (!item.shorthand.includes("|") && (!this.state[item.shorthand] || !this.state[item.shorthand] === '')) {
                return this.setState({ errorMessage: 'Please add an answer for ' + item.name + errorSuffix, isSaving: false })
              } else if (item.shorthand.includes("|") && (!this.state[item.shorthand.split("|")[0]] || this.state[item.shorthand.split("|")[0]].length === 0)) {
                return this.setState({ errorMessage: 'Please add answer(s) for the education fields' + errorSuffix, isSaving: false })
              } else if (item.shorthand.includes("|")) {
                console.log('l3')
                if (!this.state[item.shorthand.split("|")[0]] || this.state[item.shorthand.split("|")[0]].length === 0) {
                  return this.setState({ errorMessage: 'Please add answer(s) for the education fields' + errorSuffix, isSaving: false })
                } else if (!this.state[item.shorthand.split("|")[0]][0][item.shorthand.split("|")[1]]) {
                  return this.setState({ errorMessage: 'Please add answer(s) for each of the education fields' + errorSuffix, isSaving: false })
                }
                console.log('l4')
              }
            }
          }
        }

        let _id = null
        if (this.state.application) {
          if (this.state.application._id) {
            _id = this.state.application._id
          }
        }

        let email = this.state.emailId
        if (email && email !== '') {
          email = email.toLowerCase()
        }

        let username = this.state.username

        const postingId = this.state.selectedPosting._id
        const postingTitle = this.state.selectedPosting.title
        let postingEmployerName = ''
        if (this.state.selectedPosting.employerName) {
          postingEmployerName = this.state.selectedPosting.employerName
        } else {
          postingEmployerName = this.state.selectedPosting.orgName
        }
        const postingLocation = this.state.selectedPosting.location
        const pathways = this.state.selectedPosting.pathways
        const departments = this.state.selectedPosting.departments
        const selectedPrograms = this.state.selectedPosting.selectedPrograms

        const accountCode = this.state.selectedPosting.accountCode
        const applicationComponents = this.state.selectedPosting.applicationComponents
        const postType = this.state.selectedPosting.postType
        const subPostType = this.state.selectedPosting.subPostType
        const workflowType = this.state.selectedPosting.workflowType

        const applicationConfirmationSubjectLine = this.state.selectedPosting.applicationConfirmationSubjectLine
        const applicationConfirmationMessage = this.state.selectedPosting.applicationConfirmationMessage

        const workPreferenceResults = this.state.wpData
        const interestResults = this.state.interestResults
        const skillAnswers = this.state.skillAnswers
        const skillResults = this.state.skillResults
        const personalityResults = this.state.personalityResults
        const topGravitateValues = this.state.topGravitateValues
        const topEmployerValues = this.state.topEmployerValues
        let newSkillAnswers = this.state.newSkillAnswers

        const endorsements = this.state.endorsements
        const projects = this.state.projects
        const experience = this.state.experience
        const extras = this.state.extras

        let posterEmail = ''
        if (this.state.selectedPosting.posterEmail) {
          posterEmail = this.state.selectedPosting.posterEmail
        }

        const orgCode = this.state.activeOrg

        const postingOrgCode = this.state.postingOrgCode
        const postingOrgName = this.state.postingOrgName
        const postingOrgContactEmail = this.state.postingOrgContactEmail

        const politicalAlignment = this.state.politicalAlignment
        const stateRegistration = this.state.stateRegistration
        const currentCongressionalDistrict = this.state.currentCongressionalDistrict
        const hometown = this.state.hometown
        const homeCongressionalDistrict = this.state.homeCongressionalDistrict
        const dacaStatus = this.state.dacaStatus

        let newCustomAssessmentResults = null

        let customAssessmentResponses = this.state.customAssessmentResponses
        let caQuestions = this.state.caQuestions
        if (this.state.pageSource === 'landingPage' && this.state.selectedPosting.isPromotional) {
          customAssessmentResponses = this.state.responses
          caQuestions = this.state.customAssessment.questions
        } else {
          customAssessmentResponses = this.state.responses
        }

        if (customAssessmentResponses && caQuestions) {
          //convert to newCustomAssessmentResponses
          // console.log('show both cas: ', customAssessmentResponses, caQuestions)

          newCustomAssessmentResults = []
          for (let i = 1; i <= customAssessmentResponses.length; i++) {
            if (caQuestions[i - 1]) {
              console.log('show caResults: ', customAssessmentResponses[i - 1], caQuestions[i - 1])
              newCustomAssessmentResults.push({ question: caQuestions[i - 1].name, answer: customAssessmentResponses[i - 1] })
            } else {
              newCustomAssessmentResults.push({ question: 'Q' + i, answer: customAssessmentResponses[i - 1 ]})
            }
          }

        } else if (this.state.newCustomAssessmentResponses) {
          newCustomAssessmentResults = this.state.newCustomAssessmentResponses
        }

        let dealBreakerResponses = []
        let thirdPartyAssessmentResponses = []
        if (this.state.dealBreakers && this.state.dealBreakers.length > 0) {
          for (let i = 1; i <= this.state.dealBreakers.length; i++) {
            if (this.state.dbResponses[i - 1]) {
              dealBreakerResponses.push({
                question: this.state.dealBreakers[i - 1].name,
                answer: this.state.dbResponses[i - 1]
              })
            }
          }
        }
        if (this.state.thirdPartyAssessments && this.state.thirdPartyAssessments.length > 0) {
          for (let i = 1; i <= this.state.thirdPartyAssessments.length; i++) {
            if (this.state.tpaResponses[i - 1]) {
              thirdPartyAssessmentResponses.push({
                question: this.state.thirdPartyAssessments[i - 1].name,
                answer: this.state.tpaResponses[i - 1]
              })
            }
          }
        }

        let direct = false
        if (this.state.selectedPosting.direct) {
          direct = this.state.selectedPosting.direct
        }

        const pictureURL = this.state.pictureURL
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const resumeURL = this.state.resumeURL

        console.log('resumes: ', resumeURL, this.state.resumeName)
        const linkedInURL = this.state.linkedInURL
        let customWebsiteURL = this.state.customWebsiteURL
        if (customWebsiteURL && customWebsiteURL.includes('guidedcompass.com') && this.state.publicProfileExtent !== 'Public') {
          customWebsiteURL = null
        }

        const videoResumeURL = this.state.videoResumeURL
        const coverLetterURL = this.state.coverLetterURL
        const letterOfRecommendationURL = this.state.letterOfRecommendationURL
        const identificationURL = this.state.identificationURL
        const transcriptURL = this.state.transcriptURL

        let schoolName = this.state.schoolName
        let degree = this.state.degree
        let major = this.state.major
        let gradYear = this.state.gradYear
        let endDate = this.state.endDate
        const educationStatus = this.state.educationStatus
        const education = this.state.education
        if (education && education.length > 0) {
          let selectedEducation = null
          for (let i = 1; i <= education.length; i++) {
            if (education[i - 1].isContinual) {
              selectedEducation = education[i - 1]
            } else if (education[i - 1].endDate && education[i - 1].endDate.split(" ")) {
              const endYear = Number(education[i - 1].endDate.split(" ")[1])
              console.log('show endYear: ', endYear)
              if (!selectedEducation) {
                selectedEducation = education[i - 1]
              } else if (endYear > Number(selectedEducation.endDate.split(" ")[1])) {
                selectedEducation = education[i - 1]
              }
            }
          }
          if (selectedEducation) {
            if (selectedEducation.name) {
              schoolName = selectedEducation.name
            }
            if (selectedEducation.degree) {
              degree = selectedEducation.degree
            }
            if (selectedEducation.major) {
              major = selectedEducation.major
            }
            if (selectedEducation.endDate) {
              endDate = selectedEducation.endDate
              const endYear = Number(selectedEducation.endDate.split(" ")[1])
              gradYear = endYear
              console.log('show gradYear: ', gradYear)

            }
          }
        }

        const certificates = this.state.certificates
        const zipcode = this.state.zipcode
        const city = this.state.city
        const phoneNumber = this.state.phoneNumber
        const alternativeEmail = this.state.alternativeEmail
        const alternativePhoneNumber = this.state.alternativePhoneNumber

        const dateOfBirth = this.state.dateOfBirth
        const address = this.state.address
        const pathway = this.state.pathway
        const race = this.state.race
        const races = this.state.races
        const selfDescribedRace = this.state.selfDescribedRace
        const gender = this.state.gender
        const veteran = this.state.veteran
        const workAuthorization = this.state.workAuthorization
        const numberOfMembers = this.state.numberOfMembers
        const householdIncome = this.state.householdIncome
        const fosterYouth = this.state.fosterYouth
        const homeless = this.state.homeless
        const incarcerated = this.state.incarcerated
        const adversityList = this.state.adversityList

        const isVaccinated = this.state.isVaccinated

        const postingReferrerName = this.state.postingReferrerName
        const postingReferrerEmail = this.state.postingReferrerEmail
        const postingReferrerJobTitle = this.state.postingReferrerJobTitle
        const postingReferrerOrg = this.state.postingReferrerOrg

        const self = this
        function finishSaving(referralCode, referrerEmail) {
          console.log('finishSaving called')
          // const referralCode = this.state.referralCode

          // console.log('show expte')
          Axios.post('/api/applications', {
            _id, postingId, postingTitle, postingEmployerName, postingLocation,
            pathways, departments, selectedPrograms,
            firstName, lastName, email, username, educationStatus, education, schoolName, degree, major, gradYear, endDate,
            phoneNumber, alternativeEmail, alternativePhoneNumber, certificates, zipcode, city, pictureURL,
            resumeURL, coverLetterURL, linkedInURL, customWebsiteURL, videoResumeURL, letterOfRecommendationURL, identificationURL, transcriptURL,
            dateOfBirth, address, pathway, race, races, selfDescribedRace, gender, veteran, workAuthorization,
            numberOfMembers, householdIncome, fosterYouth, homeless, incarcerated, adversityList,
            customAssessmentResults: customAssessmentResponses, dealBreakerResponses, thirdPartyAssessmentResponses,
            newCustomAssessmentResults, direct, referralCode, referrerEmail,
            workPreferenceResults, interestResults, skillAnswers, newSkillAnswers, skillResults, personalityResults,
            topGravitateValues, topEmployerValues,
            applicationComponents, stage: 'Applied', isActive: true,
            postType, subPostType,
            workflowType, applicationConfirmationSubjectLine, applicationConfirmationMessage,
            orgCode, accountCode,
            politicalAlignment, stateRegistration, currentCongressionalDistrict, hometown, homeCongressionalDistrict, dacaStatus,
            postingOrgCode, postingOrgName, postingOrgContactEmail,
            endorsements, projects, experience, extras, posterEmail, isVaccinated,
            postingReferrerName, postingReferrerEmail, postingReferrerJobTitle, postingReferrerOrg,
            createdAt: new Date(), updatedAt: new Date()
          }).then((response) => {
            console.log('attempting to save')
            if (response.data.success) {
              console.log('saved successfully', response.data.message)

              let serverSuccessMessage = 'Application submitted successfully!'

              if (_id) {
                serverSuccessMessage = 'Application successfully updated!'
              }

              let tasks = self.state.tasks
              for (let i = 1; i <= tasks.length; i++) {
                tasks[i - 1]['isCompleted'] = false
                tasks[i - 1]['message'] = ''
              }

              const applicationSubmitted = true

              self.setState({
                serverSuccess: true, serverSuccessMessage, tasks, applicationSubmitted, isSaving: false
              })

              if (self.state.basicFormHasChanged) {

                const emailId = email
                const updatedAt = new Date()

                const userObject = {
                  emailId, firstName, lastName, educationStatus, education, school: schoolName, degree, major, gradYear,
                  phoneNumber, zipcode, city, pictureURL, certificates,
                  alternativePhoneNumber, alternativeEmail,
                  resumeURL, coverLetterURL, linkedInURL, customWebsiteURL, videoResumeURL, letterOfRecommendationURL, identificationURL, transcriptURL,
                  dateOfBirth, pathway, race, races, selfDescribedRace, gender, veteran, workAuthorization,
                  numberOfMembers, householdIncome, fosterYouth, homeless, incarcerated, adversityList, isVaccinated,
                  updatedAt
                }

                Axios.post('/api/users/profile/details', userObject)
                .then((response) => {

                  if (response.data.success) {
                    //save values
                    console.log('User update worked', response.data);

                  } else {
                    console.error('there was an error saving to user profile');
                    self.setState({ serverSuccess: false, serverErrorMessage: response.data.message, isSaving: false })
                  }
                }).catch((error) => {
                    console.log('Saving to user profile did not work', error);
                });
              }

            } else {
              console.log('did not save successfully', response.data)

              self.setState({
                serverSuccess: false,
                serverErrorMessage: 'error: ' + response.data.message.toString(), isSaving: false
              })
            }
          }).catch((error) => {
              console.log('save did not work', error);
              self.setState({
                serverSuccess: false,
                serverErrorMessage: 'There was an error submitting your application', isSaving: false
              })
          });
        }

        // const referrerEmail = this.state.referrerEmail
        if (this.state.referralCode) {
          // attempt to retrieve referral information

          const recipientEmail = email

          Axios.put('/api/referral', { _id: this.state.referralCode, postingId, recipientEmail })
          .then((response) => {
            console.log('Referral query attempted', response.data);

              if (response.data.success) {
                console.log('referral query worked for post')

                const referralCode = response.data.referral._id
                const referrerEmail = response.data.referral.referrerEmail
                finishSaving(referralCode, referrerEmail)

              } else {
                console.log('referral query did not work', response.data.message)
                // finishSaving(this.state.referralCode, this.state.referrerEmail)
                this.setState({ serverSuccess: false, serverErrorMessage: response.data.message, isSaving: false })
              }

          }).catch((error) => {
              console.log('Referral query did not work for some reason', error);
              finishSaving(this.state.referralCode, this.state.referrerEmail)
          });
        } else {
          console.log('no referral code')
          finishSaving(null, null)
        }

      } else {
        this.setState({
          serverSuccess: false,
          serverErrorMessage: 'All required information for the application has not yet been successfully imported!'
        })
      }
    }

    renderTasks() {
      console.log('renderTasks called check' )

      let rows = []

      for (let i = 1; i <= this.state.tasks.length; i++) {

        const index = i - 1

        let incompletedClass = "float-left top-margin-5"
        let completedClass = "float-left"
        if (window.innerWidth < 768) {
          incompletedClass = "calc-column-offset-58 top-margin-5"
          completedClass = "calc-column-offset-58"
        }

        rows.push(
          <div key={i} className="row-5">
            <div className="row-10">
              {(this.state.tasks[i - 1].isCompleted) ? (
                <div>
                  <button className="background-button clear-padding clear-margin" onClick={() => this.expandSection(index)}>
                    <div className="fixed-column-25 top-margin">
                      <img src={arrowIndicatorIcon} alt="GC" className={(this.state.tasks[index].isExpanded) ? "image-auto-15 rotate-90" : "image-auto-15"} />
                    </div>
                    <div className="fixed-column-33">
                      <img src={this.state.tasks[i - 1].icon} alt="GC" className="image-auto-20 top-margin" />
                    </div>
                    <div className={(this.state.tasks[i - 1].isCompleted) ? completedClass : incompletedClass} >
                      <div className="calc-column-offset-30 left-text">
                        <div className="half-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                        {(this.state.tasks[i - 1].required) ? (
                          <p className="heading-text-5">{this.state.tasks[i - 1].name} <label className="error-color bold-text">*</label></p>
                        ) : (
                          <p className="heading-text-5">{this.state.tasks[i - 1].name}{(this.state.tasks[i - 1].required) && " " + <label className="error-color bold-text">*</label>}</p>
                        )}
                      </div>
                      <div className="fixed-column-30">
                        <div className="half-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                        <img src={checkmarkIcon} alt="Compass checmkark icon" className="image-auto-22 left-margin"/>
                      </div>
                      <div className="clear" />
                    </div>
                  </button>


                </div>
              ) : (
                <div>
                  <button className="background-button clear-padding clear-margin left-text" onClick={() => this.expandSection(index)}>
                    <div className="fixed-column-25 top-margin">
                      <img src={arrowIndicatorIcon} alt="GC" className={(this.state.tasks[index].isExpanded) ? "image-auto-15 rotate-90" : "image-auto-15"} />
                    </div>
                    <div className="fixed-column-33">
                      <img src={this.state.tasks[i - 1].icon} alt="GC" className="image-auto-20 top-margin" />
                    </div>
                    <div className={(this.state.tasks[i - 1].isCompleted) ? completedClass : incompletedClass} >
                      <div className="full-width">
                        {(this.state.tasks[i - 1].required) ? (
                          <p className="heading-text-5 full-width">{this.state.tasks[i - 1].name} <label className="error-color bold-text">*</label></p>
                        ) : (
                          <p className="heading-text-5 full-width">{this.state.tasks[i - 1].name}</p>
                        )}
                      </div>
                    </div>
                    <div className="clear" />
                  </button>

                </div>
              )}

              <div className="clear" />
            </div>

            {(this.state.tasks[i - 1].isCompleted) ? (
              <div className="top-margin-negative-10 left-padding-20">
                {(this.state.tasks[i - 1].message !== '') && (
                  <p className="cta-color description-text-2 bold-text left-margin-5-percent top-margin">{this.state.tasks[i - 1].message}</p>
                )}
              </div>
            ) : (
              <div className="top-margin-negative-10 left-padding-20">
                {(this.state.tasks[i - 1].action === 'Upload') ? (
                  <div>
                    {(this.state.tasks[i - 1].message !== '') && (
                      <p className="error-color description-text-2 bold-text left-margin-5-percent top-margin">{this.state.tasks[i - 1].message}</p>
                    )}
                  </div>
                ) : (
                  <div>
                    {(this.state.tasks[i - 1].message !== '') && (
                      <div>
                        <div className="fixed-column-30">

                          <img src={xIcon} alt="Compass x icon" className="image-auto-16 top-margin"/>
                        </div>
                        <div className="calc-column-offset-30">
                          <p className="error-color description-text-2 bold-text left-margin-5-percent top-margin">{this.state.tasks[i - 1].message}.</p>
                        </div>
                        <div className="clear" />
                      </div>
                    )}
                    {/*
                    {(this.state.tasks[i - 1].message !== '') && (
                      <p className="error-color description-text-2 bold-text left-margin-5-percent top-margin">{this.state.tasks[i - 1].message}.</p>
                    )}*/}
                  </div>
                )}
              </div>
            )}

            {(this.state.tasks[i - 1].isExpanded) && (
              <div>
                {this.renderExpandedTask(this.state.tasks[index], index)}
              </div>
            )}

          </div>
        )
      }

      return rows
    }

    expandSection(index) {
      console.log('expandSection called', index)

      let tasks = this.state.tasks
      if (tasks[index].isExpanded) {
        tasks[index]['isExpanded'] = false
      } else {
        tasks[index]['isExpanded'] = true
      }
      this.setState({ tasks })
    }

    passData(name, value, index, source) {
      console.log('passData called', name, value, index, source)

      if (name === 'project') {
        console.log('in project')
        let projects = this.state.projects
        projects[index] = value
        this.setState({ projects })
      } else if (name === 'experience') {
        console.log('in experience')
        let experience = this.state.experience
        experience[index] = value
        this.setState({ experience })
      } else if (name === 'certificates') {
        console.log('in certificate')
        let certificates = this.state.certificates
        certificates[index] = value
        this.setState({ certificates })
      } else if (name === 'extra') {
        console.log('in extras')
        let extras = this.state.extras
        extras[index] = value
        this.setState({ extras })
      } else if (name === 'work preferences') {
        console.log('show wpValue: ', value)
        this.setState({ workPreferenceResults: value })

        const assessments = {
          workPreferences: value, interests: this.state.interestResults,
          skills: this.state.newSkillAnswers, personality: this.state.personalityResults,
          values: this.state.valuesResults
        }

        const isCompleted = this.checkSectionCompleteness('assessment', assessments, this.state.requiredCareerAssessments)
        const taskIndex = this.state.tasks.findIndex((element) => element.shorthand === 'careerAssessments')
        this.actionTapped(taskIndex, isCompleted)

      } else if (name === 'interests') {
        console.log('show interestsValue: ', value)
        this.setState({ interestResults: value })

        const assessments = {
          workPreferences: this.state.workPreferenceResults, interests: value,
          skills: this.state.newSkillAnswers, personality: this.state.personalityResults,
          values: this.state.valuesResults
        }

        const isCompleted = this.checkSectionCompleteness('assessment', assessments, this.state.requiredCareerAssessments)
        const taskIndex = this.state.tasks.findIndex((element) => element.shorthand === 'careerAssessments')
        this.actionTapped(taskIndex, isCompleted)
        // console.log('isCompleted called: ', isCompleted, taskIndex, this.state.tasks)

      } else if (name === 'skills') {
        console.log('show skillValue: ', value)
        this.setState({ newSkillAnswers: value })

        const assessments = {
          workPreferences: this.state.workPreferenceResults, interests: this.state.interestResults,
          skills: value, personality: this.state.personalityResults,
          values: this.state.valuesResults
        }

        const isCompleted = this.checkSectionCompleteness('assessment', assessments, this.state.requiredCareerAssessments)
        const taskIndex = this.state.tasks.findIndex((element) => element.shorthand === 'careerAssessments')
        this.actionTapped(taskIndex, isCompleted)
      } else if (name === 'personality') {
        console.log('show personalityValue: ', value)
        this.setState({ personalityResults: value })

        const assessments = {
          workPreferences: this.state.workPreferenceResults, interests: this.state.interestResults,
          skills: this.state.newSkillAnswers, personality: value,
          values: this.state.valuesResults
        }

        const isCompleted = this.checkSectionCompleteness('assessment', assessments, this.state.requiredCareerAssessments)
        const taskIndex = this.state.tasks.findIndex((element) => element.shorthand === 'careerAssessments')
        this.actionTapped(taskIndex, isCompleted)
      } else if (name === 'values') {
        console.log('show valueValue: ', value)
        if (value) {
          const topGravitateValues = value.topGravitateValues
          const topEmployerValues = value.topEmployerValues
          this.setState({ valuesResults: value, topGravitateValues, topEmployerValues })

          const assessments = {
            workPreferences: this.state.workPreferenceResults, interests: this.state.interestResults,
            skills: this.state.newSkillAnswers, personality: this.state.personalityResults,
            values: value
          }

          const isCompleted = this.checkSectionCompleteness('assessment', assessments, this.state.requiredCareerAssessments)
          const taskIndex = this.state.tasks.findIndex((element) => element.shorthand === 'careerAssessments')
          this.actionTapped(taskIndex, isCompleted)
        }
      } else {
        if (source === 'basic') {
          this.setState({ [name]: value, basicFormHasChanged: true })

          let basicInfo = {
            firstName: this.state.firstName, lastName: this.state.lastName,
            schoolName: this.state.schoolName, gradYear: this.state.gradYear, education: this.state.education,
            certificates: this.state.certificates
          }
          basicInfo[name] = value

          const isCompleted = this.checkSectionCompleteness('basicInfo', basicInfo, this.state.requiredBasicValues)
          const taskIndex = this.state.tasks.findIndex((element) => element.shorthand === 'basicInfo')
          this.actionTapped(taskIndex, isCompleted)


        } else {
          this.setState({ [name]: value })
        }
      }
    }

    renderExpandedTask(task, index) {
      console.log('renderExpandedTask called', task, index)

      if (task.shorthand === 'basicInfo') {
        // schoolName, major, gradYear, linkedIn, github, portfolioLink, phoneNumber,

        return (
          <div key={"expandedTask" + index} className="left-padding-20 top-padding">
            <SubEditProfileDetails passedType="Basic" passData={this.passData} />
          </div>
        )
      } else if (task.shorthand === 'profileDetails') {
        return (
          <div key={"expandedTask" + index} className="left-padding-20 top-padding">
            <SubEditProfileDetails passedType="Details" passData={this.passData}/>
          </div>
        )

      } else if (task.shorthand === 'careerAssessments') {
        return (
          <div key={"expandedTask" + index} className="left-padding-20 top-padding">
            <SubAssessments email={this.state.emailId} passData={this.passData} benchmarkId={this.state.selectedPosting.benchmarkId} tracks={this.state.selectedPosting.tracks} />
          </div>
        )
      } else if (task.shorthand === 'endorsements') {
        return (
          <div key={"expandedTask" + index} className="left-padding-20 top-padding">
            <SubEndorsements email={this.state.emailId} history={this.props.navigate} passData={this.passData} selectedOpportunity={this.state.selectedPosting}/>
          </div>
        )
      } else if (task.shorthand === 'resume') {
        return (
          <div key={"expandedTask" + index} className="left-padding-20 top-padding">
            {(this.state.tasks[index].message !== '' && this.state.tasks[index].name === 'Add Resume Website URL') ? (
              <div className="flex-container row-direction">
                <div className="flex-5" />
                <div className="flex-80">
                  <div className="spacer"/><div className="half-spacer"/>
                  <input className="text-field" type="text" placeholder="add a resume link" name="resumeURL" value={this.state.resumeURL} onChange={this.formChangeHandler} />
                </div>
                <div className="flex-15" />
              </div>
            ) : (
              <div>
                <div>
                  {(this.state.tasks[index].action === 'Upload') ? (
                    <div>
                      {(this.state.disableUploadingResume) ? (
                        <div className="row-10">
                          <label className="profile-label">Craft New Resume Using the Resume Builder</label>

                          <Link className="clear-border" to={'/app/builders'} target="_blank" rel="noopener noreferrer"><button className="btn btn-squarish">Create</button></Link>

                        </div>
                      ) : (
                        <div className="row-10">
                          <label className="profile-label">Submit New Resume</label>

                          <div className="bottom-padding">
                            <p className="description-text-2 row-5">[Only PDF files are accepted. Please convert Word files to PDF.]</p>
                          </div>

                          <label for={"file-upload-" + index} class="custom-file-upload-squarish">Upload New</label>
                          <input type="file" id={"file-upload-" + index} name={this.state.tasks[index].shorthand} onChange={this.formChangeHandler} accept="application/pdf" />
                        </div>
                      )}
                      {(this.state.resumes && this.state.resumes.length > 0) && (
                        <div>
                          <div className="row-10">
                            <label className="bold-text description-text-3 description-text-color top-padding">OR</label>
                          </div>
                          <div className="bottom-padding">
                            <label className="profile-label">Submit Existing Resume from Profile</label>
                            <div className="full-width">
                              <select name={'resumeName'} value={this.state.resumeName} className="dropdown" onChange={this.formChangeHandler}>
                                {this.state.resumeNames.map(value2 =>
                                  <option key={value2} value={value2}>{value2}</option>
                                )}
                              </select>
                            </div>
                            {/*
                            <div className="fixed-column-80">
                              <button className="btn btn-squarish" onClick={() => console.log('add')}>Submit</button>
                            </div>*/}
                            <div className="clear" />

                          </div>
                        </div>
                      )}

                    </div>
                  ) : (
                    <div className="fixed-column-70 right-text right-padding">
                      <button className="btn btn-squarish" onClick={() => this.actionTapped(index)}>{this.state.tasks[index].action}</button>
                    </div>
                  )}
                </div>
                <div className="clear" />
              </div>
            )}
          </div>
        )
      } else if (task.shorthand === 'coverLetter' || task.shorthand === 'identification' || task.shorthand === 'transcript' || task.shorthand === 'letterOfRecommendation' ) {
        return (
          <div key={this.state.tasks[index].shorthand + index} className="left-padding-20 top-padding">
          <div className="bottom-padding">
            <p className="description-text-2 row-5">[Only PDF files are accepted. Please convert Word files to PDF.]</p>
          </div>
            <div>
              <div className="fixed-column-70 right-text right-padding">
                {(this.state.tasks[index].action === 'Upload') ? (
                  <div>
                    <label for={"file-upload-" + index} class="custom-file-upload-squarish">{this.state.tasks[index].action}</label>
                    <input type="file" id={"file-upload-" + index} name={this.state.tasks[index].shorthand} onChange={this.formChangeHandler} accept={(task.shorthand === 'identification') ? ".pdf,image/*" : "application/pdf"} />
                  </div>
                ) : (
                  <button className="btn btn-squarish" onClick={() => this.actionTapped(index)}>{this.state.tasks[index].action}</button>
                )}
              </div>
              <div className="clear" />
            </div>
          </div>
        )
      } else if (task.shorthand === 'thirdPartyAssessments') {
        return (
          <div key={"expandedTask" + index} className="left-padding-20 top-padding">
            <div className="row-10">
              {this.renderQuestions(task.shorthand)}
            </div>
          </div>
        )
      } else if (task.shorthand === 'dealBreakers') {
        return (
          <div key={"expandedTask" + index} className="left-padding-20 top-padding">
            <div className="row-10">
              {this.renderQuestions(task.shorthand)}
            </div>
          </div>
        )
      } else if (task.shorthand === 'vaccinations') {
        return (
          <div key={"expandedTask" + index} className="left-padding-20 top-padding">
            <div className="row-10">
              <p>This position requests documented proof of COVID-19 vaccination or a qualifying exemption letter (medical or religious). Will you be able to provide this documentation upon hiring?</p>
              <div>
                <div>
                  <div className="float-left row-5 right-padding">
                    <button className={(this.state.isVaccinated === true) ? "background-button row-5 horizontal-padding-5 rounded-corners cta-border cta-background-color" : "background-button row-5 horizontal-padding-5 rounded-corners cta-border"} onClick={() => this.actionTapped(index, true, true)}>
                      <div>
                        <div className="float-left left-text">
                          <p className={(this.state.isVaccinated === true) ? "description-text-2 white-text" : "description-text-2"}>Yes</p>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="float-left row-5 right-padding">
                    <button className={(this.state.isVaccinated === false) ? "background-button row-5 horizontal-padding-5 rounded-corners cta-border cta-background-color" : "background-button row-5 horizontal-padding-5 rounded-corners cta-border"} onClick={() => this.actionTapped(index, true, false) }>
                      <div>
                        <div className="float-left left-text">
                          <p className={(this.state.isVaccinated === false) ? "description-text-2 white-text" : "description-text-2"}>No</p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      } else if (task.shorthand === 'referrals') {
        return (
          <div key={"expandedTask|" + task.shorthand + index} className="left-padding-20 top-padding">
            <div className="row-10">
              <p>If you were referred for this posting, please add information about the referrer.</p>
              <div>
                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Who referred you? [Name of Referrer]<label className="error-color">*</label></label>
                    <input className="text-field" type="text" placeholder="(e.g., Jon Jones)" name="postingReferrerName" value={this.state.postingReferrerName} onChange={this.formChangeHandler} />
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Email of Referrer<label className="error-color">*</label></label>
                    <input className="text-field" type="text" placeholder="(e.g., jonjones@email.com)" name="postingReferrerEmail" value={this.state.postingReferrerEmail} onChange={this.formChangeHandler}/>
                  </div>
                  <div className="clear" />
                </div>
                <div className="row-10">
                  <div className="container-left">
                    <label className="profile-label">Job Title of Referrer<label className="error-color">*</label></label>
                    <input className="text-field" type="text" placeholder="(e.g., Professor)" name="postingReferrerJobTitle" value={this.state.postingReferrerJobTitle} onChange={this.formChangeHandler} />
                  </div>
                  <div className="container-right">
                    <label className="profile-label">Organization of Referrer<label className="error-color">*</label></label>
                    <input className="text-field" type="text" placeholder="(e.g., Jones University)" name="postingReferrerOrg" value={this.state.postingReferrerOrg} onChange={this.formChangeHandler} />
                  </div>

                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>
        )
      } else if (task.shorthand === 'customAssessment') {
        return (
          <div key={"expandedTask" + index} className="left-padding-20 top-padding">
            <div className="row-10">
              {this.renderQuestions(task.shorthand)}
            </div>
          </div>
        )
      }
    }

    renderQuestions(taskShorthand) {
      console.log('renderQuestions called', taskShorthand)

      let items = []
      let responses = this.state.responses
      if (taskShorthand === 'thirdPartyAssessments') {
        // console.log('t0', this.state.thirdPartyAssessments)
        items = this.state.thirdPartyAssessments
        responses = this.state.tpaResponses
      } else if (taskShorthand === 'dealBreakers') {
        items = this.state.dealBreakers
        responses = this.state.dbResponses
      } else if (taskShorthand === 'customAssessment') {
        if (this.state.customAssessment) {
          items = this.state.customAssessment.questions
          responses = this.state.responses
        }
      }

      let rows = []
      if (items && items.length > 0) {
        console.log('show items: ', items)
        for (let i = 1; i <= items.length; i++) {
          const index = i - 1
          const question = items[index]
          const response = responses[index]
          // console.log('looping: ', i - 1, question, response)
          rows.push(
            <div key={"question|" + i} className="left-text row-10">
              {(question.prompt) && (
                <div>
                  <p className="heading-text-5 half-bold-text">{question.prompt}</p>

                  <div className="spacer" />
                  <hr />
                  <div className="spacer" /><div className="spacer" /><div className="spacer" />
                </div>
              )}

              <div>
                <label className="half-bold-text">{i}. {question.name} <label className="error-color">*</label></label>
                <div className="clear"/>
                <div className="spacer"/><div className="half-spacer"/>
              </div>

              {(question.questionType === 'Short Answer') && (
                <div>
                  <input className="text-field" type="text" placeholder={(question.placeholder) ? question.placeholder : "Write your answer..."} name={"listedAnswer|shortAnswer|" + index} value={response} onChange={this.formChangeHandler} />
                </div>
              )}

              {(question.questionType === 'Long Answer') && (
                <div>
                  <textarea className="text-field" type="text" placeholder={(question.placeholder) ? question.placeholder : "Write your answer..."} name={"listedAnswer|longAnswer|" + index} value={response} onChange={this.formChangeHandler} />
                </div>
              )}

              {(question.questionType === 'Email') && (
                <div>
                  <input className="text-field" type="text" placeholder="Write your email..." name={"listedAnswer|email|" + index} value={response} onChange={this.formChangeHandler} />
                </div>
              )}

              {(question.questionType === 'Birthdate') && (
                <div>
                  <input className="text-field" type="text" placeholder="Write your birthdate..." name={"listedAnswer|birthdate|" + index} value={response} onChange={this.formChangeHandler} />
                </div>
              )}

              {(question.questionType === 'Multiple Choice') && (
                <div>
                  {this.renderAnswerChoices('multipleChoice', index, taskShorthand)}
                </div>
              )}

              {(question.questionType === 'Linear Scale') && (
                <div className="top-margin bottom-margin-20">
                  <div className="flex-container flex-space-between row-direction description-text-2">
                    <button className="background-button" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|linearScale|" + index, value: question.answerChoices[0]}}) }>
                      <div className="width-20 height-20 circle-radius white-background dark-border">
                        {(response === question.answerChoices[0]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                      </div>
                      <p>{(question.answerChoices[0]) ? question.answerChoices[0] : "Least/most value"}</p>
                    </button>
                    <button className="background-button" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|linearScale|" + index, value: question.answerChoices[1]}}) }>
                      <div className="width-20 height-20 circle-radius white-background dark-border center-item">
                        {(response === question.answerChoices[1]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                      </div>
                      <p className="center-text">{(question.answerChoices[1]) ? question.answerChoices[1] : "Lower Mid value"}</p>
                    </button>
                    <button className="background-button" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|linearScale|" + index, value: question.answerChoices[2]}}) }>
                      <div className="width-20 height-20 circle-radius white-background dark-border center-item">
                        {(response === question.answerChoices[2]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                      </div>
                      <p className="center-text">{(question.answerChoices[2]) ? question.answerChoices[2] : "Mid value"}</p>
                    </button>
                    <button className="background-button" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|linearScale|" + index, value: question.answerChoices[3]}}) }>
                      <div className="width-20 height-20 circle-radius white-background dark-border center-item">
                        {(response === question.answerChoices[3]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                      </div>
                      <p className="center-text">{(question.answerChoices[3]) ? question.answerChoices[3] : "Higher Mid value"}</p>
                    </button>
                    <button className="background-button" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|linearScale|" + index, value: question.answerChoices[4]}}) }>
                      <div className="width-20 height-20 circle-radius white-background dark-border pin-right">
                        {(response === question.answerChoices[4]) && <div className="width-10 height-10 circle-radius primary-background left-margin-4 top-margin-4" />}
                      </div>
                      <p className="right-text">{(question.answerChoices[4]) ? question.answerChoices[4] : "Least/most value"}</p>
                    </button>

                    {/*
                    <div className="width-20 height-20 circle-radius medium-background dark-border" />
                    <div className="width-20 height-20 circle-radius medium-background dark-border" />
                    <div className="width-20 height-20 circle-radius medium-background dark-border" />
                    <div className="width-20 height-20 circle-radius medium-background dark-border" />*/}
                  </div>
                  <div className="top-margin-negative-40">
                    <div className="dark-border-bottom top-margin height-1 full-width" />
                  </div>
                </div>
              )}

              {(question.questionType === 'Checkbox') && (
                <div>
                  {this.renderAnswerChoices('checkbox', index, taskShorthand)}
                </div>
              )}

              {(question.questionType === 'Ranking') && (
                <div>
                  <p className="description-text-1 description-text-color">Drag and drop options into the order you like.</p>
                  <div className="spacer" /><div className="spacer" />
                  {this.renderRankingChoices(index)}
                </div>
              )}

              {(question.questionType === 'Number Ranges') && (
                <div>
                  <input className="number-field" type="number" placeholder="0" name={"listedAnswer|shortAnswer|" + index + "|" + taskShorthand} value={response} onChange={this.formChangeHandler} />
                </div>
              )}

              <div className="spacer" /><div className="spacer" /><div className="spacer" /><div className="half-spacer" />
            </div>
          )
        }
      }

      return rows
    }

    renderAnswerChoices(type, passedIndex, taskShorthand) {
      console.log('renderAnswerChoices called', type, passedIndex, taskShorthand)

      let rows = []

      let questionIndex = passedIndex

      let answerChoices = []
      let screening = false
      let responses = this.state.responses

      if (taskShorthand === 'thirdPartyAssessments') {
        // console.log('t0', this.state.thirdPartyAssessments)
        screening = true
        answerChoices = this.state.thirdPartyAssessments[questionIndex].screeningAnswerChoices
        responses = this.state.tpaResponses
      } else if (taskShorthand === 'dealBreakers') {
        screening = true
        answerChoices = this.state.dealBreakers[questionIndex].screeningAnswerChoices
        responses = this.state.dbResponses
      } else if (taskShorthand === 'customAssessment') {
        if (this.state.customAssessment) {
          answerChoices = this.state.customAssessment.questions[questionIndex].answerChoices
          if (this.state.customAssessment.type === 'Screening') {
            screening = true
            answerChoices = this.state.customAssessment.questions[questionIndex].screeningAnswerChoices
            responses = this.state.responses
          }
        }
      }

      if (type === 'multipleChoice') {

        if (answerChoices) {

          rows.push(
            <div key={answerChoices + passedIndex} className="top-padding">
              {(answerChoices) && (
                <div>
                  {answerChoices.map((value, optionIndex) =>
                    <div key={passedIndex}>
                      <div className="float-left row-5 right-padding">
                        {(screening) ? (
                          <div>
                            {(responses[passedIndex] === answerChoices[optionIndex].name) ? (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border cta-background-color" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex + "|" + taskShorthand, value: answerChoices[optionIndex].name}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 white-text">{value.name}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex + "|" + taskShorthand, value: answerChoices[optionIndex].name }}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2">{value.name}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {(responses[passedIndex] === answerChoices[optionIndex]) ? (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border cta-background-color" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[optionIndex]}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 white-text">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleChoice|" + passedIndex, value: answerChoices[optionIndex]}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                    </div>
                  )}
                  <div className="clear"/>
                </div>
              )}
            </div>
          )
        }

      } else if (type === 'checkbox') {

        if (answerChoices) {

          rows.push(
            <div key={answerChoices + passedIndex} className="top-padding">
              {(answerChoices) && (
                <div>
                  {answerChoices.map((value, optionIndex) =>
                    <div key={passedIndex}>
                      <div className="float-left row-5 right-padding">

                        {(screening) ? (
                          <div>
                            {(Array.isArray(responses[passedIndex]) && responses[passedIndex].includes(answerChoices[optionIndex].name)) ? (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border cta-background-color" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleAnswer|" + passedIndex, value: answerChoices[optionIndex].name}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 white-text">{value.name}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleAnswer|" + passedIndex, value: answerChoices[optionIndex].name}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2">{value.name}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {(Array.isArray(responses[passedIndex]) && responses[passedIndex].includes(answerChoices[optionIndex])) ? (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border cta-background-color" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleAnswer|" + passedIndex, value: this.state.customAssessment.questions[passedIndex].answerChoices[optionIndex]}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2 white-text">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <button className="background-button row-5 horizontal-padding-5 rounded-corners cta-border" onClick={() => this.formChangeHandler({ target: { name: "listedAnswer|multipleAnswer|" + passedIndex, value: answerChoices[optionIndex]}}) }>
                                  <div>
                                    <div className="float-left left-text">
                                      <p className="description-text-2">{value}</p>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                    </div>
                  )}
                  <div className="clear"/>
                </div>
              )}
            </div>
          )
        }
      }

      return rows
    }

    renderRankingChoices(passedIndex) {
      console.log('renderRankingChoices called', passedIndex)

      let rankingAnswerChoices = this.state.rankingAnswerChoices
      let droppableId = '111'
      rankingAnswerChoices = this.state.customAssessment.questions[passedIndex].answerChoices
      droppableId = this.state.customAssessment.questions[passedIndex]._id

      if (this.state.responses[passedIndex] && Array.isArray(this.state.responses[passedIndex])) {
        rankingAnswerChoices = this.state.responses[passedIndex]
      }

      console.log('show droppableId: ', droppableId)

      return (
        <div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <StrictModeDroppable droppableId={droppableId}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {rankingAnswerChoices.map((item, index) => (
                    <Draggable key={item} draggableId={item} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {item}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </div>
      )
    }

    onDragEnd(result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      console.log('show result: ', result)
      let answerChoices = []
      let questionIndex = 0
      for (let i = 1; i <= this.state.customAssessment.questions.length; i++) {
        console.log('compare the two: ',this.state.customAssessment.questions[i - 1]._id, result.destination.droppableId)
        if (this.state.customAssessment.questions[i - 1]._id === result.destination.droppableId) {
          answerChoices = this.state.customAssessment.questions[i - 1].answerChoices
          questionIndex = i - 1
          console.log('did this work?', answerChoices, questionIndex)
        }
      }

      const rankingAnswerChoices = reorder( answerChoices, result.source.index, result.destination.index );

      let customAssessment = this.state.customAssessment
      customAssessment['questions'][questionIndex]['answerChoices'] = rankingAnswerChoices

      let responses = this.state.responses
      responses[questionIndex] = rankingAnswerChoices

      this.setState({ customAssessment, responses })
    }

    // optionClicked(index, optionIndex) {
    //   console.log('optionClicked called', index, optionIndex)
    //
    //   let questions = this.state.questions
    //
    //   if (questions[index].answer) {
    //     if (questions[index]['answer'].includes(questions[index].options[optionIndex])) {
    //       const removeIndex = questions[index]['answer'].indexOf(questions[index].options[optionIndex])
    //       questions[index]['answer'].splice(removeIndex, 1)
    //
    //     } else {
    //       questions[index]['answer'].push(questions[index].options[optionIndex])
    //     }
    //   } else {
    //     questions[index]['answer'] = [questions[index].options[optionIndex]]
    //   }
    //
    //   this.setState({ questions })
    //
    // }

    optionClicked(index, optionIndex, value, value2) {
      console.log('optionClicked called', index, optionIndex, value, value2)

      let items = this.state[value.shortname]
      if (items) {
        if (items.includes(value2)) {
          const index = items.indexOf(value2)
          items.splice(index, 1)
        } else {
          items.push(value2)
        }
      } else {
        items = [value2]
      }

      this.setState({ [value.shortname]: items })

    }

    switchWorkspaces() {
      console.log('switchWorkspaces called')

      let myOrgs = localStorage.getItem('myOrgs');
      console.log('show myOrgs: ', myOrgs)
      if (myOrgs && myOrgs.includes(this.state.selectedPosting.orgCode)) {

        this.setState({ serverSuccessMessage: null, serverErrorMessage: null })

        const emailId = this.state.emailId
        const activeOrg = this.state.selectedPosting.orgCode
        const updatedAt = new Date()

        Axios.post('/api/users/profile/details', {
          emailId, activeOrg, updatedAt })
        .then((response) => {

          if (response.data.success) {
            //save values
            console.log('Org switch worked', response.data);

            localStorage.setItem('activeOrg', activeOrg)
            this.setState({ activeOrg })
            this.props.passActiveOrg(activeOrg)

          } else {
            console.error('there was an error switching the orgs');

          }
        }).catch((error) => {
            console.log('Org switch did not work', error);
        });
      } else {
        this.props.navigate('/app/add-workspaces/'+ this.state.selectedPosting.orgCode + '/' + this.state.selectedPosting._id)
      }
    }

    renderDemoQuestions() {
      console.log('renderDemoQuestions called')

      let questions = this.state.questions

      if (questions) {
        let rows = []
        let spaceCounter = 0
        for (let i = 1; i <= this.state.questions.length; i++) {
          const value = this.state.questions[i - 1]
          const index = i - 1

          let showQuestion = true
          if (value.questionType !== 'Multiple Answer' && value.questionType !== 'Checkbox') {
            // console.log('in it: ', value.name, spaceCounter)
            if (value.rule) {
              if (value.rule.name === 'races' && this.state.races && this.state.races.includes(value.rule.value)) {
                spaceCounter = spaceCounter + 1
              } else {
                showQuestion = false
              }
            } else {
              spaceCounter = spaceCounter + 1
            }
          }

          rows.push(
            <div key={'renderSignUpFields' + index}>
              {(showQuestion) && (
                <div>
                  <div className={(value.questionType === 'Multiple Answer' || value.questionType === 'Checkbox') ? "full-width" : "container-left"}>
                    <div>
                      <label className="profile-label">{value.name}{(value.required) && <label className="error-color bold-text">*</label>}</label>
                      {(value.questionType === 'Date' || value.questionType === 'Birthdate') && (
                        <input type="date" className="date-picker white-background" name={value.shortname} value={this.state[value.shortname]} onChange={this.formChangeHandler}></input>
                      )}
                      {(value.questionType === 'Short Answer') && (
                        <input type="text" className="text-field" placeholder={(value.placeholder) ? value.placeholder : "Your answer..."} name={value.shortname} value={this.state[value.shortname]} onChange={this.formChangeHandler}></input>
                      )}
                      {(value.questionType === 'Multiple Choice') && (
                        <select name={value.shortname} className="dropdown" value={this.state[value.shortname]} onChange={this.formChangeHandler}>
                            {[''].concat(value.answerChoices).map(value => <option key={value} value={value}>{value}</option>)}
                        </select>
                      )}
                      {(value.questionType === 'Multiple Answer' || value.questionType === 'Checkbox') && (
                        <div>
                          {value.answerChoices.map((value2, optionIndex) =>
                            <div key={value2 + optionIndex}>
                              <div className="top-margin-5 right-padding">
                                {(this.state[value.shortname] && this.state[value.shortname].includes(value2)) ? (
                                  <button className="background-button tag-container-6 float-left cta-background-color bottom-margin right-margin" onClick={() => this.optionClicked(index, optionIndex, value, value2)}>
                                    <div>
                                      <div className="float-left left-text">
                                        <p className="description-text-2 white-text">{value2}</p>
                                      </div>
                                    </div>
                                  </button>
                                ) : (
                                  <button className="background-button tag-container-6 float-left bottom-margin right-margin" onClick={() => this.optionClicked(index, optionIndex, value, value2)}>
                                    <div>
                                      <div className="float-left left-text">
                                        <p className="description-text-2">{value2}</p>
                                      </div>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}


                    </div>
                  </div>
                  {(value.questionType === 'Multiple Answer' || value.questionType === 'Checkbox' || spaceCounter % 2 === 0) && (
                    <div>
                      <div className="clear" />
                      <div className="spacer" />
                    </div>
                  )}
                  {(this.state.questions[i] && (this.state.questions[i].questionType === 'Multiple Answer' || this.state.questions[i].questionType === 'Checkbox')) && (
                    <div>
                      <div className="clear" />
                      <div className="spacer" />
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        }

        return rows
      }

      // {this.state.questions.map((value, index) =>
      //   <div key={index}>
      //     <div className="container-left">
      //       <div className="row-2-half-percent">
      //         <p className="row-5">{value.name} <label className="error-color bold-text">*</label></p>
      //
      //         {(value.questionType === 'Date' || value.questionType === 'Birthdate') && (
      //           <input type="date" className="date-picker white-background" name={value.shorthand} value={this.state[value.shorthand]} onChange={this.formChangeHandler}></input>
      //         )}
      //         {(value.questionType === 'Short Answer') && (
      //           <input type="text" className="text-field" placeholder={(value.placeholder) ? value.placeholder : "Your answer..."} name={value.shorthand} value={this.state[value.shorthand]} onChange={this.formChangeHandler}></input>
      //         )}
      //         {(value.questionType === 'Multiple Choice') && (
      //           <select name={value.shorthand} className="dropdown" value={this.state[value.shorthand]} onChange={this.formChangeHandler}>
      //               {[''].concat(value.answerChoices).map(value => <option key={value} value={value}>{value}</option>)}
      //           </select>
      //         )}
      //         {(value.questionType === 'Multiple Answer' || value.questionType === 'Checkbox') && (
      //           <div>
      //             {value.answerChoices.map((value2, optionIndex) =>
      //               <div key={value2 + optionIndex}>
      //                 <div className="top-margin-5 right-padding">
      //                   {(this.state[value.shorthand] && this.state[value.shorthand].includes(value2)) ? (
      //                     <button className="background-button tag-container-6 float-left cta-background-color" onClick={() => this.optionClicked(index, optionIndex, value, value2)}>
      //                       <div>
      //                         <div className="float-left left-text">
      //                           <p className="description-text-2 white-text">{value2}</p>
      //                         </div>
      //                       </div>
      //                     </button>
      //                   ) : (
      //                     <button className="background-button tag-container-6 float-left" onClick={() => this.optionClicked(index, optionIndex, value, value2)}>
      //                       <div>
      //                         <div className="float-left left-text">
      //                           <p className="description-text-2">{value2}</p>
      //                         </div>
      //                       </div>
      //                     </button>
      //                   )}
      //                 </div>
      //               </div>
      //             )}
      //           </div>
      //         )}
      //         {/*
      //         {(value.type === 'Date') && (
      //           <input type="date" className="date-picker" name={'question|' + index} value={value.answer} onChange={this.formChangeHandler}></input>
      //         )}
      //         {(value.type === 'Short Answer') && (
      //           <input className="text-field" type="text" placeholder={(value.placeholder) ? value.placeholder : "Write your answer..."} name={'question|' + index} value={value.answer} onChange={this.formChangeHandler} />
      //         )}
      //         {(value.type === 'Long Answer') && (
      //           <textarea className="text-field" type="text" placeholder={(value.placeholder) ? value.placeholder : "Write your answer..."} name={'question|' + index} value={value.answer} onChange={this.formChangeHandler} />
      //         )}
      //         {(value.type === 'Multiple Choice') && (
      //           <select name={'question|' + index} value={value.answer} className="dropdown" onChange={this.formChangeHandler}>
      //             {this.state.questions[index].options.map(value2 =>
      //               <option key={value2} value={value2}>{value2}</option>
      //             )}
      //           </select>
      //         )}
      //         {(value.type === 'Multiple Answer') && (
      //           <div>
      //             {this.state.questions[index].options.map((value, optionIndex) =>
      //               <div key={value + optionIndex}>
      //                 <div className="top-margin-5 right-padding">
      //                   {(this.state.questions[index].answer && this.state.questions[index].answer.includes(value)) ? (
      //                     <button className="background-button tag-container-6 float-left cta-background-color" onClick={() => this.optionClicked(index, optionIndex)}>
      //                       <div>
      //                         <div className="float-left left-text">
      //                           <p className="description-text-2 white-text">{value}</p>
      //                         </div>
      //                       </div>
      //                     </button>
      //                   ) : (
      //                     <button className="background-button tag-container-6 float-left" onClick={() => this.optionClicked(index, optionIndex)}>
      //                       <div>
      //                         <div className="float-left left-text">
      //                           <p className="description-text-2">{value}</p>
      //                         </div>
      //                       </div>
      //                     </button>
      //                   )}
      //                 </div>
      //               </div>
      //             )}
      //           </div>
      //         )}*/}
      //       </div>
      //     </div>
      //   </div>
      // )}
      //
      // <div className="clear" />

    }
    render() {

        return (
            <div>

                { this.state.selectedPosting && (
                  <div>
                      <br/>
                      <div>
                        {this.state.selectedPosting.postType === 'Track' ? (
                          <h2>Apply to join the {this.state.selectedPosting.title}</h2>
                        ) : (
                          <div>
                            {(this.state.selectedPosting.employerName) ? (
                              <div>
                                {(this.state.activeOrg === 'exp') ? (
                                  <h2>Please Complete the {this.state.selectedPosting.title}</h2>
                                ) : (
                                  <h2>{(this.state.application) ? "Update Your Application for " : "Apply for"} {this.state.selectedPosting.title}{(this.state.selectedPosting.employerName && this.state.selectedPosting.employerName !== 'No Employer Selected') && " @ " + this.state.selectedPosting.employerName}</h2>
                                )}
                              </div>
                            ) : (
                              <h2>Apply to {this.state.selectedPosting.title}</h2>
                            )}
                          </div>
                        )}
                      </div>

                      {(this.state.selectedPosting.orgCode === this.state.activeOrg || (this.state.placementPartners && this.state.placementPartners.includes(this.state.activeOrg)) || (this.state.postingOrgCode === 'sandbox') || this.state.selectedPosting.isPromotional) ? (
                        <div>
                          {(this.state.application) && (
                            <div className="full-width padding-20">
                              <p className="center-text error-color bold-text description-text-2">You have already submitted this application, but you can update at any time. To update, you must import and confirm all parts of the application and then re-submit.</p>
                            </div>
                          )}

                          <div className="top-padding-20 left-padding-20">
                            <p className="description-text-2"><label className="bold-text error-color">*</label> Asterisks indicate that this component is required to submit.</p>
                          </div>

                          {(this.state.pageSource === 'landingPage' && this.state.selectedPosting.isPromotional) ? (
                            <div className="padding-20">
                              <div className="flex-container row-direction row-10">
                                <div className="flex-5 top-margin">
                                  <p className="heading-text-5">1.</p>
                                </div>

                                <div className="flex-container flex-80 row-direction top-margin">
                                  <p className="heading-text-5">Create an Account</p>

                                  {(this.state.signedIn) && (
                                    <div>
                                      <div className="half-spacer"/>
                                      <img src={checkmarkIcon} alt="Compass checmkark icon" className="image-auto-22 left-margin"/>
                                    </div>
                                  )}

                                </div>
                                <div className="flex-container flex-15 column-direction right-text">
                                  {(this.state.sectionOneIsOpen) ? (
                                    <button className="btn btn-primary" onClick={() => this.setState({ sectionOneIsOpen: false })}>Collapse</button>
                                  ) : (
                                    <button className="btn btn-primary" onClick={() => this.setState({ sectionOneIsOpen: true })}>Start</button>
                                  )}

                                </div>
                              </div>

                              {(this.state.sectionOneIsOpen) && (
                                <div className="row-5">

                                  {(this.state.signedIn) ? (
                                    <div>
                                      <p>You are currently signed in</p>

                                      <div className="bottom-padding">
                                        <button className="background-button cta-color" onClick={() => this.signOutUser()}>
                                          <p>Click Here to Sign Out</p>
                                        </button>
                                      </div>

                                    </div>
                                  ) : (
                                    <div>
                                      {(this.state.showSignIn) ? (
                                        <div>
                                          <p className="description-text-4">New to Guided Compass?</p>

                                          <button className="background-button cta-color" onClick={() => this.setState({ showSignIn: false })}>
                                            <p>Sign Up</p>
                                          </button>

                                          <div className="row-5">
                                              <div className="container-left">
                                                  <label className="profile-label">Email</label>
                                                  <input className="text-field" type="text" placeholder="Email" name="email" value={this.state.emailId} onChange={this.formChangeHandler} />
                                              </div>
                                              <div className="container-right">
                                                  <label className="profile-label">Password</label>
                                                  <input className="number-field" type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.formChangeHandler}/>
                                              </div>
                                              <div className="clear" />
                                          </div>

                                          {(this.state.serverSuccessMessage) && <p className="success-message bottom-padding-20">{this.state.serverSuccessMessage}</p>}
                                          {(this.state.serverErrorMessage) && <p className="error-message bottom-padding-20">{this.state.serverErrorMessage}</p>}

                                          <div className="row-10">
                                            <button className="btn btn-primary" onClick={() => this.signInUser()}>Sign In</button>
                                          </div>

                                        </div>
                                      ) : (
                                        <div>
                                          <p className="description-text-4">Already registered?</p>

                                          <button className="background-button cta-color" onClick={() => this.setState({ showSignIn: true })}>
                                            <p>Sign In</p>
                                          </button>
                                          <div className="row-5">
                                              <div className="container-left">
                                                  <label className="profile-label">First Name<label className="error-color">*</label></label>
                                                  <input className="text-field" type="text" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={this.formChangeHandler} />
                                              </div>
                                              <div className="container-right">
                                                  <label className="profile-label">Last Name<label className="error-color">*</label></label>
                                                  <input className="text-field" type="text" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.formChangeHandler}/>
                                              </div>
                                              <div className="clear" />
                                          </div>

                                          <div className="row-5">
                                              <div className="container-left">
                                                  <label className="profile-label">Email<label className="error-color">*</label></label>
                                                  <input className="text-field" type="text" placeholder="Email" name="email" value={this.state.emailId} onChange={this.formChangeHandler} />
                                              </div>
                                              <div className="container-right">
                                                  <label className="profile-label">Password<label className="error-color">*</label></label>
                                                  <input className="number-field" type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.formChangeHandler}/>
                                              </div>
                                              <div className="clear" />
                                          </div>

                                          {(this.state.selectedPosting.orgCode === 'unite-la' || (this.state.selectedPosting.placementPartners && this.state.selectedPosting.placementPartners.includes('unite-la'))) && (
                                            <div className="bottom-margin">
                                              <div className="row-20">
                                                <hr />

                                                <div className="top-margin-20">
                                                  <p className="bottom-margin description-text-2">The following demographic questions are for our reporting purposes only:</p>
                                                </div>

                                              </div>

                                              {this.renderDemoQuestions()}
                                              <div className="clear" />
                                            </div>
                                          )}

                                          {(this.state.serverSuccessMessage) && <p className="success-message bottom-padding-20">{this.state.serverSuccessMessage}</p>}
                                          {(this.state.serverErrorMessage) && <p className="error-message bottom-padding-20">{this.state.serverErrorMessage}</p>}

                                          <div className="row-10">
                                            <button className="btn btn-primary" onClick={() => this.signUpUser()}>Register</button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}

                                  <div className="spacer" />

                                </div>
                              )}

                              <hr />



                              <div className="flex-container row-direction row-10">
                                <div className="flex-5 top-margin">
                                  <p className="heading-text-5">2.</p>
                                </div>

                                <div className="flex-container flex-80 row-direction top-margin">
                                  <p className="heading-text-5">Complete the Application</p>
                                  {/*
                                  {(this.state.customAssessment && this.state.responses.length === this.state.customAssessment.questions.legth) && (
                                    <div>
                                      <div className="half-spacer"/>
                                      <img src={checkmarkIcon} alt="Compass checmkark icon" className="image-auto-22 left-margin"/>
                                    </div>
                                  )}*/}
                                  {(this.state.applicationComplete) && (
                                    <div className="fixed-column-30">
                                      <div className="half-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/><div className="mini-spacer"/>
                                      <img src={checkmarkIcon} alt="Compass checmkark icon" className="image-auto-22 left-margin"/>
                                    </div>
                                  )}
                                </div>
                                <div className="flex-container flex-15 column-direction right-text">
                                  {(this.state.sectionThreeIsOpen) ? (
                                    <button className="btn btn-primary" onClick={() => this.setState({ sectionThreeIsOpen: false })}>Collapse</button>
                                  ) : (
                                    <button className={(this.state.signedIn) ? "btn btn-primary" : "btn btn-primary medium-background standard-border"} disabled={(this.state.emailId) ? false : true} onClick={() => this.setState({ sectionThreeIsOpen: true })}>Start</button>
                                  )}
                                </div>
                              </div>

                              {(this.state.sectionThreeIsOpen) && (
                                <div className="row-10">
                                  {this.renderTasks()}
                                  {/*
                                  {this.renderQuestions()}*/}
                                </div>
                              )}

                              <hr />

                            </div>
                          ) : (
                            <div className="padding-20">
                              {this.renderTasks()}
                            </div>
                          )}

                          {(this.state.selectedPosting.allowReferrals !== false && !this.state.remoteAuth) && (
                            <div className="full-width row-20 left-padding-20">

                              {(this.state.showReferralCode) ? (
                                <div className="cta-border padding-20 top-margin">
                                  <div>
                                    <div className="calc-column-offset-20">
                                      <label className="profile-label">Referral Code</label>
                                    </div>
                                    <div className="fixed-column-20">
                                      <button className="background-button pin-right" onClick={() => this.setState({ showReferralCode: false })}>
                                        <img src={closeIcon} alt="GC" className="image-auto-14 pin-right top-margin" />
                                      </button>
                                    </div>
                                    <div className="clear" />
                                  </div>

                                  <input className="text-field" type="text" placeholder="Add your referral code..." name="referralCode" value={this.state.referralCode} onChange={this.formChangeHandler} />
                                  <div className="spacer" />
                                </div>
                              ) : (
                                <p className="full-width">Were you referred? <button className="background-button cta-color bold-text" onClick={() => this.setState({ showReferralCode: true })}>Click here</button> to use your referral code.</p>
                              )}
                            </div>
                          )}

                          <hr />

                          <div className="full-width row-20 right-padding-20">
                            {(this.state.errorMessage && this.state.errorMessage!== '') && <p className="error-message bottom-padding-20">{this.state.errorMessage}</p>}
                            { (this.state.serverSuccess) ? (
                              <div>
                                {(this.state.serverSuccessMessage) && <p className="success-message bottom-padding-20">{this.state.serverSuccessMessage}</p>}
                              </div>
                            ) : (
                              <div>
                                {(this.state.serverErrorMessage) && <p className="error-message bottom-padding-20">{this.state.serverErrorMessage}</p>}
                              </div>
                            )}

                            {(this.state.isSaving) ? (
                              <div className="left-padding-20">
                                <button disabled={true} className="btn btn-primary medium-background standard-border medium-border-color" onClick={() => this.apply()}>..saving...</button>
                              </div>
                            ) : (
                              <div className="left-padding-20">
                                {(this.state.applicationSubmitted) ? (
                                  <div>
                                    <Link className="clear-border" to={'/app/opportunities'}><button className="btn btn-squarish">Browse Other Postings</button></Link>
                                  </div>
                                ) :(
                                  <div>
                                    {(this.state.applicationComplete) ? (
                                      <div>
                                        <button className="btn btn-primary" onClick={() => this.apply()}>{this.state.application ? "Update" : "Submit"}</button>
                                      </div>
                                    ) : (
                                      <div>
                                        <button className="btn btn-primary medium-background standard-border medium-border-color" onClick={() => this.apply()}>{this.state.application ? "Update" : "Submit"}</button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}

                          </div>
                        </div>
                      ) : (
                        <div className="row-20">
                          <p className="error-color">You must apply for this opportunity in the {this.state.selectedPosting.orgName} community. Click <button className="background-button cta-color" onClick={() => this.switchWorkspaces()}>here</button> to switch communities.</p>
                        </div>
                      )}

                  </div>
                )}
            </div>
        )
    }
}

export default withRouter(Apply);
